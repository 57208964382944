import React from 'react';
import { Step } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledStepGroup = styled(Step.Group).attrs({
  size: 'small',
  unstackable: true,
})`
  ${({
    theme: {
      color: {
        borderColor,
        primary,
        stepBgActive,
        stepBgDone,
        stepFgDisabled,
        stepIconActive,
        tileFg,
      },
    },
  }) => `
    &&& {
      margin-bottom: 0;
      overflow-x: auto;

      a.step {
        background-color: ${stepBgDone};
        color: ${stepFgDisabled};
        border-color: ${borderColor};

        .title,
        .description {
          color: ${stepFgDisabled};
        }

        &:hover {
          .icon {
            color: ${stepIconActive};
          }
          .title {
            color: ${primary};
          }
          .description {
            color: ${tileFg};
          }
        }

        &::after {
          background-color: ${stepBgDone};
          border-color: ${borderColor};
        }
      }

      div.step {
        background-color: ${stepBgDone};
        border-color: ${borderColor};

        &.disabled,
        .title,
        .description {
          color: ${stepFgDisabled};
        }

        &.active {
          background-color: ${stepBgActive};

          .icon {
            color: ${stepIconActive};
          }

          .title {
            color: ${primary};
          }

          .description {
            color: ${tileFg};
          }

          &::after {
            background-color: ${stepBgActive};
          }
        }

        &::after {
          background-color: ${stepBgDone};
          border-color: ${borderColor};
        }
      }
    }
  `}
`;

const BatchSteps = ({ t, step, moveTo }) => (
  <StyledStepGroup>
    <Step
      active={step === 1 || undefined}
      onClick={step > 1 ? () => moveTo(1) : undefined}
      icon="search"
      title={t('batch|Search')}
      description={t('batch|Filter forms')}
    />
    <Step
      active={step === 2 || undefined}
      disabled={step < 2 || undefined}
      onClick={step > 2 ? () => moveTo(2) : undefined}
      icon="check circle outline"
      title={t('batch|Action')}
      description={t('batch|Choose action')}
    />
    <Step
      active={step === 3 || undefined}
      disabled={step < 3 || undefined}
      icon="flag checkered"
      title={t('batch|Perform')}
      description={t('batch|Finish process')}
    />
  </StyledStepGroup>
);

export default BatchSteps;
