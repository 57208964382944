import React from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { SYSTEM_FIELD_IDS, MENU_IDS } from 'core/utils/constant';
import { getTemplates } from 'core/template/templateReducer';
import { ContextMenuTrigger } from 'components';
import { getCellData } from '../helpers/getRowData';

const StyledRow = styled(
  ({ className, admin, children, attributes, viewed, ...props }) =>
    admin ? (
      <tr className={className}>{children}</tr>
    ) : (
      <ContextMenuTrigger
        renderTag="tr"
        attributes={{
          ...attributes,
          className: `${attributes.className} ${className}`,
        }}
        {...props}
      >
        {children}
      </ContextMenuTrigger>
    )
)`
  ${({
    viewed,
    admin,
    theme: {
      color: { primary },
    },
  }) =>
    !admin &&
    !viewed &&
    `
      &&&& {
        box-shadow: inset 2px 0 0 ${primary};
      }
    `}
`;

const StyledTableCell = styled(({ children, cellStyle, ...props }) => (
  <Table.Cell {...props}>{children}</Table.Cell>
))`
  ${({ cellStyle }) =>
    cellStyle &&
    `
      &&& {
        color: ${cellStyle.color};
        background-color: ${cellStyle.backgroundColor};
      }
    `}
`;

const handleDoubleClick = (area, module, history, key, admin) => {
  if (!admin) {
    const urlNav = Utils.buildUrlNav(area, module, key);
    history.push(urlNav);
  }
};

const TableRowData = ({
  r,
  table,
  activeItem,
  handleRowClick,
  area,
  module,
  history,
  admin,
  templates,
}) => {
  let cells = [];
  let i = 0;

  if (table && table.columns) {
    cells = table.columns
      .sort((c1, c2) => c1.position - c2.position)
      .map(c => {
        return {
          index: c.columnID ? c.columnID : ++i,
          data: getCellData(
            r,
            (SYSTEM_FIELD_IDS.hasOwnProperty(c.columnID) &&
              SYSTEM_FIELD_IDS[c.columnID]) ||
              c.key,
            c.type
          ),
          position: c.position,
          style: _.find(r.cellDesigns, { key: c.key }),
        };
      });
  }

  // Disable actions for data with exceptional template
  const actionable = _.chain(templates)
    .map('name')
    .includes(r.formLayoutName)
    .value();

  return (
    <StyledRow
      id={MENU_IDS.MENU_TABLE_GRID_ROW}
      viewed={r.viewed}
      attributes={{
        className: activeItem[0] === r.key ? 'active' : '',
        onClick: () => handleRowClick(r.key, actionable),
        onDoubleClick: actionable
          ? () => handleDoubleClick(area, module, history, r.key, admin)
          : undefined,
      }}
      admin={admin}
      info={r.key}
      callbackOnShow={() => handleRowClick(r.key, actionable)}
    >
      {cells.map(c => (
        <StyledTableCell key={c.index} collapsing cellStyle={c.style}>
          {!r.viewed ? <b>{c.data}</b> : c.data}
        </StyledTableCell>
      ))}
    </StyledRow>
  );
};

const mapStateToProps = state => ({
  templates: getTemplates(state),
});

export default connect(
  mapStateToProps,
  null
)(TableRowData);
