import { createSelector } from 'reselect';
import { THEME } from 'core/utils/constant';

export const CHANGE_THEME = 'CHANGE_THEME';

export const ADD_BACKGROUND = 'ADD_BACKGROUND';
export const REMOVE_BACKGROUND = 'REMOVE_BACKGROUND';

export const CENTER_BACKGROUND = 'CENTER_BACKGROUND';
export const TOP_BACKGROUND = 'TOP_BACKGROUND';
export const BOTTOM_BACKGROUND = 'BOTTOM_BACKGROUND';
export const LEFT_BACKGROUND = 'LEFT_BACKGROUND';
export const RIGHT_BACKGROUND = 'RIGHT_BACKGROUND';

export const UPDATE_BACKGROUND_STORE = 'UPDATE_BACKGROUND_STORE';

export const bgActionCreators = {
  useTheme: theme => ({ type: CHANGE_THEME, value: theme }),
  addBg: () => ({ type: ADD_BACKGROUND }),
  removeBg: () => ({ type: REMOVE_BACKGROUND }),
  centerBg: () => ({ type: CENTER_BACKGROUND }),
  topBg: () => ({ type: TOP_BACKGROUND }),
  bottomBg: () => ({ type: BOTTOM_BACKGROUND }),
  leftBg: () => ({ type: LEFT_BACKGROUND }),
  rightBg: () => ({ type: RIGHT_BACKGROUND }),
  updateBgStore: bg => ({ type: UPDATE_BACKGROUND_STORE, value: bg }),
};

const initialState = {
  customBackground: false,
  theme: THEME.light,
  customBackgroundPosition: 'center',
};

export const backgroundReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_THEME:
      return { ...state, theme: action.value };
    case ADD_BACKGROUND:
      return { ...state, customBackground: true };
    case REMOVE_BACKGROUND:
      return { ...state, customBackground: false };
    case CENTER_BACKGROUND:
      return { ...state, customBackgroundPosition: 'center' };
    case TOP_BACKGROUND:
      return { ...state, customBackgroundPosition: 'top' };
    case BOTTOM_BACKGROUND:
      return { ...state, customBackgroundPosition: 'bottom' };
    case LEFT_BACKGROUND:
      return { ...state, customBackgroundPosition: 'left' };
    case RIGHT_BACKGROUND:
      return { ...state, customBackgroundPosition: 'right' };
    case UPDATE_BACKGROUND_STORE:
      const { customBackground, customBackgroundPosition } = action.value;
      return { ...state, customBackground, customBackgroundPosition };
    default:
      return state;
  }
};

// SELECTORS
const selectorBackground = state => state.background;

export const getBackground = createSelector(
  [selectorBackground],
  background => background
);
