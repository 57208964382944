import _ from 'lodash';

import getTableMaskData from '../../tables/helpers/getTableMaskData';

const keyIsProtected = (protectedKeys, key) => {
  return protectedKeys.includes(key);
};

// prevent table filtering on calendar start and end columns
const filterAgendaTableColumns = table => {
  // find column keys
  const { calendarStart, calendarEnd } = getTableMaskData(table);
  const protectedKeys = [calendarStart, calendarEnd];

  // add preventSort and preventFilter properties
  const protectedColumns = _.map(
    _.filter(table.columns, c => keyIsProtected(protectedKeys, c.key)),
    pc => ({ ...pc, preventSort: true, preventFilter: true })
  );
  const otherColumns = _.filter(
    table.columns,
    c => !keyIsProtected(protectedKeys, c.key)
  );

  // rebuild columns
  const newColumns = [...otherColumns, ...protectedColumns];

  // sort and return
  return _.sortBy(newColumns, c => c.position);
};

export { filterAgendaTableColumns };
