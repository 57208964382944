import React from 'react';
import { Grid } from 'semantic-ui-react';

import CalendarPicker from '../../input/calendar/CalendarPicker';

const Toolbar = ({
  // props
  selectedDate,
  // func
  handleChangeDate,
}) => {
  const onChangeDate = (e, { value }) => {
    handleChangeDate(value);
  };

  return (
    <Grid stackable columns="equal" verticalAlign="middle">
      <Grid.Column floated="left" />
      <Grid.Column textAlign="center" />
      <Grid.Column floated="right" textAlign="right">
        <CalendarPicker
          fluid
          fallback
          value={selectedDate}
          onChange={onChangeDate}
        />
      </Grid.Column>
    </Grid>
  );
};

export default Toolbar;
