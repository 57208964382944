import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import withSelections from 'core/utils/withSelections';
import {
  //Center,
  ThemedTable,
  //ThemedLabel,
  ContextMenuTrigger,
  //ThemedPagination,
} from 'components';

import RoundInfo from './components/round-info/RoundInfo';
import TimelineProgress from './components/progress/timelineProgressContainer';
import { ThemedHeaderCellHeader } from '../../../theme/ThemedTable';
import { showSingleRow } from '../../../tables/components/TableRow';
import SpinnerSegment from '../../../segments/SpinnerSegment';

//const HeaderCellMiddle = styled.div`
//  display: flex;
//  align-items: center;
//  justify-content: space-between;
//`;

//const Footer = styled.div`
//  display: grid;
//  grid-template-columns: 1fr 1fr 1fr;
//  align-items: center;
//`;

const { Header, Row, Cell, Body } = Table;

const TimelineTable = withSelections(
  ({ dataLoading, view, data, activeItem, handleRowClick, ...props }) => {
    const { t } = useTranslation();
    const { start, end } = view || {};
    const timeStart =
      start && Number.isInteger(start) && start > 0 && start < 23 ? start : 9;
    const timeEnd =
      end && Number.isInteger(end) && end > 1 && end < 24 ? end : 19;

    const timeCells = [];

    for (let i = timeStart; i < timeEnd + 1; i++) {
      timeCells.push(
        <ThemedHeaderCellHeader
          textAlign="center"
          key={i}
        >{`${Utils.toHourString(i)}:00`}</ThemedHeaderCellHeader>
      );
    }

    return (
      <ThemedTable selectable celled={false} sortable={false}>
        <Header>
          <Row>
            {/*<HeaderCell collapsing verticalAlign="middle">
              <HeaderCellMiddle>
                <Form.Field
                  control={Checkbox}
                  checked={areAllSelected(data)}
                  indeterminate={areAllIndeterminate(data)}
                  onChange={() => handleSelectAll(data)}
                />
              </HeaderCellMiddle>
            </HeaderCell>*/}
            <ThemedHeaderCellHeader collapsing />
            {timeCells}
          </Row>
        </Header>
        <Body>
          {dataLoading && (
            <tr>
              <Cell colSpan={25}>
                <SpinnerSegment />
              </Cell>
            </tr>
          )}
          {!dataLoading &&
            !(data && data.length > 0) &&
            showSingleRow(25, t('No records found'))}
          {!dataLoading &&
            data &&
            data.length > 0 &&
            _.map(data, d => (
              <ContextMenuTrigger
                id={d.name}
                key={d.name}
                renderTag="tr"
                attributes={{
                  className: activeItem === d.name ? 'active' : '',
                  onClick: () => {
                    handleRowClick(d.name);
                  },
                }}
                callbackOnShow={() => handleRowClick(d.id)}
              >
                {/*<Cell collapsing>
                <Form.Field
                  control={Checkbox}
                  checked={isItemSelected(d.id)}
                  onChange={() => handleSelect(d.id)}
                />
              </Cell>*/}
                <Cell>
                  <RoundInfo data={d} />
                </Cell>
                <TimelineProgress {...props} t={t} view={view} data={d} />
                {/*<Cell />*/}
              </ContextMenuTrigger>
            ))}
        </Body>
      </ThemedTable>
    );
  }
);

export default TimelineTable;
