import { Views } from 'react-big-calendar';
import _ from 'lodash';

import { LOCALES } from 'core/utils/constant';

import {
  getStartDateDay,
  getEndDateDay,
  getEndDateWeek,
  getStartDateWeek,
  getStartDateMonth,
  getEndDateMonth,
} from './calendarDate';
import { setCriterion } from '../../tables/helpers/tableCriteriaHelpers';

const { DAY, WEEK, MONTH } = Views;
const supportedViews = { DAY, WEEK, MONTH };

// return whether local language is French
const langIsFrench = () => {
  return localStorage.getItem('faster-lang') === LOCALES.fr;
};

// get valid agenda view from string value
const formatView = view => {
  let formattedView = undefined;

  if (view && _.has(supportedViews, view)) {
    formattedView = Views[view];
  } else if (view && _.includes(_.values(supportedViews), view)) {
    formattedView = view;
  }

  return formattedView || WEEK;
};

// find start and end dates from specified calendar view and date
const computeDateRange = (date, view) => {
  let startDate, endDate;
  const ifFrenchFormat = langIsFrench();

  const validView = formatView(view);

  if (validView === WEEK) {
    startDate = getStartDateWeek(date, ifFrenchFormat);
    endDate = getEndDateWeek(date, ifFrenchFormat);
  } else if (validView === MONTH) {
    startDate = getStartDateMonth(date);
    endDate = getEndDateMonth(date);
  } else {
    startDate = getStartDateDay(date);
    endDate = getEndDateDay(date);
  }

  const dateRange = `${startDate}|${endDate}`;

  return { startDate, endDate, dateRange };
};

const computeDayDateRange = date => {
  return computeDateRange(date, DAY);
};

// add filter by date range to table criteria
const initializeAgendaCriteria = (agenda, criteria) => {
  const { calendarStart, calendarEnd, view } = agenda;
  const { dateRange } = computeDateRange(new Date(), view);

  setCriterion(criteria, { key: calendarStart, value: dateRange });

  if (calendarEnd) {
    setCriterion(criteria, { key: calendarEnd, value: dateRange });
  }
};

export {
  langIsFrench,
  computeDateRange,
  computeDayDateRange,
  formatView,
  supportedViews,
  initializeAgendaCriteria,
};
