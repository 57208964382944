import styled from 'styled-components';
import { FIELD_TYPE } from 'core/utils/constant';
import { IMAGE_TYPES, typesAsString } from 'core/utils/mimeTypes';

const InputFileHidden = styled.input.attrs({
  type: FIELD_TYPE.file,
})`
  &&&&&& {
    opacity: 0;
    height: 0;
    width: 0;
    padding: 0 !important;
    float: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

const InputFileImageHidden = styled(InputFileHidden).attrs({
  accept: typesAsString(IMAGE_TYPES),
})``;

export { InputFileHidden, InputFileImageHidden };
