import React from 'react';
import _ from 'lodash';

import FasterItem from './FasterItem';

const FasterList = ({ handleClick, items, dataArray }) =>
  items.map(({ key, ...o }) => {
    const active = _.findIndex(dataArray, { ...o }) >= 0 ? true : undefined;

    return (
      <FasterItem
        key={key}
        active={active}
        item={{ ...o }}
        handleClick={handleClick}
      />
    );
  });

export default FasterList;
