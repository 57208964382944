import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import _ from 'lodash';

import addressApi from 'api/address/addressApi';
import { ThemedSearch, ThemedSegment } from 'components';

const initialState = { isLoading: false, results: [], value: '' };

const resultRenderer = ({ title }) => title;

resultRenderer.propTypes = {
  title: PropTypes.string,
};

const searchReducer = (state, action) => {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState;
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query };
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results };
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection };
    default:
      throw new Error();
  }
};

const WAIT_INTERVAL = 300;

const InputAddress = () => {
  const [state, dispatch] = useReducer(searchReducer, initialState);
  const { loading, results, value } = state;

  const timeoutRef = useRef();

  const buildQuery = (
    data = {},
    { focus = null, bRect = null, bCircle = null, bCountry = null }
  ) => {
    const params = {};
    if (focus) {
      params['focus.point.lat'] = focus.latitude;
      params['focus.point.lon'] = focus.longitude;
    }
    if (bRect) {
      params['boundary.rect.min_lon'] = bRect[0];
      params['boundary.rect.min_lat'] = bRect[1];
      params['boundary.rect.max_lon'] = bRect[2];
      params['boundary.rect.max_lat'] = bRect[3];
    }
    if (bCircle) {
      params['boundary.circle.lat'] = bCircle[0];
      params['boundary.circle.lon'] = bCircle[1];
      params['boundary.circle.radius'] = bCircle[2];
    }
    if (bCountry) {
      params['boundary.country'] = bCountry;
    }
    return _.assign({}, data, params);
  };

  const handleResultSelect = (e, { result }) => {
    dispatch({ type: 'UPDATE_SELECTION', selection: result.title });
  };

  const handleSearchChange = useCallback((e, { value }) => {
    clearTimeout(timeoutRef.current);
    dispatch({ type: 'START_SEARCH', query: value });

    timeoutRef.current = setTimeout(() => {
      if (value.length === 0) {
        dispatch({ type: 'CLEAN_QUERY' });
        return;
      }

      const data = buildQuery({ text: value }, {});

      addressApi.autocomplete(data).then(res => {
        if (res.data && res.data.features) {
          const { features } = res.data;

          const results = _.chain(features)
            .uniqBy(({ properties: { label } }) => label)
            .map(({ properties: { label } }) => ({ title: label }))
            .value();

          dispatch({ type: 'FINISH_SEARCH', results });
        }
      });
    }, WAIT_INTERVAL);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <Form>
      <Form.Field>
        <label>Champ de type addresse</label>
        <ThemedSearch
          loading={loading}
          results={results}
          value={value}
          onResultSelect={handleResultSelect}
          onSearchChange={handleSearchChange}
          resultRenderer={resultRenderer}
        />
      </Form.Field>
      <ThemedSegment>
        <pre style={{ overflowX: 'auto' }}>
          {JSON.stringify({ loading, results, value }, null, 2)}
        </pre>
      </ThemedSegment>
    </Form>
  );
};

export default InputAddress;
