import React from 'react';
import { Table } from 'semantic-ui-react';

import { ButtonLink, ThemedTableFooter } from 'components';

const FilterTableFooter = ({ t, options, handleClickFunc }) => (
  <ThemedTableFooter>
    <Table.Row>
      <Table.HeaderCell colSpan="10">
        {options.length > 0 && (
          <ButtonLink
            text={t('common|Add')}
            handleClickFunc={handleClickFunc}
            icon
            iconName="plus"
          />
        )}
      </Table.HeaderCell>
    </Table.Row>
  </ThemedTableFooter>
);

export default FilterTableFooter;
