import styled, { createGlobalStyle } from 'styled-components';

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const CalendarPopupWrapper = createGlobalStyle`
 ${({
   theme: {
     color: {
       agendaBgAccent,
       agendaBorder,
       agendaCellBg,
       agendaCellSelectedBg,
       agendaCellBorder,
       agendaCellBorderColor,
       agendaFgInverted,
     },
   },
   color,
 }) => `
    .rbc-overlay  .rbc-event {
      background-color: ${color || agendaCellBg};

      &.rbc-selected {
        background-color: ${color || agendaCellSelectedBg};
      }

      &.rbc-event-allday {
        border: ${agendaCellBorder};
        border-color: ${color || agendaCellBorderColor};
      }

      .rbc-event-content span {
        color: ${agendaFgInverted};
      }
    }
  `}
`;

const CalendarWrapper = styled.div`
  ${({
    color,
    theme: {
      color: {
        agendaBgAccent,
        agendaButtonFg,
        agendaButtonFgHover,
        agendaButtonBg,
        agendaButtonBgHover,
        agendaBorder,
        agendaBorderBold,
        agendaBorderColor,
        agendaBorderColorHover,
        agendaCellBg,
        agendaCellBorder,
        agendaCellBorderColor,
        agendaCellSelectedBg,
        agendaFg,
        agendaFgInverted,
        agendaTimeSlotBorder,
        agendaTodayBg,
        tableBg,
        primary,
      },
    },
  }) => `
    &&& {
      color: ${agendaFg};
      height: 670px;

      a,
      span {
        color: ${agendaButtonFg};
      }

      button {
        color: ${agendaButtonFg};
        background-color: ${agendaButtonBg};
        border-color: ${agendaBorderColor};

        &:hover,
        &:active,
        &:focus {
          color: ${agendaButtonFgHover};
          background-color: ${agendaButtonBgHover};
          border-color: ${agendaBorderColorHover};
        }
      }

      .rbc-month-view {
        border: ${agendaBorder};
        border-radius: .28571429rem;
      }

      .rbc-time-view {
        border: ${agendaBorder};
        border-radius: .28571429rem;
      }

      .rbc-agenda-view table.rbc-agenda-table {
        border: ${agendaTimeSlotBorder};

        td {
          border-left: ${agendaTimeSlotBorder};
        }
      }

      .rbc-month-row + .rbc-month-row {
        border-top: ${agendaBorder};
      }

      .rbc-day-bg + .rbc-day-bg {
        border-left: ${agendaBorder};
      }

      .rbc-day-slot {
        .rbc-time-slot {
          border-top: ${agendaTimeSlotBorder};
        }

        .rbc-event {
          border: ${agendaCellBorder};
        }
      }

      .rbc-event {
        background-color: ${color || agendaCellBg};

        &.rbc-selected {
          background-color: ${color || agendaCellSelectedBg};
        }

        &.rbc-event-allday {
          border: ${agendaCellBorder};
          border-color: ${color || agendaCellBorderColor};
        }

        .rbc-event-content span {
          color: ${agendaFgInverted};
        }

        .rbc-event-label {
          display: none;
        }
      }

      .rbc-header {
        color: ${agendaFg};
        border-bottom: ${agendaBorder};

        + .rbc-header {
          border-left: ${agendaBorder};
        }
      }

      .rbc-off-range-bg {
        background-color: ${agendaBgAccent};
      }

      .rbc-time-content {
        border-top: ${agendaBorderBold};

        > * + * > * {
          border-left: ${agendaBorder};
        }
      }

      .rbc-time-header-content {
        border-left: ${agendaBorder};
      }

      .rbc-timeslot-group {
        border-bottom: ${agendaBorder};
      }

      .rbc-today {
        background-color: ${color ? hex2rgba(color, 0.2) : agendaTodayBg};
      }

      .rbc-day-bg.selected {
        box-shadow: inset 0px 0 0px 1px ${primary} !important;
      }

      .rbc-day-slot.selected {
        box-shadow: inset 0px 0 0px 2px ${primary} !important;
      }

      .rbc-allday-cell {
        max-height: 40.2px;
        overflow-x: auto;
        -ms-overflow-style: none; /*in IE and Edge*/
        scrollbar-width: none; /*in Firefox*/
        // in Chrome
        ::-webkit-scrollbar {
          display: none;
        }

        .rbc-row-content {
          background: ${tableBg};
        }
      }
    }
  `}
`;

export { CalendarWrapper, CalendarPopupWrapper };
