import BaseApi from 'api/baseApi';
import { SYSTEM_FIELDS, OPERATOR, FIELD_TYPE } from 'core/utils/constant';
import getQueryString from 'core/utils/tableData/getQueryString';

const tableDataApi = {
  buildQuery: (tableKey, type, criteria) => {
    const baseUrlArray = ['tabledata'];

    if (type) {
      baseUrlArray.push(type);
    }

    const query = getQueryString(criteria);

    return `${baseUrlArray.join('/')}/${tableKey || ''}${query}`;
  },

  getMany: (query, ids, table, dataType, dataKey, documentID, params) => {
    if (ids && ids.length > 0) {
      const filter = {
        key: SYSTEM_FIELDS.formid,
        operator: OPERATOR.in,
        value: ids.join('|'),
        type: FIELD_TYPE.number,
      };

      // Post id filter
      return BaseApi.post(query, {
        filter,
        ...params,
      });
    } else if (dataType) {
      // Post table structure of admin to query
      return BaseApi.post(query, {
        dataType,
        dataKey,
        table,
        ...params,
      });
    } else {
      if (documentID) {
        query = `${query}&documentID=${documentID}`;
      }

      // Get table data
      return BaseApi.get(query, params);
    }
  },
};

export default tableDataApi;
