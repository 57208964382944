import { createSelector } from 'reselect';
import _ from 'lodash';

export const DISPLAY_PROD = 'DISPLAY_PROD';
export const DISPLAY_NOTPROD = 'DISPLAY_NOTPROD';
export const SHOW_ROUTE = 'SHOW_ROUTE';
export const HIDE_ROUTE = 'HIDE_ROUTE';
export const TOGGLE_PRINT = 'TOGGLE_PRINT';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const UPDATE_SETTINGS_STORE = 'UPDATE_SETTINGS_STORE';

export const settingsActionCreators = {
  displayProd: () => ({ type: DISPLAY_PROD }),
  displayNotProd: () => ({ type: DISPLAY_NOTPROD }),
  showRoute: value => ({ type: SHOW_ROUTE, value }),
  hideRoute: value => ({ type: HIDE_ROUTE, value }),
  togglePrint: () => ({ type: TOGGLE_PRINT }),
  toggleSidebar: value => ({ type: TOGGLE_SIDEBAR, value }),
  updateSettingsStore: value => ({
    type: UPDATE_SETTINGS_STORE,
    value,
  }),
};

const initialState = {
  isProd: process.env.NODE_ENV === 'production',
  unreleasedRoutes: ['/dispatch', '/operations', '/dev', '/help'],
  hiddenRoutes: ['/demand/agenda'],
  externalPrint: false,
  expandedSidebar: true,
};

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_PROD:
      return { ...state, isProd: true };
    case DISPLAY_NOTPROD:
      return { ...state, isProd: false };
    case SHOW_ROUTE:
      return {
        ...state,
        hiddenRoutes: state.hiddenRoutes.filter(r => r !== action.value),
      };
    case HIDE_ROUTE:
      return {
        ...state,
        hiddenRoutes: state.hiddenRoutes.concat([action.value]),
      };
    case TOGGLE_PRINT:
      return { ...state, externalPrint: !state.externalPrint };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        expandedSidebar: !_.isNil(action.value)
          ? action.value
            ? false
            : true
          : !state.expandedSidebar,
      };
    case UPDATE_SETTINGS_STORE:
      const {
        isProd,
        unreleasedRoutes,
        hiddenRoutes,
        externalPrint,
      } = action.value;
      return {
        ...state,
        isProd,
        unreleasedRoutes,
        hiddenRoutes,
        externalPrint,
      };
    default:
      return state;
  }
};

// SELECTORS
const selectorSettings = state => state.settings;

export const getSettings = createSelector(
  [selectorSettings],
  settings => settings
);
