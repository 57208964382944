import React, { useState } from 'react';

import { useApi } from 'api/useApi';
import trackingApi from 'api/tracking/trackingApi';
import { SpinnerSegment } from 'components';

import TrackingByAddress from './TrackingByAddress';

const TrackingByAddressContainer = ({ area }) => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState({
    number: undefined,
    complement: undefined,
    street: undefined,
    city: undefined,
  });
  const [getArgs] = useState([area.key.toUpperCase()]);
  const [trackArgs, setTrackArgs] = useState([]);

  const [fetchedAddresses, pendingFetch] = useApi(
    trackingApi.getAddresses,
    getArgs
  );
  const [fetchedDate, pendingDate] = useApi(trackingApi.track, trackArgs);

  const handleChangeMunicipality = (e, data) => {
    setAddress({
      ...address,
      city: data.value,
      street: undefined,
      number: undefined,
    });
  };

  const handleChangeStreet = (e, data) => {
    setAddress({ ...address, street: data.value, number: undefined });
  };

  const handleChangeNumber = (e, { value }) => {
    const numberAndComplement = value.split(' - ');
    const number = numberAndComplement[0];
    const complement =
      numberAndComplement.length > 1 ? numberAndComplement[1] : undefined;

    const newAddress = {
      ...address,
      number: number,
      complement: complement,
    };

    setAddress(newAddress);
    setLoading(true);
    setTrackArgs([newAddress]);
  };

  return pendingFetch ? (
    <SpinnerSegment />
  ) : (
    <TrackingByAddress
      addresses={fetchedAddresses.addresses}
      address={address}
      date={pendingDate || !fetchedDate ? undefined : fetchedDate.dateTimes[0]}
      ready={loading || pendingDate}
      handleChangeMunicipality={handleChangeMunicipality}
      handleChangeStreet={handleChangeStreet}
      handleChangeNumber={handleChangeNumber}
    />
  );
};

export default TrackingByAddressContainer;
