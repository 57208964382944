import { Container } from 'semantic-ui-react';
import styled from 'styled-components';

const QuickAccessContainer = styled(Container)`
  &&& {
    overflow-y: hidden;
    margin-top: 0.5rem;
    margin-right: 0 !important;
    margin-left: 0 !important;
    scrollbar-width: thin; /*in Firefox*/
  }

  // in Chrome
  &&&::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  //&&&::-webkit-scrollbar-track {
  //  display: none;
  //}

  //&&&::-webkit-scrollbar-thumb {
  //  background: rgba(100, 100, 100, 0.2);
  //}

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    &&& {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    &&& {
      width: 100%;
    }
  }

  @media only screen and (min-width: 1200px) {
    &&& {
      width: 100%;
    }
  }
`;

export default QuickAccessContainer;
