import baseHub from 'api/baseHub';

const events = {
  addUser: 'AddUser',
  acknowledged: 'Acknowledged',
  updated: 'Updated',
  removeConnection: 'RemoveConnection',
};

const acknowledgeUser = (connection, channel, connectionId) => {
  connection
    .invoke('AcknowledgeUser', channel, connectionId)
    .catch(err => console.error(err));
};

const documentEditionHub = function(token, channel = undefined) {
  const hub = new baseHub('DocumentEditionHub', token, channel);

  this.addHandlers = (
    addUserHandler,
    acknowledgedHandler,
    updatedHandler,
    removeConnectionHandler
  ) => {
    const { addUser, acknowledged, updated, removeConnection } = events;

    hub.addHandler(addUser, (connectionId, username) => {
      addUserHandler(connectionId, username);
      acknowledgeUser(hub.connection, channel, connectionId);
    });
    hub.addHandler(acknowledged, acknowledgedHandler);
    hub.addHandler(updated, updatedHandler);
    hub.addHandler(removeConnection, removeConnectionHandler);
  };

  this.getConnection = () => hub.getConnection();
};

export default documentEditionHub;
