import _ from 'lodash';

import { getRowData } from '../../tables/helpers/getRowData';

const getMaskRowColor = row => {
  const { cellDesigns } = row;

  // define default color
  const colors = ['rgb(33, 133, 208)'];

  // update colors when needed
  if (cellDesigns) {
    const newColors = _.map(
      _.filter(cellDesigns, cd => cd.backgroundColor),
      c => c.backgroundColor
    );

    if (newColors && newColors.length > 0) {
      colors.splice(0, colors.length, ...newColors);
    }
  }

  return colors[0];
};

const getMaskRow = (columns, row) => {
  // read data from row and columns
  const data = getRowData(row, columns);

  // read color from row cell designs
  const color = getMaskRowColor(row);

  // return masked row
  const maskRow = {
    ...row,
    data,
    color,
  };

  return maskRow;
};

const getMaskRows = (data, columns) => {
  const { rows } = data || {};
  return _.map(rows, r => getMaskRow(columns, r));
};

export default getMaskRows;
