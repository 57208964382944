import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedCheckbox = styled(Form.Field)`
  ${({ theme: { color: fg, checkboxBg, checkboxBgHover } }) => `
    &&& {
      label,
      input:focus:checked ~ label,
      input:checked ~ label {
        color: ${fg} !important;
      }

      &.toggle label {
        &::before {
          background-color: ${checkboxBg};
        }
        &:hover::before {
          background-color: ${checkboxBgHover};
        }
      }
    }
  `}
`;

export default ThemedCheckbox;
