import React from 'react';
import { Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

import { TableRowFooter, TableHeader } from './components';
import ThemedPlaceholder from '../theme/ThemedPlaceholder';

const TablePlaceholder = styled(ThemedPlaceholder).attrs({
  fluid: true,
})`
  height: 350px;
`;

const RightPlaceholder = styled(ThemedPlaceholder)`
  margin-left: 95%;
`;

const DocumentTablePlaceholder = () => (
  <>
    <TableHeader showSearchAll={false} loading />
    <TablePlaceholder>
      <Placeholder.Image rectangular />
    </TablePlaceholder>
    <TableRowFooter loading />
    <RightPlaceholder>
      <Placeholder.Line />
    </RightPlaceholder>
  </>
);

export default DocumentTablePlaceholder;
