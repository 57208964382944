import React, { useRef, useState } from 'react';
import _ from 'lodash';

import { FIELD_TYPE } from 'core/utils/constant';
import { typeMapper } from 'core/utils/mapper';
import { FasterDropdown } from 'components';

const DropdownSearch = ({
  name,
  type,
  loading,
  handleChange,
  options,
  disabled,
  defaultValue,
  singleChoice,
}) => {
  const search = useRef([]);
  const newSearch = useRef([]);
  const [val, setVal] = useState(defaultValue);

  const onBlur = (e, data) => {
    // case add an option then blur
    // check if search are updated then launch the search
    if (searchValueIsDifferent(newSearch.current)) {
      search.current = newSearch.current;
      handleChange(e, {
        name: data.name,
        value:
          newSearch.current && Array.isArray(newSearch.current)
            ? newSearch.current.length > 0
              ? newSearch.current
              : null
            : newSearch.current,
      });
    }
  };

  const onChange = (e, data) => {
    setVal(data.value);
    newSearch.current = data.value;

    if (Array.isArray(data.value)) {
      //if (data.value) {
      // update selected value but not launching the search
      const preventSearch = true;
      handleChange(e, { name: data.name, value: data.value }, preventSearch);
      //}
    } else {
      newSearch.current = data.value;
      handleChange(e, { name: data.name, value: data.value });
    }
  };

  const dropdownProps = {
    name: name,
    loading: loading,
    multiple:
      !singleChoice || _.includes([FIELD_TYPE.multiselect], typeMapper(type)),
    editable: _.includes(
      [FIELD_TYPE.editabledropdown, FIELD_TYPE.searcheditselect],
      typeMapper(type)
    ),
    disabled: disabled,
    options:
      options && options.length > 0
        ? options.filter(o => o.value.length > 0)
        : [],
    categorize: options.length > 0 && options.some(opt => opt.items !== null),
    onBlur: !singleChoice ? onBlur : undefined,
    onChange: onChange,
  };

  const searchValueIsDifferent = newValue =>
    search.current.length !== newValue.length ||
    newValue.filter(s => search.current.indexOf(s) === -1).length > 0;

  return <FasterDropdown clearable value={val} {...dropdownProps} />;
};

export default DropdownSearch;
