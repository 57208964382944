import React, { Fragment } from 'react';
import { matchPath, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Visibility } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import documentApi from 'api/document/documentApi';
import documentEditionHub from 'api/document/documentEditionHub';

import {
  FIELD_ACTION_TAG,
  FORM_LAYOUT_TYPE,
  FIELD_KEY,
  MODAL_TYPE,
  FIELD_TYPE,
  SYSTEM_FIELDS,
  DRAFT_STATUS,
} from 'core/utils/constant';
import Log from 'core/utils/log';
import Utils from 'core/utils/utils';
import Sort from 'core/utils/sort';
import { getTemplateContents } from 'core/utils/form';
import { SpinnerSegment, TableSegment, ToastMessage } from 'components';

import DocumentButtonsSegment from './components/segment/DocumentButtonsSegment';
import Template from './components/template/templateContainer';

const INITIAL_STATE = {
  ready: false,
  isOnSubmit: false,
  redirectToDemand: false,
  isNewForm: false,
  // visibleFields: [],
  // visibleSections: [],
  requiredFields: [],
  patternFields: [],
  calculations: {
    direction: 'none',
    height: 0,
    width: 0,
    topPassed: false,
    bottomPassed: false,
    pixelsPassed: 0,
    percentagePassed: 0,
    topVisible: false,
    bottomVisible: false,
    fits: false,
    passing: false,
    onScreen: false,
    offScreen: false,
  },
  tableFilters: [],
  isQueryFormClient: false,
  viewingUsers: [],
};

class SimpleForm extends React.Component {
  state = INITIAL_STATE;

  componentWillMount() {
    this.props.requestSelectedTemplate();
  }

  componentWillUnmount() {
    const { match, publicly, setStickyFunc, showStickyFunc } = this.props;

    // unmount sticky component
    showStickyFunc(false);
    setStickyFunc(undefined);

    // close hub connection
    if (match.params.id && !publicly) {
      this.closeHubConnection();
    }

    // stop router history changes listener
    this.unlisten();
  }

  connectToHub = async (token, doc) => {
    const { connection } = this.state;

    // get hub
    const hub = new documentEditionHub(token, `${doc.documentID}`);

    // define handler callbacks
    hub.addHandlers(
      this.addUserHandler,
      this.acknowledgedHandler,
      this.updatedHandler,
      this.removeConnectionHandler
    );

    // close previous connection
    this.stopConnection(connection);

    // start hub connection
    const newConnection = await hub.getConnection();

    // set connection in state
    this.setState({ connection: newConnection });
  };

  addUserHandler = (connectionId, username) => {
    const { viewingUsers } = this.state;

    viewingUsers.push({ connectionId, username });

    this.setState({ viewingUsers }, () => {
      toast.info(
        <ToastMessage info message={'A user is viewing this document'} />,
        { autoClose: false }
      );
    });
  };

  acknowledgedHandler = (channel, connectionId, username) => {
    const { doc, viewingUsers } = this.state;

    if (`${doc.documentID}` === channel) {
      viewingUsers.push({ connectionId, username });
      this.setState({ viewingUsers });
    }
  };

  updatedHandler = () => {
    const { t, changeSize, openModal } = this.props;

    const confirmReloadModalOptions = {
      type: MODAL_TYPE.warning,
      title: t('common|Reload'),
      message: t(
        'This document has been edited by another user. The page will be reloaded'
      ),
      btnTitle: t('common|Ok'),
      onBtnClick: () => window.location.reload(),
      secondaryBtnTitle: undefined,
      onSecondaryBtnClick: undefined,
    };

    changeSize('tiny');
    openModal('popup-alert', confirmReloadModalOptions);
  };

  removeConnectionHandler = connectionId => {
    const { viewingUsers } = this.state;
    _.remove(viewingUsers, { connectionId });

    this.setState({ viewingUsers });
  };

  closeHubConnection = () => {
    const { connection } = this.state;
    this.stopConnection(connection);
  };

  stopConnection = connection => {
    if (connection) {
      connection.stop();
    }
  };

  createButtonSegment = (clickables, doc, hovering, selectedTemplate) => {
    const {
      isOnSubmit,
      requiredFields,
      patternFields,
      viewingUsers,
    } = this.state;
    const { currentUser, filterSelection, match, print } = this.props;

    return (
      <DocumentButtonsSegment
        clickables={clickables}
        viewersWarning={viewingUsers && viewingUsers.length}
        documentID={doc.documentID}
        status={doc.status}
        isValidForm={isOnSubmit}
        submitFunc={this.handleSubmit.bind(this)}
        clearFunc={this.clearForm.bind(this)}
        requiredFields={requiredFields}
        patternFields={patternFields}
        filterSelection={filterSelection}
        printable={match.params.id}
        print={print}
        selectedTemplate={selectedTemplate}
        hovering={hovering}
        exceptionURole={currentUser ? currentUser.exceptionURole : undefined}
      />
    );
  };

  initSimpleForm = async () => {
    const {
      currentUser,
      match,
      filterSelection,
      selectTemplateById,
      templates,
      fetchTemplateDTO,
      setStickyFunc,
      publicly,
      location,
      clearFormData,
      area,
    } = this.props;

    let missingTemps = false;

    // templates are missing
    if (filterSelection) {
      if (
        templates &&
        _.findIndex(templates, { type: FORM_LAYOUT_TYPE.filter }) > 0
      ) {
      } else {
        throw new Error('no filter selection template defined');
      }
    } else if (
      (!templates ||
        templates.length === 0 ||
        !templates.some(t => t.templateId)) &&
      match.params.id
    ) {
      missingTemps = true;
    }

    const authed = sessionStorage.getItem('auth');
    const publicAuth = publicly && !authed && location.search;
    const token = publicAuth
      ? location.search.replace('?token=', '')
      : JSON.parse(sessionStorage.getItem('auth')).token;

    if (publicAuth) {
      sessionStorage.setItem('auth', JSON.stringify({ token }));
    }

    // load form
    const res =
      !filterSelection && match.params.id
        ? await documentApi.getByID(
            match.params.id,
            missingTemps,
            match.params.type,
            match.params.rowID,
            area && area.key ? area.key : undefined
          )
        : undefined;

    const doc =
      !filterSelection && match.params.id && res && res.document
        ? res.document
        : {
            formID: 0,
            active: false,
            key: null,
            lastEdit: '0001-01-01T00:00:00',
            data: null,
            userID: null,
            user: null,
            statusID: 0,
            status: null,
            coordinateID: null,
            coordinate: null,
            history: [],
            viewingState: [],
            form_FormLayout: [],
          };

    const templateClient =
      doc &&
      doc.template &&
      doc.template.type &&
      doc.template.type.toLowerCase() === FORM_LAYOUT_TYPE.client.toLowerCase()
        ? _.find(templates, { type: FORM_LAYOUT_TYPE.client })
        : undefined;

    const templateFilter = filterSelection
      ? _.find(templates, { type: FORM_LAYOUT_TYPE.filter })
      : undefined;

    const templateId = templateClient
      ? templateClient.templateId
      : filterSelection
      ? templateFilter.templateId
      : parseInt(
          match.params.layoutId
            ? match.params.layoutId
            : doc.template.templateId
        );

    const selectedTemplate = missingTemps
      ? doc.template
      : templateClient
      ? templateClient
      : filterSelection
      ? templateFilter
      : _.find(templates, { templateId: templateId });

    if (missingTemps) {
      await fetchTemplateDTO(selectedTemplate);
    }

    await selectTemplateById(templateId);

    clearFormData();

    await this.initializeFormDataState(currentUser, doc.data, selectedTemplate);

    const clickables = [];

    if (
      selectedTemplate &&
      selectedTemplate.clickables &&
      selectedTemplate.clickables.length > 0
    ) {
      const filteredClickables = Utils.getClickablesByStatus(
        selectedTemplate.clickables,
        doc.status
      );

      if (filteredClickables && filteredClickables.length > 0) {
        clickables.push(...filteredClickables);
      }
    }

    // This code must take place at the last action
    // If form not null then Template will render and the order of data will be wrong
    this.setState({ doc, clickables }, () => {
      if (match.params.id && !publicly) {
        this.connectToHub(token, doc);
      }
    });

    // debug UI test
    // this.setState({ form: FormData });

    this.prepareTableFilters();

    if (clickables && clickables.length > 0) {
      setStickyFunc(() => {
        return this.createButtonSegment(
          clickables,
          doc,
          true,
          selectedTemplate
        );
      });
    }

    this.setState({ ready: true });
  };

  async componentDidMount() {
    const { module, match, history, requestSelectedTemplate } = this.props;
    this.idRef = { current: match.params.id };

    // Detect route change
    this.unlisten = history.listen(async location => {
      this.setState({ ready: false });

      const newMatch = matchPath(location.pathname, {
        path: match.path,
      });

      const isAnotherDocument =
        location.pathname.includes(module.path) &&
        newMatch &&
        newMatch.params.id !== this.idRef.current;

      if (newMatch) {
        this.idRef.current = newMatch.params.id;
      }

      // Reset component only when requesting another document
      if (isAnotherDocument) {
        // Re-request null template for new init
        await requestSelectedTemplate();

        // Re-init form
        this.initSimpleForm();
      }
    });

    this.initSimpleForm();
  }

  handleScroll = (e, { calculations }) => {
    const { clickables } = this.state;

    if (clickables && clickables.length > 0) {
      this.setState({ calculations }, () => {
        this.props.showStickyFunc(this.state.calculations.pixelsPassed > 100);
      });
    }
  };

  initializeFormDataState = (currentUser, formData, template) => {
    const requiredFields = [];
    const patternFields = [];
    const visibleFields = [];
    const visibleSections = [];
    const visibleCategories = [];
    const newFormData = {};
    let isNewForm = false;

    _.filter(getTemplateContents(template), ct => ct.sectionID >= 0).forEach(
      s => {
        if (!s.isVisible) {
          return; // is hidden by backend then skip this section
        }

        visibleSections.push({
          name: s.key,
          value: s.isVisible,
        });

        return s.fields.forEach(
          ({
            key,
            name,
            type,
            dropdown,
            required,
            isVisible,
            isReadOnly,
            row,
            column,
            pattern,
          }) => {
            if (!isVisible) {
              return; // is hidden by backend then skip this field
            }

            // is new form then initialize state for each field in template
            // if field type is checkbox then the default value is false, with combo list is the selected options
            if (!formData) {
              newFormData[key] = _.includes(type, FIELD_TYPE.checkbox)
                ? false
                : _.includes(
                    [
                      FIELD_TYPE.combolist,
                      FIELD_TYPE.editabledropdown,
                      FIELD_TYPE.searchselect,
                      FIELD_TYPE.searcheditselect,
                      FIELD_TYPE.multiselect,
                    ],
                    type
                  ) &&
                  dropdown &&
                  dropdown.selectedOptionValue
                ? dropdown.selectedOptionValue
                : '';
              isNewForm = true;
            }

            // If this is the required field, then add to requiredFields
            if (required) {
              requiredFields.push({
                key,
                name,
                value: true,
                sectionRow: s.row,
                sectionColumn: s.column,
                row,
                column,
                isReadOnly,
              });
            }

            // If this is the pattern field, then add to patternFields
            if (
              pattern &&
              _.includes(
                [
                  FIELD_TYPE.autocomplete,
                  FIELD_TYPE.editabledropdown,
                  FIELD_TYPE.searchselect,
                  FIELD_TYPE.searcheditselect,
                  FIELD_TYPE.time,
                  FIELD_TYPE.text,
                  FIELD_TYPE.textarea,
                ],
                type
              )
            ) {
              patternFields.push({
                key,
                name,
                pattern,
                value: true,
                isReadOnly,
              });
            }

            // If this is dropdown field and has categories then add to visibleCategories
            if (
              dropdown &&
              dropdown.options &&
              dropdown.options.some(opt => opt.items !== null)
            ) {
              visibleCategories.push({
                name: key, // fieldKey
                categories: dropdown.options
                  .filter(opt => opt.items !== null)
                  .map(opt => {
                    return { name: opt.key, value: true };
                  }), // opt.name is categoryKey
              });
            }

            visibleFields.push({
              name: key,
              value: isVisible,
            });
          }
        );
      }
    );

    if (currentUser) {
      // add frontend user filters to data
      const { filters, selectedFilters } = currentUser;
      const userFilters = _.unionBy(filters, selectedFilters, 'key');

      _.forEach(userFilters, ({ key: fKey, value: fValue }) => {
        if (formData) {
          const dataAsJson = JSON.parse(formData);
          dataAsJson[fKey] = fValue;
          formData = JSON.stringify(dataAsJson);
        }

        if (newFormData) {
          newFormData[fKey] = fValue;
        }
      });
    }

    this.props.setFormData(formData ? JSON.parse(formData) : newFormData);
    this.props.setVisibleFields(visibleFields);
    this.props.setVisibleSections(visibleSections);
    this.props.setVisibleCategories(visibleCategories);

    this.setState({
      isNewForm: isNewForm,
      isOnSubmit: false,
      requiredFields: Sort.sortByStepRowColumnKey(requiredFields),
      patternFields: patternFields,
      // visibleFields: visibleFields,
      // visibleSections: visibleSections,
    });
  };

  getFileBase64(file, callback) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      callback(reader.result);
    };
    reader.onerror = function(error) {
      Log.error('Error: ', error); // TODO popup error ?
    };
  }

  onChangeRequiredOrPatternField = (key, requiredState) => {
    const { requiredFields, patternFields } = this.state;

    const requireds = _.filter(requiredFields, { key: key });
    const patterns = _.filter(patternFields, { key: key });

    if (requireds) {
      _.forEach(requireds, e => (e.value = requiredState));
      this.setState({ requiredFields });
    }

    if (patterns) {
      _.forEach(patterns, e => (e.value = requiredState));
      this.setState({ patternFields });
    }
  };

  changeVisible = (tag, key, isVisible) => {
    //const { form } = this.state;
    const {
      visibleFields,
      visibleSections,
      updateVisibleField,
      selectedTemplate,
    } = this.props;

    const sections = _.filter(
      getTemplateContents(selectedTemplate),
      ct => ct.sectionID >= 0
    ); // get all sections

    if (visibleFields.length > 0 && visibleSections.length > 0) {
      updateVisibleField(tag, key, isVisible);

      if (tag === FIELD_ACTION_TAG.field) {
        // is field, if field is visible and its also required then active required state else desactive
        this.onChangeRequiredOrPatternField(key, isVisible);
      } else if (tag === FIELD_ACTION_TAG.section) {
        // is section
        const sectionOnVisibleChange = _.find(sections, { key: key });

        if (sectionOnVisibleChange) {
          sectionOnVisibleChange.fields.forEach(f => {
            if (_.find(visibleFields, { name: f.key }).value) {
              // if field is visible in hiding/showing section then desactive/active its required state if it have
              this.onChangeRequiredOrPatternField(f.key, isVisible);
            }
          });
        }
      }
    }

    // this.setState({
    //   visibleFields: visibleFields,
    //   visibleSections: visibleSections,
    // });
  };

  handleChange = (e, field) => {
    // update state at each time field has changed
    // if (field.type === 'checkbox') {
    //   this.props.updateFormData(field.name, field.checked);
    // } else if (field.type === 'file') {
    //   if (e.target.files.length === 1) {
    //     const file = e.target.files[0];
    //     this.getFileBase64(file, result => {
    //       // set state of this field photo
    //       this.props.updateFormData(field.name, result);
    //       this.props.updateFormData(field.name + '_fileName', file.name);
    //     });
    //   } else {
    //     // if cancel then re-initialize value of its state
    //     this.props.updateFormData(field.name, '');
    //     this.props.updateFormData(field.name + '_fileName', '');
    //   }
    // } else {
    //   this.props.updateFormData(field.name, field.value)
    // }
  };

  handleChangeMultidates = multiDates => {
    this.props.updateFormData(null, {
      name: 'Field12',
      value: multiDates,
    });
    // this.setState({
    //   formData: { ...this.state.formData, Field12: multiDates },
    // });
  };

  clearForm = () => {
    const { currentUser, clearFormData, selectedTemplate } = this.props;

    clearFormData();
    this.initializeFormDataState(currentUser, null, selectedTemplate);
  };

  async postForm(cId) {
    let redirectPublic = false;
    const { doc } = this.state;
    const { t, publicly, formData, selectedTemplate } = this.props;

    const clicked = selectedTemplate.clickables.find(
      c =>
        c.clickableId === cId &&
        (doc.status === c.fromStatus || (!doc.status && !c.fromStatus))
    );

    if (!doc.documentID) {
      // create new document
      const formSubmit = {
        documentID: 0,
        data: JSON.stringify(formData),
        status: clicked ? clicked.toStatus : doc.status,
        template: {
          ...selectedTemplate,
          steps: [],
        },
      };

      const response = await documentApi.post(formSubmit);

      if (response.status === 201 && response.data) {
        if (publicly) {
          redirectPublic = true;
        } else {
          this.setState({ redirectToDemand: true });

          toast.success(
            <ToastMessage
              success
              message={`${t('Document created with ID')} ${response.data.id}`}
            />
          );
        }
      }
    } else {
      // update document
      const formSubmit = {
        documentID: doc.documentID,
        key: doc.key,
        data: JSON.stringify(formData),
        status: clicked ? clicked.toStatus : doc.status,
        template: {
          ...selectedTemplate,
          steps: [],
        },
      };
      //template: {
      //templateID: selectedTemplate.templateID,
      //},

      await this.closeHubConnection();

      const response = await documentApi.put(formSubmit);

      if (response.status === 204) {
        if (publicly) {
          redirectPublic = true;
        } else {
          this.setState({ redirectToDemand: true });

          toast.success(
            <ToastMessage
              success
              message={`${t('Document')} ${formSubmit.documentID} ${t(
                'was successfully updated'
              )}`}
            />
          );
        }
      }
    }

    if (redirectPublic) {
      window.location = formData['ReturnURL'];
    }
  }

  handleSubmit(confirmedSubmit, cId) {
    if (!this.state.isOnSubmit) {
      // set state valid form to submit
      this.setState({ isOnSubmit: true });
    }

    if (!confirmedSubmit) {
      // if not confirmed submit by popup then return
      return;
    }

    this.postForm(cId);
  }

  prepareTableFilters = () => {
    const { templates, formData, match } = this.props;
    const { type } = match.params;

    const isQueryFormClient = type
      ? type.toLowerCase() === FORM_LAYOUT_TYPE.client.toLowerCase()
      : false;
    const tableFilters = [];
    const keyClientCode = FIELD_KEY.client;

    if (isQueryFormClient && formData && formData[keyClientCode]) {
      const clientCodeField = _.find(Utils.getFieldsFromTemplates(templates), {
        key: keyClientCode,
      });

      if (!clientCodeField) {
        toast.error(
          <ToastMessage error message={'Client code field is missing'} />
        );
      } else {
        const { key } = clientCodeField;

        if (key) {
          const filterClientCode = {};
          filterClientCode[key] = formData[keyClientCode];
          tableFilters.push(filterClientCode);

          this.setState({ tableFilters, isQueryFormClient });
        }
      }
    }
  };

  render() {
    const {
      doc,
      clickables,
      isNewForm,
      redirectToDemand,
      tableFilters,
      isQueryFormClient,
      viewingUsers,
      ready,
    } = this.state;
    const {
      lastCustomPage,
      formData,
      selectedTemplate,
      area,
      module,
      publicly,
      coords,
      updateFormDataValue,
    } = this.props;

    if (!doc || !ready) {
      return <SpinnerSegment />;
    }

    if (redirectToDemand) {
      const { pathname } = lastCustomPage || {};
      const baseUrl = `${
        area ? `/${area.key}` : ''
      }/${module.key.toLowerCase()}`;

      return (
        <Redirect
          to={pathname && pathname.includes(baseUrl) ? pathname : baseUrl}
        />
      );
    }

    if (publicly && formData && coords) {
      // add/update the value of coordination if have any change
      if (
        formData['Longitude'] !== coords.longitude ||
        formData['Latitude'] !== coords.latitude
      ) {
        formData['Longitude'] = coords.longitude;
        formData['Latitude'] = coords.latitude;
      }
    }

    const metaData = {
      [SYSTEM_FIELDS.status]: doc.status ? doc.status : DRAFT_STATUS, // Draft form has status=null
    };

    return (
      <Visibility onUpdate={this.handleScroll}>
        {selectedTemplate &&
          clickables &&
          clickables.length > 0 &&
          this.createButtonSegment(clickables, doc, false, selectedTemplate)}
        {doc && formData && (
          <Fragment key={doc.documentID}>
            <Form id="renderedDocument">
              {!selectedTemplate && <Fragment />}
              {selectedTemplate && (
                <Template
                  template={selectedTemplate}
                  documentID={doc.documentID}
                  isNewForm={isNewForm}
                  onAutohide={this.changeVisible}
                  onChangeFunc={this.handleChange.bind(this)}
                  updateFormDataValue={updateFormDataValue}
                  //onChangeRequiredField={this.onChangeRequiredField}
                  onChangeMultiDatesFunc={this.handleChangeMultidates.bind(
                    this
                  )}
                  metaData={metaData}
                  viewingUsers={viewingUsers}
                  histories={
                    !publicly && doc.histories && doc.histories.length > 0
                      ? doc.histories
                      : undefined
                  }
                  area={area}
                  module={module}
                />
              )}
            </Form>
            {isQueryFormClient && (
              <TableSegment
                icon="list"
                area={area}
                module={module}
                tableKey="main|client"
                filters={tableFilters}
              />
            )}
          </Fragment>
        )}
      </Visibility>
    );
  }
}

export default withTranslation()(SimpleForm);
