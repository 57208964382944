import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getTemplates } from 'core/template/templateReducer';
import { getTemplateContents } from 'core/utils/form';

import FilterTable from './components/table/FilterTable';
import { getFixedFiltersBatch } from './helpers/fixed-filters';
import { getFixedFilterPropsBatch } from './helpers/fixed-filter-props';

const emptyFilter = {
  templateId: '',
  sectionID: '',
  position: Number.MAX_SAFE_INTEGER,
  columnID: '',
  column: undefined,
  value: '',
};

class Filter extends Component {
  state = {
    templateOptions: [],
    sectionOptions: [],
    columnOptions: [],
    columns: [],
  };

  async componentDidMount() {
    const { filters, templates, handleReloadColOpts } = this.props;

    // load available fields from redux-stored templates
    const templateOptions = [];
    const sectionOptions = [];
    const columnOptions = [];
    const columns = [];

    templates.forEach(t => {
      templateOptions.push({
        reference: t.key,
        key: `${t.templateId}`,
        value: `${t.templateId}`,
        text: t.name,
      });
      _.filter(getTemplateContents(t), ct => ct.sectionID >= 0).forEach(s => {
        sectionOptions.push({
          temp: `${t.templateId}`,
          reference: s.key,
          key: `${s.sectionID}`,
          value: `${s.sectionID}`,
          text: s.name,
        });
        s.fields.forEach(f => {
          columnOptions.push({
            temp: `${t.templateId}`,
            sect: `${s.sectionID}`,
            reference: f.key,
            key: `${f.fieldID}`,
            value: `${f.fieldID}`,
            text: f.name,
            disabled: filters.some(fltr => fltr.columnID === f.fieldID),
          });
          columns.push(f);
        });
      });
    });

    this.setState({ templateOptions, sectionOptions, columnOptions, columns });

    if (handleReloadColOpts) {
      handleReloadColOpts(this.reloadColOptions);
    }
  }

  handleAddFilter = () => {
    const { advanced, filters, callbackFunc } = this.props;
    const { templateOptions } = this.state;

    if (
      !filters.some(f => !f.columnID || (!f.operator && advanced)) &&
      templateOptions.length > 0
    ) {
      // compute new position
      const pos = !_.isEmpty(filters)
        ? _.maxBy(filters, 'position').position + 1
        : 1;

      // update state
      callbackFunc(filters.concat({ ...emptyFilter, position: pos }));
    }
  };

  handleRemoveFilter = filter => {
    const { filters, callbackFunc } = this.props;
    const { columnOptions } = this.state;

    // new options
    const colOpts = columnOptions.some(o => o.value === filter[2])
      ? [
          ...columnOptions.filter(o => o.value !== filter[2]),
          ...columnOptions
            .filter(o => o.value === filter[2])
            .map(o => ({ ...o, disabled: false })), // enable old option
        ]
      : [...columnOptions];

    // update state
    this.setState({
      // disable selected option
      columnOptions: colOpts,
    });

    // update state
    callbackFunc(getFixedFiltersBatch(filters, filter));
  };

  // set active row
  handleRowClick = activeFilter => () => {
    this.setState({ activeFilter });
  };

  handleChangeColumn = (filter, e, data) => {
    const { value } = data ? data : e.target;
    const { columnOptions, columns } = this.state;

    // new options
    const newColumnOptions = columnOptions.some(o => o.value === filter[2])
      ? [
          ...columnOptions.filter(
            o => o.value !== value && o.value !== filter[2]
          ),
          ...columnOptions
            .filter(o => o.value === value)
            .map(o => ({ ...o, disabled: true })), // disable selected option
          ...columnOptions
            .filter(o => o.value === filter[2])
            .map(o => ({ ...o, disabled: false })), // enable old option
        ]
      : [
          ...columnOptions.filter(o => o.value !== value),
          ...columnOptions
            .filter(o => o.value === value)
            .map(o => ({ ...o, disabled: true })), // disable selected option
        ];

    const newActiveFilter = [...filter];
    newActiveFilter[2] = value;

    // update state
    this.setState({
      columnOptions: newColumnOptions, // disable selected option
      activeFilter: newActiveFilter, // set active row
    });

    this.handleChangeAttr(
      'columnID',
      ['operator', 'value'],
      [['column', columns.find(c => `${c.fieldID}` === value)]]
    )(filter, e, data);
  };

  handleChangeAttr = (attr, attrReset, attrCustom) => (filter, e, data) => {
    const { value } = data ? data : e.target;
    const { filters, callbackFunc } = this.props;

    this.setState({ activeFilter: filter });

    const attrResets = _.reduce(
      attrReset,
      (acc, elem) => {
        acc[elem] = undefined;
        return acc;
      },
      {}
    );

    const attrCustoms = _.reduce(
      attrCustom,
      (acc, elem) => {
        acc[elem[0]] = elem[1];
        return acc;
      },
      {}
    );

    // update state
    callbackFunc([
      // leave unaffected filters unchanged
      ...getFixedFiltersBatch(filters, filter),
      {
        // leave all properties unchanged first
        ...getFixedFilterPropsBatch(filters, filter),
        // then update desired property
        [attr]: value,
        ...attrResets,
        ...attrCustoms,
      },
    ]);
  };

  reloadColOptions = filters => {
    const { columnOptions } = this.state;

    // first enable all the options
    let colOpts = columnOptions.map(o => ({ ...o, disabled: false }));

    // then disable the option which is in new filters
    filters.forEach(f => {
      colOpts = [
        ...colOpts.filter(o => o.value !== `${f.columnID}`),
        ...colOpts
          .filter(o => o.value === `${f.columnID}`)
          .map(o => ({ ...o, disabled: true })), // disable selected option
      ];
    });

    this.setState({ columnOptions: colOpts });
  };

  render() {
    const { filters, advanced } = this.props;
    const {
      templateOptions,
      sectionOptions,
      columnOptions,
      columns,
      activeFilter,
    } = this.state;

    return (
      <FilterTable
        advanced={advanced}
        filters={filters}
        templateOptions={templateOptions}
        sectionOptions={sectionOptions}
        columnOptions={columnOptions}
        columns={columns}
        activeFilter={activeFilter}
        handleAddFilter={this.handleAddFilter}
        handleRemoveFilter={this.handleRemoveFilter}
        handleRowClick={this.handleRowClick}
        handleChangeTemplate={this.handleChangeAttr('templateId', [
          'operator',
          'value',
        ])}
        handleChangeSection={this.handleChangeAttr('sectionID', [
          'operator',
          'value',
        ])}
        handleChangeColumn={this.handleChangeColumn}
        handleChangeOperator={this.handleChangeAttr('operator')}
        handleChangeValue={this.handleChangeAttr('value')}
      />
    );
  }
}

Filter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  advanced: PropTypes.bool,
  callbackFunc: PropTypes.func.isRequired,
  handleReloadColOpts: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Filter.defaultProps = {
  advanced: false,
};

const mapStateToProps = state => ({
  templates: getTemplates(state),
});

const mapDispatchToProps = null;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Filter)
);
