import React from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { ThemedHeaderCellHeader } from 'components';

const StyledHeaderCellFilter = styled(ThemedHeaderCellHeader).attrs({
  withFilter: true,
})``;

const FilterTableHeader = ({ t, advanced }) => (
  <Table.Header>
    <Table.Row>
      <StyledHeaderCellFilter>{t('Template')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter>{t('Section')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter>{t('Field')}</StyledHeaderCellFilter>
      {advanced && <StyledHeaderCellFilter />}
      <StyledHeaderCellFilter>{t('Value')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter />
    </Table.Row>
  </Table.Header>
);

const GridFilterTableHeader = ({ t, advanced }) => (
  <Table.Header>
    <Table.Row>
      <StyledHeaderCellFilter width={1}>{t('Position')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter>{t('Template')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter>{t('Section')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter>{t('Field')}</StyledHeaderCellFilter>
      {advanced && <StyledHeaderCellFilter />}
      <StyledHeaderCellFilter>{t('Value')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter width={1} />
    </Table.Row>
  </Table.Header>
);

export { FilterTableHeader, GridFilterTableHeader };
