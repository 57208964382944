import React from 'react';
import { Tab, Form, Menu } from 'semantic-ui-react';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getModal } from 'core/modal/modalReducer';

import PopupTemplate from '../template/PopupTemplate';

import styled from 'styled-components';

const StyledTab = styled(Tab)`
  ${({
    theme: {
      color: { fg, accentBg },
    },
  }) => `
    &&&&& {
      & .ui.attached.tabular.menu {
        border: none !important;
      }

      & .ui.tabular.menu .item {
        color: ${fg} !important;
        border: none !important;
      }

      & .ui.tabular.menu .active.item {
        background-color: ${accentBg} !important;
        color: ${fg} !important;
        border: none !important;
      }
    }
  `}
`;

const PopupTabs = ({ panes, handleTabChange, modal, ...other }) => (
  <PopupTemplate {...other}>
    <Form id="popupForm">
      <StyledTab
        activeIndex={modal.activeIndexTab}
        onTabChange={handleTabChange}
        panes={_.map(panes, ({ menuItem, render, isEnable = true }, index) => {
          return {
            menuItem: (
              <Menu.Item
                key={index}
                style={{
                  display: isEnable ? 'flex' : 'none',
                }}
              >
                {menuItem}
              </Menu.Item>
            ),
            render: render,
          };
        })}
      />
    </Form>
  </PopupTemplate>
);

const mapStateToProps = state => ({
  modal: getModal(state),
});

const mapDispatchToProps = null;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(PopupTabs))
);
