const validateViewport = viewport => {
  let isValid = false;

  if (viewport) {
    const { center, zoom } = viewport;

    if (
      center &&
      Array.isArray(center) &&
      center.length === 2 &&
      Number.isFinite(center[0]) &&
      Number.isFinite(center[1]) &&
      zoom &&
      Number.isFinite(zoom)
    ) {
      isValid = true;
    }
  }

  return isValid;
};

export default validateViewport;
