import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MapContainer,
  LayerGroup,
  Marker,
  TileLayer,
  Popup,
  Tooltip,
} from 'react-leaflet';
import { Divider } from 'semantic-ui-react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-polylinedecorator';
import 'leaflet-routing-machine';
import 'leaflet-control-geocoder';
import 'lrm-graphhopper';
import styled from 'styled-components';
import _ from 'lodash';

import { ButtonLink } from 'components';

import validateViewport from './helpers/validateViewport';
import getMarkerIcon from './helpers/getMarkerIcon';
import PolylineDecorator from './components/PolylineDecorator';
import {
  DblClickComponent,
  RightClickComponent,
} from './components/ClickComponents';

const DEFAULT_VIEWPORT = {
  center: [28, 4],
  zoom: 2,
};

const COLORS = ['black', 'red', 'blue', 'yellow', 'green'];

const StyledMapContainer = styled(MapContainer)`
  width: 100%;
  height: 550px;
`;

const FasterMap = ({
  mapViewport,
  markers,
  locationsOptimized,
  addMarker,
  handleOpenDocument,
}) => {
  const [viewport, setViewport] = useState(
    validateViewport(mapViewport) ? mapViewport : DEFAULT_VIEWPORT
  );

  const onViewportChanged = newViewport => {
    setViewport(newViewport);
  };

  const { t } = useTranslation();
  const openText = t('common|Open');
  const openIANTText = t('common|Open in new tab');

  return (
    <StyledMapContainer
      scrollWheelZoom={false}
      dragging={true /*false when in mobile*/}
      center={viewport.center}
      zoom={viewport.zoom}
      onClick={addMarker}
      onViewportChanged={onViewportChanged}
    >
      <DblClickComponent />
      <RightClickComponent />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <LayerGroup>
        {markers.map((m, i) => {
          const [key, lat, lng, tooltip, color] = m;
          const position = [lng, lat];
          const icon = getMarkerIcon(color);

          return (
            <Marker key={`marker-${i}`} icon={icon} position={position}>
              <Popup>
                <ButtonLink
                  text={openText}
                  handleClickFunc={() => handleOpenDocument(key)}
                />
                <Divider />
                <ButtonLink
                  text={openIANTText}
                  icon
                  iconName="external"
                  handleClickFunc={() => handleOpenDocument(key, true)}
                />
              </Popup>
              <Tooltip>
                {_.map(tooltip.split('\n'), (t, k) => (
                  <div key={k}>{t}</div>
                ))}
              </Tooltip>
            </Marker>
          );
        })}
        {locationsOptimized &&
          _.map(locationsOptimized, (p, i) => (
            <PolylineDecorator
              key={i}
              color={COLORS[i]}
              weight={3}
              opacity={0.5}
              positions={p.path}
            />
          ))}
      </LayerGroup>
    </StyledMapContainer>
  );
};

export default FasterMap;
