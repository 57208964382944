import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { FIELD_TYPE } from 'core/utils/constant';
import {
  CalendarPicker,
  FasterDropdown,
  InputFile,
  LinkInput,
  ThemedFormField,
  ThemedInput,
  ThemedMessage,
  ThemedTextArea,
} from 'components';

import FormFieldInput from './field/FormFieldInput';
import AutocompleteInput from './autocomplete/AutocompleteInput';
// import AddressAutocompleteInput from './autocomplete/AddressAutocompleteInput';

const {
  decimal,
  number,
  text,
  checkbox,
  combolist,
  file,
  photo,
  textarea,
  time,
  date,
  datetime,
  daterange,
  multidates,
  autocomplete,
  address,
  editabledropdown,
  searchselect,
  searcheditselect,
  multiselect,
  link,
  label,
} = FIELD_TYPE;

const FormInput = ({
  fis,
  visible,
  onChange,
  onFocusChange,
  onAutoComplete,
  formData,
  disabled,
  visibleCategories = undefined,
}) => {
  const fieldType = fis.type;

  let dropdownOptions = (fis.dropdown && fis.dropdown.options) || [];
  const dropdownHasCategory =
    dropdownOptions.length > 0 &&
    dropdownOptions.some(opt => opt.items !== null);

  if (visibleCategories && dropdownHasCategory) {
    // filter categories base on theirs visibility
    const stateCategories = visibleCategories.find(f => f.name === fis.key)
      .categories;
    dropdownOptions = dropdownOptions.filter(
      cat =>
        cat.items !== null &&
        stateCategories.find(c => c.name === cat.key).value
    );
  }

  const onFocusInOut = {
    //onFocus: e => onFocusChange(e, fis.key, fieldType),
    onBlur: onFocusChange
      ? e => onFocusChange(e, fis.key, fieldType)
      : undefined,
  };

  const isDisabled = disabled || fis.isReadOnly;
  const isRequired = fis.required;

  const commonProps = {
    className: 'column',
    required: isRequired,
    visible: visible,
    ...onFocusInOut,
    disabled: fieldType === textarea ? undefined : isDisabled,
    readOnly: fieldType === textarea && isDisabled ? true : undefined,
  };

  const isMultiSelect = _.includes([multiselect], fieldType);

  const onMultiSelectChange = (e, data) => {
    data.value = Utils.arrayToString(data.value); // join array value into a single string value
    onChange(e, data); // trigger change
  };

  return (
    <>
      {[label].includes(fieldType) && (
        <ThemedFormField name={fis.key} {...commonProps}>
          <label>{fis.name}</label>
          <ThemedMessage
            content={formData && formData[fis.key] ? formData[fis.key] : ''}
          />
        </ThemedFormField>
      )}

      {[decimal, number, text, textarea].includes(fieldType) && (
        <FormFieldInput
          {...commonProps}
          control={fieldType === textarea ? ThemedTextArea : ThemedInput}
          label={fis.name}
          type={fieldType}
          step={
            fieldType === decimal
              ? 'any'
              : fieldType === number
              ? '1'
              : undefined
          }
          name={fis.key}
          pattern={fis.pattern}
          onChange={onChange}
          value={formData && formData[fis.key] ? formData[fis.key] : ''}
        />
      )}

      {fieldType === checkbox && !_.isNil(visible) && (
        <ThemedFormField
          {...commonProps} // onFocus on checkbox will block the onChange event, need to investigate this problem when process onFocus
          control={Checkbox}
          label={fis.name}
          name={fis.key}
          onChange={onChange}
          checked={formData && Utils.getBoxChecked(formData[fis.key])}
        />
      )}

      {(_.includes(
        [
          combolist,
          editabledropdown,
          searchselect,
          searcheditselect,
          multiselect,
        ],
        fieldType
      ) ||
        (_.includes([time], fieldType) &&
          dropdownOptions &&
          dropdownOptions.length > 0)) &&
        !_.isNil(visible) && (
          <ThemedFormField name={fis.key} {...commonProps}>
            <label>{fis.name}</label>
            <FasterDropdown
              name={fis.key}
              value={formData && formData[fis.key] ? formData[fis.key] : ''}
              options={dropdownOptions}
              required={isRequired}
              categorize={dropdownHasCategory}
              pattern={
                _.includes(
                  [time, editabledropdown, searcheditselect],
                  fieldType
                )
                  ? fis.pattern
                  : undefined
              }
              editable={_.includes(
                [time, editabledropdown, searcheditselect],
                fieldType
              )}
              searchable={_.includes(
                [time, searchselect, searcheditselect],
                fieldType
              )}
              multiple={isMultiSelect}
              clearable={
                _.includes([time, searchselect, searcheditselect], fieldType)
                  ? true
                  : undefined
              }
              onChange={isMultiSelect ? onMultiSelectChange : onChange}
            />
          </ThemedFormField>
        )}

      {fieldType === link && !_.isNil(visible) && (
        <ThemedFormField {...commonProps} disabled={undefined}>
          <label>{fis.name}</label>
          <LinkInput
            name={fis.key}
            value={formData && formData[fis.key] ? formData[fis.key] : ''}
            required={isRequired}
            disabled={isDisabled}
            onChange={onChange}
          />
        </ThemedFormField>
      )}

      {[file, photo].includes(fieldType) && !_.isNil(visible) && (
        <ThemedFormField {...commonProps}>
          <InputFile
            name={fis.key}
            label={fis.name}
            defaultValue={formData ? formData[fis.key] : undefined}
            required={isRequired}
            disabled={isDisabled}
            onChange={onChange}
            type={fieldType}
          />
        </ThemedFormField>
      )}

      {[time, date, datetime, daterange, multidates].includes(fieldType) &&
        !(
          _.includes([time], fieldType) &&
          dropdownOptions &&
          dropdownOptions.length > 0
        ) && (
          <CalendarPicker
            type={fieldType}
            fis={fis}
            visible={visible}
            onChange={onChange}
            value={formData && formData[fis.key] ? formData[fis.key] : ''}
            disabled={isDisabled}
            {...onFocusInOut}
          />
        )}

      {fieldType === autocomplete && !_.isNil(visible) && (
        <ThemedFormField name={fis.key} {...commonProps}>
          <label>{fis.name}</label>
          <AutocompleteInput
            name={fis.key}
            fieldId={fis.fieldID}
            value={formData && formData[fis.key] ? formData[fis.key] : ''}
            pattern={fis.pattern}
            required={isRequired}
            onChange={onChange}
            onAutoComplete={onAutoComplete}
            disabled={isDisabled}
          />
        </ThemedFormField>
      )}

      {fieldType === address && !_.isNil(visible) && (
        <ThemedFormField name={fis.key} {...commonProps}>
          <label>{fis.name}</label>
          <AutocompleteInput
            type="address"
            name={fis.key}
            fieldId={fis.fieldID}
            value={formData && formData[fis.key] ? formData[fis.key] : ''}
            onChange={onChange}
            onAutoComplete={onAutoComplete}
            disabled={isDisabled}
          />
        </ThemedFormField>
      )}
    </>
  );
};

export default FormInput;
