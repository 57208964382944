import React from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

const LeftSegment = styled(({ color, ...props }) => <Segment {...props} />)`
  ${({
    color,
    theme: {
      color: { tableBg, tableFg, tableBorderColor },
    },
  }) => `
    .ui.horizontal.segments > &.segment:first-child,
    &.ui.yellow.segment:not(.inverted) {
      border-top: none !important;
      border-left: ${`3px solid ${color}`};
      border-radius: 3px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .ui.horizontal.segments > &.segment {
      cursor: pointer;
      background-color: ${tableBg};
      color: ${tableFg};
      border-color: ${tableBorderColor};
    }

    .ui.accordion .title:not(.ui) {
      color: ${tableFg};
    }

    & p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
    }

    & p:first-child {
      font-weight: bolder;
    }
  `}
`;

const CardLeftSegment = ({ title, color, handleEdit }) => {
  //const [active, setActive] = useState(false);

  //const handleSetActive = e => {
  //  setActive(active => !active);
  //};

  return (
    <LeftSegment color={color} onClick={handleEdit}>
      {title &&
        _.map(title.split('\n'), (st, index) => <p key={index}>{st}</p>)}
      {/*<Accordion>
        <Accordion.Title active={active} onClick={handleSetActive}>
          <Icon name="dropdown" />
          View description
        </Accordion.Title>
        <Accordion.Content active={active}>{description}</Accordion.Content>
      </Accordion>*/}
    </LeftSegment>
  );
};

export default CardLeftSegment;
