import styled from 'styled-components';

const ScrollableTable = styled.div`
  &&& {
    max-width: 100%;
    min-height: 350px;
    overflow-x: scroll;
    margin-bottom: 1rem;
    scrollbar-width: thin; /*in Firefox*/
  }

  // in Chrome
  &&&::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
`;

export default ScrollableTable;
