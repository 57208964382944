import React from 'react';
import { arrayOf, number, object } from 'prop-types';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import Utils from 'core/utils/utils';
import { FormInput } from 'components';

const GridColumn = styled(Grid.Column)`
  @media only screen and (max-width: 767px) {
    .ui.stackable.grid > .row > &.column {
      padding: 1rem 1rem 0.5rem 1rem !important;
    }

    .ui.stackable.grid > .row > &.column:not(:first-child) {
      padding-top: 0 !important;
    }
  }
`;

const FormSectionGridColumn = ({
  inputs,
  columnWidth,
  formSectionProps: { onAutoFields, ...rest },
}) => (
  <GridColumn key={Utils.getEltKey(inputs[0])} width={columnWidth}>
    {inputs.map(f => (
      <FormInput
        key={Utils.getEltKey(f)}
        fis={f}
        visible={f.visible}
        onChange={onAutoFields}
        {...rest}
      />
    ))}
  </GridColumn>
);

FormSectionGridColumn.propTypes = {
  inputs: arrayOf(object).isRequired,
  columnWidth: number,
};

FormSectionGridColumn.defaultProps = { columnWidth: undefined };

export default FormSectionGridColumn;
