import React from 'react';
import { Form } from 'semantic-ui-react';

import { FasterDropdown, ThemedSegment } from 'components';

const BatchSearch = ({
  t,
  value,
  batchTempOptions,
  handleChangeBatchTemplate,
}) => (
  <ThemedSegment>
    <Form>
      <Form.Field>
        <label>{t('common|Predefined filters')}</label>
        <FasterDropdown
          value={value}
          options={batchTempOptions}
          onChange={handleChangeBatchTemplate}
        />
      </Form.Field>
    </Form>
  </ThemedSegment>
);

export default BatchSearch;
