import React from 'react';
import { Segment, Header, Icon } from 'semantic-ui-react';

const NotFound = ({ t }) => (
  <Segment basic padded="very" textAlign="center">
    <Header as="h1" icon textAlign="center">
      <Icon name="close" circular />
      <Header.Content>{`[404] ${t(
        'common|This page does not exist'
      )}`}</Header.Content>
    </Header>
  </Segment>
);

export default NotFound;
