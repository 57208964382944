import React, { useState, useRef } from 'react';
import { Form, Icon, Input } from 'semantic-ui-react';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { FIELD_TYPE, OPERATOR } from 'core/utils/constant';
import { typeMapper } from 'core/utils/mapper';
import { CalendarPicker, ThemedInput } from 'components';

//import CalendarPicker from '../calendar/CalendarPicker';

import TimeRange from '../calendar/TimeRange';
import DropdownSearch from './DropdownSearch';
import CheckboxSearch from './CheckboxSearch';

const WAIT_INTERVAL = 700;
let timer = null;

const InputSearch = ({
  t,
  column,
  handleValue,
  controlled = false,
  disabled = false,
  defaultValue,
  operator = OPERATOR.contains,
  levelFilter = 'quick',
  singleChoice,
  loading,
  dispatch,
  clearable,
  icon,
  ...props
}) => {
  // const [loading, setLoading] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState('');
  const inputRef = useRef();

  const handleClick = e => {
    e.stopPropagation();
  };

  // input value change handler
  const handleChange = (e, data, preventSearch) => {
    clearTimeout(timer);
    const colWithVal = {
      ...column,
      value:
        !column || column.checked === undefined
          ? data.checked === undefined
            ? data.value
            : data.checked
          : column.checked,
    };

    if (levelFilter === 'quick') {
      timer = setTimeout(
        () => handleValue(e, colWithVal, preventSearch),
        WAIT_INTERVAL
      );
    } else {
      handleValue(e, colWithVal, preventSearch);
    }
  };

  const handleDateRangeChange = (e, data) => {
    setDateRangeValue(data.value);

    if (data.value.indexOf(' - ') < 0) {
      // is both dates are selected then trigger search
      handleChange(e, data);
    }
  };

  // prefill input with data to facilitate date period selection
  const handleClickDatetime = (e, val) => {
    const event = e;
    const d = { value: '' };

    d.value = Utils.getDefaultDateTimePeriod(val);

    handleChange(event, d, column);
  };

  //render
  // if table column input search (as opposed to all data search)
  if (column) {
    // Checkbox case
    if (_.includes([FIELD_TYPE.checkbox], typeMapper(column.type))) {
      return (
        <CheckboxSearch
          name={column.name}
          disabled={disabled}
          controlled={controlled}
          handleChange={handleChange}
          defaultValue={defaultValue}
        />
      );
    }

    // ComboList/editable dropdown case
    if (
      _.includes(
        [
          FIELD_TYPE.combolist,
          FIELD_TYPE.editabledropdown,
          FIELD_TYPE.searchselect,
          FIELD_TYPE.searcheditselect,
          FIELD_TYPE.multiselect,
        ],
        column.type
      )
    ) {
      return (
        <DropdownSearch
          name={column.name}
          type={column.type}
          options={column.dropdown.options}
          loading={loading}
          handleChange={handleChange}
          disabled={disabled}
          defaultValue={defaultValue}
          singleChoice={
            singleChoice ||
            _.includes(
              [FIELD_TYPE.editabledropdown, FIELD_TYPE.searcheditselect],
              column.type
            )
          }
          controlled={controlled}
        />
      );
    }

    if (
      _.includes(
        [FIELD_TYPE.decimal, FIELD_TYPE.number],
        typeMapper(column.type)
      ) &&
      operator === OPERATOR.between
    ) {
      const leftValue = defaultValue ? defaultValue.split('|')[0] : '';
      const rightValue =
        defaultValue && defaultValue.includes('|')
          ? defaultValue.split('|')[1]
          : '';

      return (
        <>
          <ThemedInput
            type={FIELD_TYPE.number}
            step={
              _.includes([FIELD_TYPE.decimal], typeMapper(column.type))
                ? '0.01'
                : '0'
            }
            disabled={disabled}
            defaultValue={controlled ? undefined : leftValue}
            value={controlled ? leftValue : undefined}
            onChange={(e, data) => {
              data.value = `${data.value}|${rightValue}`;
              handleChange(e, data);
            }}
          />
          <Form.Field>{t('And')}</Form.Field>
          <ThemedInput
            type={FIELD_TYPE.number}
            step={
              _.includes([FIELD_TYPE.decimal], typeMapper(column.type))
                ? '0.01'
                : '0'
            }
            disabled={disabled}
            defaultValue={controlled ? undefined : rightValue}
            value={controlled ? rightValue : undefined}
            onChange={(e, data) => {
              data.value = `${leftValue}|${data.value}`;
              handleChange(e, data);
            }}
          />
        </>
      );
    }

    if (_.includes([FIELD_TYPE.decimal], typeMapper(column.type)))
      return (
        <ThemedInput
          name={column.name}
          type={FIELD_TYPE.number}
          step="0.01"
          loading={loading}
          onChange={handleChange}
          onClick={handleClick}
          disabled={disabled}
          defaultValue={controlled ? undefined : defaultValue}
          value={controlled ? defaultValue : undefined}
          icon={icon}
          {...props}
        />
      );

    if (
      levelFilter === 'quick' &&
      _.includes(
        [FIELD_TYPE.date, FIELD_TYPE.datetime],
        typeMapper(column.type)
      )
    ) {
      // for quick search, we need to use hooks to set the current value of date range picker
      return (
        <CalendarPicker
          fluid
          type={FIELD_TYPE.daterange}
          onClick={handleClick}
          onChange={handleDateRangeChange}
          value={dateRangeValue}
        />
      );
    }

    if (
      levelFilter === 'quick' &&
      _.includes([FIELD_TYPE.time], typeMapper(column.type))
    ) {
      return <TimeRange fluid onChange={handleChange} />;
    }

    if (
      levelFilter === 'advanced' &&
      _.includes([FIELD_TYPE.date], typeMapper(column.type)) &&
      operator === OPERATOR.between
    ) {
      return (
        <CalendarPicker
          type={FIELD_TYPE.daterange}
          onChange={handleChange}
          value={defaultValue}
        />
      );
    }

    if (
      _.includes([FIELD_TYPE.datetime], typeMapper(column.type)) &&
      levelFilter === 'advanced' &&
      operator === OPERATOR.between
    ) {
      const leftValue = defaultValue ? defaultValue.split('|')[0] : '';
      const rightValue = defaultValue ? defaultValue.split('|')[1] : '';

      return (
        <>
          <CalendarPicker
            type={FIELD_TYPE.datetime}
            disabled={disabled}
            value={leftValue}
            onClick={e => handleClickDatetime(e, defaultValue)}
            onChange={(e, data) => {
              data.value = `${data.value}|${rightValue}`;
              handleChange(e, data);
            }}
          />
          <Form.Field>{t('And')}</Form.Field>
          <CalendarPicker
            type={FIELD_TYPE.datetime}
            disabled={disabled}
            value={rightValue}
            onClick={e => handleClickDatetime(e, defaultValue)}
            onChange={(e, data) => {
              data.value = `${leftValue}|${data.value}`;
              handleChange(e, data);
            }}
          />
        </>
      );
    }

    if (
      _.includes([FIELD_TYPE.time], typeMapper(column.type)) &&
      levelFilter === 'advanced' &&
      operator === OPERATOR.between
    ) {
      return (
        <TimeRange
          onChange={handleChange}
          defaultValue={controlled ? defaultValue : undefined}
        />
      );
    }

    // for input type date, datetime, time which doesn't have operator between (in batch edit)
    if (
      levelFilter === 'advanced' &&
      _.includes(
        [FIELD_TYPE.date, FIELD_TYPE.time, FIELD_TYPE.datetime],
        column.type
      )
    ) {
      return (
        <CalendarPicker
          type={column.type.toLowerCase()}
          onChange={handleChange}
          disabled={disabled}
          value={defaultValue}
        />
      );
    }
  }

  if (clearable) {
    const inputValue =
      inputRef.current && inputRef.current.inputRef.current.value;

    const clearInput = e => {
      e.preventDefault();
      e.stopPropagation();
      if (inputRef.current) {
        inputRef.current.inputRef.current.value = '';
        handleChange(e, '');
      }
    };

    if (inputValue !== undefined && inputValue !== '') {
      icon = <Icon name="delete" link onClick={clearInput} />;
    }
  }

  // fallbacks to Input for all cases
  return (
    <Input
      ref={inputRef}
      as={ThemedInput}
      name={column ? column.name : undefined}
      type={column ? column.type : undefined}
      loading={loading}
      onChange={handleChange}
      onClick={handleClick}
      disabled={disabled}
      defaultValue={controlled ? undefined : defaultValue}
      value={controlled ? defaultValue : undefined}
      icon={icon}
      {...props}
    />
  );
};

export default InputSearch;
