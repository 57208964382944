import React from 'react';

import Utils from 'core/utils/utils';
import { history } from 'config/store';

import TimelineProgress from './TimelineProgress';

const TimelineProgressContainer = ({ t, area, module, data, ...props }) => {
  const handleDoubleClick = event => {
    if (area || module) {
      const urlNav = Utils.buildUrlNav(area, module, event.key);
      history.push(urlNav);
    }
  };

  return (
    <TimelineProgress
      {...props}
      data={data}
      handleDoubleClick={handleDoubleClick}
    />
  );
};

export default TimelineProgressContainer;
