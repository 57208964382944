import React from 'react';

import Toolbar from './components/Toolbar';
import TimelineTable from './components/table/timelineTableContainer';

const Timeline = ({
  className,
  tlProps: { selectedDate, timeline },
  handleChangeDate,
  ...props
}) => {
  return (
    <div className={className}>
      <Toolbar
        selectedDate={selectedDate}
        handleChangeDate={handleChangeDate}
      />
      <TimelineTable {...props} data={timeline} />
    </div>
  );
};

export default Timeline;
