import React, { useCallback } from 'react';
import _ from 'lodash';

import { getDateFormatted, getDateTimeFormatted } from 'core/utils/date';
import { FIELD_TYPE } from 'core/utils/constant';
import { typeMapper } from 'core/utils/mapper';

import { StyledLabelValue } from './HistoryComponents';

const HistoryLabelValue = ({ value, fieldType }) => {
  const formatHistoryValue = useCallback(
    value => {
      let valueFormatted = value;

      // single value, return by type
      if (_.includes([FIELD_TYPE.datetime], typeMapper(fieldType))) {
        valueFormatted = getDateTimeFormatted(value);
      } else if (
        _.includes(
          [FIELD_TYPE.date, FIELD_TYPE.multidates, FIELD_TYPE.daterange],
          typeMapper(fieldType)
        )
      ) {
        // default remove time format to only show date
        valueFormatted = getDateFormatted(value);
      }

      return valueFormatted;
    },
    [fieldType]
  );

  // default single value
  let content = formatHistoryValue(value);

  // check multiple values, get content of formatted values
  const valueSplitted = value.split('|');

  if (valueSplitted.length > 1) {
    const separator = fieldType === FIELD_TYPE.daterange ? ' - ' : ' | ';

    content = _.map(valueSplitted, v => formatHistoryValue(v)).join(separator);
  }

  return <StyledLabelValue content={content} />;
};

export default HistoryLabelValue;
