import React from 'react';
import { Table } from 'semantic-ui-react';
import { ThemedButton } from 'components';

const FilterTableCellButton = ({ icon, onClick, disabled }) => (
  <Table.Cell>
    <ThemedButton circular icon={icon} onClick={onClick} disabled={disabled} />
  </Table.Cell>
);
export default FilterTableCellButton;
