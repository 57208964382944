import styled from 'styled-components';

export const ThemedHeader = styled.h2`
  ${({
    theme: {
      color: { fg },
    },
  }) => `
    &&&:first-child {
      margin-top: 0;
    }
    color: ${fg} !important;
  `}
`;
