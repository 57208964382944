import Batch from '../batch/batchContainer';
import SimpleForm from '../form/simpleFormContainer';

const documentRoutes = [
  {
    path: '/:id(\\d+)',
    exact: true,
    component: SimpleForm,
  },
  {
    path: '/new/:layoutId(\\d+)',
    exact: true,
    component: SimpleForm,
  },
  {
    path: '/new/:layoutId([-]\\d+)',
    exact: true,
    component: SimpleForm,
  },
  {
    path: '/:type/:id(\\d+)',
    exact: true,
    component: SimpleForm,
  },
  {
    path: '/:type/:id(\\d+)/:rowID(\\d+)',
    exact: true,
    component: SimpleForm,
  },
  {
    path: '/batch',
    exact: true,
    component: Batch,
  },
];

export default documentRoutes;
