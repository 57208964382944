import React from 'react';
import { withTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';

import { FilterTableCellInput } from './components/cell/FilterTableCellInput';
import FilterTableCellButton from './components/cell/FilterTableCellButton';
import {
  FilterTableCellSelect,
  FilterTableCellOperatorSelect,
} from './components/cell/FilterTableCellSelect';
import { FilterTableHeader } from './components/layout/FilterTableHeader';
import FilterTableFooter from './components/layout/FilterTableFooter';
import { ThemedTable } from 'components';

const FilterTable = ({
  advanced,
  filters,
  templateOptions,
  sectionOptions,
  columnOptions,
  columns,
  activeFilter,
  handleAddFilter,
  handleRemoveFilter,
  handleRowClick,
  handleChangeTemplate,
  handleChangeSection,
  handleChangeColumn,
  handleChangeOperator,
  handleChangeValue,
  t,
}) => (
  <ThemedTable>
    <FilterTableHeader t={t} advanced={advanced} />
    <Table.Body>
      {filters.length > 0 &&
        filters
          .sort((f1, f2) => f1.position - f2.position)
          .map(({ templateId, sectionID, columnID, operator, value }) => {
            const keyColumns = [templateId, sectionID, columnID];

            return (
              <Table.Row
                onFocus={handleRowClick(keyColumns)}
                active={_.isEqual(activeFilter, keyColumns)}
                key={keyColumns}
              >
                <FilterTableCellSelect
                  options={templateOptions}
                  value={templateId}
                  onChange={(e, data) =>
                    handleChangeTemplate(keyColumns, e, data)
                  }
                />
                <FilterTableCellSelect
                  disabled={!templateId ? true : undefined}
                  options={sectionOptions.filter(so => so.temp === templateId)}
                  value={
                    sectionOptions.findIndex(
                      so => so.temp === templateId && so.value === sectionID
                    ) >= 0
                      ? sectionID
                      : ''
                  }
                  onChange={(e, data) =>
                    handleChangeSection(keyColumns, e, data)
                  }
                />
                <FilterTableCellSelect
                  disabled={sectionID ? undefined : true}
                  options={columnOptions.filter(
                    co => co.temp === templateId && co.sect === sectionID
                  )}
                  value={
                    columnOptions.findIndex(
                      co =>
                        co.temp === templateId &&
                        co.sect === sectionID &&
                        co.value === columnID
                    ) > 0
                      ? columnID
                      : ''
                  }
                  onChange={(e, data) =>
                    handleChangeColumn(keyColumns, e, data)
                  }
                />
                <FilterTableCellOperatorSelect
                  advanced={advanced}
                  columns={columns}
                  fieldID={columnID}
                  operator={operator}
                  handleChangeOperator={(e, data) =>
                    handleChangeOperator(keyColumns, e, data)
                  }
                />
                <FilterTableCellInput
                  t={t}
                  advanced={advanced}
                  columns={columns}
                  fieldID={columnID}
                  operator={operator}
                  value={value}
                  handleChangeValue={(e, data) =>
                    handleChangeValue(keyColumns, e, data)
                  }
                />
                <FilterTableCellButton
                  icon="times"
                  onClick={() => handleRemoveFilter(keyColumns)}
                />
              </Table.Row>
            );
          })}
    </Table.Body>
    <FilterTableFooter
      t={t}
      options={templateOptions}
      handleClickFunc={handleAddFilter}
    />
  </ThemedTable>
);

export default withTranslation()(FilterTable);
