import React from 'react';
import styled from 'styled-components';
import { Header } from 'semantic-ui-react';

const Title = styled(Header)`
  margin-bottom: 1.5em;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.5;
`;

const PopupTitle = ({ title, size }) => (
  <Title as="h2">{`${title} (${size})`}</Title>
);

export default PopupTitle;
