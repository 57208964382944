export const MAP_DATA = {
  MARKERS_DATA: 'markersData',
  MARKER_INDEX: 'markerIndex',
  LOCATIONS_OPTIMIZED: 'locationsOptimized',
  IS_ERROR: 'isError',
  IS_MAP_NEW_WINDOW: 'isMapNewWindow',
};

const { MARKERS_DATA, MARKER_INDEX, LOCATIONS_OPTIMIZED, IS_ERROR } = MAP_DATA;

export const keepStorageMapState = form => {
  const { markersData, markerIndex, locationsOptimized, isError } = form;

  // Pass current state into localStorage
  localStorage.setItem(MARKERS_DATA, JSON.stringify(markersData));
  localStorage.setItem(MARKER_INDEX, markerIndex);
  localStorage.setItem(LOCATIONS_OPTIMIZED, JSON.stringify(locationsOptimized));
  localStorage.setItem(IS_ERROR, isError);
};

export const clearStorageMapState = () => {
  localStorage.removeItem(MARKERS_DATA);
  localStorage.removeItem(MARKER_INDEX);
  localStorage.removeItem(LOCATIONS_OPTIMIZED);
  localStorage.removeItem(IS_ERROR);
};

export const parseStorageMapState = handleSetKey => {
  const storageMarkersData = JSON.parse(localStorage.getItem(MARKERS_DATA));
  const storageMarkerIndex = JSON.parse(localStorage.getItem(MARKER_INDEX));
  const storageLocationsOptimized = JSON.parse(
    localStorage.getItem(LOCATIONS_OPTIMIZED)
  );
  const storageIsError = localStorage.getItem(IS_ERROR);

  handleSetKey(MARKER_INDEX, storageMarkerIndex);
  handleSetKey(MARKERS_DATA, storageMarkersData);
  handleSetKey(LOCATIONS_OPTIMIZED, storageLocationsOptimized);
  handleSetKey(IS_ERROR, storageIsError === 'true');
};

export const parseAndClearMapStorage = handleSetKey => {
  if (localStorage.getItem(MARKERS_DATA) !== null) {
    parseStorageMapState(handleSetKey);
    clearStorageMapState();
  }
};
