import React from 'react';

import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedInput = styled(({ inputRef, component: Component, ...props }) =>
  Component ? <Component {...props} /> : <Input ref={inputRef} {...props} />
)`
  ${({
    theme: {
      color: {
        inputBg,
        inputFg,
        borderColor,
        inputFgHover,
        inputDisabled,
        iconColor,
      },
    },
  }) => `
    &&& {
      input {
        text-overflow: ellipsis;
        background-color: ${inputBg};
        color: ${inputFg};
        border-color: ${borderColor};

        &:hover,
        &:focus {
          background-color: ${inputBg};
          color: ${inputFgHover};
        }
      }

      .ui.form &.field.disabled {
        opacity: 1;

        > label {
          opacity: 1;
        }
      }

      > :disabled,
      .disabled.input > input {
        background-color: ${inputDisabled} !important;
        opacity: 1;
      }

      &.action.input > :disabled {
        opacity: 1 !important;
      }

      i {
        color: ${iconColor};
      }

      input:-webkit-autofill + i.icon {
        color: #000;
      }
    }

    &&&&&& input {
      /*Semantic defaults to width auto, which is too wide on 767 to 1100 px-wide screens*/
      width: 100%;
    }
  `}
`;

export default ThemedInput;
