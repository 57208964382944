import React from 'react';
import { arrayOf, number, object } from 'prop-types';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import { buildPage } from '../helpers';

const GridColumn = styled(Grid.Column)`
  @media only screen and (max-width: 767px) {
    .ui.stackable.grid > .row > &.column {
      padding: 0 !important;
    }
  }
`;

const CustomPageGridColumn = ({
  pcs,
  columnWidth,
  area,
  module,
  currentUser,
  ...props
}) => (
  <GridColumn width={columnWidth}>
    {pcs.map(pc => buildPage(pc, area, module, currentUser, props))}
  </GridColumn>
);

CustomPageGridColumn.propTypes = {
  pcs: arrayOf(object).isRequired,
  columnWidth: number,
};

CustomPageGridColumn.defaultProps = { columnWidth: undefined };

export default CustomPageGridColumn;
