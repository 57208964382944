import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import SimpleForm from './SimpleForm';

import {
  FormDataCreators,
  getVisibleFormData,
} from 'core/form-data/formDataReducer';
import {
  TemplateCreators,
  TemplateOperations,
  getTemplates,
  getSelectedTemplate,
} from 'core/template/templateReducer';
import { getCurrentUser } from 'core/auth/authReducer';
import { ModalCreators } from 'core/modal/modalReducer';
import { getLastCustomPage } from 'core/custom-page/customPageReducer';

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  selectedTemplate: getSelectedTemplate(state),
  templates: getTemplates(state),
  lastCustomPage: getLastCustomPage(state),
  ...getVisibleFormData(state),
});

const mapDispatchToProps = dispatch => ({
  updateFormData: (event, field) =>
    dispatch(FormDataCreators.updateFormData(event, field)),
  setFormData: formData => dispatch(FormDataCreators.setFormData(formData)),
  clearFormData: () => dispatch(FormDataCreators.clearFormData()),

  setVisibleFields: visibleFields =>
    dispatch(FormDataCreators.setVisibleFields(visibleFields)),
  updateVisibleField: (tag, key, isVisible) =>
    dispatch(FormDataCreators.updateVisibleField(tag, key, isVisible)),

  setVisibleSections: visibleSections =>
    dispatch(FormDataCreators.setVisibleSections(visibleSections)),

  setVisibleCategories: visibleCategories =>
    dispatch(FormDataCreators.setVisibleCategories(visibleCategories)),

  updateFormDataValue: (name, value) =>
    dispatch(FormDataCreators.updateFormDataValue(name, value)),

  requestSelectedTemplate: () =>
    dispatch(TemplateCreators.requestSelectedTemplate()),
  selectTemplateById: templateId =>
    dispatch(TemplateCreators.selectTemplateById(templateId)),
  fetchTemplateDTO: template =>
    dispatch(TemplateOperations.fetchTemplateDTO(template)),
  updateTemplate: (templateId, field, value) =>
    dispatch(TemplateCreators.updateTemplate(templateId, field, value)),

  openModal: (modalType, options) =>
    dispatch(ModalCreators.openModal(modalType, options)),
  changeSize: size => dispatch(ModalCreators.changeSize(size)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(SimpleForm))
);
