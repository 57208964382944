import React, { useState } from 'react';

import Utils from 'core/utils/utils';

import LinkInput from './LinkInput';

const LinkInputContainer = ({ name, value, required, disabled, onChange }) => {
  const [showIframe, setShowIframe] = useState(false);

  const handleToggleShowIframe = () => {
    setShowIframe(!showIframe);
  };

  const handleOpenInNewTab = () => {
    Utils.openInNewTab(value);
  };

  const linkProps = {
    name,
    value,
    required,
    disabled,
    showIframe,
    onChange,
    handleToggleShowIframe,
    handleOpenInNewTab,
  };

  return <LinkInput {...linkProps} />;
};

export default LinkInputContainer;
