import _ from 'lodash';

const getSortedSetOptionsHelper = items => [
  ...new Set(
    _.chain([...new Set(items)])
      .map(i => ({ key: i, value: i, text: i }))
      .sortBy('text')
      .value()
  ),
];

const getItemsSelected = (addresses, address, key) => {
  switch (key) {
    case 'city':
      return addresses.map(a => a.city);
    case 'street':
      return addresses.filter(a => a.city === address.city).map(a => a.street);
    case 'number':
      return addresses
        .filter(a => a.city === address.city && a.street === address.street)
        .map(a => `${a.number}${a.complement ? ` - ${a.complement}` : ''}`);
    default:
      return addresses;
  }
};

export default function getSortedSetOptions(addresses, address, key) {
  return addresses
    ? getSortedSetOptionsHelper(getItemsSelected(addresses, address, key))
    : [];
}
