import React from 'react';
import { node } from 'prop-types';
import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

const Container = styled(Segment)`
  &&& {
    height: 100%;
    background-color: ${({ theme: { color } }) => color.bgGeneral} !important;

    label {
      color: ${({ theme: { color } }) => color.fg} !important;
    }
  }
`;

const PopupInfoPanel = ({ children }) => <Container>{children}</Container>;

PopupInfoPanel.propTypes = {
  children: node.isRequired,
};

export default PopupInfoPanel;
