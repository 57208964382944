import _ from 'lodash';

import Utils from 'core/utils/utils';
import { CONTENT_TYPE, FIELD_TYPE, IMAGE_URL } from 'core/utils/constant';
import { getTemplateContents } from 'core/utils/form';

import { getVisibleRowsHelper } from './form';

const addImageToPrint = (images, url) => {
  const name = Utils.getNameFromUrl(url);
  images[name] = url;
  return {
    image: name,
    fit: [200, 300],
    style: 'image',
  };
};

export function definePrint(data, template) {
  const { name } = template;
  const contents = getTemplateContents(template);

  const imageBaseURL = `${window.location.protocol}//${window.location.host}`;
  const images = { logo: `${imageBaseURL}${IMAGE_URL.logoMedium}` };
  const imageContents = _.map(
    _.filter(contents, ct => ct.type === CONTENT_TYPE.image),
    i => {
      return addImageToPrint(images, `${imageBaseURL}${i.value}`);
    }
  );
  const sections = _.filter(contents, ct => ct.sectionID >= 0);
  const { documentID, formData /*, visibleSections, visibleFields*/ } = data;

  const visibleSections = sections.map(s => ({ name: s.key, value: true }));
  const sectionRows = getVisibleRowsHelper(sections, visibleSections);

  const sectionRowsSpans = sectionRows.map(r => {
    return Utils.getRowSpan(r, sections).totalSpanInRow;
  });

  const date = new Date();

  const maxiSectionRowSpan = Math.max(Math.max(...sectionRowsSpans), 1);

  const docDefinition = {
    content: [
      {
        text: name,
        style: 'docheader',
      },
      {
        text: ` ID ${documentID} `,
        style: 'subheader',
      },
      {
        text: `\n`,
      },
      ...imageContents,
      {
        layout: 'noBorders',
        widths: Array(maxiSectionRowSpan).fill('*'),
        table: {
          //// headers are automatically repeated if the table spans over multiple pages
          //headerRows: 1,
          body: sectionRows.map(row => {
            const {
              totalSpanInRow,
              elementsInRow,
              colsSorted,
            } = Utils.getRowSpan(row, sections);

            const cellsz = colsSorted.map(col => {
              // can have more than 1 input with same position (case has rowSpan)
              const sectionsInRow = elementsInRow
                .filter(s => s.column === col)
                .sort((a, b) => a.nestedRow - b.nestedRow);

              //const getSectionKey = s =>
              //s.sectionID > 0 ? s.sectionID : s.key;

              //const hasColSpan = sectionsInRow.some(s => s.columnSpan >= 2);
              //const columnWidth = hasColSpan
              //  ? Utils.columnWidthByColsSpan(
              //    Utils.maxColSpan(sectionsInRow),
              //    totalSpanInRow
              //  )
              //  : undefined;

              return {
                headlineLevel: 1,
                type: 'none',
                ul: sectionsInRow.map(
                  ({ sectionID, name, key, fields, color, visible }) => {
                    const visibleFields = fields.map(f => ({
                      name: f.key,
                      value: true,
                    }));
                    const fieldRows = getVisibleRowsHelper(
                      fields,
                      visibleFields
                    );

                    const fieldRowsSpans = fieldRows.map(r => {
                      return Utils.getRowSpan(r, fields).totalSpanInRow;
                    });

                    const maxiRowSpan = Math.max(
                      Math.max(...fieldRowsSpans),
                      1
                    );

                    const h = [
                      {
                        text: ` ${name} `,
                        style: 'sectionheader',
                        colSpan: maxiRowSpan,
                      },
                    ];

                    return {
                      layout: 'noBorders',
                      fillColor: color,
                      table: {
                        widths: Array(maxiRowSpan).fill('*'),
                        body: [
                          maxiRowSpan > 1
                            ? h.concat(Array(maxiRowSpan - 1).fill({}))
                            : h,
                          ...fieldRows.map(row => {
                            const {
                              totalSpanInRow,
                              elementsInRow,
                              colsSorted,
                            } = Utils.getRowSpan(row, fields);

                            const cells = colsSorted.map(col => {
                              // can have more than 1 section with same position (case has rowSpan)
                              const inputs = elementsInRow
                                .filter(f => f.column === col)
                                .sort((a, b) => a.nestedRow - b.nestedRow);

                              //const hasColSpan = inputs.some(f => f.columnSpan >= 2);
                              //const columnWidth = hasColSpan
                              //  ? Utils.columnWidthByColsSpan(
                              //    Utils.maxColSpan(inputs),
                              //    totalSpanInRow
                              //  )
                              //  : undefined;

                              //const getFieldKey = _field =>
                              //  _field.sectionID > 0 ? _field.sectionID : _field.key;

                              return {
                                type: 'none',
                                ul: inputs.map(fis => {
                                  const ul = [
                                    {
                                      text: fis.name,
                                      style: 'inputheader',
                                    },
                                    {
                                      text: `${
                                        formData[fis.key]
                                          ? fis.type === FIELD_TYPE.checkbox
                                            ? Utils.getBoxChecked(
                                                formData[fis.key]
                                              )
                                              ? '[X]'
                                              : '_'
                                            : formData[fis.key]
                                          : '_'
                                      } `,
                                      style: 'value',
                                    },
                                  ];

                                  if (
                                    formData[fis.key] &&
                                    fis.type === FIELD_TYPE.photo
                                  ) {
                                    // print photo
                                    ul.push(
                                      addImageToPrint(
                                        images,
                                        `${imageBaseURL}${formData[fis.key]}`
                                      )
                                    );
                                  }

                                  return {
                                    headlineLevel: 2,
                                    type: 'none',
                                    ul,
                                  };
                                }),
                              };
                            });

                            return cells.length === maxiRowSpan
                              ? cells
                              : cells.concat(
                                  Array(maxiRowSpan - totalSpanInRow).fill({})
                                );
                          }),
                        ],
                      },
                    };
                  }
                ),
              };
            });

            return cellsz.length === maxiSectionRowSpan
              ? cellsz
              : cellsz.concat(
                  Array(maxiSectionRowSpan - totalSpanInRow).fill({})
                );
          }),
        },
      },
    ],
    styles: {
      docheader: {
        fontSize: 20,
        bold: true,
        alignment: 'center',
      },
      image: {
        alignment: 'center',
      },
      subheader: {
        fontSize: 10,
        bold: true,
        alignment: 'center',
        color: '#5D5D5D',
        background: '#E8E8E8',
      },
      sectionheader: {
        bold: true,
        color: '#5D5D5D',
        background: '#E8E8E8',
      },
      inputheader: {
        fontSize: 10,
        bold: true,
        italics: true,
        color: '#5D5D5D',
      },
      value: {
        background: '#fff',
      },
      headfoot: {
        fontSize: 10,
        color: '#5D5D5D',
        alignment: 'center',
      },
    },
    //pageOrientation: 'landscape',
    //defaultStyle: {
    //  font: 'Lato',
    //},
    pageMargins: [30, 70, 40, 60],
    header: {
      image: 'logo',
      fit: [200, 60],
      margin: [40, 10, 0, 0],
    },
    footer: function(currentPage, pageCount) {
      return {
        text: `${name} #${documentID} [${date.getFullYear()}-${date.getMonth()}-${date.getDate()}]\n${currentPage.toString()} / ${pageCount}`,
        style: 'headfoot',
      };
    },
    pageBreakBefore: function(
      currentNode,
      followingNodesOnPage,
      nodesOnNextPage,
      previousNodesOnPage
    ) {
      return (
        // break sections overflowing to next page from bottom of current page
        (currentNode.headlineLevel === 1 &&
          currentNode.pageNumbers &&
          currentNode.pageNumbers.length > 1 &&
          currentNode.startPosition &&
          currentNode.startPosition.top > 675) ||
        // break rows overflowing to next page
        (currentNode.headlineLevel === 2 &&
          currentNode.pageNumbers &&
          currentNode.pageNumbers.length > 1)
      );
    },
    // dictionary of images
    images,
  };

  return docDefinition;
}
