import { Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedPlaceholder = styled(Placeholder)`
  ${({
    theme: {
      color: { placeholderImg },
    },
  }) => `
    &&& {
      background-color: transparent;
      background-image: ${placeholderImg};

      > .line {
        background-color: transparent;
      }
    }
  `}
`;

export default ThemedPlaceholder;
