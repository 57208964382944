import React from 'react';
import { func, string, number, arrayOf, object } from 'prop-types';
import { Icon, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  PageSegment,
  ThemedLabel,
  ThemedPopup,
  ThemedHeader,
} from 'components';

const ListLabel = styled(ThemedLabel)`
  &&& {
    display: block;
    margin: 0 0 5px 0;
  }
`;

const TemplateHeader = ({
  t,
  name,
  documentID,
  viewingUsers,
  backgroundColor,
  color,
}) => {
  return (
    <PageSegment backgroundColor={backgroundColor} color={color}>
      <Header as={ThemedHeader} textAlign="center">
        {name}
        {documentID > 0 && (
          <div>
            <ThemedLabel>{`ID ${documentID}`}</ThemedLabel>
            {viewingUsers.length > 0 && (
              <ThemedPopup
                content={viewingUsers.map(({ connectionId, username }) => (
                  <ListLabel key={connectionId}>{username}</ListLabel>
                ))}
                header={t('glossary|These users are viewing this document')}
                trigger={
                  <ThemedLabel basic color="red" size="tiny">
                    <Icon name="eye" />
                    {viewingUsers.length}
                  </ThemedLabel>
                }
                position="bottom center"
                size="tiny"
              />
            )}
          </div>
        )}
      </Header>
    </PageSegment>
  );
};

TemplateHeader.propTypes = {
  t: func.isRequired,
  name: string.isRequired,
  documentID: number,
  viewingUsers: arrayOf(object),
};

TemplateHeader.defaultProps = {
  documentID: undefined,
  viewingUsers: [],
};

export default TemplateHeader;
