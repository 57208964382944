import React from 'react';
import { Grid } from 'semantic-ui-react';
import _ from 'lodash';

import { SpinnerSegment } from 'components';

import Card from './components/Card';
import demoCardsList from './helpers/demoCardsList';

import styled from 'styled-components';

const GridRow = styled(Grid.Row)`
  &&.row {
    padding-top: 0;
  }
`;

const FasterCards = ({
  cardsProps,
  dataLoading,
  handleEdit,
  admin,
  area,
  module,
  //currentPage,
  //totalPage,
  //totalRow,
  ...props
}) => {
  let cards = demoCardsList;

  if (cardsProps) {
    cards = cardsProps.cards;
  }

  const { className } = props;

  return (
    <div className={className}>
      {/*cardsProps && (
        <TableHeader
          totalItems={totalRow}
          totalPages={totalPage}
          activePage={currentPage}
          area={area}
          module={module}
          loading={dataLoading}
          admin={admin}
        />
      )*/}
      <Grid columns="equal">
        <GridRow>
          <Grid.Column>
            {!dataLoading && cards ? (
              _.map(cards, ({ key, ...card }, index) => (
                <Card
                  key={key || index}
                  card={{ key, ...card }}
                  handleEdit={handleEdit}
                />
              ))
            ) : (
              <SpinnerSegment />
            )}
          </Grid.Column>
        </GridRow>
      </Grid>
      {/*cardsProps && (
        <TableRowFooter
          totalItems={totalRow}
          totalPages={totalPage}
          activePage={currentPage}
          handlePageChange={handlePageChange}
          loading={dataLoading}
        />
      )*/}
    </div>
  );
};

export default FasterCards;
