import React, { Fragment } from 'react';

import _ from 'lodash';

import { FIELD_TYPE, LIMIT_CHAR } from 'core/utils/constant';
import getValueMatch from 'core/utils/pattern/getValueMatch';
import { ThemedFormField } from 'components';

import FormField from './FormField';

const {
  multidates,
  date,
  decimal,
  number,
  text,
  textarea,
  time,
  datetime,
} = FIELD_TYPE;

export const getInputType = type => {
  let typeHtml = 'number';
  switch (type) {
    case multidates:
    case date:
      typeHtml = 'date';
      break;
    case decimal:
    case number:
      typeHtml = 'number';
      break;
    case text:
    case textarea:
      typeHtml = 'text';
      break;
    case time:
      typeHtml = 'time';
      break;
    case datetime:
      typeHtml = 'datetime-local';
      break;
    default:
      typeHtml = null;
      break;
  }

  return typeHtml;
};

class FormFieldInput extends FormField {
  constructor(props) {
    super(props);
    this.state = {
      prevValue: props.value,
    };
  }

  render() {
    const { prevValue } = this.state;
    const { type, visible, pattern, ...rest } = this.props;
    // console.log(`rerender ${this.props.label}`)

    if (getInputType(type) === null || _.isNil(visible)) return <Fragment />;

    if ([text, textarea].includes(type)) {
      rest.maxLength = LIMIT_CHAR;

      if (pattern) {
        rest.onInput = data => {
          const sEn = data.target.selectionEnd;
          const newValue = getValueMatch(
            pattern,
            prevValue,
            data.target.value,
            sEn
          );

          this.setState({ prevValue: newValue });
          data.target.value = newValue;
        };
      }
    }

    if ([number, decimal].includes(type)) {
      rest.onInput = data => {
        if (data.target.value.length > LIMIT_CHAR) {
          data.target.value = data.target.value.slice(0, LIMIT_CHAR);
        }
      };
    }

    return (
      <ThemedFormField type={getInputType(type)} visible={visible} {...rest} />
    );
  }
}

export default FormFieldInput;
