import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

const { Types, Creators } = createActions({
  openModal: ['modalType', 'options'],
  closeModal: null,
  changeSize: ['size'],
  updateModalStore: ['modal'],
  enableTab: ['index'],
  disableTab: ['index'],
  setActiveIndexTab: ['index'],
  updateIsSubmitting: ['isSubmitting'],
});

export const ModalCreators = Creators;
export const ModalTypes = Types;

export const INITIAL_STATE = Immutable({
  isOpen: false,
  modalType: '',
  options: null,
  size: 'small',
  activeIndexTab: 0,
  isSubmitting: false,
});

export const openModal = (state = INITIAL_STATE, action) => {
  const { modalType, options } = action;

  return {
    ...state,
    modalType: modalType,
    options: options,
    isOpen: true,
  };
};

export const closeModal = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    modalType: null,
    options: null,
    isOpen: false,
    size: 'small',
  };
};

export const enableTab = (state = INITIAL_STATE, action) => {
  const { index } = action;
  const { options } = state;
  const tmpOptions = { ...options };

  tmpOptions.panes[index].isEnable = true;

  return {
    ...state,
    options: tmpOptions,
  };
};

export const disableTab = (state = INITIAL_STATE, action) => {
  const { index } = action;
  const { options } = state;
  const tmpOptions = { ...options };

  tmpOptions.panes[index].isEnable = false;

  return {
    ...state,
    options: tmpOptions,
  };
};

export const setActiveIndexTab = (state = INITIAL_STATE, action) => {
  const { index } = action;

  return {
    ...state,
    activeIndexTab: index,
  };
};

export const changeSize = (state = INITIAL_STATE, action) => {
  const { size } = action;

  return {
    ...state,
    size: size,
  };
};

export const updateModalStore = (state = INITIAL_STATE, action) => {
  const { isOpen, modalType, options, size } = action.modal;

  return {
    ...state,
    isOpen,
    modalType,
    options,
    size,
  };
};

export const updateIsSubmitting = (
  state = INITIAL_STATE,
  { isSubmitting }
) => ({
  ...state,
  isSubmitting,
});

export const HANDLERS = {
  [Types.OPEN_MODAL]: openModal,
  [Types.CLOSE_MODAL]: closeModal,
  [Types.CHANGE_SIZE]: changeSize,
  [Types.UPDATE_MODAL_STORE]: updateModalStore,
  [Types.ENABLE_TAB]: enableTab,
  [Types.DISABLE_TAB]: disableTab,
  [Types.SET_ACTIVE_INDEX_TAB]: setActiveIndexTab,
  [Types.UPDATE_IS_SUBMITTING]: updateIsSubmitting,
};

export default createReducer(INITIAL_STATE, HANDLERS);

// SELECTORS
const selectorModal = state => state.modal;

export const getModal = createSelector(
  [selectorModal],
  modal => modal
);

export const getIsSubmitting = createSelector(
  [selectorModal],
  modal => modal.isSubmitting
);
