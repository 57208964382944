import _ from 'lodash';

import {
  FIELD_ACTION,
  FIELD_TYPE,
  FIELD_ACTION_TAG,
  SYSTEM_FIELDS,
} from 'core/utils/constant';
import { typeMapper } from 'core/utils/mapper';

export default (dependent, rules, formData, metaData, field, filledValue) => {
  let isVisible = null;

  const isPerformOnCategory = dependent.tag === FIELD_ACTION_TAG.category;
  const dependentKey = isPerformOnCategory
    ? dependent.key.split('|')[0]
    : dependent.key;
  const catKey = isPerformOnCategory ? dependent.key.split('|')[1] : undefined;

  // find all fieldAction that affect to this dependent Field/Section/Category 's visible
  const fieldsActionByDependentField = isPerformOnCategory
    ? _.filter(
        rules,
        fa =>
          _.includes([FIELD_ACTION.show, FIELD_ACTION.visible], fa.action) &&
          fa.dependent != null &&
          fa.dependent.tag === dependent.tag &&
          fa.dependent.key === dependentKey &&
          fa.dependent.categories.length > 0 &&
          fa.dependent.categories.includes(catKey)
      )
    : _.filter(
        rules,
        fa =>
          _.includes([FIELD_ACTION.show, FIELD_ACTION.visible], fa.action) &&
          fa.dependent != null &&
          fa.dependent.tag === dependent.tag &&
          fa.dependent.key === dependentKey
      );

  for (var i = 0; i < fieldsActionByDependentField.length; i++) {
    const fa = fieldsActionByDependentField[i];
    const independField = fa.independent;

    const dependentVisibleState =
      fa.dependent.value && fa.dependent.value.toLowerCase() === 'true'
        ? true
        : fa.action === FIELD_ACTION.show
        ? null
        : false;

    // State hidden or state display none when in opposite will show the element (!false => true)
    // State show element when in opposite it depends on the action
    // - !(show=true) => oppoState=null (display: none)
    // - !(visible=true) => oppoState=false (visibility: hidden)
    const oppoState = dependentVisibleState
      ? fa.action === FIELD_ACTION.show
        ? null
        : false
      : !dependentVisibleState;

    let independentValue = SYSTEM_FIELDS[independField.key.toLowerCase()]
      ? metaData[independField.key]
      : formData[independField.key];
    let independentChecked = formData[independField.key]
      ? formData[independField.key].toString().toLowerCase() === 'true'
      : false;

    // because the set state of method updateFormData is not affect to the formData yet
    // so we need to use the filledValue to compare
    if (field && independField.key === field.name) {
      independentValue = filledValue;
      independentChecked = filledValue;
    }

    const { type, value } = independField;
    const isEqualIndependentValue = _.includes(
      [FIELD_TYPE.checkbox],
      typeMapper(type)
    )
      ? (value.toLowerCase() === 'true') === independentChecked
      : value === independentValue;

    if (isEqualIndependentValue) {
      // is matching value then apply directly this rule on dependent element
      isVisible = dependentVisibleState;
      break;
    } else {
      isVisible = processLogicVisible(isVisible, oppoState);
    }
  }

  return isVisible;
};

const processLogicVisible = (val1, val2) => {
  return val1 || val2
    ? true // show
    : val1 === false || val2 === false
    ? false // visiblity=hidden
    : null; // display=none
};
