import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import _ from 'lodash';

const { Types, Creators } = createActions({
  // setItems: ['items'],
  addMenu: ['menu'],
  updateContextMenuStore: ['menu'],
});

export const ContextMenuCreators = Creators;
export const ContextMenuTypes = Types;

export const INITIAL_STATE = Immutable({
  // menuItems: [],
  menus: [],
});

// export const setItems = (state = INITIAL_STATE, action) => {
//   const { items } = action;

//   return {
//     ...state,
//     menuItems: items,
//   };
// };

export const addMenu = (state = INITIAL_STATE, action) => {
  const { menu } = action;
  let newMenus = [...state.menus];

  if (_.find(newMenus, ['id', menu.id]) === undefined) {
    newMenus.push(menu);
  } else {
    const indexReplace = newMenus.findIndex(el => el.id === menu.id);
    newMenus[indexReplace] = menu;
  }

  return {
    ...state,
    menus: newMenus,
  };
};

export const updateContextMenuStore = (state = INITIAL_STATE, action) => {
  const { menus } = action.menu;
  return { ...state, menus };
};

export const HANDLERS = {
  // [Types.SET_ITEMS]: setItems,
  [Types.ADD_MENU]: addMenu,
  [Types.UPDATE_CONTEXT_MENU_STORE]: updateContextMenuStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);

// SELECTORS
// const selectorContextMenuItems = state => state.contextMenu.menuItems;
const selectorContextMenus = state => state.contextMenu.menus;

// export const getContextMenuItems = createSelector(
//   [selectorContextMenuItems],
//   menuItems => menuItems
// );

export const getContextMenus = createSelector(
  [selectorContextMenus],
  menus => menus
);
