import _ from 'lodash';
import { CONTENT_TYPE } from 'core/utils/constant';

import getTableMaskData from './getTableMaskData';
import { initializeAgendaCriteria } from '../../agenda/helpers/initializeAgendaCriteria';
import initializeTimelineCriteria from '../../timeline/helpers/initializeTimelineCriteria';

const { agenda, timeline } = CONTENT_TYPE;

// update search by input criterion
const setCriterion = (criteria, { key, value }) => {
  criteria.searchByInput[key] = value === '' ? undefined : value;
};

// initialize criteria
const initializeTableCriteria = (table, filters, mask) => {
  const initCrit = { searchByInput: {} };

  // add criteria for filters
  if (filters && filters.length > 0) {
    _.forOwn(filters, filter => {
      _.forOwn(filter, (value, key) => {
        setCriterion(initCrit, { key, value });
      });
    });
  }

  //// add criteria for user filters
  //if (userFilters && userFilters.length > 0) {
  //  _.forEach(userFilters, userFilter => {
  //    setCriterion(initCrit, userFilter);
  //  });
  //}

  // add criteria for sort by default
  initCrit.orderByField = table.ascendingSort
    ? table.sortByColumnKey
    : undefined;
  initCrit.orderByFieldDesc = table.ascendingSort
    ? undefined
    : table.sortByColumnKey;

  // add criteria for table mask
  if (mask) {
    const maskData = getTableMaskData(table);

    // if mask is agenda
    if (mask === agenda) {
      initializeAgendaCriteria(maskData, initCrit);
    } else if (mask === timeline) {
      initializeTimelineCriteria(maskData, initCrit);
    }
  }

  return initCrit;
};

export { setCriterion, initializeTableCriteria };
