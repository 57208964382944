import _ from 'lodash';
import { getEventStartAndEnd } from '../../agenda/helpers/maskRowToEvent';

import getMaskRowText from '../../mask/helpers/getMaskRowText';

const maskRowsToTimelineData = (maskStructure, maskRows) => {
  const { resource, view, event } = maskStructure;
  const { start: eventStart, length: eventLength, end: eventEnd } = event;

  const resources = _.uniq(_.map(maskRows, mr => mr.data[resource]));

  const data = _.map(resources, r => ({
    name: r,
    view,
    labels: _.map(
      _.filter(maskRows, mr => mr.data[resource] === r),
      maskRow => {
        const { key, data: rowData, color, lastEdit } = maskRow;
        const { start, end, length } = getEventStartAndEnd(rowData, lastEdit, {
          eventStart,
          eventLength,
          eventEnd,
        });

        // define text
        const text = getMaskRowText(maskStructure, rowData);

        return {
          key,
          text,
          color,
          start,
          length,
          end,
        };
      }
    ),
  }));

  return data;
};

export default maskRowsToTimelineData;
