import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { ThemedMessage } from 'components';

const getContent = (t, action, docs, ids, completed) => {
  const actionProcessing =
    action === 'print' ? '...Printing' : '...Downloading';
  const actionFinished = action === 'print' ? 'Printed' : 'Downloaded';
  const qty = action === 'print' ? docs.length : ids.length;

  const content = completed
    ? `${t(actionFinished)} ${qty} ${t('form(s)')}`
    : `${t(actionProcessing)} ${qty} ${t('form(s)')}`;

  return (action === 'print' && docs.length > 0) || action === 'download'
    ? content
    : '';
};

const MessageResult = ({ t, action, docs, ids, completed }) => (
  <ThemedMessage icon positive={completed ? true : undefined}>
    <Icon name={completed ? 'check' : 'circle notched'} loading={!completed} />
    <Message.Content>
      <Message.Header>
        {completed ? t('Success') : t('Just one moment')}
      </Message.Header>
      {getContent(t, action, docs, ids, completed)}
    </Message.Content>
  </ThemedMessage>
);

export default MessageResult;
