import _ from 'lodash';

const getQueryString = criteria => {
  const query = ['?'];

  if (!_.isEmpty(criteria) && !_.isEmpty(criteria.searchByInput)) {
    _.forOwn(criteria.searchByInput, (value, key) => {
      if (!_.isNil(key) && !_.isNil(value)) {
        let qVal = '';

        if (Array.isArray(value)) {
          qVal = `&values=${value.join('|')}`;
        } else {
          qVal = `&values=${value}`;
        }

        const qID = `&fields=${key}`;
        const queryString = `${qID}${qVal}`;

        !_.isNil(criteria.searchByInput[key]) && query.push(queryString);
      }
    });
  }

  criteria.orderByField && query.push('&orderBy=', criteria.orderByField);
  criteria.orderByFieldDesc &&
    query.push('&orderByDesc=', criteria.orderByFieldDesc);
  criteria.searchAllByValue &&
    query.push('&searchAll=', criteria.searchAllByValue);
  criteria.indexPage && query.push('&indexPage=', criteria.indexPage);

  return `${query.join('')}`;
};

export default getQueryString;
