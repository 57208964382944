import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

const { Types, Creators } = createActions({
  setPlan: ['plan'],
  setRound: ['round'],
  setTransport: ['transport'],
  pinSidebar: null,
  unpinSidebar: null,
  openSidebar: null,
  closeSidebar: null,
  showUnassignedOrders: null,
  hideUnassignedOrders: null,
  openMapNewWindow: null,
  closeMapNewWindow: null,
  updateDispatchStore: ['dispatch'],
});

export const DispatchCreators = Creators;
export const DispatchTypes = Types;

export const INITIAL_STATE = Immutable({
  plan: null,
  round: null,
  transport: null,
  pinnedSidebar: false,
  visibleSidebar: false,
  visibleUnassignedOrders: true,
  isMapNewWindow: false,
});

export const setPlan = (state = INITIAL_STATE, action) => {
  const { plan } = action;
  return { ...state, plan };
};

export const setRound = (state = INITIAL_STATE, action) => {
  const { round } = action;
  return { ...state, round };
};

export const setTransport = (state = INITIAL_STATE, action) => {
  const { transport } = action;
  return { ...state, transport };
};

export const pinSidebar = (state = INITIAL_STATE, action) => {
  return { ...state, pinnedSidebar: true };
};

export const unpinSidebar = (state = INITIAL_STATE, action) => {
  return { ...state, pinnedSidebar: false };
};

export const closeSidebar = (state = INITIAL_STATE, action) => {
  return { ...state, visibleSidebar: false };
};

export const openSidebar = (state = INITIAL_STATE, action) => {
  return { ...state, visibleSidebar: true };
};

export const showUnassignedOrders = (state = INITIAL_STATE, action) => {
  return { ...state, visibleUnassignedOrders: true };
};

export const hideUnassignedOrders = (state = INITIAL_STATE, action) => {
  return { ...state, visibleUnassignedOrders: false };
};

export const openMapNewWindow = (state = INITIAL_STATE, action) => {
  return { ...state, isMapNewWindow: true };
};

export const closeMapNewWindow = (state = INITIAL_STATE, action) => {
  return { ...state, isMapNewWindow: false };
};

export const updateDispatchStore = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.dispatch,
});

export const HANDLERS = {
  [Types.SET_PLAN]: setPlan,
  [Types.SET_ROUND]: setRound,
  [Types.SET_TRANSPORT]: setTransport,
  [Types.PIN_SIDEBAR]: pinSidebar,
  [Types.UNPIN_SIDEBAR]: unpinSidebar,
  [Types.CLOSE_SIDEBAR]: closeSidebar,
  [Types.OPEN_SIDEBAR]: openSidebar,
  [Types.SHOW_UNASSIGNED_ORDERS]: showUnassignedOrders,
  [Types.HIDE_UNASSIGNED_ORDERS]: hideUnassignedOrders,
  [Types.OPEN_MAP_NEW_WINDOW]: openMapNewWindow,
  [Types.CLOSE_MAP_NEW_WINDOW]: closeMapNewWindow,
  [Types.UPDATE_DISPATCH_STORE]: updateDispatchStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);

// SELECTORS
const selectorPlan = state => state.dispatch.plan;
const selectorRound = state => state.dispatch.round;
const selectorTransport = state => state.dispatch.transport;
const selectorPinnedSidebar = state => state.dispatch.pinnedSidebar;
const selectorVisibleSidebar = state => state.dispatch.visibleSidebar;
const selectorVisibleUnassignedOrders = state =>
  state.dispatch.visibleUnassignedOrders;
const selectorIsMapNewWindow = state => state.dispatch.isMapNewWindow;

export const getPlan = createSelector(
  [selectorPlan],
  plan => plan
);

export const getRound = createSelector(
  [selectorRound],
  round => round
);

export const getTransport = createSelector(
  [selectorTransport],
  transport => transport
);

export const getPinnedSidebar = createSelector(
  [selectorPinnedSidebar],
  pinnedSidebar => pinnedSidebar
);

export const getVisibleSidebar = createSelector(
  [selectorVisibleSidebar],
  visibleSidebar => visibleSidebar
);

export const getVisibleUnassignedOrders = createSelector(
  [selectorVisibleUnassignedOrders],
  visibleUnassignedOrders => visibleUnassignedOrders
);

export const getIsMapNewWindow = createSelector(
  [selectorIsMapNewWindow],
  isMapNewWindow => isMapNewWindow
);
