import React from 'react';

import { ColoredIcon, ThemedInput } from 'components';

import InputButton from '../components/InputButton';

const LinkInput = ({
  name,
  value,
  required,
  disabled,
  showIframe,
  onChange,
  handleToggleShowIframe,
  handleOpenInNewTab,
}) => {
  return (
    <>
      <ThemedInput
        fluid
        actionPosition="left"
        name={name}
        required={required}
        disabled={disabled}
        value={value || ''}
        icon={
          value ? (
            <ColoredIcon
              name={showIframe ? 'eye slash' : 'eye'}
              link
              onClick={handleToggleShowIframe}
            />
          ) : (
            undefined
          )
        }
        action={
          <InputButton icon="external alternate" onClick={handleOpenInNewTab} />
        }
        onChange={onChange}
      />
      {value && showIframe && (
        <iframe
          title={name}
          src={value}
          frameBorder="0"
          width="100%"
          height="500px"
        />
      )}
    </>
  );
};

export default LinkInput;
