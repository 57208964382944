const getComponentValue = (valueTotal, formatTotal, formatComponent) => {
  const indexFound = formatTotal
    .toLowerCase()
    .indexOf(formatComponent.toLowerCase());

  if (indexFound >= 0) {
    return valueTotal.substr(indexFound, formatComponent.length);
  }
};

export default function convertToStandardFormat(value, localDateFormat) {
  // standard format is yyyy-MM-ddTHH:mm
  if (!value) {
    return '';
  }

  if (value.indexOf(' ') < 0 && value.indexOf(':') >= 0) {
    // type time
    return value;
  } else {
    // type date or datetime
    const yyyy = getComponentValue(value, localDateFormat, 'yyyy');
    const MM = getComponentValue(value, localDateFormat, 'MM');
    const dd = getComponentValue(value, localDateFormat, 'dd');

    if (value.indexOf(' ') >= 0) {
      // type date time
      const valueArray = value.split(' '); // get time

      if (
        valueArray[1].indexOf(':') > 0 &&
        valueArray[1].split(':').length === 2
      ) {
        valueArray[1] = `${valueArray[1]}:00`;
      } else if (
        !(
          valueArray[1].indexOf(':') > 0 &&
          valueArray[1].split(':').length === 3
        )
      ) {
        valueArray[1] = '00:00:00';
      }

      const time = valueArray[1];

      return `${yyyy}-${MM}-${dd}T${time}`;
    }

    return `${yyyy}-${MM}-${dd}`; // type date
  }
}
