import L from 'leaflet';

const markerHtmlStyles = color => `
  background-color: ${color};
  width: 1.4rem;
  height: 1.4rem;
  display: block;
  left: -0.7rem;
  top: -1.72rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
  border: 3px solid #FFFFFF;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.5);
`;

const getMarkerIcon = color =>
  L.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [0, 0],
    labelAnchor: [0, 0],
    tooltipAnchor: [0, -9.8],
    popupAnchor: [0, -19.6],
    html: `<span style="${markerHtmlStyles(color)}" />`,
  });

export default getMarkerIcon;
