import React from 'react';
import { string, func, bool, node } from 'prop-types';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  && {
    background: none;
  }
`;

const ModalContent = styled(Modal.Content)`
  ${({
    theme: {
      color: { fg, modalBg },
    },
  }) => `
    &&& {
      color: ${fg};
      background-color: ${modalBg};
    }
  `}
`;

const ActionModal = ({ open, onClose, children, size }) => (
  <StyledModal size={size} dimmer="blurring" open={open} onClose={onClose}>
    <ModalContent>{children}</ModalContent>
  </StyledModal>
);

ActionModal.propTypes = {
  size: string,
  onClose: func.isRequired,
  open: bool.isRequired,
  children: node,
};

ActionModal.defaultProps = {
  size: 'small',
  children: null,
};

export default ActionModal;
