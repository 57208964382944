import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Header, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { getIsSubmitting, ModalCreators } from 'core/modal/modalReducer';
import { MODAL_TYPE } from 'core/utils/constant';
import { ThemedButton, ThemedLabel } from 'components';

const Container = styled.div``;

const StyledHeader = styled(Header.Content)`
  ${({
    theme: {
      color: { fg },
    },
  }) => `
    && {
      &.content {
        color: ${fg};
        width: 100%;
      }
    }
  `}
`;

const StyledSubheader = styled(Header.Subheader)`
  ${({
    theme: {
      color: { popupFg },
    },
  }) => `
    &&& {
      &&.sub.header {
        color: ${popupFg};
      }
    }
  `}
`;

const Content = styled.div`
  display: block;
  width: 100%;
  font-size: 1em;
  line-height: 1.4;
`;

const ButtonToolbar = styled.div`
  margin-top: 1em;
  text-align: right;
`;

const PrimaryButton = ({
  btnTo,
  onBtnClick,
  btnTitle,
  popupInputs,
  disabled,
}) =>
  btnTo ? (
    <ThemedButton
      as={Link}
      primary
      disabled={disabled}
      to={btnTo}
      onClick={onBtnClick}
    >
      {btnTitle}
    </ThemedButton>
  ) : popupInputs ? (
    <ThemedButton
      primary
      form="popupForm"
      type="submit"
      disabled={disabled}
      onClick={onBtnClick}
    >
      {btnTitle}
    </ThemedButton>
  ) : (
    <ThemedButton primary disabled={disabled} onClick={onBtnClick}>
      {btnTitle}
    </ThemedButton>
  );

const SecondaryButton = ({
  secondaryBtnTo,
  onSecondaryBtnClick,
  secondaryBtnTitle,
  disabled,
}) =>
  secondaryBtnTo ? (
    <ThemedButton
      as={Link}
      disabled={disabled}
      to={secondaryBtnTo}
      onClick={onSecondaryBtnClick}
    >
      {secondaryBtnTitle}
    </ThemedButton>
  ) : (
    <ThemedButton disabled={disabled} onClick={onSecondaryBtnClick}>
      {secondaryBtnTitle}
    </ThemedButton>
  );

const PopupTemplateHeader = ({ t, type, modalType, title, children }) => (
  <Header as="h3">
    {!['popup-inputs-clean', 'form-action'].includes(modalType) && (
      <Icon
        color={type === MODAL_TYPE.warning ? 'yellow' : 'blue'}
        name={type === MODAL_TYPE.warning ? 'warning sign' : 'question circle'}
      />
    )}
    <StyledHeader>
      {!['popup-inputs-clean', 'form-action'].includes(modalType)
        ? `${t(`common|${type}`)} - ${title}`
        : title}
      <StyledSubheader>{children}</StyledSubheader>
    </StyledHeader>
  </Header>
);

const PopupTemplate = ({
  t,
  title,
  btnTo,
  onBtnClick,
  btnTitle,
  secondaryBtnTitle,
  secondaryBtnTo,
  onSecondaryBtnClick,
  children,
  warning,
  popupInputs,
  type = MODAL_TYPE.confirm,
  modalType,
  isSubmitting,
  updateIsSubmitting,
}) => {
  useEffect(() => {
    updateIsSubmitting(false);
  }, [updateIsSubmitting]);

  return (
    <Container>
      <Content>
        <PopupTemplateHeader
          t={t}
          type={type}
          modalType={modalType}
          title={title}
          children={children}
        />
        {warning && (
          <ThemedLabel basic color="red">
            {warning}
          </ThemedLabel>
        )}
      </Content>
      <ButtonToolbar>
        {btnTitle && (
          <PrimaryButton
            disabled={isSubmitting}
            btnTo={btnTo}
            btnTitle={btnTitle}
            onBtnClick={onBtnClick}
            popupInputs={popupInputs}
          />
        )}
        {secondaryBtnTitle && (
          <SecondaryButton
            disabled={isSubmitting}
            secondaryBtnTo={secondaryBtnTo}
            secondaryBtnTitle={secondaryBtnTitle}
            onSecondaryBtnClick={onSecondaryBtnClick}
          />
        )}
      </ButtonToolbar>
    </Container>
  );
};

const mapStateToProps = state => ({
  isSubmitting: getIsSubmitting(state),
});

const mapDispatchToProps = dispatch => ({
  updateIsSubmitting: isSubmitting =>
    dispatch(ModalCreators.updateIsSubmitting(isSubmitting)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PopupTemplate));
