import { IMAGE_URL } from 'core/utils/constant';

export default {
  drivers: [
    {
      id: 1,
      name: 'John P.',
      avatar: IMAGE_URL.logoMedium,
      capacity: 40,
      status: 'On transit',
    },
    {
      id: 2,
      name: 'Joe W.',
      avatar: IMAGE_URL.logoMedium,
      capacity: 0,
      status: 'Not working',
    },
    {
      id: 3,
      name: 'Alex D.',
      avatar: IMAGE_URL.logoMedium,
      capacity: 90,
      status: 'Not working',
    },
    {
      id: 4,
      name: 'Alex D.',
      avatar: IMAGE_URL.logoMedium,
      capacity: 90,
      status: 'Not working',
    },
    {
      id: 5,
      name: 'Alex D.',
      avatar: IMAGE_URL.logoMedium,
      capacity: 90,
      status: 'Not working',
    },
    {
      id: 6,
      name: 'Alex D.',
      avatar: IMAGE_URL.logoMedium,
      capacity: 90,
      status: 'Not working',
    },
  ],
};
