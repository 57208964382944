import styled from 'styled-components';

const ThemedFieldSet = styled.fieldset`
  ${({
    theme: {
      color: { borderPrincipal },
    },
  }) => `
    border-radius: 5px;
    border-color: ${borderPrincipal} !important;
    border-style: solid;
    border-width: 1px;
  `}
`;

export default ThemedFieldSet;
