import moment from 'moment';
import _ from 'lodash';
import { INVALID_DATE } from 'core/utils/constant';

export default function convertToLocalFormat(value, localDateFormat) {
  if (!value) {
    return '';
  }

  if (value === INVALID_DATE) {
    return INVALID_DATE;
  }

  if (!_.isEmpty(value.trim())) {
    if (localDateFormat) {
      const isValid = value => moment(value, localDateFormat, true).isValid();
      const dateFormatted = value =>
        moment(new Date(value)).format(localDateFormat);

      // have date format => contains date
      if (value.indexOf('|') >= 0) {
        // is type date range and all 2 dates are selected
        const dateSplit = value.split('|');
        let date1 = dateSplit[0];
        let date2 = dateSplit[1];

        if (!isValid(dateSplit[0]) && !isValid(dateSplit[1])) {
          date1 = dateFormatted(dateSplit[0]);
          date2 = dateFormatted(dateSplit[1]);
        }

        return `${date1} - ${date2}`;
      } else if (value.indexOf(' - ') >= 0) {
        // is type date range but not selected all 2 dates
        // then return the value itself
        return value;
      } else {
        return isValid(value) ? value : dateFormatted(value);
      }
    }
  }

  // is time
  return value;
}
