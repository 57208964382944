import React, { useState } from 'react';
import { arrayOf, object, func } from 'prop-types';
import _ from 'lodash';

import { CustomPage, TilesPanel } from 'components';

const TemplateGridStep = ({ steps, getVisibleRows, ...pageProps }) => {
  const [activeStepID, setActiveStepID] = useState(steps[0].stepID);

  const handleChange = (e, stepID) => {
    setActiveStepID(stepID);
  };

  const stepsAsRoutes = _.map(
    steps,
    ({ stepID, name, color, icon, iconColor }) => ({
      key: stepID,
      name,
      active: activeStepID === stepID,
      color,
      icon,
      iconColor,
      handleChangeTile: e => handleChange(e, stepID),
    })
  );

  const getStepsWithContents = _.filter(
    steps,
    ({ stepID, contents }) => stepID === activeStepID && !_.isEmpty(contents)
  );

  return (
    <>
      <TilesPanel scroll routes={stepsAsRoutes} />
      {_.map(
        getStepsWithContents,
        ({ stepID, name, contents, ...props }) =>
          getVisibleRows(contents) && (
            <CustomPage key={stepID} pageContent={contents} {...pageProps} />
          )
      )}
    </>
  );
};

TemplateGridStep.propTypes = {
  steps: arrayOf(object).isRequired,
  getVisibleRows: func.isRequired,
};

export default TemplateGridStep;
