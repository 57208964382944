import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';

import {
  getIsMapNewWindow,
  DispatchCreators,
} from 'core/dispatch/dispatchReducer';
import Utils from 'core/utils/utils';
import { useForm } from 'core/hooks/useForm';
import { ThemedButton, ThemedSegment } from 'components';
import { DISPATCH_PATHNAME } from 'core/utils/constant';
import mapApi, { MAP_QUERY } from 'api/map/mapApi';

import FasterMap from './FasterMap';
import {
  MAP_DATA,
  clearStorageMapState,
  keepStorageMapState,
  parseAndClearMapStorage,
} from './helpers/storage';
import maskRowToMarker from './helpers/maskRowToMarker';

const { IS_MAP_NEW_WINDOW } = MAP_DATA;

const StyledDiv = styled.div`
  ${({ hidden }) => `
    margin: -1em -1em 0;
    ${hidden ? `display: none;` : ''}
  `}
`;

const FasterMapContainer = ({
  // 3rd-party props
  className,
  // mapProps data
  maskStructure,
  maskRows,
  area,
  module,
  updateCriteriaFunc,
  // map props
  hidden,
  withToolbar = false,
  history,
  location: { pathname },
  isMapNewWindow,
  closeMapNewWindow,
  openMapNewWindow,
}) => {
  const { viewport } = maskStructure || {};

  const [mapProps, , setMapProp] = useForm({
    mapViewport: viewport,
    markers: [],
    markerIndex: 0,
    locationsOptimized: [],
    isError: false,
    isMapped: false,
    toggleMapContent: '',
    handleToggleMap: null,
  });

  useEffect(() => {
    if (maskStructure && maskRows) {
      setMapProp(
        'markers',
        _.map(maskRows, mr => maskRowToMarker(maskStructure, mr))
      );
    }
  }, [maskStructure, maskRows, setMapProp]);

  const lsIsMapNewWindow = localStorage.getItem(IS_MAP_NEW_WINDOW);
  const isDispatchPathname = pathname === DISPATCH_PATHNAME;

  useEffect(() => {
    // Detect changes of isNewMapWindow
    window.addEventListener(
      'storage',
      () => {
        if (lsIsMapNewWindow === null) {
          closeMapNewWindow();

          if (isDispatchPathname) {
            window.close();
          } else {
            parseAndClearMapStorage(setMapProp);
          }
        }
      },
      false
    );

    // Close Map when close tab
    window.addEventListener('beforeunload', () => {
      if (lsIsMapNewWindow) {
        keepStorageMapState();
      }
      closeMapNewWindow();
    });
  }, [closeMapNewWindow, setMapProp, lsIsMapNewWindow, isDispatchPathname]);

  const handleOpenNewMap = useCallback(() => {
    keepStorageMapState(mapProps);
    localStorage.setItem(IS_MAP_NEW_WINDOW, true);
    openMapNewWindow();
    window.open(DISPATCH_PATHNAME, '_blank');
  }, [mapProps, openMapNewWindow]);

  const handleCloseMap = useCallback(() => {
    keepStorageMapState(mapProps);
    localStorage.removeItem(IS_MAP_NEW_WINDOW);
    closeMapNewWindow();

    if (isDispatchPathname) {
      window.close();
    }
  }, [closeMapNewWindow, mapProps, isDispatchPathname]);

  useEffect(() => {
    if (!lsIsMapNewWindow) {
      // Must enter from dispatch page
      if (isDispatchPathname) {
        history.push('/dispatch');
      }

      setMapProp('toggleMapContent', 'Open');
      setMapProp('handleToggleMap', handleOpenNewMap);
    } else {
      // Keep current state
      parseAndClearMapStorage();

      setMapProp('toggleMapContent', 'Close');
      setMapProp('handleToggleMap', handleCloseMap);
    }
  }, [
    setMapProp,
    history,
    lsIsMapNewWindow,
    isDispatchPathname,
    // TODO: Need to check re-render problem when pass object state as useEffect dependencies
    // handleOpenNewMap,
    // handleCloseMap,
  ]);

  const addMarker = e => {
    setMapProp('markerIndex', mapProps.markerIndex + 1);
    setMapProp('markers', [
      ...mapProps.markers,
      [e.latlng.lat, e.latlng.lng, mapProps.markerIndex],
    ]);
  };

  const onOptimize = async e => {
    const { markers } = mapProps;

    let points = [];
    markers.forEach(m => {
      points.push(`${m[0]},${m[1]}`);
    });

    const data = await mapApi({ points }, MAP_QUERY.MATRIX_SYMMETRIC);

    if (data !== undefined) {
      // route optimized
      if (_.isEmpty(data)) {
        setMapProp('isError', true);
      } else {
        const tmp = [];
        for (let i = 0; i < data.length; i++) {
          const points = data[i].pointsOrdered;
          const path = Utils.decodePath(data[i].paths[0].points, false);
          const vehicle = data[i].vehicle;
          tmp.push({ points, path, vehicle });
        }

        setMapProp('isError', false);
        setMapProp('locationsOptimized', tmp);
      }
    }
  };

  // TODO: Check using of useMap after remove mapRef
  const clearMap = map => {
    for (const i in map._layers) {
      if (map._layers[i]._path !== undefined) {
        try {
          map.removeLayer(map._layers[i]);
        } catch (e) {
          console.log('problem with ' + e + map._layers[i]);
        }
      }
    }
  };

  const onReset = () => {
    setMapProp('markers', []);
    setMapProp('markerIndex', 0);
    setMapProp('locationsOptimized', null);
    clearMap();
    clearStorageMapState();
  };

  const handleOpenDocument = (key, newTab) => {
    if (area || module) {
      const urlNav = Utils.buildUrlNav(area, module, key);

      if (newTab) {
        window.open(urlNav, '_blank');
      } else {
        history.push(urlNav);
      }
    }
  };

  const {
    mapViewport,
    markers,
    locationsOptimized,
    isError,
    toggleMapContent,
    handleToggleMap,
  } = mapProps;

  return (
    <StyledDiv className={className} hidden={hidden}>
      {withToolbar && (
        <ThemedSegment basic>
          <ThemedButton
            size="mini"
            content="Optimize Matrix"
            onClick={onOptimize}
          />
          <ThemedButton size="mini" content="Reset" onClick={onReset} />
          <ThemedButton
            size="mini"
            content={`${toggleMapContent} map in new tab`}
            onClick={() => handleToggleMap()}
          />
        </ThemedSegment>
      )}
      <FasterMap
        mapViewport={mapViewport}
        markers={markers}
        locationsOptimized={locationsOptimized}
        addMarker={withToolbar ? addMarker : undefined}
        handleOpenDocument={handleOpenDocument}
      />
      {isError && <ThemedSegment>Error</ThemedSegment>}
    </StyledDiv>
  );
};

const mapStateToProps = state => ({
  isMapNewWindow: getIsMapNewWindow(state),
});

const mapDispatchToProps = dispatch => ({
  closeMapNewWindow: () => dispatch(DispatchCreators.closeMapNewWindow()),
  openMapNewWindow: () => dispatch(DispatchCreators.openMapNewWindow()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FasterMapContainer));
