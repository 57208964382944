import React from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import CardLeftSegment from './CardLeftSegment';
//import CardRightSegment from './CardRightSegment';

const SegmentGroup = styled(Segment.Group)`
  &&& {
    background-color: ${({ theme: { color } }) => color.tableBg};
    color: ${({ theme: { color } }) => color.tableFg};
    border-color: ${({ theme: { color } }) => color.tableBorderColor};
  }
`;

const Card = ({ card, handleEdit }) => {
  const { key, title, color } = card;

  const handleClickEdit = () => {
    if (handleEdit) {
      handleEdit(key);
    }
  };

  return (
    <SegmentGroup horizontal>
      <CardLeftSegment
        title={title}
        color={color}
        handleEdit={handleClickEdit}
      />
      {/*<CardRightSegment handleEdit={handleClickEdit} />*/}
    </SegmentGroup>
  );
};

export default Card;
