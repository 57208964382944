import React from 'react';
import { withTranslation } from 'react-i18next';
import { Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

import { Center, ThemedPagination } from 'components';

import { TableTotalRows } from './TableTotalItems';
import ThemedPlaceholder from '../../theme/ThemedPlaceholder';

const StyledPlaceholder = styled(ThemedPlaceholder)`
  &&&&& {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;

const Footer = styled.div`
  ${({
    theme: {
      color: { tableFg },
    },
  }) => `
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    color: ${tableFg};
  `}
`;

const TableRowFooter = ({
  totalItems,
  totalPages,
  activePage,
  handlePageChange,
  loading,
}) => {
  return loading ? (
    <StyledPlaceholder>
      <Placeholder.Line />
    </StyledPlaceholder>
  ) : (
    <Footer>
      <div>
        <TableTotalRows totalItems={totalItems} />
      </div>
      <Center>
        <ThemedPagination
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          firstItem={null}
          lastItem={null}
          prevItem={null}
          nextItem={null}
        />
      </Center>
    </Footer>
  );
};

export default withTranslation()(TableRowFooter);
