import styled from 'styled-components';

// For support Dropdown required field
const InputHidden = styled.input`
  &&& {
    opacity: 0;
    height: 0;
    padding: 0 !important;
    border: none;
    width: 0;
    float: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export default InputHidden;
