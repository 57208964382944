import { computeDayDateRange } from '../../agenda/helpers/initializeAgendaCriteria';
import { setCriterion } from '../../tables/helpers/tableCriteriaHelpers';

// add filter by date range to table criteria
const intializeTimelineCriteria = (timeline, criteria) => {
  const {
    view: { date },
  } = timeline;
  const { dateRange } = computeDayDateRange(new Date());

  setCriterion(criteria, { key: date, value: dateRange });
};

export default intializeTimelineCriteria;
