import { Message } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedMessage = styled(Message)`
  ${({
    theme: {
      color: { inputBg, inputFg },
    },
  }) => `
    &&& {
      color: ${inputFg};
      background-color: ${inputBg};
      margin: 0;
    }
  `}
`;

export default ThemedMessage;
