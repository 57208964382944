import axios from 'axios';

import { DISPATCH_BASE_URL } from 'core/utils/constant';

import {
  GHRequestInfo,
  GHResponseInfo,
  MatrixSymmetric,
  OptimizeModel,
} from './models';

export const MAP_QUERY = {
  MATRIX_SYMMETRIC: 'matrix-sym',
  TSP: 'tsp',
};

const getPayload = (data, query) => {
  const { points, noVehicle } = data;

  const ghRequestInfo = new GHRequestInfo();
  const ghResponseInfo = new GHResponseInfo();

  if (query === MAP_QUERY.MATRIX_SYMMETRIC)
    return new MatrixSymmetric({
      points,
      ghRequestInfo,
      ghResponseInfo,
    });
  else
    return new OptimizeModel({
      points,
      noVehicle,
      ghRequestInfo,
      ghResponseInfo,
    });
};

const pageApi = {
  post: (data, query) => {
    try {
      return axios.post(
        `${DISPATCH_BASE_URL}/${query}`,
        getPayload(data, query)
      );
    } catch (e) {
      console.error(e);
    }
  },
};

export default pageApi;
