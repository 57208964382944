import { history } from '../../config/store';

class AuthService {
  constructor() {
    this.store = null;
    this.persistor = null;
  }

  init = (store, persistor) => {
    this.store = store;
    this.persistor = persistor;
  };

  logout = area => {
    const path = area ? '/' + area.key : '';
    this.store.dispatch({ type: 'RESET' });
    sessionStorage.removeItem('auth');
    sessionStorage.removeItem('persist:root');

    // Logout all other sessions
    localStorage.setItem('SESSIONS_FLUSH', Date.now().toString());
    localStorage.removeItem('SESSIONS_FLUSH');

    history.push(path + '/public/login');
    const purgePromise = this.persistor.purge();
    return Promise.all([purgePromise]);
  };
}

export default new AuthService();
