import React from 'react';
import { func, object } from 'prop-types';
import { withTranslation } from 'react-i18next';

import appPreferenceApi from 'api/appPreference/appPreferenceApi';
import preferencesStructure from 'core/dto/user/preferencesStructure';

import { EntityInfo } from 'components';

const Preferences = ({
  t,
  appPreference,
  handleSetKey,
  handleEntityChange,
}) => {
  return (
    <EntityInfo
      autosave
      icon="sliders horizontal"
      label={t('common|Preferences')}
      api={appPreferenceApi}
      entityKey={appPreference.appPreferenceID}
      keyName={'appPreferenceID'}
      entity={appPreference}
      type={'appPreference'}
      structure={preferencesStructure}
      onEntityChange={handleEntityChange}
      onSetKey={handleSetKey}
    />
  );
};

Preferences.propTypes = {
  t: func.isRequired,
  appPreference: object.isRequired,
  handleSetKey: func.isRequired,
  handleEntityChange: func.isRequired,
};

export default withTranslation()(Preferences);
