import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { withTranslation } from 'react-i18next';

import { getTemplateContents } from 'core/utils/form';
import { CustomPage } from 'components';

import TemplateHeader from './components/TemplateHeader';
import TemplateGridStep from './components/TemplateGridStep';

const Template = ({
  // state
  focusedInput,
  startDate,
  endDate,
  fieldsNeedToUpdate,
  // props
  template,
  documentID,
  formData,
  visibleFields,
  visibleCategories,
  viewingUsers,
  getVisibleRows,
  onAutoFields,
  onDatesChange,
  onFocusChange,
  onAutoComplete,
  histories,
  area,
  module,
  // props in file
  t,
}) => {
  const { templateId, name, steps, backgroundColor, color } = template;

  const pageProps = {
    area,
    module,
    histories,
    templateName: name,
    documentID,
    viewingUsers,
    onAutoFields,
    fieldsNeedToUpdate,
    formData,
    visibleFields,
    visibleCategories,
    focusedInput,
    startDate,
    endDate,
    onDatesChange,
    onFocusChange,
    onAutoComplete,
    backgroundColor,
    color,
  };

  const contents = getTemplateContents(template);

  return (
    <Fragment key={templateId}>
      <TemplateHeader
        t={t}
        name={name}
        documentID={documentID}
        viewingUsers={viewingUsers}
        backgroundColor={backgroundColor}
        color={color}
      />
      {steps && steps.length > 1 ? (
        <TemplateGridStep
          steps={steps}
          getVisibleRows={getVisibleRows}
          {...pageProps}
        />
      ) : (
        getVisibleRows(contents) && (
          <CustomPage pageContent={contents} {...pageProps} />
        )
      )}
    </Fragment>
  );
};

Template.propTypes = {
  template: object.isRequired,
};

export default withTranslation()(Template);
