import BaseApi from 'api/baseApi';

const areaApi = {
  getAll: params => BaseApi.get('area', params),
  get: (key, params) => BaseApi.get(`area/${key}`, params),
  put: (area, params) => BaseApi.put('area', { area }, params),
  post: (area, params) => BaseApi.post('area', { area }, params),
};

export default areaApi;
