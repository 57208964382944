import _ from 'lodash';

const getItemFromValue = (items, value, categorize = false) => {
  let item;

  if (
    (!categorize && _.some(items, ['value', value])) ||
    (categorize &&
      _.some(items, i => i.items && _.some(i.items, ['value', value])))
  ) {
    if (categorize) {
      const flatOpts = _.chain(items)
        .flatMap(o => o.items)
        .compact()
        .value();

      item = _.find(flatOpts, ['value', value]);
    } else {
      item = _.find(items, ['value', value]);
    }
  } else {
    item = value
      ? {
          key: value,
          text: value,
          value,
        }
      : undefined;
  }

  return item;
};

const getActiveDataFromValue = (
  items,
  value,
  categorize = false,
  multiple = false
) => {
  let data;

  if (multiple) {
    if (value && !Array.isArray(value)) {
      if (value.includes('|')) {
        value = value.split('|');
      } else {
        value = [value];
      }
    }

    data = _.map(value, v => getItemFromValue(items, v));
  } else {
    data = getItemFromValue(items, value, categorize);
  }

  return data;
};

export default getActiveDataFromValue;
