import React, { useEffect, useState, useRef } from 'react';
import { any, arrayOf, bool, func, shape, string } from 'prop-types';

import { LIMIT_CHAR } from 'core/utils/constant';
import getValueMatch from 'core/utils/pattern/getValueMatch';

import getOptions from './helpers/getOptions';
import getSearchedOptions from './helpers/getSearchedOptions';
import getActiveDataFromValue from './helpers/getActiveDataFromValue';
import showClearAction from './helpers/showClearAction';
import FasterDropdown from './FasterDropdown';

const FasterDropdownContainer = ({
  loading,
  value,
  tracked,
  multiple,
  editable,
  categorize,
  searchable,
  clearable,
  options,
  pattern,
  onChange,
  onBlur,
  // ignored props
  selection,
  selectOnBlur,
  noResultsMessage,
  // rest of props
  ...props
}) => {
  const optReady = useRef(false);
  const valReady = useRef(false);
  const optRef = useRef(getOptions(options));
  const valRef = useRef(value);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState();
  const [activeData, setActiveData] = useState(
    getActiveDataFromValue(optRef.current, valRef.current, categorize, multiple)
  );
  const [items, setItems] = useState(optRef.current);

  // rerender when controlled options changed
  useEffect(() => {
    if (optReady.current) {
      if (options) {
        optRef.current = getOptions(options);
        setItems(optRef.current);
        setActiveData(
          getActiveDataFromValue(
            optRef.current,
            valRef.current,
            categorize,
            multiple
          )
        );
      }
    } else {
      optReady.current = true;
    }
  }, [categorize, multiple, options]);

  // rerender when controlled value changed
  useEffect(() => {
    if (valReady.current) {
      valRef.current = value;
      setActiveData(
        getActiveDataFromValue(optRef.current, value, categorize, multiple)
      );
    } else {
      valReady.current = true;
    }
  }, [categorize, multiple, value]);

  // open list on input click for searchable and editable dropdowns
  const handleMouseDown = (e, data) => {
    if (
      showClearAction(
        clearable,
        multiple,
        activeData,
        searchable,
        editable,
        open
      ) &&
      e &&
      e.target &&
      e.target.className.includes('dropdown icon')
    ) {
      const newData = multiple ? [] : undefined;
      setActiveData(newData);

      handleChange(e, { ...data, value: newData });

      if (!open) {
        handleBlur(e, data, true);
      }
    } else if (!open) {
      setOpen(true);

      if (searchable || editable) {
        if (activeData) {
          setSearch(activeData.text);

          if (searchable) {
            setItems(
              getSearchedOptions(activeData.text, optRef.current, categorize)
            );
          }
        }
      }
    } else if (open && !searchable && !editable) {
      if (
        e &&
        e.target &&
        e.target.className &&
        (e.target.className.includes('item') ||
          e.target.className.includes('menu transition') ||
          (e.target.parentElement &&
            e.target.parentElement.className.includes('item')))
      ) {
        // do not close if clicking item or scrollbar
        return;
      } else {
        close();
      }
    }
  };

  // clear search and close
  const close = () => {
    if (searchable || editable) {
      setSearch('');
    }

    setOpen(false);
  };

  // handle click outside
  const handleBlur = (e, data, clear = false) => {
    if (searchable && (!editable || (editable && clear))) {
      setItems(optRef.current);
    }

    close();

    if (onBlur) {
      onBlur(e, data);
    }
  };

  // handle click on an item in list
  const handleClickItem = (e, data) => {
    if (multiple) {
      const newData = data.active
        ? activeData.filter(d => d.value !== data.value)
        : [...activeData, data];

      if (onChange) {
        handleChange(e, { value: newData.map(d => d.value) });
      } else {
        setActiveData(newData);
      }
    } else {
      if (onChange) {
        handleChange(e, data);
      } else {
        setActiveData(data);
      }

      close();
    }
  };

  // handle typing in input
  const handleSearchChange = (e, data) => {
    let searchQuery = data.searchQuery;

    if (pattern) {
      const sEn = e.target.selectionEnd;
      searchQuery = getValueMatch(pattern, search, data.searchQuery, sEn);
    }

    setSearch(
      searchQuery && searchQuery.length > LIMIT_CHAR
        ? searchQuery.slice(0, LIMIT_CHAR)
        : searchQuery
    );

    if (searchQuery === '' || (searchQuery && searchQuery.length > 0)) {
      if (searchable) {
        // filter items based on search
        const newItems = getSearchedOptions(searchQuery, options, categorize);
        setItems(newItems);
      }

      if (editable) {
        // create item from edit
        const data = {
          text: searchQuery,
          value: searchQuery,
        };
        const addition = {
          key: searchQuery,
          ...data,
        };

        // set selected item
        if (onChange) {
          handleChange(e, data);
        } else {
          setActiveData(addition);
        }
      }
    } else {
      setItems(optRef.current);
      setActiveData(valRef.current);
    }
  };

  // notify of change
  const handleChange = (e, data, type) => {
    if (onChange) {
      // convert local event (item click data) to field event (dropdown onchange)
      const staticProps = {
        multiple,
        editable,
        categorize,
        searchable,
        clearable,
        ...props,
        ...data,
      };

      onChange(e, staticProps);
    }
  };

  const dropdownProps = {
    selectOnBlur: false,
    noResultsMessage: undefined,
    ...props,
  };

  const fasterProps = {
    loading,
    activeData,
    items,
    open,
    tracked,
    search,
    multiple,
    editable,
    categorize,
    searchable,
    clearable,
    handleMouseDown,
    handleClickItem,
    handleSearchChange,
    handleBlur,
    dropdownProps,
  };

  return <FasterDropdown {...fasterProps} />;
};

FasterDropdownContainer.propTypes = {
  value: any,
  tracked: bool,
  multiple: bool,
  editable: bool,
  categorize: bool,
  searchable: bool,
  clearable: bool,
  options: arrayOf(
    shape({
      key: any,
      value: any,
      text: string,
    })
  ).isRequired,
  onChange: func,
  onBlur: func,
};

export default FasterDropdownContainer;
