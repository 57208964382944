import React, { useState, useRef } from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

import { Tooltip } from 'components';
import ThemedPopup from '../theme/ThemedPopup';

const isIconCircularPrimaryInverted = ({ icon, circular, primary, inverted }) =>
  icon && circular && primary && inverted;

const InlineBlock = styled.div`
  display: inline-block;
`;

const StyledButton = styled(Button)`
  ${({
    theme: {
      color: { primary, headerItemBgHover },
    },
    ...props
  }) => `
    &&&&& {
      ${isIconCircularPrimaryInverted(props) &&
        `
          color: ${primary};
          background: transparent;
          box-shadow: none !important;

          &:focus,
          &:hover {
            background: ${headerItemBgHover}
          }
        `}
    }
  `}
`;

const Popuptrigger = ({ buttonProps, popupProps, tooltipProps }) => {
  const context = useRef();
  const triggerRef = useRef();
  const pendingLeave = useRef(false);
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  const cancelPendingLeave = () => {
    pendingLeave.current = false;
  };

  const handleClose = (e, data) => {
    cancelPendingLeave();
    setOpen(false);
    triggerRef.current.ref.current.blur();
  };

  const handleEnter = e => {
    cancelPendingLeave();
  };

  const handleLeave = e => {
    pendingLeave.current = true;

    setTimeout(() => {
      if (pendingLeave.current) {
        handleClose(e);
      }
    }, 400);
  };

  const ButtonContainer = ({ children }) =>
    tooltipProps ? (
      <Tooltip {...tooltipProps}>{children}</Tooltip>
    ) : (
      <>{children}</>
    );

  return (
    <>
      <ButtonContainer>
        <InlineBlock ref={context}>
          <StyledButton
            ref={triggerRef}
            onClick={toggle}
            onMouseEnter={handleClose}
            {...buttonProps}
          />
        </InlineBlock>
      </ButtonContainer>
      <ThemedPopup
        context={context}
        open={open}
        onClose={handleClose}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        onClick={handleClose}
        {...popupProps}
      />
    </>
  );
};

export default Popuptrigger;
