import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';

import { ModalCreators, getModal } from 'core/modal/modalReducer';
import { DispatchCreators } from 'core/dispatch/dispatchReducer';
import TimelineTable from './TimelineTable';
import { Ref } from 'semantic-ui-react';
import useClickAwayListener from 'core/utils/useClickAwayListener';

const TimelineTableContainer = ({
  data,
  setRound,
  setTransport,
  openSidebar,
  ...props
}) => {
  const timelineTableRef = useRef();

  const [activeItem, setActiveItem] = useState(undefined);

  const handleRowClick = key => {
    setActiveItem(key);
  };

  useClickAwayListener(timelineTableRef, activeItem, () => {
    handleRowClick(undefined);
  });

  return (
    <Ref innerRef={timelineTableRef}>
      <TimelineTable
        {...props}
        data={data}
        activeItem={activeItem}
        handleRowClick={handleRowClick}
        openSidebar={openSidebar}
        setRound={setRound}
        setTransport={setTransport}
      />
    </Ref>
  );
};

const mapStateToProps = state => ({
  modal: getModal(state),
});

const mapDispatchToProps = dispatch => ({
  openModal: (modalType, options) =>
    dispatch(ModalCreators.openModal(modalType, options)),
  closeModal: () => dispatch(ModalCreators.closeModal()),
  setRound: round => dispatch(DispatchCreators.setRound(round)),
  setTransport: transport => dispatch(DispatchCreators.setTransport(transport)),
  openSidebar: () => dispatch(DispatchCreators.openSidebar()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineTableContainer);
