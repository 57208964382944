import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, Image } from 'semantic-ui-react';
import styled from 'styled-components';

import { ThemedLabel } from 'components';

import ImageSrcValidator from './ImageSrcValidator';

const StyledImage = styled(Image)`
  ${({ onClick }) => (onClick ? `cursor: pointer;` : '')}
  margin: 0 auto;
`;

const ImagePreview = ({ t, url, data, size, onClick }) => {
  const src = data ? data : url ? url : undefined;

  const ErrorComponent = (
    <ThemedLabel basic color="red">
      <Icon name="image outline" />
      {`${t('Image not found')}${url ? ` (${url})` : ''}`}
    </ThemedLabel>
  );

  return !src ? (
    <></>
  ) : (
    <ImageSrcValidator
      src={src}
      errorComponent={ErrorComponent}
      children={<StyledImage src={src} size={size} onClick={onClick} />}
    />
  );
};

export default withTranslation()(ImagePreview);
