import React, { useState, useEffect } from 'react';
import {
  bool,
  func,
  string,
  arrayOf,
  object,
  oneOfType,
  number,
} from 'prop-types';
import { Form, Icon } from 'semantic-ui-react';
import _ from 'lodash';

import { ThemedSearch } from 'components';
import getOptions from './helpers/getOptions';

const resultRenderer = ({ title }, isAddition) =>
  isAddition ? `Add ${title}` : title;

const FasterSearch = ({
  editable,
  clearable,
  label,
  required,
  name,
  value,
  options,
  onChange,
  onFocus,
  onBlur,
  onClickLabel,
  ...props
}) => {
  const isCategory = _.some(
    options,
    o => o.items !== undefined && o.items !== null
  );

  const [innerValue, setInnerValue] = useState(value);
  const [initOptions, setInitOptions] = useState(
    getOptions(options, isCategory, editable, value)
  );
  const [loading, setLoading] = useState(false);
  const [keySuggestions, setKeySuggestions] = useState(initOptions);
  const [isAddition, setIsAddition] = useState(false);

  useEffect(() => {
    if (value) {
      setInnerValue(value);
      const re = new RegExp(_.escapeRegExp(value), 'i');
      const isMatch = result => re.test(result.title);
      const newKeySuggestions = _.filter(initOptions, isMatch);
      setKeySuggestions(newKeySuggestions);
    }
  }, [value, initOptions]);

  const handleSearchChange = (e, field) => {
    setLoading(true);
    setInnerValue(field.value);

    setTimeout(() => {
      if (field.value && field.value.length < 1) {
        setLoading(false);
        setKeySuggestions(initOptions);
        return;
      }
      const re = new RegExp(_.escapeRegExp(field.value), 'i');
      const isMatch = result => re.test(result.title);
      const newKeySuggestions = _.filter(initOptions, isMatch);

      if (_.isEmpty(newKeySuggestions)) {
        newKeySuggestions.push({ title: field.value });
        if (isAddition === false) {
          setIsAddition(true);
        }
      } else {
        if (isAddition === true) {
          setIsAddition(false);
        }
      }

      setLoading(false);
      setKeySuggestions(newKeySuggestions);
    }, 300);
  };

  const handleResultSelect = (e, field) => {
    onChange(e, field);

    if (isAddition) {
      setInitOptions(oldOptions => [...oldOptions, { title: field.value }]);
    }
  };

  let icon = 'search';

  if (clearable) {
    const clearInput = e => {
      e.preventDefault();
      e.stopPropagation();
      if (innerValue) {
        setInnerValue('');
        setKeySuggestions(initOptions);
      }
    };

    if (innerValue !== undefined && innerValue !== '') {
      icon = <Icon name="delete" link onClick={clearInput} />;
    }
  }

  return (
    <Form.Field className="column" required={required} {...props}>
      {label && (
        <label
          style={onClickLabel ? { cursor: 'pointer' } : undefined}
          onClick={onClickLabel}
        >
          {label}
        </label>
      )}
      <ThemedSearch
        loading={loading}
        category={isCategory}
        name={name}
        value={innerValue}
        onSearchChange={_.debounce(handleSearchChange, 500, {
          leading: true,
        })}
        results={keySuggestions}
        onResultSelect={handleResultSelect}
        resultRenderer={data => resultRenderer(data, isAddition)}
        showNoResults={false}
        onFocus={onFocus}
        onBlur={onBlur}
        icon={icon}
        {...props}
      />
    </Form.Field>
  );
};

FasterSearch.propTypes = {
  label: string,
  name: string,
  value: oneOfType([string, number]),
  options: arrayOf(object),
  onChange: func.isRequired,
  onFocus: func,
  onBlur: func,
  required: bool,
  onClickLabel: func,
  editable: bool,
  clearable: bool,
};

FasterSearch.defaultProps = {
  label: undefined,
  name: undefined,
  value: undefined,
  options: [],
  required: false,
  onClickLabel: undefined,
  onFocus: undefined,
  onBlur: undefined,
  editable: false,
  clearable: false,
};

export default FasterSearch;
