import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import FasterDropdown from 'components/input/dropdown/FasterDropdown';

import _ from 'lodash';

const AdditionsInput = ({
  t,
  options,
  handleAddition,
  handleChange,
  handleFocusChange,
  handleOnClose,
  value,
  name,
  multiple = false,
  clearable = false,
}) => {
  const [, setSearchQuery] = useState('');

  useEffect(() => {
    if (value && value !== '') {
      setSearchQuery(value);
    }
  }, []);

  const onChange = (e, field) => {
    const { value } = field && field.value ? field : e.target;

    // if (!multiple) {
    //   setSearchQuery(value); // keep entered value when focus back to this input after selection.
    // }

    setSearchQuery(value);

    handleChange(e, field);
  };

  //const onSearchChange = (e, field) => {
  //  setSearchQuery(field.searchQuery);

  //  handleChange(e, { name: field.name, value: field.searchQuery });
  //};

  // const searchQueryProps = multiple
  //   ? {}
  //   : {
  //       onSearchChange: (e, data) => onSearchChange(e, data),
  //       searchQuery: searchQuery,
  //     };

  return (
    <FasterDropdown
      // editable
      name={name}
      value={value}
      options={_.uniqBy(
        _.concat(
          [{ key: '', value: '', text: '' }],
          options.map(o => {
            return { key: o.value, value: o.value, text: o.text };
          })
        ),
        'value'
      )}
      onAddItem={handleAddition}
      onChange={onChange}
      onClose={handleOnClose}
      onFocus={handleFocusChange}
      onBlur={handleFocusChange}
      clearable={clearable}
      // {...searchQueryProps}
    />
  );
};

export default withTranslation()(AdditionsInput);
