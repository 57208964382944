import moment from 'moment';
import _ from 'lodash';

const defaultDateFormat = 'YYYY-MM-DD';
const altDateFormat = 'DD/MM/YYYY';

const defaultTimeFormat = 'HH:mm';

moment.updateLocale('ca', { longDateFormat: { L: defaultDateFormat } });
moment.updateLocale('be', { longDateFormat: { L: altDateFormat } });
moment.updateLocale('fr', { longDateFormat: { L: altDateFormat } });
moment.updateLocale('ch', { longDateFormat: { L: altDateFormat } });

const getLocaleCode = () => {
  const langCode = _.toLower(localStorage.getItem('faster-lang'));
  const countryCode = sessionStorage.getItem('countryCode');

  return langCode && countryCode
    ? `${langCode}-${countryCode}`
    : langCode
    ? langCode
    : countryCode
    ? countryCode
    : `fr-ca`;
};

const getDateFormatString = (locale = getLocaleCode()) => {
  moment.locale(locale);
  let dateFormat = defaultDateFormat;

  if (['fr', `fr-fr`, `fr-ch`, `fr-be`].includes(locale)) {
    dateFormat = altDateFormat;
  }

  return dateFormat;
};

const getDateFormatted = (isoString, dateFormat = getDateFormatString()) => {
  return getDateTimeFormatted(isoString, dateFormat, null);
};

const getDateTimeFormatted = (
  isoString,
  dateFormat = getDateFormatString(),
  timeFormat = defaultTimeFormat
) => {
  if (!timeFormat) {
    return moment(isoString).format(`${dateFormat}`);
  } else {
    return moment(isoString).format(`${dateFormat} ${timeFormat}`);
  }
};

const getMoment = (
  dateString,
  dateFormat = getDateFormatString(),
  timeFormat = defaultTimeFormat
) => {
  return moment(dateString, `${dateFormat} ${timeFormat}`);
};

export {
  getDateFormatString,
  getDateFormatted,
  getDateTimeFormatted,
  getMoment,
};
