import React from 'react';
import { Sticky, Rail } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledRail = styled(({ expandedSidebar, ...props }) => (
  <Rail {...props} />
)).attrs({
  internal: true,
  attached: true,
  position: 'right',
})`
  ${({ visible, expandedSidebar }) => `
    &.ui.rail {
      width: ${
        !visible
          ? '100%'
          : expandedSidebar
          ? 'calc(100% - 150px)'
          : 'calc(100% - 48px)'
      };
    }
  `}
`;

const StickyTop = ({
  contextRef,
  expandedSidebar,
  component: Component,
  visible,
}) => (
  <StyledRail
    visible={visible ? 'true' : undefined}
    expandedSidebar={expandedSidebar}
  >
    <Sticky context={contextRef}>
      <Component />
    </Sticky>
  </StyledRail>
);

export default StickyTop;
