import React from 'react';
import { Search } from 'semantic-ui-react';
import styled from 'styled-components';
import { ThemedInput } from 'components';

const ThemedSearch = styled(({ ...props }) => (
  <ThemedInput as={Search} showNoResults={false} fluid {...props} />
))`
  ${({
    theme: {
      color: { fg, resultSpanFg, dropdownBg, borderColor, dropdownBgHover },
    },
  }) => `
    &&&& {
      > .results {
        margin: 0;
        background: ${dropdownBg};
        border: none;
        min-width: 18em;
        overflow-y: auto;
        max-height: 200px;

        > .message.empty {
          background: #fff;
        }
      }

      .result {
        min-height: 3.1em;

        background: ${dropdownBg};
        color: ${fg};
        border-color: ${borderColor};

        &:hover {
          background-color: ${dropdownBgHover};
        }

        .item .meta span {
          font-weight: bolder;
          color: ${resultSpanFg};
        }
      }
    }
  `}
`;

export default ThemedSearch;

// use like this
//{
//  editable && (
//    <ThemedSearch
//      input={{ icon: undefined }}
//      loading={false}
//      required={required}
//      open={show}
//      value={inputValue}
//      results={results}
//      resultRenderer={resultRenderer}
//      onResultSelect={handleResultSelect}
//      onSearchChange={handleResultSelect}
//      onMouseDown={handleFocus}
//      onBlur={handleOnBlur}
//    />
//  )
//}

// improve by making clearable etc.
