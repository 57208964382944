import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, Image } from 'semantic-ui-react';
import styled from 'styled-components';

import logoFastercom from './logo-fastercom.png';

const StyledFooter = styled.footer`
  ${({
    theme: {
      color: { fg, publicBg, boxShadowFooter },
    },
  }) => `
    background: ${publicBg};
    color: ${fg};
    padding: 10px;
    margin-bottom: 2rem;
    box-shadow: ${boxShadowFooter};
    text-align: center;
    font-size: 10px;
    z-index: 1;
  `}
`;

const StyledP = styled.p`
  margin-top: 5px;
`;

const FasterFooter = ({ t }) => (
  <StyledFooter>
    <span>
      {t('glossary|Powered by')}{' '}
      <a href="https://fastercom.ca">
        <Image
          src={logoFastercom}
          width="135px"
          verticalAlign="bottom"
          spaced="left"
        />
      </a>
    </span>
    <StyledP>
      <Icon fitted name="copyright outline" /> {new Date().getFullYear()} -
      Fastercom
    </StyledP>
  </StyledFooter>
);

export default withTranslation()(FasterFooter);
