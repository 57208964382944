import { useEffect } from 'react';

export default function useClickAwayListener(ref, cond, func) {
  function handleClickOutside(event) {
    if (cond && ref.current && !ref.current.contains(event.target)) {
      func();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}
