import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Utils from 'core/utils/utils';
import { SegmentMenu } from 'components';

const StyledIframe = styled.iframe`
  &&& {
    margin-top: -1em;
    margin-right: -1em;
    margin-left: -1em;
    width: calc(100% + 2em);
  }
`;

const Embedded = ({ title, url, compact }) => {
  const { t } = useTranslation();

  const icon = 'external';
  const text = t('See full screen');
  const onClick = () => Utils.openInNewTab(url);
  const menuButtonProps = { icon, text, onClick };

  return (
    <>
      <StyledIframe
        title={title}
        src={url}
        frameBorder="0"
        height={compact ? '450px' : '500px'}
        allowtransparency
      />
      <SegmentMenu mainButtonProps={menuButtonProps} />
    </>
  );
};

export default Embedded;
