import React from 'react';
import styled from 'styled-components';

import { ThemedSearch } from 'components';

import getShadowByState from './helpers/getShadowByState';

const TrackedSearch = styled(({ unchanged, saved, ...props }) => (
  <ThemedSearch {...props} />
))`
  ${({ saved, unchanged }) => `
    &&&&& div input {
      box-shadow: ${getShadowByState(saved, unchanged)};
    }
  `}
`;

export default TrackedSearch;
