import { locales } from 'config/i18n';
import { FIELD_TYPE } from 'core/utils/constant';

const structure = {
  rows: [
    {
      position: 1,
      columns: [
        {
          position: 1,
          name: 'Language',
          key: 'language',
          type: FIELD_TYPE.combolist,
          attrs: {
            options: locales,
          },
        },
        //{
        //  position: 2,
        //  name: 'Default module',
        //  key: 'defaultModule',
        //  type: FIELD_TYPE.text,
        //},
      ],
    },
    {
      position: 2,
      columns: [
        {
          position: 1,
          name: 'Collapse menu',
          key: 'smallMenu',
          type: FIELD_TYPE.checkbox,
        },
      ],
    },
  ],
};

export default structure;
