import React from 'react';
import { connect } from 'react-redux';

import { IMAGE_URL } from 'core/utils/constant';
import { getBackground } from 'core/background/backgroundReducer';

import Background from './Background';
import ImageSrcValidator from '../image/ImageSrcValidator';

const BackgroundContainer = ({
  area,
  background: { customBackgroundPosition },
}) => {
  const backgroundUrl = area
    ? area.areaContent
      ? area.areaContent.background
      : ''
    : IMAGE_URL.background;

  const backgroundProps = {
    backgroundUrl,
    backgroundPosition: customBackgroundPosition,
  };

  return (
    <ImageSrcValidator src={backgroundUrl}>
      <Background {...backgroundProps} />
    </ImageSrcValidator>
  );
};

const mapStateToProps = state => ({
  background: getBackground(state),
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackgroundContainer);
