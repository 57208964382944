import React from 'react';

import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

export const ThemedTable = styled(
  ({ tableColor, celled, sortable, ...props }) => (
    <Table
      celled={celled === false ? undefined : true}
      sortable={sortable === false ? undefined : true}
      {...props}
    />
  )
).attrs({
  selectable: true,
})`
  ${({
    sortable,
    theme: {
      color: {
        errorFg,
        errorBg,
        fg,
        rowBg,
        positiveFg,
        positiveBg,
        tableFg,
        tableBg,
        tableBorderColor,
        tableHeaderBg,
        tableHeaderBgHover,
        tableHeaderBgFocus,
        tableItemBgActive,
        warningFg,
        warningBg,
      },
    },
  }) => `
    &&&&& {
      color: ${tableFg};
      background-color: ${tableBg};
      border-color: ${tableBorderColor};

      &.ui.table tr td {
        border-top: 1px solid ${tableBorderColor};
      }

      &.ui.table tr:first-child td {
        border-top: none;
      }

      &.ui.celled.table tr td, .ui.celled.table tr th {
          border-left: 1px solid ${tableBorderColor};
      }

      &.ui.celled.table tr td:first-child, .ui.celled.table tr th:first-child {
          border-left: none;
      }
    }

    &.ui.sortable.table thead th.sorted:after {
      display: none;
    }

    &&&& th, &&&& thead th {
      cursor: ${sortable ? 'pointer' : 'default'};
      color: ${fg};
      background-color: ${tableHeaderBg};

      ${
        sortable
          ? `
        &:hover {
          background-color: ${tableHeaderBgHover};
          color: ${tableFg};
        }`
          : ''
      }

      &.sorted,
      &.sorted:hover {
        background-color: ${tableHeaderBgFocus};
      }

      &.sorted > div:first-child:after {
        display: inline-block;
      }

      & > div:first-child:after {
        display: none;
        font-style: normal;
        font-weight: 400;
        text-decoration: inherit;
        height: 1em;
        width: auto;
        opacity: 0.8;
        margin: 0 0 0 0.5em;
        font-family: Icons;
        content: '';
      }

      &.ascending > div:first-child:after {
        content: '\\F0D8';
      }

      &.descending > div:first-child:after {
        content: '\\F0D7';
      }
    }

    &&&& tr {
      &:hover {
        color: ${tableFg} !important;
        background: ${({ theme: { color } }) =>
          color.tableItemBgHover} !important;
      }

      &.active,
      &.active:hover {
        color: ${tableFg} !important;
        background: ${tableItemBgActive} !important;
      }

      &.positive,
      &.warning {
        background: ${rowBg} !important;
      }
    }

    td {
      border-top: ${tableBorderColor};

      &.positive {
        color: ${positiveFg} !important;
        background: ${positiveBg} !important;
      }

      &.warning {
        color: ${warningFg} !important;
        background: ${warningBg} !important;
      }

      &.error {
        color: ${errorFg} !important;
        background: ${errorBg} !important;
      }
    }
  `}
`;

export const ThemedTableFooter = styled(Table.Footer)`
  ${({
    theme: {
      color: { tableBorderColor, tableHeaderBg, tableFg },
    },
  }) => `
    &&& {
      border-color: ${tableBorderColor};
      tr th {
        background-color: ${tableHeaderBg};
        color: ${tableFg};
      }
    }
  `}
`;

// For all header cells (header, footer)
export const ThemedHeaderCell = styled(Table.HeaderCell)`
  ${({
    theme: {
      color: {
        tableFg,
        tableBg,
        tableHeaderBg,
        tableHeaderBgHover,
        tableBorderColor,
      },
    },
  }) => `
    &&&& {
      background-color: ${tableHeaderBg};
      color: ${tableFg};
      border-color: ${tableBorderColor};
    }
  `}
`;

// For the header cell in the table header
export const ThemedHeaderCellHeader = styled(({ withFilter, ...props }) => (
  <ThemedHeaderCell {...props} />
))`
  ${({
    theme: {
      color: { tableFg, tableBg },
    },
    withFilter,
  }) => `
    &&&& {
      > div:first-child {
        margin-bottom: 0.3em;
        color: ${tableFg};
      }

      > div:nth-child(2) {
        width: 100%;

        ${
          withFilter
            ? `
              &,
              & div,
              input {
                color: ${tableFg};
                background-color: ${tableBg} !important;

                + i {
                  color: ${tableFg};
                }
              }
            `
            : ''
        }
      }
    }
  `}
`;
