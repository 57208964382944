import React, { useRef, useState, useEffect } from 'react';
import { any, bool, func, object, string } from 'prop-types';
import _ from 'lodash';

import { useApi } from 'api/useApi';
import { useForm } from 'core/hooks/useForm';
import Utils from 'core/utils/utils';

import EntityInfo from './EntityInfo';

const EntityInfoContainer = ({
  autosave,
  icon,
  label,
  api,
  entityKey,
  keyName,
  entity,
  type,
  structure,
  onSetKey,
  onEntityChange,
}) => {
  const newInfo = useRef();
  const entityRef = useRef(_.cloneDeep(entity));
  const [prevInfo, setPrevInfo] = useState(_.cloneDeep(entity));
  const [loading, setLoading] = useState(false);
  const [args, setArgs] = useState([]);
  const [exists, setExists] = useState(entityKey ? true : false);

  // send api request
  const triggerApiCall = (formSubmit, field) => {
    // set loading state
    setLoading(true);

    // store new value
    newInfo.current = formSubmit;

    // compute api arguments
    const newArgs =
      exists && field !== undefined
        ? [{ [type]: formSubmit }, field]
        : [{ [type]: formSubmit }];

    // trigger useApi hook
    setArgs(newArgs);
  };

  // define form state management
  const onTimeout = (formSubmit, field) => {
    // call api
    triggerApiCall(formSubmit, Utils.toPascalCase(field));
  };
  const [form, changeForm, changeFormKey] = useForm(
    prevInfo,
    autosave ? onTimeout : undefined
  );

  // define api call (either put or post of the entity)
  const [response, pending] = useApi(exists ? api.put : api.post, args);

  // receive response from api call
  useEffect(() => {
    if (response && (response.status === 201 || response.status === 204)) {
      // prevent further api call by clearing args
      setArgs([]);

      // remove loading state
      setLoading(false);

      // when api request was a post
      if (response.status === 201 && !exists) {
        if (autosave) {
          // set key
          const { id, key, username } = response.data;
          const newKey = id ? id : key ? key : username ? username : undefined;
          onSetKey(newKey);
        }

        // make next call a put
        setExists(true);
      }

      // update tracked state by setting base entity
      setPrevInfo(_.cloneDeep(newInfo.current));
    }
  }, [response, autosave, exists, onSetKey]);

  // update form after api post request
  useEffect(() => {
    if (
      autosave &&
      keyName &&
      entityKey &&
      newInfo.current &&
      newInfo.current[keyName] !== entityKey &&
      changeFormKey
    ) {
      newInfo.current[keyName] = entityKey;
      changeFormKey(keyName, entityKey);
    }
  }, [entityKey, autosave, keyName, changeFormKey]);

  // notify parent component of change
  const notifyChange = info => {
    if (onEntityChange) {
      onEntityChange(info);
    }
  };

  // when a value changes in form
  const handleChange = (e, data) => {
    // show change in UI
    changeForm(e, data);

    // if autosave
    if (autosave) {
      // notify change
      const { field, value } = Utils.getFieldValueFromEData(e, data);
      notifyChange({ [field]: value });
    }
  };

  // when form is submitted via submit button
  const handleSubmit = async e => {
    e.preventDefault();

    // call api
    triggerApiCall(form);

    // notify change
    notifyChange(form);
  };

  const infoProps = {
    autosave,
    icon,
    label,
    structure,
    loading,
    exists,
    entity: entityRef.current,
    saved:
      !pending &&
      response &&
      (response.status === 204 || response.status === 201),
    entityPrev: prevInfo,
    entityInfo: form,
    handleChange,
    handleSubmit,
  };

  return <EntityInfo {...infoProps} />;
};

EntityInfoContainer.propTypes = {
  autosave: bool,
  icon: string,
  label: string,
  api: object,
  entityKey: any,
  keyName: string,
  entity: object.isRequired,
  type: string,
  structure: object.isRequired,
  onSetKey: func,
  onEntityChange: func,
};

export default EntityInfoContainer;
