import React from 'react';
import { array, bool, object, func } from 'prop-types';
import { Form, Header } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import { FIELD_TYPE } from 'core/utils/constant';
import { typeMapper } from 'core/utils/mapper';
import { ThemedHeader } from 'components';

import ButtonSave from './button/ButtonSave';
import TrackedFormInput from './input/TrackedFormInput';

const TrackedForm = ({
  t,
  structure,
  autosave,
  loading,
  exists,
  currentData,
  changeTracker,
  saveTracker,
  unchanged,
  handleChange,
  handleSubmit,
}) => (
  <Form onSubmit={handleSubmit}>
    {structure.title && (
      <Header as={ThemedHeader}>{t(`common|${structure.title}`)}</Header>
    )}
    {structure.rows.map((r, rIndex) => (
      <Form.Group key={rIndex} widths="equal">
        {r.columns.map((c, cIndex) => {
          const { key, name, type, required, disabled, readonly } = c;
          const changeTracking = _.find(changeTracker, { key });
          const saveTracking = _.find(saveTracker, { key });
          const disabledOrReadOnly = disabled || (readonly && exists);

          return (
            <Form.Field
              key={cIndex}
              required={required}
              disabled={disabledOrReadOnly}
            >
              <label>{t(`common|${name}`)}</label>
              <TrackedFormInput
                name={`${key}`}
                label={
                  _.includes([FIELD_TYPE.checkbox], typeMapper(c.type))
                    ? t(`common|${name}`)
                    : undefined
                }
                type={type}
                value={currentData[key]}
                required={required}
                disabled={disabledOrReadOnly}
                unchanged={changeTracking ? changeTracking.unchanged : true}
                saved={saveTracking ? saveTracking.saved : false}
                onChange={handleChange}
                {...c.attrs}
              />
            </Form.Field>
          );
        })}
      </Form.Group>
    ))}
    {!autosave && (
      <Form.Field>
        <ButtonSave t={t} loading={loading} disabled={unchanged} />
      </Form.Field>
    )}
  </Form>
);

TrackedForm.propTypes = {
  t: func.isRequired,
  structure: object.isRequired,
  autosave: bool,
  loading: bool,
  currentData: object.isRequired,
  changeTracker: array,
  saveTracker: array,
  unchanged: bool,
  handleChange: func.isRequired,
  handleSubmit: func.isRequired,
};

export default withTranslation()(TrackedForm);
