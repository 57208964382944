import React, { useRef, useEffect } from 'react';
import { Polyline } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-polylinedecorator';

// TODO: Check using of useMap after remove mapRef
const PolylineDecorator = props => {
  const { patterns, mapRef } = props;

  const polyRef = useRef();

  const patternArrowDefault = [
    {
      offset: 10,
      endOffset: 10,
      repeat: 100,
      symbol: L.Symbol.arrowHead({
        polygon: true,
        headAngle: 45,
        pixelSize: 6,
        pathOptions: {},
      }),
    },
  ];

  useEffect(() => {
    const polyline = polyRef.current.leafletElement; //get native Leaflet polyline

    L.polylineDecorator(polyline, {
      patterns: patterns || patternArrowDefault,
    }).addTo(mapRef.current.leafletElement);
  }, [patternArrowDefault, mapRef, patterns]);

  return <Polyline ref={polyRef} {...props} />;
};

export default PolylineDecorator;
