import BaseApi from 'api/baseApi';
import { SYSTEM_FIELDS, OPERATOR, FIELD_TYPE } from 'core/utils/constant';
import getQueryString from 'core/utils/tableData/getQueryString';

const exportApi = {
  exportTo: (fileType, tableKey, criteria, ids, documentID) => {
    let query = `export/${fileType}/${tableKey}${getQueryString(criteria)}`;

    if (ids && ids.length > 0) {
      const filter = {
        key: SYSTEM_FIELDS.formid,
        operator: OPERATOR.in,
        value: ids.join('|'),
        type: FIELD_TYPE.number,
      };

      // Post id filter
      return BaseApi.post(query, { filter });
    } else {
      if (documentID) {
        query = `${query}&documentID=${documentID}`;
      }

      // Export data
      return BaseApi.get(query);
    }
  },
};

export default exportApi;
