import React from 'react';
import styled from 'styled-components';

const FormSectionFieldSet = styled(({ visible, ...props }) => (
  <fieldset {...props} />
))`
  ${({
    color,
    visible,
    theme: {
      color: { fg, borderPrincipal },
    },
  }) => `
    ${visible === false ? 'visibility: hidden;' : ''}

    &&&&& {
      border: none;
      background-color: ${color};
      padding-top: 1rem;
      ${borderPrincipal ? `border-color: ${borderPrincipal} !important;` : ''}
    }

    & label {
      color: ${fg} !important;
    }
  `}
`;

export default FormSectionFieldSet;
