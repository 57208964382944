import React from 'react';
import { Icon } from 'semantic-ui-react';

const NewGridIcons = () => (
  <>
    <Icon name="clipboard list" />
    <Icon name="plus" corner />
  </>
);

export default [
  {
    type: 'link',
    content: '/admin/grids',
    iconName: undefined,
    icons: NewGridIcons,
    popupMenu: undefined,
  },
];
