import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';

const ToastMessage = ({ t, success, warning, error, message }) => (
  <>
    <Icon
      color={success ? 'green' : warning ? 'yellow' : error ? 'red' : 'teal'}
      name={success ? 'check' : warning ? 'warning' : error ? 'times' : 'info'}
    />
    <p>{t(`common|${message}`)}</p>
  </>
);

export default withTranslation()(ToastMessage);
