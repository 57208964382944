import { useEffect, useState } from 'react';
import axios from 'axios';

export function useApi(func, data, defaultValue = undefined, xhrParams) {
  const [state, setState] = useState({
    value: defaultValue,
    isPending: func && func.length === data.length + 1 ? true : false,
  });
  const { value, isPending } = state;

  useEffect(() => {
    let cancelled = false;
    let source = axios.CancelToken.source();

    const callAPI = (endpoint, params, cancelToken, xhrParams) => {
      const promise = params
        ? endpoint(...params, { cancelToken, ...xhrParams })
        : endpoint({ cancelToken, ...xhrParams });

      promise.then(response => {
        if (response && !cancelled) {
          setState({ value: response, isPending: false });
        }
      });
    };

    if (typeof func === 'function' && func.length === data.length + 1) {
      // console.log(func, data, 'call');
      callAPI(func, data, source.token, xhrParams);
    }

    return () => {
      cancelled = true;
      source.cancel('');
    };
  }, [func, data, xhrParams]);

  return [value, isPending];
}
