import React from 'react';
import styled from 'styled-components';

const MaskedComponent = styled(({ masked, component: Component, ...props }) => (
  <Component {...props} />
))`
  ${({ masked }) => `
    & {
      ${masked ? 'display: none;' : ''}
    }
  `}
`;

export default MaskedComponent;
