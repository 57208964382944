import React from 'react';
import { node } from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';

const InfoPanelFormContainer = ({ children }) => (
  <Form>
    <Grid
      verticalAlign="middle"
      columns="equal"
      style={{
        margin: 0,
        marginBottom: '2rem',
      }}
    >
      {children}
    </Grid>
  </Form>
);

InfoPanelFormContainer.propTypes = {
  children: node.isRequired,
};

export default InfoPanelFormContainer;
