import React, { useState } from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';

import Utils from 'core/utils/utils';

const CheckboxSearch = ({
  name,
  handleChange,
  disabled,
  controlled,
  defaultValue,
}) => {
  const [indeterminate, setIndeterminate] = useState(true);
  const checked = Utils.getBoxChecked(defaultValue);

  return (
    <div>
      <Checkbox
        name={name}
        disabled={disabled}
        checked={controlled ? checked : undefined}
        onChange={handleChange}
        indeterminate={indeterminate}
        onClick={e => {
          if (indeterminate) {
            setIndeterminate(false);
          }

          e.stopPropagation();
        }} //Prevent event handleSort from parent component
      />
      <div>
        <Icon
          name="remove"
          onClick={(e, data) => {
            if (!indeterminate) {
              setIndeterminate(true);

              handleChange(e, { name: data.name, checked: undefined });
            }

            e.stopPropagation();
          }}
        />
      </div>
    </div>
  );
};

export default CheckboxSearch;
