import React from 'react';
import { Grid, Menu } from 'semantic-ui-react';
import styled from 'styled-components';

import Utils from 'core/utils/utils';

import TileContent from './TileContent';

const TileColumn = styled(Menu.Item)`
  .ui.menu &.fitted.item {
    height: auto;
    margin-right: 1em;
  }
  .ui.text.menu &.fitted.item {
    min-width: 160px;
    width: 160px;
    max-width: 160px;
  }
`;

const Tile = ({
  scroll,
  path,
  icon,
  iconColor,
  label,
  rotated,
  color,
  active,
  handleChangeTile,
}) => {
  const renderTile = (
    <TileContent
      path={path}
      icon={icon}
      iconColor={Utils.getColor(iconColor)}
      label={label}
      rotated={rotated}
      color={Utils.getColor(color)}
      scroll={scroll}
      active={active}
    />
  );

  return (
    <Grid.Column
      as={TileColumn}
      mobile={scroll ? undefined : 8}
      tablet={scroll ? undefined : 4}
      computer={scroll ? undefined : 3}
      fitted={scroll ? true : undefined}
      onClick={!path ? handleChangeTile : undefined}
    >
      {renderTile}
    </Grid.Column>
  );
};

export default Tile;
