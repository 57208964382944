import React from 'react';

import { CONTENT_TYPE } from 'core/utils/constant';

import MaskedComponent from './MaskedComponent';
import getMaskRows from './helpers/getMaskRows';
import getTableMaskData from '../tables/helpers/getTableMaskData';
import FasterAgenda from '../agenda/fasterAgendaContainer';
import FasterMap from '../map/fasterMapContainer';
import FasterCards from '../cards/fasterCardsContainer';
import Timeline from '../timeline/timelineContainer';

const { agenda, cards, map, timeline } = CONTENT_TYPE;

const maskByType = {
  [agenda]: FasterAgenda,
  [cards]: FasterCards,
  [map]: FasterMap,
  [timeline]: Timeline,
};

const MaskContainer = ({ type, table, data, ...props }) => {
  const maskStructure = getTableMaskData(table);
  const maskRows = getMaskRows(data, table.columns);
  //const { currentPage, totalPage, totalRow } = data;

  const maskProps = {
    ...props,
    maskStructure,
    maskRows,
    component: maskByType[type],
    //currentPage,
    //totalPage,
    //totalRow,
  };

  return <MaskedComponent {...maskProps} />;
};

export default MaskContainer;
