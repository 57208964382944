import React from 'react';
import styled from 'styled-components';

const StyledBackgroundDiv = styled.div`
  ${({
    backgroundUrl,
    backgroundPosition,
    theme: {
      color: { bgGeneral },
    },
  }) => `
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${bgGeneral};
    background-size: cover;
    background-position: ${backgroundPosition};
    ${backgroundUrl ? `background-image: url(${backgroundUrl});` : ''}
  `}
`;

const Background = props => {
  return <StyledBackgroundDiv {...props} />;
};

export default Background;
