import moment from 'moment';
import { getDateFormatString } from 'core/utils/date';

const dateFormat = getDateFormatString();

const getStartDateDay = date =>
  moment(date)
    .startOf('day')
    .format(dateFormat);

const getEndDateDay = date =>
  moment(date)
    .endOf('day')
    .format(dateFormat);

const getStartDateWeek = (date, ifFrechFormat) =>
  moment(date)
    .startOf('isoWeek')
    .subtract(ifFrechFormat ? 0 : 1, 'days')
    .format(dateFormat);

const getEndDateWeek = (date, isFrenchFormat) =>
  moment(date)
    .endOf('isoWeek')
    .subtract(isFrenchFormat ? 0 : 1, 'days')
    .format(dateFormat);

const getStartDateMonth = date =>
  moment(date)
    .startOf('month')
    .format(dateFormat);

const getEndDateMonth = date =>
  moment(date)
    .endOf('month')
    .format(dateFormat);

export {
  getStartDateDay,
  getEndDateDay,
  getStartDateWeek,
  getEndDateWeek,
  getStartDateMonth,
  getEndDateMonth,
};
