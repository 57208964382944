import React from 'react';
import { ContextMenuTrigger as ReactContextMenuTrigger } from 'react-contextmenu';

// takes props as input and returns the data to be passed to contextmenu.
const collect = ({ info, callbackOnShow }) => ({ info, callbackOnShow });

const ContextMenuTrigger = ({ id, children, ...rest }) => (
  <ReactContextMenuTrigger
    id={id}
    collect={collect}
    holdToDisplay={1000}
    onTouchStart={e => {
      e.stopPropagation();
    }}
    {...rest}
  >
    {children}
  </ReactContextMenuTrigger>
);

export default ContextMenuTrigger;
