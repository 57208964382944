import React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import styled from 'styled-components';

import { getSubtleStyle, ButtonLink, ThemedDropdown } from 'components';

const StyledMenu = styled(Menu)`
  ${({ theme }) => `
  &&& {
    margin: 0;
    margin-bottom: -0.5rem;

    .item {
      margin: 0;
    }

    .item:not(.ui.dropdown.item) {
      padding: 0;
    }

    .ui.dropdown.item {
      ${getSubtleStyle(theme)}
    }
  }
  `}
`;

const SegmentMenu = ({ mainButtonProps, dropdownItems: DropdownItems }) => {
  const { icon, text, onClick } = mainButtonProps || {};

  return (
    <StyledMenu secondary>
      {mainButtonProps && (
        <Menu.Item>
          <ButtonLink
            icon
            iconName={icon}
            text={text}
            handleClickFunc={onClick}
          />
        </Menu.Item>
      )}
      {DropdownItems && (
        <ThemedDropdown
          fluid={false}
          item
          upward
          icon="ellipsis horizontal"
          className="right icon button"
        >
          <Dropdown.Menu>
            <DropdownItems />
          </Dropdown.Menu>
        </ThemedDropdown>
      )}
    </StyledMenu>
  );
};

export default SegmentMenu;
