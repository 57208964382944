import styled from 'styled-components';

import { ThemedButton } from 'components';

const InputButton = styled(ThemedButton).attrs(() => ({
  type: 'button',
  size: 'tiny',
}))`
  ${({ theme: { color: buttonDisabled } }) => `
    &&&& {
      max-width: calc(50% - 0.25em);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:disabled {
        background-color: 
          ${buttonDisabled} !important;
        opacity: 1 !important;
      }
    }
  `}
`;

export default InputButton;
