import baseHub from 'api/baseHub';

const events = {
  updated: 'Updated',
};

const tableHub = function(token) {
  const hub = new baseHub('TableHub', token);

  this.addHandlers = updatedHandler => {
    const { updated } = events;

    hub.addHandler(updated, updatedHandler);
  };

  this.getConnection = () => hub.getConnection();
};

export default tableHub;
