import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { useApi } from 'api/useApi';
import documentApi from 'api/document/documentApi';

import { history } from 'config/store';
import { ModalCreators, getModal } from 'core/modal/modalReducer';
import { getTemplates } from 'core/template/templateReducer';
import { getCurrentUser } from 'core/auth/authReducer';
import { getBatchTemplates } from 'core/batch-template/batchTemplateReducer';
import Utils from 'core/utils/utils';
import { FIELD_TYPE } from 'core/utils/constant';
import { typeMapper } from 'core/utils/mapper';
import { getTemplateContents } from 'core/utils/form';
import { ToastMessage } from 'components';

import Batch from './Batch';

const BatchContainer = ({
  t,
  currentUser,
  module,
  batchTemplates,
  templates,
  openModal,
  closeModal,
  page,
}) => {
  const [step, setStep] = useState(1);
  const [action, setAction] = useState('');
  const [filters, setFilters] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [ids, setIds] = useState([]);
  const [edits, setEdits] = useState([]);
  const [docs, setDocs] = useState([]);
  const [duplicate, setDuplicate] = useState(false);
  const [printTemplateKey, setPrintTemplateKey] = useState();
  const [completed, setCompleted] = useState(false);
  const [selectedBatchFilterId, setSelectedBatchFilterId] = useState('');
  const [selectedBatchEditId, setSelectedBatchEditId] = useState('');

  const [argsDocIds, setArgsDocIds] = useState([filters]);
  const [fetchedDocIds] = useApi(
    step === 2 ? documentApi.getIDs : undefined,
    argsDocIds
  );

  const [argsDocs, setArgsDocs] = useState([ids]);
  const [fetchedDocs] = useApi(
    step === 3 && action === 'print' ? documentApi.getByIDs : undefined,
    argsDocs
  );

  useEffect(() => {
    if (step === 2) {
      if (fetchedDocIds && fetchedDocIds.ids) {
        setIds(fetchedDocIds.ids);
        setFiltered(true);
      } else {
        toast.error(
          <ToastMessage error message="Selected filters are not valid" />
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedDocIds]);

  useEffect(() => {
    if (step === 3) {
      if (fetchedDocs && fetchedDocs.documents) {
        setDocs(fetchedDocs.documents);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedDocs]);

  const loadBatchTemplate = selectedValue => {
    const batchTemplate = batchTemplates.find(
      batch => `${batch.batchTemplateId}` === selectedValue
    );

    const { filters: btFilters, edits: btEdits } = batchTemplate;

    // init batchFilters and batchEdits by batchTemplate
    const batchFilters = [];
    const batchEdits = [];

    // set filters and edits by batchTemplate
    if (
      batchTemplate &&
      ((btFilters && btFilters.length > 0) || (btEdits && btEdits.length > 0))
    ) {
      const sections = Utils.getSectionsFromTemplates(templates);
      const fields = Utils.getFieldsFromSections(sections);

      // fill filters
      fillBatchList(batchFilters, btFilters, fields, sections, templates);

      // fill edits
      fillBatchList(batchEdits, btEdits, fields, sections, templates);
    }

    return {
      batchFilters,
      batchEdits,
    };
  };

  const fillBatchList = (batchItems, items, fields, sections, _templates) => {
    if (items) {
      _.forEach(items, item => {
        batchItems.push(
          mapItemToBatchItem(batchItems, item, fields, sections, _templates)
        );
      });
    }
  };

  const mapItemToBatchItem = (
    batchItems,
    item,
    fields,
    sections,
    _templates
  ) => {
    // find first matching field by key, then retrieve its template and section
    const field = _.find(fields, { key: item.key });
    const section = _.find(
      sections,
      section => _.findIndex(section.fields, { fieldID: field.fieldID }) >= 0
    );
    const template = _.find(
      _templates,
      template =>
        _.findIndex(
          _.filter(getTemplateContents(template), ct => ct.sectionID >= 0),
          {
            sectionID: section.sectionID,
          }
        ) >= 0
    );

    // map item to batch item
    return {
      templateId: `${template.templateId}`,
      sectionID: `${section.sectionID}`,
      columnID: `${field.fieldID}`,
      column: field,
      position: batchItems.length + 1,
      operator: item.operator,
      value: item.value,
    };
  };

  const moveTo = newStep => {
    if (newStep === 1) {
      setIds([]);
      setFiltered(false);
    }

    if (newStep !== 3) {
      setAction('');
      setDocs([]);
    }

    setStep(newStep);
    setCompleted(false);
  };

  const funnel = () => {
    moveTo(2);
    setArgsDocIds([filters]);
  };

  const onOpenModalBatch = () => {
    const modalOptions = {
      title: t('common|Batch print'),
      message: t(
        'Printing many documents at once might require a long time. Do you wish to continue?'
      ),
      btnTitle: t('common|Yes'),
      onBtnClick: () => {
        closeModal();
        print(printTemplateKey);
      },
      secondaryBtnTitle: t('common|No'),
      onSecondaryBtnClick: closeModal,
    };
    openModal('popup-alert', modalOptions);
  };

  const print = templateKey => {
    setAction('print');
    moveTo(3);
    setArgsDocs([ids]);
    setPrintTemplateKey(templateKey);
  };

  const download = async (templateName, type) => {
    if (templateName && type) {
      setAction('download');
      moveTo(3);

      const fileUrl = await documentApi.downloadManyAs(ids, templateName, type);

      if (fileUrl) {
        complete();
        Utils.openInNewTab(fileUrl); // open to save file
      }
    }
  };

  const preview = () => {
    setAction('preview');
    moveTo(3);
  };

  const selectEdits = () => {
    setAction('edit');
    moveTo(3);
  };

  const edit = async () => {
    const edit = {
      documentIDs: ids,
      edits: _.map(edits, ({ column, value }) => ({
        key: column.key,
        value: _.includes([FIELD_TYPE.multiselect], typeMapper(column.type))
          ? Utils.arrayToString(value) // is multiple select then join the value into a single string
          : _.includes([FIELD_TYPE.checkbox], typeMapper(column.type)) &&
            value === undefined
          ? false
          : value !== undefined
          ? value
          : '',
      })),
    };

    const response = duplicate
      ? await documentApi.duplicate(edit)
      : await documentApi.edit(edit);

    if (response.status === 204 || (!response.status && response.ids)) {
      toast.success(
        <ToastMessage
          success
          message={
            response.status === 204
              ? `${ids.length} ${t('document(s) updated')}`
              : t('Success')
          }
        />
      );

      const { path, relativePath } = page;
      history.push({
        pathname: path.replace(relativePath, ''),
        state: { from: path },
      });
    }
  };

  const complete = () => {
    setCompleted(true);
  };

  const handleSetFilters = newFilters => {
    setFilters(newFilters);
  };

  const handleSetEdits = newEdits => {
    setEdits(newEdits);
  };

  const toggleDuplicate = () => {
    setDuplicate(prevDuplicate => !prevDuplicate);
  };

  const handleChangeBatchTemplate = (e, data) => {
    const { value } = data ? data : e.target;

    if (value) {
      // have value of batch template then load it
      const { batchFilters, batchEdits } = loadBatchTemplate(value);

      if (step === 1) {
        // is filter
        setSelectedBatchFilterId(value);
        setFilters(batchFilters);

        reloadColOpts(batchFilters);
      } else if (step === 3) {
        // is edit
        setSelectedBatchEditId(value);
        setEdits(batchEdits);

        reloadColOpts(batchEdits);
      }
    } else {
      // reset to default
      if (step === 1) {
        setSelectedBatchFilterId('');
        setFilters([]);
      } else if (step === 3) {
        setSelectedBatchEditId('');
        setEdits([]);
      }

      reloadColOpts([]);
    }
  };

  let reloadColOpts = () => {};

  const bindReloadColOpts = func => {
    reloadColOpts = func;
  };

  return (
    <Batch
      t={t}
      currentUser={currentUser}
      module={module}
      batchTemplates={batchTemplates}
      step={step}
      action={action}
      filters={filters}
      ids={ids}
      edits={edits}
      docs={docs}
      duplicate={duplicate}
      filtered={filtered}
      completed={completed}
      selectedBatchFilterId={selectedBatchFilterId}
      selectedBatchEditId={selectedBatchEditId}
      toggleDuplicate={toggleDuplicate}
      onOpenModalBatch={onOpenModalBatch}
      printTemplateKey={printTemplateKey}
      print={print}
      moveTo={moveTo}
      handleChangeBatchTemplate={handleChangeBatchTemplate}
      bindReloadColOpts={bindReloadColOpts}
      funnel={funnel}
      preview={preview}
      selectEdits={selectEdits}
      handleSetFilters={handleSetFilters}
      handleSetEdits={handleSetEdits}
      edit={edit}
      complete={complete}
      download={download}
    />
  );
};

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  modal: getModal(state),
  batchTemplates: getBatchTemplates(state),
  templates: getTemplates(state),
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(ModalCreators.closeModal()),
  openModal: (modalType, options) =>
    dispatch(ModalCreators.openModal(modalType, options)),
  changeSize: size => dispatch(ModalCreators.changeSize(size)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(BatchContainer))
);
