import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Segment } from 'semantic-ui-react';
import { SpinnerSegment } from 'components';

import getSortedSetOptions from './helpers/getSortedSetOptions';

const TrackingByAddress = ({
  t,
  addresses,
  address,
  date,
  ready,
  handleChangeMunicipality,
  handleChangeStreet,
  handleChangeNumber,
}) => {
  const isAddressCityAvailable = addresses && address && address.city;
  const isAddressStreetAvailable = isAddressCityAvailable && address.street;
  const isAddressNumberAvailable = isAddressStreetAvailable && address.number;

  return (
    <>
      <Segment>
        <h3> {t(`tracking|Tracking by address`)}</h3>
        <p>{t('Please select your address in the form below')}</p>
      </Segment>
      <Segment>
        <Form>
          {!addresses && <SpinnerSegment />}
          {addresses && (
            <Form.Select
              required
              label={t('common|Municipality')}
              options={getSortedSetOptions(addresses, address, 'city')}
              onChange={handleChangeMunicipality}
            />
          )}
          {isAddressCityAvailable && (
            <Form.Select
              required
              label={t('common|Street')}
              options={getSortedSetOptions(addresses, address, 'street')}
              onChange={handleChangeStreet}
            />
          )}
          {isAddressStreetAvailable && (
            <Form.Select
              required
              label={t('common|Civic number')}
              options={getSortedSetOptions(addresses, address, 'number')}
              onChange={handleChangeNumber}
            />
          )}
          {isAddressNumberAvailable && ready && !date && <SpinnerSegment />}
          {isAddressNumberAvailable && date && (
            <>
              {date && new Date(Date.parse(date)) < new Date() && (
                <p>{t(`tracking|Drain is not yet planned for this address`)}</p>
              )}
              {date && new Date(Date.parse(date)) >= new Date() && (
                <p>
                  {t(`tracking|Your drain is planned on`)}{' '}
                  {new Date(date).toLocaleDateString('fr-fr', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </p>
              )}
            </>
          )}
        </Form>
      </Segment>
    </>
  );
};

export default withTranslation()(TrackingByAddress);
