import React from 'react';
import { Popup } from 'semantic-ui-react';

const Tooltip = ({ children, ...props }) => {
  const popupProps = {
    inverted: true,
    hideOnScroll: true,
    size: 'mini',
    mouseEnterDelay: 500,
    style: { opacity: 0.85 },
    ...props,
  };

  return <Popup trigger={children} {...popupProps} />;
};

export default Tooltip;
