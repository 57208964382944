import React, { useEffect } from 'react';
import _ from 'lodash';

import { useForm } from 'core/hooks/useForm';
import Utils from 'core/utils/utils';
import { history } from 'config/store';

import FasterCards from './FasterCards';
import formToCard from './helpers/formToCard';
// import getTableMaskData from '../tables/helpers/getTableMaskData';

const FasterCardsContainer = ({
  maskStructure,
  maskRows,
  area,
  module,
  updateCriteriaFunc,
  dataType,
  dataKey,
  ...props
}) => {
  // const cardsListData = getTableMaskData(table);
  const admin = dataType && dataKey ? true : false;

  const [cardsProps, , setCardsProp] = useForm({
    cards: null,
  });

  useEffect(() => {
    if (maskStructure && maskRows) {
      setCardsProp(
        'cards',
        _.map(maskRows, mr => formToCard(maskStructure, mr))
      );
    }
  }, [maskStructure, maskRows, setCardsProp]);

  const handleEdit = key => {
    if (area || module) {
      const urlNav = Utils.buildUrlNav(area, module, key);
      history.push(urlNav);
    }
  };

  return (
    <FasterCards
      cardsProps={cardsProps}
      maskRows={maskRows}
      handleEdit={handleEdit}
      admin={admin}
      area={area}
      module={module}
      {...props}
    />
  );
};

export default FasterCardsContainer;

export { formToCard, FasterCards };
