import React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { getBackground } from 'core/background/backgroundReducer';

import useTheme from './styles/useTheme';
import GlobalStyle from './GlobalStyle';

const Theme = ({ children, background }) => (
  <ThemeProvider theme={useTheme(background.theme)}>
    <>
      {children}
      <GlobalStyle />
    </>
  </ThemeProvider>
);

const mapStateToProps = state => ({
  background: getBackground(state),
});

export default connect(mapStateToProps)(Theme);
