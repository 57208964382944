import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';

const StyledToast = styled(ToastContainer).attrs({
  suppressClassNameWarning: true,
  closeButton: false,
  position: toast.POSITION.TOP_CENTER,
  autoClose: 10000,
})`
  ${({
    theme: {
      color: { border, toastBg, toastFg, boxShadowToast },
    },
  }) => `
  .Toastify__toast {
    padding: 1em 1.5em;

    &--info,
    &--success,
    &--warning,
    &--error {
      background-color: ${toastBg};
      color: ${toastFg};
      border: ${border};
      -webkit-box-shadow: ${boxShadowToast};
      box-shadow: ${boxShadowToast};
      border-radius: 0.28571429rem;
    }

    .Toastify__toast-body {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
      max-width: 100%;

      > i {
        margin-right: 0.5rem;
      }

      > p {
        flex-grow: 1;
        overflow-wrap: anywhere;
      }
    }

    &--info .Toastify__progress-bar {
      background: #00b5ad;
      opacity: 0.7 !important;
    }
    &--success .Toastify__progress-bar {
      background: #21ba45;
      opacity: 0.7 !important;
    }
    &--warning .Toastify__progress-bar {
      background: #fbbd08;
      opacity: 0.7 !important;
    }
    &--error .Toastify__progress-bar {
      background: #db2828;
      opacity: 0.7 !important;
    }
  }
`}
`;

export default StyledToast;
