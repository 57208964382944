import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import _ from 'lodash';

import { FIELD_TYPE } from 'core/utils/constant';
import { typeMapper } from 'core/utils/mapper';

// TYPES / ACTIONS
const { Types, Creators } = createActions({
  setPopupData: ['popupData'],
  updatePopupData: ['event', 'field'],
  updatePopupDataValue: ['name', 'value'],
  updateAdminTemplateStore: ['adminTemplate'],
  clearPopupData: [],
});

export const AdminTemplateCreators = Creators;
export const AdminTemplateTypes = Types;

export const INITIAL_STATE = Immutable({
  popupData: {},
});

// REDUCERS
export const clearPopupData = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    popupData: {},
  };
};

export const updatePopupDataValue = (state = INITIAL_STATE, action) => {
  const { name, value } = action;
  const clonePopupData = { ...state.popupData };

  clonePopupData[name] = value;

  return {
    ...state,
    popupData: clonePopupData,
  };
};

export const updatePopupData = (state = INITIAL_STATE, action) => {
  const { field } = action;
  const clonePopupData = { ...state.popupData };

  if (_.includes([FIELD_TYPE.checkbox], typeMapper(field.type))) {
    clonePopupData[field.name] = field.checked;
  } else {
    clonePopupData[field.name] = field.value;
  }

  return {
    ...state,
    popupData: clonePopupData,
  };
};

export const setPopupData = (state = INITIAL_STATE, action) => {
  const { popupData } = action;

  return {
    ...state,
    popupData: popupData,
  };
};

export const updateAdminTemplateStore = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.adminTemplate,
});

export const HANDLERS = {
  [Types.UPDATE_POPUP_DATA]: updatePopupData,
  [Types.UPDATE_POPUP_DATA_VALUE]: updatePopupDataValue,
  [Types.SET_POPUP_DATA]: setPopupData,
  [Types.CLEAR_POPUP_DATA]: clearPopupData,
  [Types.UPDATE_ADMIN_TEMPLATE_STORE]: updateAdminTemplateStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);

// SELECTORS
const selectorPopupData = state => state.adminTemplate.popupData;

export const getPopupData = createSelector(
  [selectorPopupData],
  popupData => popupData
);
