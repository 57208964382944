import React, { forwardRef } from 'react';
import { Ref, Label } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedLabel = styled(
  forwardRef(({ accent, backgroundColor, ...props }, ref) => (
    <Ref innerRef={ref}>
      <Label {...props} />
    </Ref>
  ))
)`
  ${({
    backgroundColor,
    theme: {
      color: {
        labelFg,
        labelBg,
        labelBasicBg,
        labelRed,
        labelBlue,
        labelGreen,
        labelYellow,
        accentBg,
        primary,
      },
    },
    accent,
    basic,
  }) => `
    &&&.label {
      ${
        accent
          ? `
            background-color: ${accentBg};
            color: ${primary};
            border-color: ${primary};
          `
          : `
            color: ${labelFg};
            background-color: ${backgroundColor || labelBg};

            ${
              basic
                ? `
                  &.basic {
                    background-color: ${labelBasicBg} !important;
                  }
                `
                : `
                  &.red {
                    background-color: ${labelRed} !important;
                  }
                  &.blue {
                    background-color: ${labelBlue} !important;
                  }
                  &.green {
                    background-color: ${labelGreen} !important;
                  }
                  &.yellow {
                    background-color: ${labelYellow} !important;
                  }
                `
            }
          `
      }
    }
  `}
`;

export default ThemedLabel;
