import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Grid } from 'semantic-ui-react';

import sortedFieldSection from '../helpers/sortedFieldSection';

import stopsForm from '../form/stopsForm';
import { FormInput } from 'components';
import PopupInfoPanel from '../../components/info-panel/PopupInfoPanel';
import InfoPanelFormContainer from '../../components/info-panel/InfoPanelFormContainer';
import { STOP_STATUS } from '../../../../api/stop/stopsMock';

const GridRow = styled(Grid.Row)`
  .ui.grid > &.row {
    margin: 0;
    padding: 1rem 0 0 0;
  }

  @media only screen and (min-width: 768px) {
    .ui.grid > &.row {
      flex-wrap: nowrap;
    }
  }

  .ui.grid > &.row:first-child {
    margin-top: 1rem;
  }

  .ui.grid > &.row:last-child {
    margin-bottom: 1rem;
  }
`;

const StopInfoPanel = ({ stop, edited }) => {
  const [formLayout, setFormLayout] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    console.log(stop);
    setFormLayout(stopsForm);
    setFormData({
      ...stop,
      clientName: stop && stop.client ? stop.client.name : '',
      clientPhone: stop && stop.client ? stop.client.phone : '',
    });
  }, [stop]);

  return (
    <PopupInfoPanel>
      <InfoPanelFormContainer>
        {sortedFieldSection(formLayout).map(row => (
          <Form.Group key={row} widths="equal" as={GridRow}>
            {formLayout
              .filter(fis => fis.row === row)
              .map(field => (
                <FormInput
                  key={field.key}
                  fis={field}
                  visible={true}
                  formData={formData}
                  onChange={() => {}}
                />
              ))}
          </Form.Group>
        ))}
      </InfoPanelFormContainer>
      {edited && (
        <>
          <Button content="Save" primary floated="right" />
          {stop.status === STOP_STATUS.SCHEDULED ? (
            <Button content="Unscheduled" primary floated="right" />
          ) : (
            <Button content="Scheduled" primary floated="right" />
          )}
        </>
      )}
    </PopupInfoPanel>
  );
};

export default StopInfoPanel;
