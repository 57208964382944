import React from 'react';
import { bool, func, object, string } from 'prop-types';

import { PageSegment, TrackedForm } from 'components';

const EntityInfo = ({
  autosave,
  icon,
  label,
  structure,
  loading,
  saved,
  exists,
  entity,
  entityPrev,
  entityInfo,
  handleChange,
  handleSubmit,
}) => {
  return (
    <PageSegment icon={icon} label={label}>
      {entity && (
        <TrackedForm
          structure={structure}
          autosave={autosave}
          loading={loading}
          saved={saved}
          exists={exists}
          refData={entity}
          prevData={entityPrev}
          currentData={entityInfo}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      )}
    </PageSegment>
  );
};

EntityInfo.propTypes = {
  autosave: bool,
  icon: string,
  label: string,
  structure: object.isRequired,
  loading: bool.isRequired,
  saved: bool,
  exists: bool.isRequired,
  entity: object,
  entityPrev: object,
  entityInfo: object.isRequired,
  handleChange: func.isRequired,
  handleSubmit: func.isRequired,
};

export default EntityInfo;
