import React from 'react';
import { ThemedButton } from 'components';

const DocumentButton = ({ name, ...props }) => (
  <ThemedButton {...props}>{name}</ThemedButton>
);

DocumentButton.propTypes = {};

DocumentButton.defaultProps = {};

export default DocumentButton;
