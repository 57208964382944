import React from 'react';
import { withTranslation } from 'react-i18next';

import { OPERATOR, FIELD_TYPE } from 'core/utils/constant';
import { FasterDropdown } from 'components';

const {
  decimal,
  number,
  text,
  checkbox,
  combolist,
  editabledropdown,
  searchselect,
  searcheditselect,
  multiselect,
  textarea,
  time,
  date,
  datetime,
  autocomplete,
} = FIELD_TYPE;

const Operators = [
  {
    text: 'Contains',
    value: OPERATOR.contains,
    types: [decimal, number, text, textarea, autocomplete],
  },
  {
    text: 'Does not contain',
    value: OPERATOR.notContains,
    types: [decimal, number, text, textarea, autocomplete],
  },
  {
    text: 'Equals',
    value: OPERATOR.equals,
    types: [
      checkbox,
      combolist,
      editabledropdown,
      searchselect,
      searcheditselect,
      multiselect,
      decimal,
      number,
      text,
      textarea,
      autocomplete,
    ],
  },
  {
    text: 'Does not equal',
    value: OPERATOR.notEquals,
    types: [
      checkbox,
      combolist,
      editabledropdown,
      searchselect,
      searcheditselect,
      multiselect,
      decimal,
      number,
      text,
      textarea,
      autocomplete,
    ],
  },
  {
    text: 'Between',
    value: OPERATOR.between,
    types: [date, datetime, time, decimal, number],
  },
  {
    text: 'Starts with',
    value: OPERATOR.startsWith,
    types: [decimal, number, text, textarea, autocomplete],
  },
  {
    text: 'Ends with',
    value: OPERATOR.endsWith,
    types: [decimal, number, text, textarea, autocomplete],
  },
];

const FilterTableOperatorSelect = ({
  t,
  disabled,
  columnType,
  operator,
  handleChangeOperator,
}) => (
  <FasterDropdown
    disabled={disabled}
    value={operator ? operator : ''}
    options={
      columnType && Operators.some(o => o.types.some(ot => ot === columnType))
        ? Operators.filter(o => o.types.some(ot => ot === columnType)).map(
            ({ text, value }) => ({ key: value, text: t(`${text}`), value })
          )
        : []
    }
    onChange={handleChangeOperator}
  />
);

export default withTranslation()(FilterTableOperatorSelect);
