import _ from 'lodash';
import Sort from 'core/utils/sort';

const getVisibleRowsHelper = (arrays, visibleArrays) => {
  _.forEach(arrays, arr => {
    const visibleArray = _.find(visibleArrays, {
      name: arr.key,
    });

    if (visibleArray) {
      arr.visible = visibleArray.value;
    }
  });

  return Sort.sortByStepRowColumnKey(
    arrays.filter(arr => arr.visible || arr.visible === false),
    true
  );
};

export default getVisibleRowsHelper;
