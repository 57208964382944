import { useEffect, useRef, useState, useCallback } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { definePrint } from 'core/utils/definePrint';

export function usePrint(formDataArray, template) {
  // ref for ready state of print
  const ready = useRef(false);
  const namedBlobs = useRef([]);

  // returned pending state
  const [state, setState] = useState({
    isPending: false,
  });
  const { isPending } = state;

  // define fonts if not already defined
  if (!ready.current) {
    const virtualFileSystem = pdfFonts.pdfMake.vfs;
    pdfMake.vfs = virtualFileSystem;
    ready.current = true;
  }

  // end of print
  const onPrintEnd = useCallback(() => {
    // zip and download blobs
    if (Array.isArray(namedBlobs.current) && namedBlobs.current.length > 0) {
      const zip = new JSZip();

      for (var j = 0; j < namedBlobs.current.length; j++) {
        const { filename, blob } = namedBlobs.current[j];
        zip.file(filename, blob);
      }

      // generate the zip file
      zip.generateAsync({ type: 'blob' }).then(b => {
        // trigger the download
        saveAs(b, template.name);
      });
    }

    // set pending false
    if (ready.current) {
      setState({ isPending: false });
    }
  }, [template]);

  // add blob
  const addBlob = useCallback(
    (lastIteration, namedBlob) => {
      namedBlobs.current.push(namedBlob);

      if (lastIteration) {
        onPrintEnd();
      }
    },
    [onPrintEnd]
  );

  // trigger print
  useEffect(() => {
    if (Array.isArray(formDataArray) && formDataArray.length > 0 && template) {
      setState({ isPending: true });

      for (var i = 0; i < formDataArray.length; i++) {
        // define pdf
        const filename = `${template.name}_${formDataArray[i].documentID}.pdf`;
        const docDef = definePrint(formDataArray[i], template);

        const pdfDocGenerator = pdfMake.createPdf(docDef);

        if (formDataArray.length < 2) {
          // dowload pdf
          pdfDocGenerator.download(filename, onPrintEnd);
          //.open();
        } else {
          const lastIteration = i === formDataArray.length - 1;
          pdfDocGenerator.getBlob(blob =>
            addBlob(lastIteration, { filename, blob })
          );
        }
      }

      return () => {
        ready.current = undefined;
      };
    }
  }, [formDataArray, template, addBlob, onPrintEnd]);

  return [isPending];
}
