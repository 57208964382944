import { useMapEvent } from 'react-leaflet';

const DblClickComponent = () => {
  const map = useMapEvent('dblclick', e => {
    const zoom = map.getZoom();
    map.setView(e.latlng, zoom + 2);
  });

  return null;
};

const RightClickComponent = () => {
  const map = useMapEvent('contextmenu', () => {
    const zoom = map.getZoom();
    map.setZoom(zoom - 2);
  });

  return null;
};

export { DblClickComponent, RightClickComponent };
