import getOptions from './getOptions';
import _ from 'lodash';

const searchItems = (searchQuery, items) => {
  return _.filter(items, i => {
    return _.deburr(i.text.toLowerCase()).includes(
      _.deburr(searchQuery.toLowerCase())
    );
  });
};

const searchOptions = (searchQuery, options, categorize) => {
  const result = [];

  // if search is valid
  if (searchQuery && searchQuery.length > 0) {
    if (categorize) {
      // for each category
      _.forEach(options, item => {
        const { items } = item;

        // search options
        const searchedOptions = searchItems(searchQuery, items);

        // add to result of search
        if (searchedOptions && searchedOptions.length > 0) {
          result.push({ ...item, items: searchedOptions });
        }
      });
    } else {
      // no categories, search options directly
      result.push(...searchItems(searchQuery, options));
    }
  }
  // else return all options
  else {
    result.push(...options);
  }

  return result;
};

const getSearchedOptions = (searchQuery, items, categorize) => {
  return getOptions(searchOptions(searchQuery, items, categorize));
};

export default getSearchedOptions;
