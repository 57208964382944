import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const getSubtleStyle = theme => {
  const {
    color: { buttonFg, buttonFgHover, headerItemBgHover },
  } = theme;

  return `
      color: ${buttonFg} !important;
      background: none !important;

      &:hover {
        color: ${buttonFgHover} !important;
        background-color: ${headerItemBgHover} !important;
      }
  `;
};

const SubtleIconButton = styled(Button).attrs(({ icon }) => ({
  basic: true,
  icon,
}))`
  ${({ theme }) => `
    &.ui.basic.button {
      box-shadow: none;
      vertical-align: middle;
      ${getSubtleStyle(theme)}
  `}
`;

export { getSubtleStyle };
export default SubtleIconButton;
