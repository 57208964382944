// import axios from 'axios';
// import BaseApi from 'api/baseApi';
import _ from 'lodash';
import driversMock from './driversMock';

// const CancelToken = axios.CancelToken;
const sources = [];

const driverApi = {
  getAll: () => {
    setTimeout(function() {
      console.log('Mocking drivers data');
    }, 1500);

    return driversMock;
  },
  cancelRequests: () => {
    _.forEach(sources, source => {
      source.cancel('Cancellation');
    });
  },
};

export default driverApi;
