import React from 'react';
import { TextArea } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedTextArea = styled(({ inputRef, ...props }) => (
  <TextArea ref={inputRef} {...props} />
))`
  ${({
    theme: {
      color: { inputBg, inputFg, inputFgHover, borderColor, inputDisabled },
    },
  }) => `
    &&& {
      min-height: 77px;
      background-color: ${inputBg};
      color: ${inputFg};
      border-color: ${borderColor};

      &:hover,
      &:focus {
        background-color: ${inputBg};
        color: ${inputFgHover};
      }

      &:read-only {
        background-color: ${inputDisabled} !important;
      }
    }
  `}
`;

export default ThemedTextArea;
