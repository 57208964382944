import _ from 'lodash';
import { FIELD_ACTION_TAG } from 'core/utils/constant';
import Utils from 'core/utils/utils';

export default autoVisibleElts => {
  const dependentVisibleElts = [];

  // group by dependent element
  _.forEach(autoVisibleElts, f => {
    const { dependent } = f;

    if (dependent.tag === FIELD_ACTION_TAG.category) {
      dependent.categories.forEach(cat => {
        addToArrayDependent(
          dependentVisibleElts,
          dependent.tag,
          Utils.buildKeyAutoHideForCategory(dependent.key, cat)
        ); // key format is FieldKey|CatKey
      });
    } else {
      addToArrayDependent(dependentVisibleElts, dependent.tag, dependent.key);
    }
  });

  return dependentVisibleElts;
};

const addToArrayDependent = (array, tag, key) => {
  if (!_.some(array, { tag: tag, key: key })) {
    array.push({ tag: tag, key: key });
  }
};
