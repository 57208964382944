import React, { useState, useEffect, useRef } from 'react';
import { Item } from 'semantic-ui-react';
import { createGlobalStyle } from 'styled-components';
import _ from 'lodash';

import { useApi } from 'api/useApi';
import autocompleteApi from 'api/form/autocompleteApi';
import addressApi from 'api/address/addressApi';
import { LIMIT_CHAR } from 'core/utils/constant';
import getValueMatch from 'core/utils/pattern/getValueMatch';
import { ThemedSearch } from 'components';

let timer = null;
const WAITING_INPUT = 500;

const SuggestionContainer = createGlobalStyle`
    .search .results.transition {
        overflow-y: scroll;
        max-height: 200px;
    }
`;

const resultRenderer = ({ title, fields }) => (
  <Item>
    <Item.Header as="h4">{title}</Item.Header>
    {_.map(fields, (item, index) => (
      <Item.Meta key={index}>
        <span>{item.name} - </span>
        {item.value}
      </Item.Meta>
    ))}
  </Item>
);

const AutocompleteInput = ({
  name,
  fieldId,
  value,
  type,
  pattern,
  required,
  disabled,
  onAutoComplete,
  onChange,
}) => {
  const searchRef = useRef(value);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const [args, setArgs] = useState([]);
  const [fetchedSuggestions, pendingSuggestions] = useApi(
    type === 'address'
      ? addressApi.autocomplete
      : autocompleteApi.getSuggestions,
    args
  );

  const handleResultSelect = (_e, { result }) => {
    const { fields } = result;

    onAutoComplete(name, result.title); // update autocomplete input value in form data
    _.forEach(fields, ({ key, value }) => {
      onAutoComplete(key, value);
    });
  };

  const buildAddressQuery = (
    data = {},
    { focus = null, bRect = null, bCircle = null, bCountry = null }
  ) => {
    const params = {};
    if (focus) {
      params['focus.point.lat'] = focus.latitude;
      params['focus.point.lon'] = focus.longitude;
    }
    if (bRect) {
      params['boundary.rect.min_lon'] = bRect[0];
      params['boundary.rect.min_lat'] = bRect[1];
      params['boundary.rect.max_lon'] = bRect[2];
      params['boundary.rect.max_lat'] = bRect[3];
    }
    if (bCircle) {
      params['boundary.circle.lat'] = bCircle[0];
      params['boundary.circle.lon'] = bCircle[1];
      params['boundary.circle.radius'] = bCircle[2];
    }
    if (bCountry) {
      params['boundary.country'] = bCountry;
    }
    return _.assign({}, data, params);
  };

  useEffect(() => {
    if (fetchedSuggestions) {
      let newSuggestions = [];

      if (type === 'address') {
        if (fetchedSuggestions.data) {
          const { features } = fetchedSuggestions.data;

          newSuggestions = _.chain(features)
            .uniqBy(feature => feature.properties.label)
            .map((feature, index) => ({
              title: feature.properties.label,
              key: index,
              fields: [],
            }))
            .value();
        }
      } else {
        if (
          fetchedSuggestions.suggestions &&
          fetchedSuggestions.keyword === value.trim()
        ) {
          _.forEach(fetchedSuggestions.suggestions, (suggestion, index) => {
            const { autoFill } = suggestion;

            newSuggestions.push({
              key: index,
              title: suggestion.value,
              fields: autoFill,
            });
          });
        }
      }

      setLoading(false);
      setSuggestions(newSuggestions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedSuggestions]);

  const getSuggestions = (fieldId, fieldValue) => {
    if (type === 'address') {
      const data = buildAddressQuery({ text: fieldValue }, {});
      setArgs(data);
    } else {
      setArgs([fieldId, fieldValue]);
    }
  };

  const handleSearchChange = (e, data) => {
    let matchedValueByPattern;
    const field = { name, value };

    if (pattern) {
      const sEn = e.target.selectionEnd;
      matchedValueByPattern = getValueMatch(
        pattern,
        searchRef.current,
        data.value,
        sEn
      );
    } else {
      matchedValueByPattern = data.value;
    }

    if (matchedValueByPattern !== undefined) {
      searchRef.current = matchedValueByPattern;
      field.value = matchedValueByPattern; // update value with matched pattern
      onChange(e, field); // update autocomplete input value in form data

      clearTimeout(timer);

      timer = setTimeout(() => {
        if (field.value) {
          setLoading(true);
          getSuggestions(fieldId, field.value.trim());
        }
      }, WAITING_INPUT);
    }
  };

  return (
    <>
      <ThemedSearch
        fluid
        showNoResults={false}
        loading={loading || pendingSuggestions}
        value={value}
        required={required}
        disabled={disabled}
        results={suggestions}
        maxLength={LIMIT_CHAR}
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        resultRenderer={resultRenderer}
      />
      <SuggestionContainer />
    </>
  );
};

export default AutocompleteInput;
