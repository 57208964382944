import { THEME } from 'core/utils/constant';

const common = {
  white: '#fff',
  black: '#000',
  transparent: 'transparent',
};

const theme = {
  transparent: common.transparent,

  white: common.white,
  white_5: 'rgba(255,255,255,.05)',
  white_6: 'rgba(255,255,255,.06)',
  white_8: 'rgba(255,255,255,.08)',
  white_10: 'rgba(255,255,255,.1)',
  white_15: 'rgba(255,255,255,.15)',
  white_20: 'rgba(255,255,255,.2)',
  white_30: 'rgba(255,255,255,.3)',
  white_40: 'rgba(255,255,255,.4)',
  white_60: 'rgba(255,255,255,.6)',
  white_80: 'rgba(255,255,255,.8)',
  white_85: 'rgba(255,255,255,.85)',
  white_87: 'rgba(255,255,255,.87)',
  white_90: 'rgba(255,255,255,.9)',
  white_95: 'rgba(255,255,255,.95)',

  black: common.black,
  black_5: 'rgba(0,0,0,.05)',
  black_6: 'rgba(0,0,0,.06)',
  black_8: 'rgba(0,0,0,.08)',
  black_10: 'rgba(0,0,0,.1)',
  black_15: 'rgba(0,0,0,.15)',
  black_20: 'rgba(0,0,0,.2)',
  black_40: 'rgba(0,0,0,.4)',
  black_60: 'rgba(0,0,0,.6)',
  black_80: 'rgba(0,0,0,.8)',
  black_85: 'rgba(0,0,0,.85)',
  black_87: 'rgba(0,0,0,.87)',
  black_90: 'rgba(0,0,0,.9)',
  black_95: 'rgba(0,0,0,.95)',

  darkBlue: 'rgb(33,133,208)',
  darkerBlue: 'rgb(22,120,194)',
  lightBlue: 'rgb(91, 161, 211)',
  lighterBlue: 'rgb(62, 138, 193)',

  gray25: 'rgb(25,25,25)',
  gray30: 'rgb(30,30,30)',
  gray43: 'rgb(43,43,43)',
  gray45: 'rgb(45,45,45)',
  gray51: 'rgb(51,51,51)',
  gray53: 'rgb(53,53,53)',
  gray56: 'rgb(56,56,56)',
  gray61: 'rgb(61,61,61)',
  gray67: 'rgb(67,67,67)',
  gray72: 'rgb(72,72,72)',
  gray90: 'rgb(90,90,90)',
  gray118: 'rgb(118,118,118)',
  gray120: 'rgb(120,120,120)',
  gray188: 'rgb(188,188,188)',
  gray200: 'rgb(200,200,200)',
  gray221: 'rgb(221,221,221)',
  gray224: 'rgb(224,224,224)',
  gray229: 'rgb(229,229,229)',
  gray232: 'rgb(232,232,232)',
  gray245: 'rgb(245,245,245)',
  gray247: 'rgb(247,247,247)',
  gray248: 'rgb(248,248,248)',
  gray250: 'rgb(250,250,250)',

  gray40_5: 'rgba(40,40,40,.05)',
  gray36_15: 'rgba(36,36,36,.15)',
  gray36_30: 'rgba(36,36,36,.3)',
  gray40_30: 'rgba(40,40,40,.3)',
  gray61_45: 'rgba(61,61,61,.45)',
  gray72_20: 'rgba(72,72,72,.2)',
  gray72_80: 'rgba(72,72,72,.8)',
  gray72_90: 'rgba(72,72,72,.9)',
  gray120_35: 'rgba(120,120,120,.35)',
  gray110_20: 'rgba(110,110,110,.2)',
  gray110_80: 'rgba(110,110,110,.8)',
  gray118_5: 'rgba(118,118,118,.05)',
  gray212_100: 'rgba(212,212,213,1)',
  gray215_30: 'rgba(215,215,215,.3)',

  //SemanticUi
  gray34_10: 'rgba(34,36,38,.1)',
  gray34_12: 'rgba(34,36,38,.12)',
  gray34_15: 'rgba(34,36,38,.15)',
  gray202_: 'rgb(202,203,205)',
  gray224_: 'rgb(224,225,226)',
  white253: 'rgb(253,253,253)',
  green252_: 'rgb(252,255,245)',

  //warning
  deepBronze: 'rgb(87, 58, 8)',
  lightBronze: 'rgb(255, 250, 243)',

  //error
  deepRed: 'rgb(159, 58, 56)',
  lightRed: 'rgb(255, 246, 246)',

  //positive
  deepGreen: 'rgb(44, 102, 45)',
  lightGreen: 'rgb(252, 255, 245)',

  //timeline
  green: 'rgb(33, 186, 69)',
  red: 'rgb(219, 40, 40)',
  darkRed: 'rgb(208, 25, 25)',
  yellow: 'rgb(251, 189, 8)',

  //agenda
  agendaTodayBg: 'rgb(234, 246, 255)',
  agendaDarkTodayBg: 'rgb(92, 105, 126)',
  agendaCellBg: 'rgb(49, 116, 173)',
  agendaDarkCellBg: 'rgb(19, 86, 130)',
  agendaBorderColor: 'rgb(38, 89, 133)',
  agendaDarkBorderColor: 'rgb(16, 36, 51)',
};

const lightTheme = {
  bg: theme.white,
  fg: theme.black_87,
  accentBg: theme.white,
  publicBg: theme.white_80,
  disabled: theme.black_80,

  iconColor: theme.gray118,
  bgGeneral: theme.white253,
  bgFrosted: theme.white_90,
  bgTransparent: theme.transparent,
  metadata: theme.black_40,
  timeline: theme.gray202_,

  warningBg: theme.lightBronze,
  warningFg: theme.deepBronze,
  errorBg: theme.lightRed,
  errorFg: theme.deepRed,
  positiveBg: theme.lightGreen,
  positiveFg: theme.deepGreen,

  buttonFg: theme.black_60,
  buttonFgHover: theme.black_80,
  buttonNegative: theme.red,
  buttonNegativeHover: theme.darkRed,
  buttonPrimary: theme.darkBlue,
  buttonBg: theme.gray224_,
  buttonBgHover: theme.gray202_,
  buttonDisabled: theme.gray120_35,

  border: `1px solid ${theme.gray34_10}`,
  borderColor: theme.gray34_10,
  borderColorHover: theme.black_80,
  borderPrincipal: theme.gray34_10,

  labelFg: theme.black_60,
  labelBg: theme.gray232,
  labelBasicBg: theme.white,
  labelRed: theme.red,
  labelBlue: theme.blue,
  labelGreen: theme.green,
  labelYellow: theme.yellow,

  boxShadow: `0 1px 2px 0 ${theme.gray36_15}, 0 0 0 1px ${theme.gray36_15}`,
  boxShadowSegment: `0 1px 2px 0 ${theme.gray34_15}`,
  boxShadowFooter: `0 1px 5px 0 ${theme.gray120_35}`,
  boxShadowMini: `1px 1px 0px ${theme.gray34_15}`,
  boxShadowDropdown: `0 2px 4px 0 ${theme.gray34_12}, 0 2px 10px 0 ${
    theme.gray34_15
  }`,
  boxShadowToast: `0 2px 4px 1px ${theme.gray34_15}`,

  primary: theme.darkBlue,
  primaryHover: theme.darkerBlue,
  primaryText: theme.white_87,

  linkFg: theme.darkBlue,
  linkFgHover: theme.darkerBlue,

  checkboxBg: theme.black_5,
  checkboxBgHover: theme.black_15,

  inputBg: theme.white,
  inputBgSelected: theme.black_5,
  inputFg: theme.black_87,
  inputFgHover: theme.black_80,
  inputDisabled: theme.gray40_5,

  dropdownBg: theme.white,
  dropdownBgOther: theme.gray224_,
  dropdownBgHover: theme.white,
  dropdownBgSelected: theme.black_5,
  dropdownItemBgHover: theme.black_10,
  dropdownFg: theme.black_60,
  dropdownFgHover: theme.black_80,
  dropdownText: theme.black_87,
  dropdownMenuBorderColor: theme.gray34_10,
  dropdownMenuItemBorderColor: theme.gray250,

  resultSpanFg: theme.black_40,

  tableBg: theme.white,
  tableHeaderBg: theme.gray250,
  tableHeaderBgHover: theme.black_5,
  tableHeaderBgFocus: theme.black_5,
  tableItemBgHover: theme.black_5,
  tableItemBgActive: theme.gray224,
  tableFg: theme.black_95,
  tableBorder: `1px solid ${theme.gray34_10}`,
  tableBorderColor: theme.gray34_10,

  placeholderImg: `linear-gradient(to right,${theme.black_8} 0,${
    theme.black_15
  } 15%,${theme.black_8} 30%)`,

  popupBg: theme.white,
  popupBgHover: theme.black_5,
  popupFg: theme.black_60,
  popupBorderColor: theme.gray212_100,
  popupArrowBoxShadow: `${theme.gray34_15}`,

  sidebarBoxShadow: `0 0 20px ${theme.gray36_15}`,
  sidebarMenuItemBgHover: theme.black_5,
  sidebarMenuItemBgActive: theme.black_5,

  headerItemFg: theme.black,
  headerItemBg: theme.black_20,
  headerItemBgHover: theme.black_5,

  tileFg: theme.gray118,
  tileFgHover: theme.gray56,
  tileFgActive: theme.gray90,
  tileBgActive: theme.gray229,

  dragdropBg: theme.white,
  dragdropFg: theme.black_87,
  dragdropBorder: `1px dashed ${theme.gray72};`,

  stepFgDisabled: theme.gray40_30,
  stepBgActive: theme.gray245,
  stepBgDisabled: theme.gray30,
  stepBgDone: theme.white,
  stepIconActive: theme.black_85,

  modalBg: theme.white_90,

  toastBg: theme.white,
  toastFg: theme.gray118,

  agendaBg: theme.white,
  agendaBgAccent: theme.gray229,
  agendaFg: theme.black_87,
  agendaFgInverted: theme.white,
  agendaButtonFg: theme.black_80,
  agendaButtonFgHover: theme.black_90,
  agendaButtonBg: theme.white,
  agendaButtonBgHover: theme.gray202_,
  agendaBorderColor: theme.gray221,
  agendaBorder: `1px solid ${theme.gray221}`,
  agendaBorderBold: `2px solid ${theme.gray221}`,
  agendaTimeSlotBorder: `1px solid ${theme.gray247}`,
  agendaTodayBg: theme.agendaTodayBg,
  agendaCellBorder: `1px solid ${theme.agendaBorderColor}`,
  agendaCellBorderColor: theme.agendaBorderColor,
  agendaCellBg: theme.agendaCellBg,
  agendaCellSelectedBg: theme.agendaBorderColor,

  menuItemBgDisabled: theme.gray40_5,
  menuItemFgDisabled: theme.gray40_30,

  ...common,
};

const darkTheme = {
  bg: theme.gray51,
  fg: theme.white_87,
  accentBg: theme.gray72,
  publicBg: theme.gray72_80,
  disabled: theme.gray72,

  iconColor: theme.gray188,
  bgGeneral: theme.gray51,
  bgFrosted: theme.gray72_90,
  bgTransparent: theme.transparent,
  metadata: theme.white_60,

  timeline: theme.gray43,
  timelineFg: theme.white,
  timelineGreenBg: theme.green,
  timelineRedBg: theme.red,
  timelineYellowBg: theme.yellow,
  timelineBasicBg: theme.black_87,
  timelineBorder: theme.gray61,
  timelineBlueBg: theme.darkBlue,

  warningBg: theme.deepBronze,
  warningFg: theme.lightBronze,
  errorBg: theme.deepRed,
  errorFg: theme.lightRed,
  positiveBg: theme.deepGreen,
  positiveFg: theme.lightGreen,

  buttonFg: theme.white_60,
  buttonFgHover: theme.white_80,
  buttonNegative: theme.red,
  buttonNegativeHover: theme.darkRed,
  buttonPrimary: theme.darkBlue,
  buttonBg: theme.gray61,
  buttonBgHover: theme.gray56,
  buttonDisabled: theme.gray53,

  border: `1px solid ${theme.gray34_10}`,
  borderColor: theme.gray34_10,
  borderColorHover: theme.white_80,
  borderPrincipal: theme.white_10,

  labelFg: theme.white_60,
  labelBg: theme.gray43,
  labelBasicBg: theme.black_87,
  labelRed: theme.deepRed,
  labelBlue: theme.darkBlue,
  labelGreen: theme.deepGreen,
  labelYellow: theme.deepBronze,

  boxShadow: `0 1px 2px 0 ${theme.gray45}, 0 0 0 1px ${theme.gray45}`,
  boxShadowSegment: `0 1px 2px 0 ${theme.gray45}`,
  boxShadowFooter: `0 1px 5px 0 ${theme.gray45}`,
  boxShadowMini: `1px 1px 0px ${theme.gray45}`,
  boxShadowDropdown: `0 2px 4px 0 ${theme.gray53}, 0 2px 10px 0 ${
    theme.gray45
  }`,
  boxShadowToast: `0 2px 4px 1px ${theme.gray45}`,

  primary: theme.lightBlue,
  primaryHover: theme.lighterBlue,
  primaryText: theme.white_90,

  linkFg: theme.lightBlue,
  linkFgHover: theme.lighterBlue,

  checkboxBg: theme.white_10,
  checkboxBgHover: theme.white_15,

  inputBg: theme.gray61,
  inputBgSelected: theme.gray45,
  inputFg: theme.white_90,
  inputFgHover: theme.white_80,
  inputDisabled: theme.gray118_5,

  dropdownBg: theme.gray61,
  dropdownBgOther: theme.gray61,
  dropdownBgHover: theme.gray56,
  dropdownBgSelected: theme.gray45,
  dropdownItemBgHover: theme.gray30,
  dropdownFg: theme.white_60,
  dropdownFgHover: theme.white_80,
  dropdownText: theme.white_60,
  dropdownMenuBorderColor: theme.gray215_30,
  dropdownMenuItemBorderColor: theme.gray36_30,

  resultSpanFg: theme.white_40,

  tableBg: theme.gray67,
  tableHeaderBg: theme.gray43,
  tableHeaderBgHover: theme.white_5,
  tableHeaderBgFocus: theme.black_60,
  tableItemBgHover: theme.white_8,
  tableItemBgActive: theme.white_10,
  tableFg: theme.white_90,
  tableFgSelected: theme.white_90,
  tableBorder: `1px solid ${theme.white_10}`,
  tableBorderColor: theme.white_10,

  placeholderImg: `linear-gradient(to right,${theme.white_8} 0,${
    theme.white_15
  } 15%,${theme.white_8} 30%)`,

  popupBg: theme.gray61,
  popupBgHover: theme.gray43,
  popupFg: theme.white_60,
  popupBorderColor: theme.gray34_10,
  popupBoxShadow: `3px 3px 5px ${theme.gray45}`,
  popupArrowBoxShadow: `${theme.gray34_15}`,

  sidebarBoxShadow: `0 0 20px ${theme.gray45}`,
  sidebarMenuItemBgHover: theme.white_5,
  sidebarMenuItemBgActive: theme.white_5,

  headerItemFg: theme.white,
  headerItemBg: theme.white_20,
  headerItemBgHover: theme.white_5,

  tileFg: theme.gray188,
  tileFgHover: theme.gray229,
  tileFgActive: theme.gray200,
  tileBgActive: theme.gray53,

  dragdropBg: theme.gray72,
  dragdropFg: theme.white_87,
  dragdropBorder: `1px dashed ${theme.gray45};`,

  stepFgDisabled: theme.gray215_30,
  stepIconActive: theme.white_85,
  stepBgActive: theme.gray61,
  stepBgDisabled: theme.gray51,
  stepBgDone: theme.gray25,

  modalBg: theme.gray43,

  toastBg: theme.gray61,
  toastFg: theme.gray188,

  agendaBg: theme.gray51,
  agendaBgAccent: theme.gray43,
  agendaFg: theme.white_80,
  agendaFgInverted: theme.white,
  agendaButtonFg: theme.white_80,
  agendaButtonFgHover: theme.white_90,
  agendaButtonBg: theme.gray61,
  agendaButtonBgHover: theme.gray56,
  agendaBorder: `1px solid ${theme.gray110_80}`,
  agendaBorderBold: `2px solid ${theme.gray110_80}`,
  agendaBorderColor: theme.gray110_80,
  agendaTimeSlotBorder: `1px solid ${theme.gray110_20}`,
  agendaTodayBg: theme.agendaDarkTodayBg,
  agendaCellBorder: `1px solid ${theme.agendaDarkBorderColor}`,
  agendaCellBorderColor: theme.agendaDarkBorderColor,
  agendaCellBg: theme.agendaDarkCellBg,
  agendaCellSelectedBg: theme.agendaDarkBorderColor,

  //Additional
  rowBg: theme.gray72,
  rowColor: theme.fg,

  menuItemBgDisabled: theme.gray40_5,
  menuItemFgDisabled: theme.gray40_30,

  ...common,
};

export default theme => (theme === THEME.dark ? darkTheme : lightTheme);
