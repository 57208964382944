import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${({
    theme: {
      color: { bg, fg },
    },
  }) => `
    *,
    *::after,
    *::before {
      box-sizing: border-box;
    }

    body{
      background-color: ${bg};
      color: ${fg};
    }

    .blurring.dimmed.dimmable>:not(.dimmer) {
      filter: none;
    }

    .blurring.dimmed.dimmable>#renderBody>div:not(.Toastify) {
      -webkit-filter: blur(5px) grayscale(.7);
      filter: blur(5px) grayscale(.7);
    }
  `}
`;

export default GlobalStyle;
