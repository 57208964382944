import React from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import Utils from 'core/utils/utils';

const ThemedSegment = styled(
  ({
    active,
    inline,
    flat,
    frosted,
    transparent,
    iconColor,
    color,
    backgroundColor,
    theme,
    ...props
  }) => <Segment {...props} />
)`
  ${({
    active,
    inline,
    flat,
    frosted,
    transparent,
    iconColor,
    color,
    backgroundColor,
    theme: {
      color: {
        bgFrosted,
        bgTransparent,
        tileBgActive,
        accentBg,
        fg,
        boxShadowSegment,
      },
    },
  }) => `
    &&& {
      ${
        inline
          ? `
            display: flex;
            padding: 0.5em 0.5em;

            i.icon {
              margin-right: 0.5rem;
              color: ${Utils.getColor(iconColor)}
            }
          `
          : `
            display: block;
            padding: 1em 1em;
          `
      }


      background-color: ${
        transparent
          ? bgTransparent
          : frosted
          ? bgFrosted
          : active
          ? tileBgActive
          : Utils.getColor(backgroundColor) || accentBg
      };
      color: ${Utils.getColor(color) || fg};
      box-shadow: ${flat ? 'none' : boxShadowSegment};
      border: ${flat ? 'none' : ''};

      label,
      .header {
        color: ${Utils.getColor(color) || fg};
      }
    }
  `}
`;

ThemedSegment.defaultProps = {
  theme: {
    color: {},
  },
};

export default ThemedSegment;
