import React from 'react';
import { Item, Button } from 'semantic-ui-react';
import { InputSearch } from 'components';
import styled from 'styled-components';
import PopupStatusMessage from './PopupStatusMessage';

const ScrollableItemGroup = styled(Item.Group)`
  max-height: 500px;
  overflow-y: scroll;
  padding-right: 10px;
`;

const PopupList = ({ loading, title, handleSearch, children }) => (
  <>
    <InputSearch
      name="search"
      icon="search"
      placeholder={`Search for a ${title}...`}
      handleValue={handleSearch}
      loading={loading}
    />
    <ScrollableItemGroup>{children}</ScrollableItemGroup>
    <Button
      content={`Upload ${title} list`}
      primary
      floated="left"
      style={{ marginRight: '10px' }}
    />
    <PopupStatusMessage title={title} />
  </>
);

export default PopupList;
