import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Container, Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import NotFound from './scenes/not-found/NotFound';

const Error = ({ t, area }) => (
  <Container textAlign="center">
    <Switch>
      <Route render={() => <NotFound t={t} />} />
    </Switch>
    <Button
      primary
      as={Link}
      to={area ? `/${area}` : '/'}
      content={t('common|Go to home')}
    />
  </Container>
);

export default withTranslation()(Error);
