import _ from 'lodash';

import { FIELD_ACTION_TAG } from 'core/utils/constant';

const areEqual = (prevProps, nextProps) => {
  if (!nextProps) return true;

  let areEqual = true;

  _.each(nextProps.fieldsNeedToUpdate, field => {
    if (
      (field.tag === FIELD_ACTION_TAG.field &&
        _.find(prevProps.visibleFields, { name: field.key })) ||
      (field.tag === FIELD_ACTION_TAG.section &&
        field.key === prevProps.section.key)
    ) {
      areEqual = false;
      return;
    }
  });

  const fieldSection = prevProps.section.fields;
  if (_.find(fieldSection, { key: nextProps.focusedInput })) {
    areEqual = false;
  }

  return areEqual;
};

export default areEqual;
