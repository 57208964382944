import { combineReducers } from 'redux';
import { resettableReducer } from 'reduxsauce';
import { connectRouter } from 'connected-react-router';

// Redux-persist
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

// Reducers
import { settingsReducer } from 'core/settings/settingsReducer';
import { backgroundReducer } from 'core/background/backgroundReducer';
import { customPageReducer } from 'core/custom-page/customPageReducer';
import authReducer from 'core/auth/authReducer';
import modalReducer from 'core/modal/modalReducer';
import fieldsActionReducer from 'core/fields-action/fieldsActionReducer';
import templateReducer from 'core/template/templateReducer';
import batchTemplateReducer from 'core/batch-template/batchTemplateReducer';
import formDataReducer from 'core/form-data/formDataReducer';
import contextMenuReducer from 'core/context-menu/contextMenuReducer';
import adminTemplaceReducer from 'core/template/adminTemplateReducer';
import modulesReducer from '../core/modules/modulesReducer';
import dispatchReducer from 'core/dispatch/dispatchReducer';

const rootPersistConfig = {
  key: 'root',
  storage,
};

const resettable = resettableReducer('RESET');

const rootReducer = history =>
  persistReducer(
    rootPersistConfig,
    combineReducers({
      auth: resettable(authReducer),
      settings: resettable(settingsReducer),
      background: backgroundReducer,
      customPage: customPageReducer,
      modal: resettable(modalReducer),
      fieldsAction: resettable(fieldsActionReducer),
      modules: resettable(modulesReducer),
      template: resettable(templateReducer),
      adminTemplate: resettable(adminTemplaceReducer),
      batchTemplate: resettable(batchTemplateReducer),
      formData: resettable(formDataReducer),
      contextMenu: resettable(contextMenuReducer),
      dispatch: resettable(dispatchReducer),
      router: connectRouter(history),
    })
  );

export default rootReducer;
