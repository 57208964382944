export class GHRequestInfo {
  constructor({
    vehicle = 'car',
    weighting = 'fastest',
    algorithm,
    locale = 'en_US',
    pathDetails,
    pointHints,
  } = {}) {
    this.vehicle = vehicle;
    this.weighting = weighting;
    this.algorithm = algorithm;
    this.locale = locale;
    this.pathDetails = pathDetails;
    this.pointHints = pointHints;
  }
}

export class GHResponseInfo {
  constructor({
    calcPoints = true,
    pointsEncoded = true,
    includeElevation = false,
    enableInstructions = true,
  } = {}) {
    this.calcPoints = calcPoints;
    this.pointsEncoded = pointsEncoded;
    this.includeElevation = includeElevation;
    this.enableInstructions = enableInstructions;
  }
}
