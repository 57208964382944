import React from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';

const BasicSegment = styled(Segment).attrs({
  basic: true,
})`
  &&& {
    margin-top: 0;
    flex: 1 1 0%;
    padding: 0.5em 1.2em 1.5em;
    //margin-right: 14px;
    //background-color: ${({ theme: { color } }) => color.inputBg};
  }
`;

const PublicSegment = styled(BasicSegment)`
  &&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const FlexSegment = ({ isPublic, children }) =>
  isPublic ? (
    <PublicSegment>{children}</PublicSegment>
  ) : (
    <BasicSegment>{children}</BasicSegment>
  );

FlexSegment.displayName = 'FlexSegment';

export default FlexSegment;
