import React, { useState, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import stopApi from '../../../api/stop/stopApi';

import StopListItem from './components/StopListItem';
import StopInfoPanel from './components/StopInfoPanel';
import PopupTitle from '../components/container/PopupTitle';
import PopupList from '../components/list/PopupList';

const PopupStops = () => {
  const [loading, setLoading] = useState(false);
  const [stops, setStops] = useState([]);
  const [selectedStop, setSelectedStop] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchStops();
  }, []);

  const fetchStops = async () => {
    setLoading(true);
    const response = await stopApi.getAll();

    if (response && response.stops) {
      const { stops } = response;
      setStops(stops);
      setSelectedStop(stops[0]);
    }
    setLoading(false);
  };

  const handleSelectStop = stop => {
    setEditMode(false);
    setSelectedStop(stop);
  };

  const enableEditMode = async (e, stop) => {
    e.stopPropagation();
    if (selectedStop.id !== stop.id) {
      // const res = await setSelectedStop(stop);
    }
    setEditMode(true);
  };

  const handleSearch = (e, { name, value }) => {
    fetchStops();
  };

  return (
    <div>
      <PopupTitle title="Stops" size={stops.length} />
      <Grid columns={2}>
        <Grid.Column width={7}>
          <PopupList
            loading={loading}
            title="stops"
            handleSearch={handleSearch}
          >
            {stops.map((stop, index) => (
              <StopListItem
                key={stop.id}
                info={stop}
                handleClick={() => handleSelectStop(stop)}
                handleEditMode={e => enableEditMode(e, stop)}
              />
            ))}
          </PopupList>
        </Grid.Column>
        <Grid.Column width={9}>
          <StopInfoPanel stop={selectedStop} edited={editMode} />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default PopupStops;
