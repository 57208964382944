import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ContextMenu from './ContextMenu';
import {
  // getContextMenuItems,
  getContextMenus,
} from 'core/context-menu/contextMenuReducer';
import { MENU_IDS } from 'core/utils/constant';

const ContextMenuContainer = ({ menus }) => {
  const handleClick = (dataMerged, onClick) => {
    onClick(dataMerged.action, dataMerged.info);
  };

  const handleOnShow = event => {
    const { callbackOnShow } = event.detail.data;

    if (callbackOnShow) {
      callbackOnShow();
    }
  };

  return _.map(MENU_IDS, id => {
    const menu = _.find(menus, { id });
    return (
      menu && (
        <ContextMenu
          // menuItems={menuItems}
          key={id}
          id={id}
          menu={menu.items}
          handleClick={handleClick}
          handleOnShow={handleOnShow}
        />
      )
    );
  });
};

const mapStateToProps = state => ({
  // menuItems: getContextMenuItems(state),
  menus: getContextMenus(state),
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContextMenuContainer);
