import _ from 'lodash';

import { getMoment } from 'core/utils/date';

import getMaskRowText from '../../mask/helpers/getMaskRowText';

// retrieve missing start or end from the other one and length
const getMissingDateTimeFromLength = (data, start, end, durationKey) => {
  const missingStart = start ? false : true;

  return getMoment(missingStart ? end : start)
    .add(`${missingStart ? '-' : ''}${data[durationKey]}`, 'm')
    .toDate();
};

// compute start or end of event
const getEventStartOrEnd = (data, keysArray, eventLength) => {
  const values = _.compact(_.map(keysArray, key => data[key]));
  const dateString = values && values.length > 0 ? values.join(' ') : null;

  return dateString && (dateString.includes(':') || !data[eventLength])
    ? getMoment(dateString).toDate()
    : null;
};

// compute both start and end of event
const getEventStartAndEnd = (
  data,
  lastEdit,
  { eventStart, eventLength, eventEnd }
) => {
  const eventStartAndEnd = {
    start: getEventStartOrEnd(data, eventStart, eventLength),
    end: getEventStartOrEnd(data, eventEnd, eventLength),
  };

  if (!eventStartAndEnd.start && eventStartAndEnd.end) {
    // start data is missing, compute it from end and length
    eventStartAndEnd.start = getMissingDateTimeFromLength(
      data,
      undefined,
      eventStartAndEnd.end,
      eventLength
    );
  } else if (eventStartAndEnd.start && !eventStartAndEnd.end) {
    // end data is missing, compute it from start and length
    eventStartAndEnd.end = getMissingDateTimeFromLength(
      data,
      eventStartAndEnd.start,
      undefined,
      eventLength
    );
  }

  // data is missing -> fallback to lastEdit
  if (!eventStartAndEnd.start || !eventStartAndEnd.end) {
    eventStartAndEnd.start = getMoment(lastEdit).toDate();
    eventStartAndEnd.end = getMoment(lastEdit).toDate();
  }

  return eventStartAndEnd;
};

// convert mask row to agenda event
const maskRowToEvent = (maskStructure, maskRow) => {
  const { eventStart, eventLength, eventEnd } = maskStructure;
  const { key, lastEdit, data: rowData, color } = maskRow;

  // compute event start and end datetimes
  const { start, end } = getEventStartAndEnd(rowData, lastEdit, {
    eventStart,
    eventLength,
    eventEnd,
  });

  // define title
  const title = getMaskRowText(maskStructure, rowData);

  return {
    key,
    title,
    start,
    end,
    color,
  };
};

export { getEventStartAndEnd };

export default maskRowToEvent;
