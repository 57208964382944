export class Matrix {
  constructor({
    fromPoints,
    toPoints,
    fromPointHints,
    toPointHints,
    outArrays = ['weights', 'times', 'distances'],
    vehicle = 'car',
    ghRequestInfo,
    ghResponseInfo,
  }) {
    this.fromPoints = fromPoints;
    this.toPoints = toPoints;
    this.fromPointHints = fromPointHints;
    this.toPointHints = toPointHints;
    this.outArrays = outArrays;
    this.vehicle = vehicle;
    this.ghRequestInfo = ghRequestInfo;
    this.ghResponseInfo = ghResponseInfo;
  }
}

export class MatrixSymmetric {
  constructor({
    points,
    pointHints,
    outArrays = ['weights', 'times', 'distances'],
    vehicle = 'car',
    ghRequestInfo,
    ghResponseInfo,
  }) {
    this.points = points;
    this.pointHints = pointHints;
    this.outArrays = outArrays;
    this.vehicle = vehicle;
    this.ghRequestInfo = ghRequestInfo;
    this.ghResponseInfo = ghResponseInfo;
  }
}
