import React from 'react';
import styled from 'styled-components';
import { ContextMenu as ReactContextMenu, MenuItem } from 'react-contextmenu';
import { Menu, Icon } from 'semantic-ui-react';
import _ from 'lodash';

const StyledContextMenu = styled(ReactContextMenu)`
  ${({
    theme: {
      color: { menuItemBgDisabled, menuItemFgDisabled },
    },
  }) => `
    z-index: 99;
    min-width: 250px;

    & .react-contextmenu-item--disabled {
      background-color: ${menuItemBgDisabled};

      > div.link.item {
        color: ${menuItemFgDisabled};
      }
    }
  `}
`;

const ContextMenu = ({ id, menu, handleOnShow, handleClick }) => (
  <div>
    <Menu as={StyledContextMenu} id={id} vertical onShow={handleOnShow}>
      {!_.isEmpty(menu) &&
        menu.map(({ disabled, icon, title, data, onClick }, index) => (
          <MenuItem
            disabled={disabled}
            data={data}
            onClick={(e, dataMerged) => handleClick(dataMerged, onClick)}
            key={index}
          >
            <Menu.Item link>
              {icon && <Icon name={icon} />}
              {title}
            </Menu.Item>
          </MenuItem>
        ))}
    </Menu>
  </div>
);

export default ContextMenu;
