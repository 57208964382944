import getMaskRowText from '../../mask/helpers/getMaskRowText';

//// compute both start and end of event
//const getCoordinatesXY = (data, { defaultX, defaultY }) => {
//  const coordinatesXY = {
//    coordinateX: data.CoordinateX,
//    coordinateY: data.CoordinateY,
//  };

//  if (!coordinatesXY.coordinateX && coordinatesXY.coordinateY) {
//    coordinatesXY.coordinateX = defaultX;
//  } else if (coordinatesXY.coordinateX && !coordinatesXY.coordinateY) {
//    coordinatesXY.coordinateY = defaultY;
//  }

//  // data is missing -> fallback to default cooordinate of map
//  if (!coordinatesXY.coordinateX || !coordinatesXY.coordinateY) {
//    coordinatesXY.coordinateX = defaultX;
//    coordinatesXY.coordinateX = defaultY;
//  }

//  return coordinatesXY;
//};

// convert mask row to map marker
const maskRowToMarker = (maskStructure, maskRow) => {
  const { coordinateX: filedKeyLong, coordinateY: fieldKeyLat } = maskStructure;
  const { key, data: rowData, color } = maskRow;

  // get coordinates X and Y
  const coordinateX = rowData[filedKeyLong];
  const coordinateY = rowData[fieldKeyLat];

  // define tooltip
  const tooltip = getMaskRowText(maskStructure, rowData);

  return [key, coordinateX, coordinateY, tooltip, color];
};

export default maskRowToMarker;
