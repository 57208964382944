import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

import Utils from 'core/utils/utils';

import CustomPageGridRow from './components/CustomPageGridRow';

const StyledGrid = styled(Grid)`
  &&& {
    margin-top: 0;
  }
`;

const CustomPage = ({ pageContent, area, module, currentUser, ...props }) => (
  <StyledGrid stackable columns="equal">
    {_.map(
      _.groupBy(
        _.filter(
          pageContent,
          ({ areaOnly, areas, sectionID }) =>
            Utils.showInArea({ areaOnly, areas }, area) || sectionID
        ).sort((pc1, pc2) => pc1.row - pc2.row),
        'row'
      ), // { 'r1': [{r1, c1}, {r1, c2}] }
      groupedRow => (
        <CustomPageGridRow
          key={groupedRow[0].row}
          groupedRow={groupedRow}
          area={area}
          module={module}
          currentUser={currentUser}
          {...props}
        />
      )
    )}
  </StyledGrid>
);

export default CustomPage;
