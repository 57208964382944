import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  SpinnerSegment,
  InputSearch,
  ThemedHeaderCell,
  ThemedHeaderCellHeader,
} from 'components';

const HeaderCellHeader = styled(ThemedHeaderCellHeader)`
  &&& {
    padding: 0.5rem;
    position: relative;
    min-width: 7rem;
  }
`;

const HeaderCellFilter = ({
  t,
  activeColumn,
  c,
  direction,
  handleSearchFieldChange,
  handleSort,
}) => (
  <HeaderCellHeader
    sorted={activeColumn === c.key ? direction : null}
    onClick={c.preventSort ? undefined : handleSort(c.key)}
  >
    <div>{c.name}</div>
    {!c.preventFilter && (
      <InputSearch
        t={t}
        fluid
        column={c}
        handleValue={handleSearchFieldChange}
        clearable
      />
    )}
  </HeaderCellHeader>
);

const TableHeader = ({
  t,
  table,
  activeColumn,
  direction,
  handleSort,
  handleSearchFieldChange,
}) => {
  if (!table || !table.columns) {
    return <ThemedHeaderCell content={<SpinnerSegment />} />;
  }

  if (table.columns.length < 1) {
    return (
      <ThemedHeaderCell
        content={t('No fields found, please add fields to see the results')}
      />
    );
  }

  return table.columns.map(c => (
    <HeaderCellFilter
      key={c.key}
      c={c}
      t={t}
      direction={direction}
      activeColumn={activeColumn}
      handleSort={handleSort}
      handleSearchFieldChange={handleSearchFieldChange}
    />
  ));
};

export default withTranslation()(TableHeader);
