import React from 'react';
import styled from 'styled-components';
import { Form, Checkbox } from 'semantic-ui-react';

import getShadowByState from './helpers/getShadowByState';

const TrackedCheckbox = styled(
  ({ value, unchanged, saved, type, ...props }) => (
    <Form.Field control={Checkbox} checked={value} {...props} />
  )
)`
  ${({
    saved,
    unchanged,
    theme: {
      color: { fg, checkboxBg, checkboxBgHover },
    },
  }) => `
    &&&&& label::before {
      box-shadow: ${getShadowByState(saved, unchanged)};
    }

    &&& {
      label,
      input:active:checked ~ label,
      input:checked ~ label {
        color: ${fg} !important;
      }
    }

    &.toggle label {
      &::before {
        background-color: ${checkboxBg};
      }
      &:hover::before {
        background-color: ${checkboxBgHover};
      }
    }
  `}
`;

export default TrackedCheckbox;
