import { FIELD_TYPE } from 'core/utils/constant';

export default [
  {
    row: 1,
    column: 1,
    key: 'id',
    name: 'ID',
    pattern: null,
    comboList: null,
    type: FIELD_TYPE.text,
    required: true,
    isDisabled: true,
    isVisible: true,
  },
  {
    row: 2,
    column: 1,
    key: 'name',
    name: 'Name',
    pattern: null,
    comboList: null,
    type: FIELD_TYPE.text,
    required: true,
    isVisible: true,
  },
  {
    row: 3,
    column: 1,
    key: 'address',
    name: 'Address',
    pattern: null,
    comboList: null,
    type: FIELD_TYPE.text,
    required: true,
    isVisible: true,
  },
  {
    row: 4,
    column: 1,
    key: 'clientName',
    name: 'Client Name',
    pattern: null,
    comboList: null,
    type: FIELD_TYPE.text,
    required: true,
    isVisible: true,
  },
  {
    row: 4,
    column: 2,
    key: 'clientPhone',
    name: 'Client Phone',
    pattern: null,
    comboList: null,
    type: FIELD_TYPE.text,
    required: true,
    isVisible: true,
  },
  {
    row: 6,
    column: 1,
    key: 'status',
    name: 'Status',
    pattern: null,
    comboList: null,
    type: FIELD_TYPE.text,
    required: true,
    isDisabled: true,
    isVisible: true,
  },
];
