import React from 'react';
import { Responsive } from 'semantic-ui-react';
import { ThemedButton } from 'components';

const ResponsiveButtonCore = ({ children, ...props }) => {
  // render
  return (
    <>
      <Responsive as={ThemedButton} fluid {...Responsive.onlyMobile} {...props}>
        {children}
      </Responsive>
      <Responsive
        as={ThemedButton}
        minWidth={Responsive.onlyTablet.minWidth}
        {...props}
      >
        {children}
      </Responsive>
    </>
  );
};

export default ResponsiveButtonCore;
