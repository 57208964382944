import React, { useState, useRef } from 'react';
import { bool, func, string } from 'prop-types';
import _ from 'lodash';

import documentApi from 'api/document/documentApi';
import { DOWNLOAD_TYPE } from 'core/utils/constant';

import DownloadButton from './DownloadButton';

const { pdf } = DOWNLOAD_TYPE;
const fileTypeDownload = [pdf];

// map template names and types to dropdown options
const getDownloadOptions = templateNames =>
  _.map(fileTypeDownload, t => ({
    key: `${t}`,
    value: `${t}`,
    text: `${t}`,
    items: _.map(templateNames, n => ({
      key: `${t}_${n}`,
      value: `${t}_${n}`,
      text: n,
    })),
  }));

const DownloadButtonContainer = ({
  disabled,
  name,
  icon,
  iconColor,
  color,
  onDownload,
}) => {
  const dropdownRef = useRef();
  const [loading, setLoading] = useState(false);
  const [downloadOptions, setDownloadOptions] = useState();

  // get all download templates on dropdown opening
  const handleFetch = async () => {
    if (!downloadOptions) {
      setLoading(true);
      const result = await documentApi.getDownloadTemplates();

      if (result && result.downloadTemplateNames) {
        setDownloadOptions(getDownloadOptions(result.downloadTemplateNames));
        setLoading(false);
      }
    }
  };

  // start download on item click in dropdown
  const handleChange = (e, { value }) => {
    const split = value.split('_', 2);
    const fileType = split[0];
    const templateName = split[1];

    onDownload(templateName, fileType);
  };

  const buttonProps = {
    name,
    icon,
    iconColor,
    color,
    disabled,
    loading,
    dropdownRef,
    downloadOptions,
    handleFetch,
    handleChange,
  };

  return <DownloadButton {...buttonProps} />;
};

DownloadButtonContainer.propTypes = {
  disabled: bool,
  name: string,
  onDownload: func,
};

export default DownloadButtonContainer;
