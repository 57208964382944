import React from 'react';
import { bool, func, object } from 'prop-types';
import _ from 'lodash';

import Utils from 'core/utils/utils';

import TrackedForm from './TrackedForm';

const TrackedFormContainer = ({
  structure,
  autosave,
  loading,
  saved,
  exists,
  refData,
  prevData,
  currentData,
  handleChange,
  handleSubmit,
}) => {
  const dataProps = Object.keys(refData);

  // initialize unchanged and saved state by field
  const changeTracker = exists ? [] : undefined;
  const saveTracker = exists ? [] : undefined;

  // compute
  if (exists) {
    _.forEach(dataProps, key => {
      changeTracker.push({
        key,
        unchanged: `${prevData[key]}` === `${currentData[key]}`,
      });
      saveTracker.push({
        key,
        saved:
          saved &&
          `${refData[key]}` !== `${prevData[key]}` &&
          _.find(changeTracker, { key }).unchanged,
      });
    });
  }

  // compute unchanged state of form
  const unchangedArray = exists
    ? Utils.getFlatSubArray(changeTracker, [t => t.unchanged], false)
    : undefined;

  const unchanged = exists
    ? Utils.reduceAndBoolArray(unchangedArray)
    : undefined;

  return (
    <TrackedForm
      structure={structure}
      autosave={autosave}
      loading={loading}
      exists={exists}
      currentData={currentData}
      changeTracker={changeTracker}
      saveTracker={saveTracker}
      unchanged={unchanged}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
    />
  );
};

TrackedFormContainer.propTypes = {
  structure: object.isRequired,
  autosave: bool,
  loading: bool,
  saved: bool,
  refData: object.isRequired,
  prevData: object.isRequired,
  currentData: object.isRequired,
  handleChange: func.isRequired,
  handleSubmit: func.isRequired,
};

export default TrackedFormContainer;
