import React from 'react';
import { func, bool } from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { ResponsiveButtonCore } from 'components';

const ButtonSave = ({ t, loading, disabled }) => (
  <ResponsiveButtonCore
    icon
    primary
    labelPosition="left"
    type="submit"
    disabled={disabled || loading}
  >
    <Icon
      loading={loading ? true : false}
      name={loading ? 'circle notch' : 'save'}
    />
    {t('common|Save')}
  </ResponsiveButtonCore>
);

ButtonSave.propTypes = {
  t: func.isRequired,
  loading: bool.isRequired,
};

export default ButtonSave;
