import BaseApi from 'api/baseApi';

const autocompleteApi = {
  getSuggestions: (fieldId, fieldValue, params) =>
    BaseApi.get(
      `Suggestion?fieldID=${fieldId}&fieldValue=${fieldValue}`,
      params
    ),
};

export default autocompleteApi;
