import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Utils from 'core/utils/utils';
import { FIELD_TYPE } from 'core/utils/constant';

import InputFile from './InputFile';
import ToastFileError from './components/ToastFileError';

const { photo } = FIELD_TYPE;

const InputFileContainer = ({
  name,
  label,
  type,
  defaultValue,
  required: propRequired,
  disabled,
  disableDelete,
  onChange,
}) => {
  const htmlInput = useRef(null);

  const filename = Utils.getFullNameFromUrl(defaultValue);
  const initialValue = filename.includes('_')
    ? `${Utils.base64DecodeUnicode(filename.split('_')[0])}.${filename
        .split('.')
        .pop()}`
    : filename;

  const [value, setValue] = useState(initialValue);
  const [fileUrl, setFileUrl] = useState(defaultValue);
  const [data, setData] = useState();

  const handleChangeFile = (e, field) => {
    e.preventDefault();

    const file = e.target.files[0];
    const fileIsPhoto = type === photo;

    if (file) {
      const res = Utils.fileValidated(file, fileIsPhoto);

      if (res && res.result) {
        // set data as local (no url)
        setFileUrl();

        if (fileIsPhoto) {
          const reader = new FileReader();

          reader.readAsDataURL(file);
          setValue(file.name);

          reader.onloadend = () => {
            setData(reader.result);
          };
        } else {
          setValue(file.name);
        }

        onChange(e, field);
      } else {
        toast.error(<ToastFileError invalidType={res.invalidType} />);
      }
    }
  };

  const handleResetFile = e => {
    // reset state
    setValue();
    setData();
    setFileUrl();

    // reset html input
    htmlInput.current.value = '';
    onChange(e, htmlInput.current);
  };

  const handleSelectFile = e => {
    e.preventDefault();
    htmlInput.current.click();
  };

  const handleOpenInNewTab = () => {
    Utils.openInNewTab(defaultValue);
  };

  // XOR operation for toggle the required validation
  const required =
    propRequired &&
    (!!htmlInput.current && !!htmlInput.current.value) === (!!value || !!data);

  return (
    <InputFile
      label={label}
      name={name}
      type={type}
      value={value}
      url={fileUrl}
      data={data}
      disableDelete={disableDelete}
      required={required}
      disabled={disabled}
      htmlInput={htmlInput}
      handleChangeFile={handleChangeFile}
      handleSelectFile={handleSelectFile}
      handleOpenInNewTab={handleOpenInNewTab}
      handleResetFile={handleResetFile}
    />
  );
};

export default InputFileContainer;
