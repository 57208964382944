import React from 'react';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { ThemedSegment } from '..';

const StyledSegment = styled(ThemedSegment)`
  &&& {
    align-self: center;
  }

  .ui.segment &&& {
    // when wrapped in page body segment, align-self is not applied
    max-width: 76px;
    margin: auto;
  }
`;

const SpinnerSegment = ({ padded, frosted, ...props }) => (
  <StyledSegment
    flat
    padded={padded || 'very'}
    frosted={frosted === false ? undefined : true}
    {...props}
  >
    <Loader active inline="centered" size="large" />
  </StyledSegment>
);

export default SpinnerSegment;
