import { Component } from 'react';

class FormField extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.label !== nextProps.label) {
      return true;
    }

    if (this.props.value !== nextProps.value) {
      return true;
    }

    if (this.props.visible !== nextProps.visible) {
      return true;
    }

    if (this.props.disabled !== nextProps.disabled) {
      return true;
    }

    return false;
  }
}

export default FormField;
