import React from 'react';
import { any, arrayOf, bool, func, object, shape, string } from 'prop-types';
import { Dropdown, Placeholder, Segment } from 'semantic-ui-react';

import showClearAction from './helpers/showClearAction';
import BaseDropdown from './components/BaseDropdown';
import FasterList from './components/FasterList';
import InputHidden from './components/InputHidden';
import ThemedPlaceholder from '../../theme/ThemedPlaceholder';

const FasterDropdown = ({
  loading,
  activeData,
  items,
  open,
  tracked,
  search,
  multiple,
  editable,
  categorize,
  searchable,
  clearable,
  handleMouseDown,
  handleClickItem,
  handleSearchChange,
  handleBlur,
  dropdownProps,
}) => {
  const { required } = dropdownProps;

  // display text in input
  const text = activeData
    ? multiple
      ? Array.isArray(activeData) && activeData.length > 0
        ? activeData.length > 1
          ? [
              `(${activeData.length})`,
              activeData.map(d => d.text).join(' | '),
            ].join(' ')
          : activeData[0].text
        : ''
      : activeData.text
    : undefined;

  // wrap active data in array when not multiple
  const dataArray = multiple ? activeData : [activeData];

  // display clear icon
  const showClear = showClearAction(
    clearable,
    multiple,
    activeData,
    searchable,
    editable,
    open
  );

  return (
    <>
      <BaseDropdown
        tracked={tracked}
        open={open}
        text={text}
        value={multiple ? text : activeData ? activeData.value : undefined}
        search={(searchable || editable) && open ? true : undefined}
        searchQuery={search}
        clearable={showClear}
        closeOnChange={!multiple}
        onMouseDown={handleMouseDown}
        onBlur={handleBlur}
        onSearchChange={handleSearchChange}
        {...dropdownProps}
      >
        {/*Dropdown.Menu must be directly wrapped into a Dropdown or Dropdown.Menu component*/}
        {/*It thus cannot be factored*/}
        <Dropdown.Menu>
          {loading && (
            <Segment basic>
              <ThemedPlaceholder>
                <Placeholder.Line />
              </ThemedPlaceholder>
              <ThemedPlaceholder>
                <Placeholder.Line />
              </ThemedPlaceholder>
              <ThemedPlaceholder>
                <Placeholder.Line />
              </ThemedPlaceholder>
            </Segment>
          )}
          {!loading &&
            (categorize ? (
              items
                .filter(({ items: options }) => options && options.length > 0)
                .map(({ key, text, items: options }) => (
                  <Dropdown.Menu key={key}>
                    <Dropdown.Header content={text} />
                    <FasterList
                      items={options}
                      dataArray={dataArray}
                      handleClick={handleClickItem}
                    />
                  </Dropdown.Menu>
                ))
            ) : (
              <FasterList
                items={items}
                dataArray={dataArray}
                handleClick={handleClickItem}
              />
            ))}
        </Dropdown.Menu>
      </BaseDropdown>
      <InputHidden
        required={required}
        defaultValue={
          multiple ? text : activeData ? activeData.value : undefined
        }
      />
    </>
  );
};

FasterDropdown.propTypes = {
  activeData: any,
  items: arrayOf(
    shape({
      key: any,
      value: any,
      text: string,
    })
  ).isRequired,
  open: bool,
  tracked: bool,
  search: string,
  multiple: bool,
  editable: bool,
  categorize: bool,
  searchable: bool,
  clearable: bool,
  handleMouseDown: func,
  handleClickItem: func,
  handleSearchChange: func,
  handleBlur: func,
  dropdownProps: object,
};

export default FasterDropdown;
