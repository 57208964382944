import React, { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { getDateFormatted } from 'core/utils/date';
import { useForm } from 'core/hooks/useForm';
import { history } from 'config/store';

import maskRowToEvent from './helpers/maskRowToEvent';
import FasterAgenda from './FasterAgenda';
import {
  formatView,
  supportedViews,
  computeDateRange,
} from './helpers/initializeAgendaCriteria';

const FasterAgendaContainer = ({
  maskStructure,
  maskRows,
  dataLoading,
  area,
  module,
  updateCriteriaFunc,
  ...props
}) => {
  const { calendarStart, calendarEnd, view } = maskStructure;
  const currentView = formatView(view);

  const [selectedDate, setSelectedDate] = useState(
    getDateFormatted(new Date())
  );
  const [calendarProps, , setCalendarProp] = useForm({
    agendaFieldKeyFrom: calendarStart,
    agendaFieldKeyTo: calendarEnd,
    events: [],
    color: null,
    views: _.values(supportedViews),
    currentView,
    currentDate: new Date(),
    dateRange: computeDateRange(new Date(), currentView),
  });

  useEffect(() => {
    if (maskStructure && maskRows) {
      setCalendarProp(
        'events',
        _.map(maskRows, mr => maskRowToEvent(maskStructure, mr))
      );
    }
  }, [maskStructure, maskRows, setCalendarProp]);

  const handleSetDateRange = (date, view) => {
    const {
      currentDate,
      currentView,
      agendaFieldKeyFrom,
      agendaFieldKeyTo,
    } = calendarProps;

    // update date input value
    setSelectedDate(getDateFormatted(date));

    // determine whether view bounds have changed
    const { dateRange: oldDateRange } = computeDateRange(
      currentDate,
      currentView
    );
    const { startDate, endDate, dateRange } = computeDateRange(date, view);

    if (oldDateRange !== dateRange && agendaFieldKeyFrom && agendaFieldKeyTo) {
      // update state
      setCalendarProp('dateRange', { startDate, endDate });

      // trigger callback to update table query
      updateCriteriaFunc([
        { key: agendaFieldKeyFrom, value: dateRange },
        { key: agendaFieldKeyTo, value: dateRange },
      ]);
    }
  };

  const handleNavigate = (date, view) => {
    handleSetDateRange(date, view);
    setCalendarProp('currentDate', date);
  };

  const handleChangeView = view => {
    handleSetDateRange(calendarProps.currentDate, view);
    setCalendarProp('currentView', view);
  };

  const handleDoubleClick = event => {
    if (area || module) {
      const urlNav = Utils.buildUrlNav(area, module, event.key);
      history.push(urlNav);
    }
  };

  const handleChangeDate = newValue => {
    const { currentView } = calendarProps;
    const newDate = moment(newValue).toDate();

    setCalendarProp('currentDate', newDate);
    handleSetDateRange(newDate, currentView);
  };

  return (
    <FasterAgenda
      {...props}
      calendarProps={calendarProps}
      dataLoading={dataLoading}
      selectedDate={selectedDate}
      handleChangeDate={handleChangeDate}
      handleNavigate={handleNavigate}
      handleChangeView={handleChangeView}
      handleDoubleClick={handleDoubleClick}
    />
  );
};

FasterAgendaContainer.propTypes = {};

export default FasterAgendaContainer;

export { maskRowToEvent };
