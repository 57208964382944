import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ path, Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      sessionStorage.getItem('auth') ? (
        Component
      ) : (
        <Redirect
          to={{
            pathname: path + 'public/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
