import React, { lazy } from 'react';
import { Route, Switch } from 'react-router';
import _ from 'lodash';

import { Background, PrivateRoute, Toast, FasterMap } from 'components';

const Dashboard = lazy(() => import('./scenes/dashboard/dashboardContainer'));
const Public = lazy(() => import('./scenes/public/Public'));
const PublicForm = lazy(() =>
  import('./scenes/public/components/publicFormContainer')
);

const buildRoutesForArea = a => {
  const mainArea = a === null;
  const areaKey = mainArea ? '' : a.key;
  const basePath = mainArea ? '/' : `/${areaKey}/`;
  const areaProp = { area: mainArea ? undefined : a };

  return (
    <Route
      key={areaKey}
      path={basePath}
      render={() => (
        <>
          <Background area={a} />
          <Switch>
            <Route
              exact
              path={`${basePath}public/demand/:id(\\d+)`}
              render={() => <PublicForm {...areaProp} />}
            />
            <PrivateRoute
              exact
              path={`${basePath}filterselection`}
              Component={<PublicForm filterSelection {...areaProp} />}
            />
            <Route
              path={`${basePath}public`}
              render={() => <Public {...areaProp} />}
            />
            <PrivateRoute
              path={basePath}
              Component={<Dashboard {...areaProp} />}
            />
          </Switch>
        </>
      )}
    />
  );
};

const App = ({ areas }) => (
  <>
    <Switch>
      <PrivateRoute exact path="/dispatch/map" render={() => <FasterMap />} />
      {!_.isEmpty(areas) && areas.map(a => buildRoutesForArea(a))}
      {buildRoutesForArea(null)}
    </Switch>
    <Toast />
  </>
);

export default App;
