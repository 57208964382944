import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { useApi } from 'api/useApi';
import areaApi from 'api/area/areaApi';
import { AuthCreators } from 'core/auth/authReducer';
import { settingsActionCreators } from 'core/settings/settingsReducer';
import { bgActionCreators } from 'core/background/backgroundReducer';
import { ModalCreators } from 'core/modal/modalReducer';
import { FieldsActionCreators } from 'core/fields-action/fieldsActionReducer';
import { ModulesCreators } from 'core/modules/modulesReducer';
import { TemplateCreators } from 'core/template/templateReducer';
import { AdminTemplateCreators } from 'core/template/adminTemplateReducer';
import { BatchTemplateCreators } from 'core/batch-template/batchTemplateReducer';
import { FormDataCreators } from 'core/form-data/formDataReducer';
import { ContextMenuCreators } from 'core/context-menu/contextMenuReducer';
import { DispatchCreators } from 'core/dispatch/dispatchReducer';
import { SpinnerSegment } from 'components';

import { setRemoveLocalStorage } from './appHelpers';
import App from './App';

const AppContainer = ({
  updateAccessToken,
  updateCurrentUser,
  updateSettingsStore,
  updateBgStore,
  updateModalStore,
  updateFieldsActionStore,
  updateModulesStore,
  updateTemplateStore,
  updateAdminTemplateStore,
  updateBatchTemplateStore,
  updateFormDataStore,
  updateContextMenuStore,
  updateDispatchStore,
  closeModal,
}) => {
  const [args] = useState([]);
  const [fetchedAreas, pendingAreas] = useApi(areaApi.getAll, args);

  useEffect(() => {
    // Turn off the modal opening
    window.addEventListener('beforeunload', () => {
      closeModal();
    });

    // Share session storages
    window.addEventListener('storage', event => {
      const credentials = sessionStorage.getItem('auth');
      const data = sessionStorage.getItem('persist:root');
      const defaultUrl = sessionStorage.getItem('defaultUrl');
      const countryCode = sessionStorage.getItem('countryCode');
      if (
        event.key === 'REQUESTING_SHARED_CREDENTIALS' &&
        credentials &&
        data
      ) {
        setRemoveLocalStorage('CREDENTIALS_SHARING', credentials);
        setRemoveLocalStorage('DATA_SHARING', data);
        setRemoveLocalStorage('DEFAULT_URL', defaultUrl);
        setRemoveLocalStorage('COUNTRY_CODE', countryCode);
      }
      if (event.key === 'CREDENTIALS_SHARING' && !credentials) {
        sessionStorage.setItem('auth', event.newValue);

        const auth = JSON.parse(sessionStorage.getItem('auth'));
        const { accessToken, ...infos } = auth;

        updateAccessToken(accessToken);
        updateCurrentUser({ ...infos });
      }
      if (event.key === 'DATA_SHARING' && !data && event.newValue) {
        const {
          settings,
          background,
          modal,
          fieldsAction,
          modules,
          template,
          adminTemplate,
          batchTemplate,
          formData,
          contextMenu,
          dispatch,
        } = JSON.parse(event.newValue);

        // Parse objects and update redux
        updateSettingsStore(JSON.parse(settings));
        updateBgStore(JSON.parse(background));
        updateModalStore(JSON.parse(modal));
        updateFieldsActionStore(JSON.parse(fieldsAction));
        updateModulesStore(JSON.parse(modules));
        updateTemplateStore(JSON.parse(template));
        updateAdminTemplateStore(JSON.parse(adminTemplate));
        updateBatchTemplateStore(JSON.parse(batchTemplate));
        updateFormDataStore(JSON.parse(formData));
        updateContextMenuStore(JSON.parse(contextMenu));
        updateDispatchStore(JSON.parse(dispatch));
      }
      if (event.key === 'DEFAULT_URL' && !data && event.newValue) {
        sessionStorage.setItem('defaultUrl', event.newValue);
      }
      if (event.key === 'COUNTRY_CODE' && !data && event.newValue) {
        sessionStorage.setItem('countryCode', event.newValue);
      }
      if (event.key === 'SESSIONS_FLUSH' && credentials) {
        sessionStorage.removeItem('auth');
      }
    });

    // set local storage temp path for direct access
    localStorage.setItem('TEMP_PATH', window.location.pathname);

    // Share session storages
    setRemoveLocalStorage(
      'REQUESTING_SHARED_CREDENTIALS',
      Date.now().toString()
    );
  }, [
    updateAccessToken,
    updateCurrentUser,
    updateSettingsStore,
    updateBgStore,
    updateModalStore,
    updateFieldsActionStore,
    updateModulesStore,
    updateTemplateStore,
    updateAdminTemplateStore,
    updateBatchTemplateStore,
    updateFormDataStore,
    updateContextMenuStore,
    updateDispatchStore,
    closeModal,
  ]);

  return pendingAreas ? (
    <SpinnerSegment transparent />
  ) : (
    <App areas={fetchedAreas.areas} />
  );
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  updateAccessToken: accessToken =>
    dispatch(AuthCreators.updateAccessToken(accessToken)),

  updateCurrentUser: user => dispatch(AuthCreators.updateCurrentUser(user)),

  updateSettingsStore: settings =>
    dispatch(settingsActionCreators.updateSettingsStore(settings)),

  updateBgStore: bg => dispatch(bgActionCreators.updateBgStore(bg)),

  updateModalStore: modal => dispatch(ModalCreators.updateModalStore(modal)),
  closeModal: () => dispatch(ModalCreators.closeModal()),

  updateFieldsActionStore: fieldsAction =>
    dispatch(FieldsActionCreators.updateFieldsActionStore(fieldsAction)),

  updateModulesStore: modules =>
    dispatch(ModulesCreators.updateModulesStore(modules)),

  updateTemplateStore: template =>
    dispatch(TemplateCreators.updateTemplateStore(template)),

  updateAdminTemplateStore: adminTemplate =>
    dispatch(AdminTemplateCreators.updateAdminTemplateStore(adminTemplate)),

  updateBatchTemplateStore: batchTemplate =>
    dispatch(BatchTemplateCreators.updateBatchTemplateStore(batchTemplate)),

  updateFormDataStore: formData =>
    dispatch(FormDataCreators.updateFormDataStore(formData)),

  updateContextMenuStore: contextMenu =>
    dispatch(ContextMenuCreators.updateContextMenuStore(contextMenu)),

  updateDispatchStore: dispatchData =>
    dispatch(DispatchCreators.updateDispatchStore(dispatchData)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppContainer)
);
