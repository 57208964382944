import React from 'react';
import { Table as SemanticTable } from 'semantic-ui-react';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { ThemedTable, ScrollableTable } from 'components';

import { TableRow, TableRowFooter, TableRowHeader, TableHeader } from '.';

const Table = ({
  //state
  table,
  data,
  activeItem,
  activeColumn,
  direction,
  loading,
  // func
  handleSearchAllChange,
  handleSort,
  handleSearchFieldChange,
  handlePageChange,
  handleRowClick,
  // props
  area,
  module,
  history,
  dataType,
  dataKey,
  className,
}) => {
  const { columns, color } = table || {};
  const { currentPage, rows, totalPage, totalRow } = data || {};

  const admin = dataType && dataKey ? true : false;

  // Calculate all infos for display
  const colSpanLength = table && !_.isEmpty(columns) ? columns.length : 0;
  const tableColor = Utils.getColor(color);

  return (
    <div className={className}>
      <TableHeader
        activeItem={activeItem}
        totalItems={totalRow}
        totalPages={totalPage}
        activePage={currentPage}
        handleSearchAllChange={handleSearchAllChange}
        area={area}
        module={module}
        loading={loading}
        showSearchAll={true} // TODO get from backend via table dto
        admin={admin}
      />
      <ScrollableTable>
        <ThemedTable sortable celled selectable tableColor={tableColor}>
          <SemanticTable.Header>
            <SemanticTable.Row textAlign="center">
              <TableRowHeader
                table={table}
                activeColumn={activeColumn}
                direction={direction}
                handleSort={handleSort}
                handleSearchFieldChange={handleSearchFieldChange}
              />
            </SemanticTable.Row>
          </SemanticTable.Header>
          <SemanticTable.Body>
            <TableRow
              rows={rows}
              table={table}
              activeItem={activeItem}
              colSpanLength={colSpanLength}
              handleRowClick={handleRowClick}
              loading={loading}
              area={area}
              module={module}
              history={history}
              admin={admin}
            />
          </SemanticTable.Body>
        </ThemedTable>
      </ScrollableTable>
      <TableRowFooter
        totalItems={totalRow}
        totalPages={totalPage}
        activePage={currentPage}
        handlePageChange={handlePageChange}
        loading={loading}
      />
    </div>
  );
};

export default Table;
