import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import _ from 'lodash';

import {
  Filter,
  ResponsiveButtonCore,
  TableSegment,
  ThemedMessage,
  ThemedSegment,
} from 'components';

import BatchTemplates from '../templates/BatchTemplates';
import PrintTrigger from './PrintTrigger';
import MessageResult from './MessageResult';

const BatchPerform = ({
  t,
  module,
  action,
  ids,
  docs,
  duplicate,
  toggleDuplicate,
  batchTemplates,
  batchTempOptions,
  selectedBatchEditId,
  handleChangeBatchTemplate,
  edits,
  setEdits,
  bindReloadColOpts,
  edit,
  completed,
  printTemplateKey,
  printCallback,
}) => {
  const messageResultProps = { t, action, docs, ids, completed };

  return (
    <>
      {action === 'preview' && (
        <TableSegment
          icon="list"
          module={module}
          ids={ids}
          tableKey="main|preview"
        />
      )}
      {action === 'edit' && docs.length <= 0 && (
        <>
          {!_.isEmpty(batchTemplates) && (
            <BatchTemplates
              t={t}
              value={selectedBatchEditId}
              batchTempOptions={batchTempOptions}
              handleChangeBatchTemplate={handleChangeBatchTemplate}
            />
          )}
          <ThemedSegment>
            <Form>
              <Form.Field>
                <label>{t('common|Values')}</label>
                <Filter
                  filters={edits}
                  callbackFunc={setEdits}
                  handleReloadColOpts={bindReloadColOpts}
                />
              </Form.Field>
              <ThemedMessage>
                <Form.Field>
                  <label>
                    {t(
                      'batch|Check this option to duplicate previously selected documents before applying edits'
                    )}
                  </label>
                </Form.Field>
                <Form.Field
                  control={Checkbox}
                  label={t(
                    'batch|Duplicate documents and apply edits only to the duplicates'
                  )}
                  name="duplicate"
                  checked={duplicate}
                  onChange={toggleDuplicate}
                />
              </ThemedMessage>
              <Form.Field>
                <ResponsiveButtonCore
                  primary
                  icon="check"
                  type="button"
                  labelPosition="left"
                  onClick={edit}
                  disabled={edits.length < 1 || _.some(edits, { columnID: -1 })}
                  content={t('common|Confirm')}
                />
              </Form.Field>
            </Form>
          </ThemedSegment>
        </>
      )}
      {action === 'print' && (
        <ThemedSegment>
          <MessageResult {...messageResultProps} />
          {docs.length > 0 && (
            <PrintTrigger
              docs={docs}
              callback={printCallback}
              printTemplateKey={printTemplateKey}
            />
          )}
        </ThemedSegment>
      )}
      {action === 'download' && (
        <ThemedSegment>
          <MessageResult {...messageResultProps} />
        </ThemedSegment>
      )}
    </>
  );
};

export default BatchPerform;
