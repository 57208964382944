import _ from 'lodash';

const IMAGE_TYPES = {
  png: 'image/png',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  bmp: 'image/bmp',
  ico: 'image/vnd.microsoft.icon',
  tiff: 'image/tiff',
  svg: 'image/svg+xml',
};

const FILE_TYPES = {
  txt: 'text/plain',
  html: 'text/html',
  css: 'text/css',
  js: 'application/javascript',
  json: 'application/json',
  xml: 'application/xml',
  csv: 'text/csv',

  // archives
  zip: 'application/zip',
  rar: 'application/x-rar-compressed',

  // adobe
  pdf: 'application/pdf',

  // ms office
  doc: 'application/msword',
  rtf: 'application/rtf',
  xls: 'application/vnd.ms-excel',
  ppt: 'application/vnd.ms-powerpoint',
  oldExcel: 'application/vnd.ms-excel	',
  excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  oldWord: 'application/msword',
  word:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

const MIME_TYPES = {
  ...IMAGE_TYPES,
  ...FILE_TYPES,
};

const typesAsArray = types => {
  return _.values(types);
};

const typesAsString = types => {
  return typesAsArray(types).join(', ');
};

export { IMAGE_TYPES, FILE_TYPES, MIME_TYPES, typesAsArray, typesAsString };
