import BaseApi from 'api/baseApi';

const ruleApi = {
  getByActionAndTemplateId: (action, templateId) =>
    BaseApi.get(`Rule?action=${action}&templateId=${templateId}`),
  post: rule => BaseApi.post(`Rule`, { ruleManagement: rule }),
  update: rule => BaseApi.put(`Rule`, { ruleManagement: rule }),
  delete: ruleID => BaseApi.delete(`Rule/${ruleID}`),
};

export default ruleApi;
