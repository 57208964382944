import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic-ui-react';
import styled from 'styled-components';

import { FIELD_TYPE } from 'core/utils/constant';

import InputButton from '../components/InputButton';
import {
  InputFileHidden,
  InputFileImageHidden,
} from './components/InputFileHidden';
import { Center, ColoredIcon, ImagePreview, ThemedInput } from '../..';

const { file, photo } = FIELD_TYPE;

const StyledDivider = styled(Divider)`
  &&&& {
    margin: 5px 0;
  }
`;

const InputFile = ({
  name,
  label,
  type,
  value,
  url,
  data,
  disableDelete,
  htmlInput,
  required,
  disabled,
  handleChangeFile,
  handleOpenInNewTab,
  handleSelectFile,
  handleResetFile,
}) => {
  const { t } = useTranslation();
  const isPhoto = type === photo;
  const isFile = type === file;

  const InputHidden = isPhoto ? (
    InputFileImageHidden
  ) : isFile ? (
    InputFileHidden
  ) : (
    <></>
  );

  return (
    <>
      <label>{label}</label>
      <InputHidden
        ref={htmlInput}
        name={name}
        onChange={handleChangeFile}
        required={required}
      />
      <ThemedInput
        readOnly
        fluid
        actionPosition="left"
        disabled={disabled}
        value={value || ''}
        icon={
          !disabled && !disableDelete && value ? (
            <ColoredIcon name="delete" link onClick={handleResetFile} />
          ) : (
            undefined
          )
        }
        action={
          <InputButton
            content={t('common|Select')}
            icon="plus"
            disabled={disabled}
            onClick={handleSelectFile}
          />
        }
      />
      {(data || url) && (
        <Center>
          <StyledDivider />
          {isPhoto && (
            <ImagePreview
              url={url}
              data={data}
              size="medium"
              onClick={handleOpenInNewTab}
            />
          )}
          {isFile && (
            <InputButton
              content={t('common|Download')}
              icon="download"
              onClick={handleOpenInNewTab}
            />
          )}
        </Center>
      )}
    </>
  );
};

export default InputFile;
