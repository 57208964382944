import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Icon } from 'semantic-ui-react';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { FORM_LAYOUT_TYPE } from 'core/utils/constant';
import { getTemplates } from 'core/template/templateReducer';
import {
  FasterDropdown,
  ThemedButton,
  ThemedSegment,
  DownloadButton,
} from 'components';

const BatchAction = ({
  // stores
  t,
  templates,
  // props
  filtered,
  ids,
  preview,
  step,
  currentUser,
  selectEdits,
  printTemplateKey,
  batchPrint,
  download,
}) => {
  const step2Props = {
    labelPosition: 'left',
    disabled: step === 2 && !_.isEmpty(ids) ? undefined : true,
  };

  const printOptions = _.map(
    _.filter(templates, {
      type: FORM_LAYOUT_TYPE.print,
    }),
    t => ({
      value: t.key,
      text: t.name,
      key: t.key,
    })
  );

  const [templateKey, setTemplateKey] = useState(
    printTemplateKey
      ? printTemplateKey
      : printOptions[0]
      ? printOptions[0].key
      : undefined
  );

  const changePrint = (e, { value }) => {
    setTemplateKey(value);
    batchPrint(value);
  };

  return (
    <>
      {!filtered && (
        <ThemedSegment>
          <Icon name="circle notched" loading />
          {` ${t('Just one moment')}`}
        </ThemedSegment>
      )}
      {filtered && (
        <ThemedSegment>
          <Form>
            <Form.Field>
              <label>{t('common|Results')}</label>
              <p>
                <Icon name={!_.isEmpty(ids) ? 'check' : 'times'} /> {ids.length}{' '}
                {t('batch|matching form(s) found')}
              </p>
            </Form.Field>
            <Form.Field label="Actions" />
            <Form.Field>
              <ThemedButton
                primary
                icon="table"
                onClick={preview}
                content={t('common|Batch preview')}
                {...step2Props}
              />
            </Form.Field>
            {Utils.canPerform(currentUser, 'batchedit') && (
              <Form.Field>
                <ThemedButton
                  primary
                  icon="pencil"
                  onClick={selectEdits}
                  content={t('common|Batch edit')}
                  {...step2Props}
                />
              </Form.Field>
            )}
            {templateKey && (
              <Form.Field>
                <Button.Group primary>
                  <ThemedButton
                    primary
                    icon="print"
                    onClick={() => batchPrint(templateKey)}
                    content={t('common|Batch print')}
                    {...step2Props}
                  />
                  <FasterDropdown
                    className="icon right button"
                    fluid={false}
                    value={templateKey}
                    options={printOptions}
                    onChange={changePrint}
                  />
                </Button.Group>
              </Form.Field>
            )}
            <DownloadButton
              name={t('common|Batch download')}
              primary
              icon="download"
              onDownload={download}
              {...step2Props}
            />
          </Form>
        </ThemedSegment>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  templates: getTemplates(state),
});

export default connect(
  mapStateToProps,
  null
)(BatchAction);
