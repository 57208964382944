import React, { useCallback } from 'react';
import { Comment, Icon } from 'semantic-ui-react';

import { getDateTimeFormatted } from 'core/utils/date';

import {
  StyledAuthor,
  StyledLabelValue,
  StyledMetadata,
  StyledText,
} from './HistoryComponents';
import HistoryLabelValue from './HistoryLabelValue';

const HistoryStatus = ({
  t,
  h: {
    editor,
    creation,
    fieldName,
    fieldType,
    dateTime,
    valueBefore,
    valueAfter,
  },
}) => {
  const dateTimeFormatted = getDateTimeFormatted(dateTime.split('.')[0]);

  const renderHistoryLabelValue = useCallback(
    value => <HistoryLabelValue value={value} fieldType={fieldType} />,
    [fieldType]
  );

  const renderHistoryCreation = (
    <>
      <Icon name="file outline" />
      {t('Created document with ID')} {renderHistoryLabelValue(valueAfter)}
    </>
  );

  // Prevent the render empty valueBefore
  const renderHistoryModification =
    valueBefore && valueAfter ? (
      <>
        <Icon name="pencil" />
        {t('Changed')} <StyledLabelValue content={fieldName} /> {t('from')}{' '}
        {renderHistoryLabelValue(valueBefore)} {t('to')}{' '}
        {renderHistoryLabelValue(valueAfter)}
      </>
    ) : valueAfter ? (
      <>
        <Icon name="plus" />
        {t('Initialized')} <StyledLabelValue content={fieldName} />{' '}
        {t('with value')} {renderHistoryLabelValue(valueAfter)}
      </>
    ) : valueBefore ? (
      <>
        <Icon name="minus" />
        {t('Deleted from')} <StyledLabelValue content={fieldName} />{' '}
        {t('previous value')} {renderHistoryLabelValue(valueBefore)}
      </>
    ) : (
      <></>
    );

  return valueAfter || valueBefore ? (
    <Comment>
      <Comment.Content>
        <StyledAuthor>{editor}</StyledAuthor>
        <StyledMetadata content={dateTimeFormatted} />
        <StyledText>
          {creation ? renderHistoryCreation : renderHistoryModification}
        </StyledText>
      </Comment.Content>
    </Comment>
  ) : (
    <></>
  );
};

export default HistoryStatus;
