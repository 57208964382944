import React from 'react';
import styled from 'styled-components';

import { ThemedDropdown } from 'components';

import getShadowByState from './helpers/getShadowByState';

const TrackedDropdown = styled(({ unchanged, saved, ...props }) => (
  <ThemedDropdown {...props} />
))`
  ${({ saved, unchanged }) => `
    &&&&& {
      box-shadow: ${getShadowByState(saved, unchanged)};
    }
  `}
`;

export default TrackedDropdown;
