import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'lodash';

import ruleApi from 'api/rule/ruleApi';
import { FIELD_ACTION } from 'core/utils/constant';
import { createSelector } from 'reselect';

const { Types, Creators } = createActions({
  requestFieldsAction: null,
  receiveFieldsAction: ['fields'],
  addFieldAction: ['action', 'autoFieldType', 'templateID'],
  updateFieldAction: ['ruleID', 'metaField', 'updateRuleChild'],
  removeFieldAction: ['ruleID'],
  resetFieldsAction: [],
  updateFieldsActionStore: ['fieldsAction'],
});

export const FieldsActionCreators = Creators;
export const FieldsActionTypes = Types;

export const INITIAL_STATE = Immutable({
  isLoading: false,
  fieldsAction: [], // all fields action (field, dependentField) in form layout
  newFields: [],
  updatedFields: [],
});

export const requestFieldsAction = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: true,
    fieldsAction: [],
    newFields: [],
    updatedFields: [],
  };
};

export const receiveFieldsAction = (state = INITIAL_STATE, action) => {
  const { fields } = action;

  return {
    ...state,
    isLoading: false,
    fieldsAction: fields,
  };
};

export const addFieldAction = (state = INITIAL_STATE, act) => {
  const cloneRules = [...state.fieldsAction];
  const cloneNewRules = [...state.newFields];

  const { action, autoFieldType, templateID } = act;

  const newRuleID = 'newRule_' + cloneNewRules.length;
  const newRule = {
    ruleID: newRuleID,
    action: autoFieldType,
    independent: {},
    dependent: action === FIELD_ACTION.initialize ? null : {},
    templateID: templateID,
  };

  if (cloneNewRules.indexOf(newRuleID) === -1) {
    cloneNewRules.push(newRuleID);
  }

  return {
    ...state,
    fieldsAction: _.concat(cloneRules, newRule),
    newFields: cloneNewRules,
  };
};

export const removeFieldAction = (state = INITIAL_STATE, action) => {
  const { ruleID } = action;
  const cloneRules = [...state.fieldsAction];
  const cloneNewRules = [...state.newFields];
  const cloneUpdatedRules = [...state.updatedFields];

  _.remove(cloneRules, { ruleID: ruleID });
  _.pull(cloneNewRules, ruleID);
  _.pull(cloneUpdatedRules, ruleID);

  return {
    ...state,
    fieldsAction: cloneRules,
    newFields: cloneNewRules,
    updatedFields: cloneUpdatedRules,
  };
};

// update indepent/dependent
export const updateFieldAction = (state = INITIAL_STATE, action) => {
  const { ruleID, metaField, updateRuleChild } = action;
  const cloneRules = [...state.fieldsAction];
  const cloneUpdatedRules = [...state.updatedFields];

  const updateRule = _.find(cloneRules, {
    ruleID: ruleID,
  });

  // 'independent' to determinate the ruleChild need to update .independent or .dependent
  updateRule[metaField] = updateRuleChild;

  // If rule is nearly created and it is not available at db then dont push it to updatedRules
  if (
    state.newFields.indexOf(ruleID) === -1 &&
    cloneUpdatedRules.indexOf(ruleID) === -1
  ) {
    cloneUpdatedRules.push(ruleID);
  }

  return {
    ...state,
    fieldsAction: cloneRules,
    updatedFields: cloneUpdatedRules,
  };
};

export const resetFieldsAction = (state = INITIAL_STATE, action) => ({
  ...state,
  updatedFields: [],
  newFields: [],
});

export const updateFieldsActionStore = (state = INITIAL_STATE, action) => {
  const {
    isLoading,
    fieldsAction,
    newFields,
    updatedFields,
  } = action.fieldsAction;
  return {
    ...state,
    isLoading,
    fieldsAction,
    newFields,
    updatedFields,
  };
};

export const HANDLERS = {
  [Types.REQUEST_FIELDS_ACTION]: requestFieldsAction,
  [Types.RECEIVE_FIELDS_ACTION]: receiveFieldsAction,
  [Types.ADD_FIELD_ACTION]: addFieldAction,
  [Types.REMOVE_FIELD_ACTION]: removeFieldAction,
  [Types.UPDATE_FIELD_ACTION]: updateFieldAction,
  [Types.RESET_FIELDS_ACTION]: resetFieldsAction,
  [Types.UPDATE_FIELDS_ACTION_STORE]: updateFieldsActionStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);

// OPERATIONS
export const FieldsActionOperations = {
  fetchFieldsAction: (action, templateId) => async dispatch => {
    dispatch(FieldsActionCreators.requestFieldsAction());
    const res = await ruleApi.getByActionAndTemplateId(action, templateId);
    if (res && res.rules) {
      dispatch(FieldsActionCreators.receiveFieldsAction(res.rules));
    }
  },
};

// SELECTORS

const selectorFieldsAction = state => state.fieldsAction;

export const getFieldsAction = createSelector(
  [selectorFieldsAction],
  fieldsAction => fieldsAction
);
