import React from 'react';
import styled from 'styled-components';

import { ThemedInput } from 'components';

import getShadowByState from './helpers/getShadowByState';

const TrackedInput = styled(({ unchanged, saved, value, ...props }) => (
  <ThemedInput value={value || (value === 0 ? 0 : '')} {...props} />
))`
  ${({ saved, unchanged }) => `
    &&&&& input {
      box-shadow: ${getShadowByState(saved, unchanged)};
    }
  `}
`;

export default TrackedInput;
