import React from 'react';
import { Icon, Segment, Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ThemedSegment, ThemedLabel } from 'components';
import TilesPanel from '../tiles/TilesPanel';

const BodySegment = styled(Segment)`
  &&& {
    padding: 0;
    margin: 0.5rem 0 0 0;

    > .ui.segment:not(:first-child) {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }
`;

const BackButton = styled(Button)`
  &&& {
    font-weight: 700;
    margin-top: 1px;
  }
`;

const StyledSegment = styled(({ embedding, ...props }) => (
  <ThemedSegment {...props} />
))`
  ${({ embedding }) =>
    embedding
      ? `
    &&& {
      overflow: hidden;
    }
  `
      : ''}
`;

const PageSegment = ({
  t,
  icon,
  label,
  embedding,
  quickaccess,
  tiles,
  routes,
  scroll,
  children,
  basic,
  onBack,
  backgroundColor,
  color,
}) => (
  <BodySegment basic>
    {onBack && (
      <BackButton
        basic
        color="blue"
        onClick={onBack}
        size="mini"
        content="Back"
        icon="chevron left"
      />
    )}
    {!quickaccess && (icon && label) && (
      <ThemedLabel accent basic>
        <Icon name={quickaccess ? 'bolt' : icon} />
        {quickaccess ? t('common|Quick access') : t(`common|${label}`)}
      </ThemedLabel>
    )}
    {!tiles && children && (
      <StyledSegment
        embedding={embedding}
        basic={basic}
        backgroundColor={backgroundColor}
        color={color}
      >
        {children}
      </StyledSegment>
    )}
    {tiles && routes && (
      <TilesPanel scroll={quickaccess || scroll} routes={routes} />
    )}
  </BodySegment>
);

export default withTranslation()(PageSegment);
