import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const ColoredIcon = styled(({ icon, iconColor, ...props }) => (
  <Icon name={icon} {...props} />
))`
  ${({ iconColor }) => (iconColor ? `color: ${iconColor};` : '')}
`;

export default ColoredIcon;
