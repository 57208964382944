class Sort {
  sortByStepRowColumnKey = (array, isMapRow = false) => {
    let arraySorted = array.sort(function(a, b) {
      if (a.step < b.step) {
        return -1;
      } else if (a.step > b.step) {
        return 1;
      } else {
        if (a.sectionRow < b.sectionRow) {
          return -1;
        } else if (a.sectionRow > b.sectionRow) {
          return 1;
        } else {
          if (a.sectionColumn < b.sectionColumn) {
            return -1;
          } else if (a.sectionColumn > b.sectionColumn) {
            return 1;
          } else {
            if (a.row < b.row) {
              return -1;
            } else if (a.row > b.row) {
              return 1;
            } else {
              if (a.column < b.column) {
                return -1;
              } else if (a.column > b.column) {
                return 1;
              } else {
                if (a.key < b.key) {
                  return -1;
                } else if (a.key > b.key) {
                  return 1;
                } else {
                  return 0;
                }
              }
            }
          }
        }
      }
    });

    if (isMapRow) {
      arraySorted = arraySorted.map(item => item.row);
    }

    return [...new Set(arraySorted)];
  };
}

export default new Sort();
