// import axios from 'axios';
// import BaseApi from 'api/baseApi';
import _ from 'lodash';
import stopsMock from './stopsMock';

// const CancelToken = axios.CancelToken;
const sources = [];

const stopApi = {
  getAll: () => {
    setTimeout(function() {
      console.log('Mocking stops data');
    }, 1500);

    return stopsMock;
  },
  cancelRequests: () => {
    _.forEach(sources, source => {
      source.cancel('Cancellation');
    });
  },
};

export default stopApi;
