import React, { useState } from 'react';
import { any, object } from 'prop-types';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { AuthCreators } from 'core/auth/authReducer';
import {
  settingsActionCreators,
  getSettings,
} from 'core/settings/settingsReducer';
import { dispatchPreferences } from 'core/preferences/helpers';

import Preferences from './Preferences';

const PreferencesContainer = ({
  i18n,
  user,
  updateCurrentUser,
  settings,
  settingsActionCreators,
}) => {
  // build app preference with current settings
  const [appPreference, setAppPreference] = useState({
    ...user.appPreference,
    language: i18n.language,
    smallMenu: !settings.expandedSidebar,
  });

  const handleSetKey = key => {
    const newPreferences = { ...appPreference, appPreferenceID: key };

    // set in redux store
    updateCurrentUser({ ...user, appPreference: newPreferences });

    // set in state
    setAppPreference(newPreferences);
  };

  // apply new preferences
  const handleEntityChange = data => {
    const { toggleSidebar } = settingsActionCreators;
    dispatchPreferences(data, { toggleSidebar, i18n });
  };

  const props = {
    appPreference,
    handleSetKey,
    handleEntityChange,
  };

  return <Preferences {...props} />;
};

const mapStateToProps = state => ({
  settings: getSettings(state),
});

const mapDispatchToProps = dispatch => ({
  settingsActionCreators: bindActionCreators(settingsActionCreators, dispatch),
  updateCurrentUser: currentUser =>
    dispatch(AuthCreators.updateCurrentUser(currentUser)),
});

PreferencesContainer.propTypes = {
  i18n: any.isRequired,
  user: object.isRequired,
  settings: object.isRequired,
  settingsActionCreators: object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PreferencesContainer));
