import React from 'react';

import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedFormField = styled(({ visible, ...props }) => (
  <Form.Field {...props} />
))`
  ${({
    visible,
    theme: {
      color: { inputDisabled },
    },
  }) => `
    ${visible === false ? 'visibility: hidden;' : ''}

    &&&&&& .disabled {
      opacity: 1 !important;
    }

    .ui.form &.field.disabled {
      opacity: 1 !important;

      > label {
        opacity: 1 !important;
      }

      > div.dropdown {
        background-color: ${inputDisabled} !important;
        opacity: 1 !important;
      }

      > div.disabled.checkbox {
        label {
          opacity: 1 !important;
        }

        input[type='checkbox'],
        label::before {
          background-color: ${inputDisabled} !important;
          opacity: 1 !important;
        }
      }
    }
  `}
`;

export default ThemedFormField;
