export const STOP_STATUS = {
  SCHEDULED: 'Scheduled',
  UNSCHEDULED: 'Unscheduled',
};

export default {
  stops: [
    {
      id: 1,
      name: 'Location X',
      address: '123 Loc.1 Paris',
      client: {
        name: 'John P.',
        phone: '1234567890',
      },
      time: '09:00 - 12:00',
      status: STOP_STATUS.UNSCHEDULED,
    },
    {
      id: 2,
      name: 'Location SY',
      address: '123 Loc.1 Paris',
      client: {
        name: 'John P.',
        phone: '1234567890',
      },
      time: '09:00 - 12:00',
      status: STOP_STATUS.SCHEDULED,
    },
    {
      id: 3,
      name: 'Location X',
      address: '123 Loc.1 Paris',
      client: {
        name: 'John P.',
        phone: '1234567890',
      },
      time: '09:00 - 12:00',
      status: STOP_STATUS.UNSCHEDULED,
    },
    {
      id: 4,
      name: 'Location YZ',
      address: '123 Loc.1 Paris',
      client: {
        name: 'John P.',
        phone: '1234567890',
      },
      time: '09:00 - 12:00',
      status: STOP_STATUS.SCHEDULED,
    },
    {
      id: 5,
      name: 'Location X',
      address: '123 Loc.1 Paris',
      client: {
        name: 'John P.',
        phone: '1234567890',
      },
      time: '09:00 - 12:00',
      status: STOP_STATUS.SCHEDULED,
    },
    {
      id: 6,
      name: 'Location X',
      address: '123 Loc.1 Paris',
      client: {
        name: 'John P.',
        phone: '1234567890',
      },
      time: '09:00 - 12:00',
      status: STOP_STATUS.UNSCHEDULED,
    },
  ],
};
