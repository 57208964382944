import { createSelector } from 'reselect';

export const VISIT_CUSTOMPAGE = 'VISIT_CUSTOMPAGE';

const initialState = {
  lastCustomPage: undefined,
};

export const customPageActionCreators = {
  setLastCustomPage: location => ({ type: VISIT_CUSTOMPAGE, value: location }),
};

export const customPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case VISIT_CUSTOMPAGE:
      return { ...state, lastCustomPage: action.value };
    default:
      return state;
  }
};

const customPageSelector = state => state.customPage;

export const getLastCustomPage = createSelector(
  [customPageSelector],
  customPage => customPage.lastCustomPage
);
