import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedPopup = styled(Popup)`
  ${({
    theme: {
      color: { fg, popupBg, popupBorderColor, popupArrowBoxShadow },
    },
  }) => `
    &&& {
      &,
      &::before {
        background-color: ${popupBg};
        border-color: ${popupBorderColor};
      }

      &.ui.bottom.right.popup::before {
        box-shadow: -1px -1px 0 0 ${popupArrowBoxShadow};
      }

      color: ${fg};
    }
  `}
`;

export default ThemedPopup;
