import React from 'react';
import { string, bool } from 'prop-types';
import styled from 'styled-components';

import { ThemedLabel } from 'components';

const FormSectionFieldSetLabel = styled(({ visible, ...props }) => (
  <ThemedLabel {...props} />
))`
  ${({ visible }) => `
    &&& {
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      ${visible === false ? 'visibility: hidden;' : ''}
    }
  `}
`;

const FormSectionHeader = ({ name, visible }) => (
  <FormSectionFieldSetLabel size="large" visible={visible}>
    {name}
  </FormSectionFieldSetLabel>
);

FormSectionHeader.propTypes = {
  name: string.isRequired,
  visible: bool.isRequired,
};

export default FormSectionHeader;
