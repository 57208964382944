export default [
  {
    title: 'Card Title 1',
    description: 'Card Description 1',
  },
  {
    title: 'Card Title 2',
    description: 'Card Description 2',
  },
  {
    title: 'Card Title 3',
    description: 'Card Description 3',
  },
];
