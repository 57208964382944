import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  updateAccessToken: ['accessToken'],
  updateCurrentUser: ['currentUser'],
  logOut: null,
});

export const AuthCreators = Creators;
export const AuthTypes = Types;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  accessToken: null,
  currentUser: null,
});

/* ------------- Reducers ------------- */
export const updateAccessToken = (state = INITIAL_STATE, action) => {
  const { accessToken } = action;
  return {
    ...state,
    accessToken,
  };
};

export const updateCurrentUser = (state = INITIAL_STATE, action) => {
  const { currentUser } = action;
  return {
    ...state,
    currentUser,
  };
};

/* ------------- Hookup Reducers To Types ------------- */

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.UPDATE_ACCESS_TOKEN]: updateAccessToken,
  [Types.UPDATE_CURRENT_USER]: updateCurrentUser,
};

export default createReducer(INITIAL_STATE, HANDLERS);

// SELECTORS
const selectorAuth = state => state.auth;
const selectorCurrentUser = state => state.auth.currentUser;

export const getAuth = createSelector(
  [selectorAuth],
  auth => auth
);

export const getCurrentUser = createSelector(
  [selectorCurrentUser],
  currentUser => currentUser
);
