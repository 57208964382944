import { FIELD_TYPE } from 'core/utils/constant';

const typeMapper = htmlType => {
  switch (htmlType) {
    case 'checkbox':
      return FIELD_TYPE.checkbox;
    case 'file':
      return FIELD_TYPE.file;
    case 'text':
      return FIELD_TYPE.text;
    default: {
      return htmlType;
    }
  }
};

export { typeMapper };
