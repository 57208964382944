import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  float: left;
  text-transform: uppercase;
  > * {
    display: block;
  }
`;

const ModifcationsLink = styled.span`
  text-decoration: underline;
  font-size: 0.8em;
  font-style: italic;
  text-transform: uppercase;
`;

const PopupStatusMessage = ({ title }) => (
  <Container>
    All {title} uploaded!
    <ModifcationsLink>See modifications</ModifcationsLink>
  </Container>
);

PopupStatusMessage.propTypes = {
  title: string.isRequired,
};

export default PopupStatusMessage;
