import React, { useState, Fragment } from 'react';
import { element, string } from 'prop-types';
import { Image } from 'semantic-ui-react';
import styled from 'styled-components';

const HiddenImage = styled(Image)`
  &&&& {
    display: none;
  }
`;

const ImageSrcValidator = ({
  src,
  errorComponent: ErrorComponent,
  children: Children,
}) => {
  const [loaded, setLoaded] = useState(undefined);
  const [error, setError] = useState(false);

  // if image loads correctly, set loaded state
  const handleLoad = () => {
    setLoaded(true);
  };

  // if image pops error, set loaded and error states
  const handleError = () => {
    setLoaded(true);
    setError(true);
  };

  const TestImage = (
    <HiddenImage src={src} onLoad={handleLoad} onError={handleError} />
  );

  // try loading image first, then either display children or error component
  return loaded && error
    ? ErrorComponent || Fragment
    : !loaded
    ? TestImage
    : Children || TestImage;
};

ImageSrcValidator.propTypes = {
  src: string,
  errorComponent: element,
  children: element,
};

export default ImageSrcValidator;
