import React from 'react';
import { arrayOf, object } from 'prop-types';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import Utils from 'core/utils/utils';

import { getContentKey } from '../helpers';
import CustomPageGridColumn from './CustomPageGridColumn';

const GridRow = styled(Grid.Row)`
  .ui.grid > &.row {
    padding: 0;
  }

  @media only screen and (max-width: 767px) {
    .ui.stackable.grid:not(.vertically) > &.row {
      margin: 0;
    }
  }
`;

const CustomPageGridRow = ({ groupedRow, ...props }) => {
  const { totalSpanInRow, colsSorted } = Utils.getRowSpan(groupedRow);

  return (
    <GridRow widths="equal">
      {colsSorted.map(col => {
        const pcs = groupedRow
          .filter(r => r.column === col)
          .sort((a, b) => a.nestedRow - b.nestedRow);

        const hasColSpan = pcs.some(s => s.columnSpan >= 2);
        const columnWidth = hasColSpan
          ? Utils.columnWidthByColsSpan(Utils.maxColSpan(pcs), totalSpanInRow)
          : undefined;

        return (
          <CustomPageGridColumn
            key={getContentKey(pcs[0])}
            pcs={pcs}
            columnWidth={columnWidth}
            {...props}
          />
        );
      })}
    </GridRow>
  );
};

CustomPageGridRow.propTypes = {
  groupedRow: arrayOf(object).isRequired,
};

export default CustomPageGridRow;
