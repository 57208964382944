const sortedFieldSection = fields => [
  ...new Set(
    //section.field_Section
    fields
      .sort(function(a, b) {
        if (a.row < b.row) {
          return -1;
        } else if (a.row > b.row) {
          return 1;
        } else {
          if (a.column < b.column) {
            return -1;
          } else if (a.column > b.column) {
            return 1;
          } else {
            return -1;
          }
        }
      })
      .map(fs => fs.row)
  ),
];

export default sortedFieldSection;
