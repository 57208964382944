import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { applyTheme } from './ThemedButton';

const ThemedDropdown = styled(
  ({ dropdownRef, clearable, fluid, iconColor, color, ...props }) => (
    <Dropdown
      ref={dropdownRef}
      fluid={fluid === false ? undefined : true}
      scrolling
      {...props}
    />
  )
)`
  ${({
    color,
    clearable,
    theme,
    theme: {
      color: {
        inputFg,
        fg,
        dropdownBg,
        dropdownMenuBorderColor,
        dropdownMenuItemBorderColor,
        dropdownBgSelected,
        dropdownItemBgHover,
        sidebarMenuItemBgActive,
        boxShadowDropdown,
        primary,
        secondary,
        positive,
        negative,
        buttonFg,
        buttonBg,
        buttonFgHover,
        buttonBgHover,
      },
    },
  }) => `
    &&& {
      cursor: pointer;
      min-height: 2.71428571em;
      display: block;
      box-shadow: none;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
      -webkit-transition: box-shadow 0.1s ease, width 0.1s ease;
      transition: box-shadow 0.1s ease, width 0.1s ease;

      background-color: ${dropdownBg};
      color: ${fg};

      &.ui.active.dropdown {
        border-color: ${primary};
      }

      &:not(.ui.labeled.button) {
        padding: 0.75em 0.75em 0.35em 1em;

        > .text {
          position: absolute;
          line-height: 1.1em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: calc(100% - 2.6em);
        }
      }

      &.ui.search.dropdown.active:not(.button) > .text {
        display: none;
      }

      input.search {
        color: ${fg} !important;
      }

      &.ui.dropdown {
        > .dropdown.icon {
          margin-top: 0.2108em;

          ${
            clearable
              ? `
                &:before {
                  content: '\\F00D';
                  color: ${inputFg};
                  opacity: 0.5;
                  transition: opacity .3s ease;
                }

                &:hover:before {
                  opacity: 1;
                }
              `
              : `
                &:before { content: '\\F0D7'; }
              `
          }
        }

        &.ui.button {
          min-height: 0;
          border: none;

          ${
            color
              ? `background-color: ${color}!important;`
              : applyTheme({ theme, primary, secondary, positive, negative }) &&
                `background-color: ${buttonBg}!important;`
          }
          ${
            applyTheme({ theme, primary, secondary, positive, negative })
              ? `color: ${buttonFg}!important;`
              : ''
          }

          &:hover,
          &:active,
          &:focus {
            ${applyTheme({ theme, primary, secondary, positive, negative }) &&
              `
                background-color: ${buttonBgHover}!important;
                color: ${buttonFgHover}!important;
              `}
          }

          &:not(.labeled) {
            margin: 0.2em 0;
            padding: .78571429em .78571429em .78571429em;
          }
        }

        &.ui.icon.right.button:not(.labeled):not(.item) {
          margin: 0.2em 0;
          padding: .78571429em .78571429em .78571429em;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .visible.menu .menu {
          display: block;
          max-height: unset;
          position: static;
          border: none;
          box-shadow: none !important;
          border-radius: 0 !important;
          margin: 0 !important;
          min-width: 100% !important;
          width: auto !important;
          border-top: none;

          > .item {
            padding-left: 2.14285714rem!important;
            padding-right: 2.14285714rem!important;
          }
        }

        .visible.menu .menu:not(:first-child) {
          border-top: 1px solid ${dropdownMenuBorderColor};
        }
      }

      &.ui.multiple.dropdown {
        padding-top: 0.3em;
        padding-bottom: 0.22619048em;
        padding-left: 0.35714286em;

        > .dropdown.icon {
          margin-top: 0.7361em;
        }
      }
    }

    &&&&&& {
      .menu {
        z-index: 1000;
        background-color: ${dropdownBg};
        box-shadow: ${boxShadowDropdown}, ${boxShadowDropdown};
        border-color: ${dropdownMenuBorderColor};

        .item {
          min-height: 2.543em;
          background-color: ${dropdownBg} !important;
          color: ${fg} !important;
          border-color: ${dropdownMenuItemBorderColor};

          &:hover {
            background-color: ${dropdownItemBgHover} !important;
          }

          &.selected {
            background-color: ${dropdownBgSelected} !important
          }
        }

        .active.item {
          box-shadow: inset 2px 0 0 ${primary};
          background-color: ${sidebarMenuItemBgActive} !important;

          &:hover {
            background-color: ${dropdownItemBgHover} !important;
          }
        }

        .header {
          font-style: italic;
        }
      }
    }
  `}
`;

export default ThemedDropdown;
