import React, { useEffect, useState, useCallback } from 'react';
import { Icon } from 'semantic-ui-react';
import { ALL_ICONS_IN_ALL_CONTEXTS } from 'semantic-ui-react/dist/commonjs/lib/SUI';
import { func, string } from 'prop-types';
import _ from 'lodash';

import { TrackedSearch } from 'components';

const resultRenderer = ({ title }) => (
  <div>
    <Icon name={title} />
    {title}
  </div>
);

resultRenderer.propTypes = {
  title: string.isRequired,
};

const IconInput = ({ name, value, onChange, type, ...props }) => {
  const [val, setVal] = useState(value || '');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);

      if (!val) {
        setResults([]);
      } else {
        setResults(
          _.filter(ALL_ICONS_IN_ALL_CONTEXTS, s => s.indexOf(val) >= 0).map(
            s => ({ title: s })
          )
        );
      }
    }, 200);
  }, [val]);

  const handleResultSelect = useCallback(
    (e, { result }) => {
      setVal(result.title);
      onChange(e, { name, value: result.title });
    },
    [name, onChange]
  );

  const handleSearchChange = (e, { value }) => {
    if (!loading) {
      setLoading(true);
    }

    setVal(value);
    onChange(e, { name, value });
  };

  return (
    <TrackedSearch
      input={{
        icon: val && ALL_ICONS_IN_ALL_CONTEXTS.includes(val) ? val : '',
        iconPosition: 'left',
      }}
      showNoResults={false}
      loading={loading}
      value={val}
      results={results}
      resultRenderer={resultRenderer}
      onResultSelect={handleResultSelect}
      onSearchChange={handleSearchChange}
      {...props}
    />
  );
};

IconInput.propTypes = {
  name: string.isRequired,
  value: string,
  onChange: func.isRequired,
};

export default IconInput;
