import React, { useState } from 'react';
import { TimeInput } from 'semantic-ui-calendar-react';
import { ThemedInput } from 'components';

const initialState = { start: undefined, end: undefined };

const TimeRange = ({ onChange, defaultValue, ...props }) => {
  const defaultState = defaultValue
    ? { start: defaultValue.split('|')[0], end: defaultValue.split('|')[1] }
    : initialState;

  const [timeRange, setTimeRange] = useState(defaultState);
  const [oldTimeRange, setOldTimeRange] = useState(defaultState);

  const handleSet = (e, { value }) => {
    const { start, end } = timeRange;

    const tr =
      start && end
        ? { start: value, end: '' } // already select two value then when we select it again, we start to select a new one
        : {
            start: start ? start : value,
            end: start && start !== value ? value : '',
          };

    if (tr.start && tr.end) {
      // switch start-end time if start time is greater than end time
      const startTime = parseInt(tr.start.substr(0, 2));
      const endTime = parseInt(tr.end.substr(0, 2));

      if (startTime > endTime) {
        tr.start = `${endTime}:00`;
        tr.end = `${startTime}:00`;
      }

      // is finish select time range and the value is different than before then trigger search
      if (tr.start !== oldTimeRange.start || tr.end !== oldTimeRange.end) {
        onChange(e, { value: `${tr.start}|${tr.end}` });
        setOldTimeRange(tr); // save the filter of search to compare with previous later
      }
    }

    setTimeRange(tr);
  };

  const reset = e => {
    const { start, end } = timeRange;
    if (start && end) {
      // remove filter in search if only the value is searched before
      onChange(e, { value: '' }); // reset filter then trigger search
      setOldTimeRange(initialState);
      setTimeRange(initialState);
    } else if (start && !end) {
      setTimeRange(oldTimeRange); // is clear when selecting new time range then set value to before
    }
  };

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
      onKeyDown={e => {
        if (e.key !== 'Tab') {
          e.preventDefault(); // block others keys if this is not Tab key
        }
      }}
      className="field column"
    >
      <ThemedInput
        component={TimeInput}
        value={timeRange.start ? `${timeRange.start} - ${timeRange.end}` : ''}
        iconPosition="right"
        clearable
        closable={timeRange.start && timeRange.end ? true : false}
        onChange={handleSet}
        onClear={reset}
        disableMinute
        {...props}
      />
    </div>
  );
};

export default TimeRange;
