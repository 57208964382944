import React, { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import documentApi from 'api/document/documentApi';
import { usePrint } from 'core/hooks/usePrint';
import { getTemplates } from 'core/template/templateReducer';

const PrintTrigger = ({
  // redux
  settings: { externalPrint },
  templates,
  // props
  callback,
  docs,
  printTemplateKey,
}) => {
  const printTemplates = externalPrint
    ? undefined
    : _.find(templates, {
        key: printTemplateKey,
      });

  const printStarted = useRef(false);
  const printData = useRef(
    docs.map(({ documentID, data }) => ({
      documentID,
      formData: JSON.parse(data),
    }))
  );
  const [template, setTemplate] = useState(
    printTemplates ? printTemplates : undefined
  );

  const [printPending] = usePrint(printData.current, template);

  // send print to api
  const sendExternalPrint = useCallback(async () => {
    const response = await documentApi.print(docs);
    window.location.href = response;

    if (callback) {
      callback();
    }
  }, [docs, callback]);

  // print hook
  useEffect(() => {
    // print started
    if (!externalPrint && printPending) {
      setTemplate();
      printStarted.current = true;
    }

    // print ended
    if (!externalPrint && printStarted.current === false && !printPending) {
      printStarted.current = false;

      if (callback) {
        callback();
      }
    }

    // start external print
    if (externalPrint && printStarted.current === false) {
      sendExternalPrint();
      printStarted.current = true;
    }
  }, [externalPrint, callback, printPending, sendExternalPrint]);

  return <></>;
};

const mapStateToProps = state => ({
  settings: state.settings,
  templates: getTemplates(state),
});

export default connect(
  mapStateToProps,
  null
)(PrintTrigger);
