import React, { useState, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import driverApi from '../../../api/driver/driverApi';

import PopupTitle from '../components/container/PopupTitle';
import PopupList from '../components/list/PopupList';

import DriverListItem from './components/DriverListItem';
import DriverInfoPanel from './components/DriverInfoPanel';

const PopupDrivers = () => {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchDrivers();
  }, []);

  const fetchDrivers = async () => {
    setLoading(true);
    const response = await driverApi.getAll();

    if (response && response.drivers) {
      const { drivers } = response;
      setDrivers(drivers);
      setSelectedDriver(drivers[0]);
    }
    setLoading(false);
  };

  const handleSelectDriver = driver => {
    setEditMode(false);
    setSelectedDriver(driver);
  };

  const enableEditMode = async (e, driver) => {
    e.stopPropagation();
    if (selectedDriver.id !== driver.id) {
      // const res = await setSelectedDriver(driver);
    }
    setEditMode(true);
  };

  const handleSearch = (e, { name, value }) => {
    fetchDrivers();
  };

  return (
    <div>
      <PopupTitle title="Drivers" size={drivers.length} />
      <Grid columns={2}>
        <Grid.Column width={7}>
          <PopupList
            loading={loading}
            title="drivers"
            handleSearch={handleSearch}
          >
            {drivers.map((driver, index) => (
              <DriverListItem
                key={driver.id}
                info={driver}
                handleClick={() => handleSelectDriver(driver)}
                handleEditMode={e => enableEditMode(e, driver)}
              />
            ))}
          </PopupList>
        </Grid.Column>
        <Grid.Column width={9}>
          <DriverInfoPanel driver={selectedDriver} edited={editMode} />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default PopupDrivers;
