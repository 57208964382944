import { useCallback, useEffect, useReducer, useRef } from 'react';
import _ from 'lodash';

import Utils from 'core/utils/utils';

const formReducer = (state, { field, value }) => ({ ...state, [field]: value });

export function useForm(initialData, onTimeout) {
  // ref for data
  const formRef = useRef(_.cloneDeep(initialData));

  // ref for timeouts
  const previousTimeouts = useRef({});

  // current form data
  const [form, dispatch] = useReducer(formReducer, _.cloneDeep(initialData));

  const handleChange = useCallback(
    (e, data) => {
      let newTimeout;

      // get field name and new value
      const { field, value } = Utils.getFieldValueFromEData(e, data);

      // update form with new value for field
      dispatch({ field, value });

      if (onTimeout) {
        // cancel previous timeout for this field
        if (previousTimeouts.current && previousTimeouts.current[field]) {
          clearTimeout(previousTimeouts.current[field]);
        }

        // build new timeout for this field
        newTimeout = setTimeout(() => {
          // if value has changed...
          if (formRef.current[field] !== value) {
            // ...store new value
            formRef.current[field] = value;
            // ...send timeout notification
            onTimeout(_.cloneDeep(formRef.current), field);
          }
        }, 1000);

        // store timeout for the field
        previousTimeouts.current[field] = newTimeout;
      }
    },
    [dispatch, onTimeout]
  );

  const handleSetKey = useCallback(
    (field, value) => {
      // update form with new value for field
      dispatch({ field, value });

      // store new value
      formRef.current[field] = value;
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      // ignore warning as long as ref does not relate to DOM
      // see https://github.com/facebook/react/issues/15841
      _.forEach(previousTimeouts.current, (value, _propertyName) =>
        clearTimeout(value)
      );
    };
  }, []);

  return [form, handleChange, handleSetKey];
}
