import _ from 'lodash';

import { FIELD_TYPE, SYSTEM_FIELDS } from 'core/utils/constant';
import { getDateFormatted, getDateTimeFormatted } from 'core/utils/date';
import { typeMapper } from 'core/utils/mapper';

const systemFields = _.values(SYSTEM_FIELDS);

const { date, datetime } = FIELD_TYPE;

// map system column key with row system data
const getCellSystemData = (row, columnKey) => {
  switch (columnKey) {
    case SYSTEM_FIELDS.formid:
      return row.rowID;
    case SYSTEM_FIELDS.formlayoutname:
      return row.formLayoutName;
    case SYSTEM_FIELDS.formlayouttype:
      return row.type;
    case SYSTEM_FIELDS.lastedit:
      return getDateTimeFormatted(row.lastEdit);
    case SYSTEM_FIELDS.status:
      return row.status;
    case SYSTEM_FIELDS.user:
      return row.creator;
    default:
      return '';
  }
};

// retrieve and format cell data by row and column
const getCellData = (row, columnKey, columnType) => {
  let cellData = '';

  // column is system column
  if (systemFields.includes(columnKey)) {
    // search in row system data
    cellData = getCellSystemData(row, columnKey);
  } else {
    // prepare data
    const parsedData = row.data && JSON.parse(row.data);
    const isDataHasProp = parsedData && parsedData.hasOwnProperty(columnKey);

    if (isDataHasProp) {
      // extract column data
      cellData = parsedData[columnKey] ? parsedData[columnKey].toString() : '';

      //format
      if (_.includes([datetime], typeMapper(columnType))) {
        cellData = getDateTimeFormatted(cellData);
      } else if (_.includes([date], typeMapper(columnType))) {
        cellData = getDateFormatted(cellData);
      }
    }
  }

  return cellData;
};

// extract and filter row data by columns
const getRowData = (row, columns, convertData = true) => {
  const data = {};

  // get data for each cell in row
  _.forEach(columns, c => {
    data[c.key] = getCellData(row, c.key, convertData ? c.type : undefined);
  });

  return data;
};

export { getCellData, getRowData };
