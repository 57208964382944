import { Pagination } from 'semantic-ui-react';
import styled from 'styled-components';

// Pagination
const ThemedPagination = styled(Pagination)`
  ${({
    theme: {
      color: { tableFg, tableBg, tableItemBgHover },
    },
  }) => `
    &&& {
      background-color: ${tableBg};

      a {
        color: ${tableFg};

        &:hover {
          color: ${tableFg};
          background-color: ${tableItemBgHover};
        }
        &.active.item {
          color: ${tableFg} !important;
          background-color: ${tableItemBgHover} !important;
        }
      }
    }
  `}
`;

export default ThemedPagination;
