import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import ActionModal from '../../action-modal/ActionModal';
import PopupAlert from '../alert/PopupAlert';
import PopupInputs from '../inputs/PopupInputs';

const Container = styled.div`
  padding: 10px;
`;

const PopupActionModal = props => {
  const { popupAction, onBtnClick, onSecondaryBtnClick } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [popupActionYesNo, setPopupActionYesNo] = useState(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  const configConfirmModal = (action, onClick) => {
    const { name, message, yes_OkButton, no_CancelButton } = action;

    const confirmModalOptions = {
      title: name ? name : '',
      message: message ? message : '',
      btnTitle: yes_OkButton ? yes_OkButton.name : '',
      onBtnClick: onClick,
      secondaryBtnTitle: no_CancelButton ? no_CancelButton.name : '',
      onSecondaryBtnClick: closeModal,
    };

    setIsOpen(true);
    setPopupActionYesNo(confirmModalOptions);
  };

  const showConfirmModal = (yesNoAction, onClick) =>
    yesNoAction ? configConfirmModal(yesNoAction, onClick) : onClick();

  const PopupType =
    popupAction.inputs && popupAction.inputs.length > 0
      ? PopupInputs
      : PopupAlert;

  return (
    <Container>
      <PopupType
        {...props}
        inputs={popupAction.inputs}
        onBtnClick={() =>
          showConfirmModal(popupAction.yes_OkPopupAction, onBtnClick)
        }
        onSecondaryBtnClick={() =>
          showConfirmModal(
            popupAction.no_CancelPopupAction,
            onSecondaryBtnClick
          )
        }
      />
      <Modal.Actions>
        <ActionModal size="mini" onClose={closeModal} open={isOpen}>
          <PopupAlert {...popupActionYesNo} />
        </ActionModal>
      </Modal.Actions>
    </Container>
  );
};

export default PopupActionModal;
