import React from 'react';
import styled from 'styled-components';

import { ThemedLabel } from 'components';

const StyledLabel = styled(({ empty, left, width, ...props }) => (
  <ThemedLabel {...props} />
))`
  &&&&& {
    ${({ empty, left, width }) => `
      cursor: pointer;
      position: absolute;
      top: calc(50% - ${empty ? '9px' : '14.83px'});
      min-width: 5px;
      margin: 0;
      border: 2px solid white;
      color: white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      left: ${left}%;
      width: ${width}%;
    `}
  }
`;

const CompactLabel = styled(StyledLabel)`
  &&&&& {
    padding-left: 0;
    padding-right: 0;
    text-indent: 200%;
  }
`;

const EventLabel = ({ compact, ...props }) => {
  return compact ? <CompactLabel {...props} /> : <StyledLabel {...props} />;
};

export default EventLabel;
