import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { arrayOf, bool, func, object } from 'prop-types';
import _ from 'lodash';

import userFilterApi from 'api/user/userFilterApi';
import documentApi from 'api/document/documentApi';
import Utils from 'core/utils/utils';
import { AuthCreators, getCurrentUser } from 'core/auth/authReducer';
import { TemplateCreators, getTemplates } from 'core/template/templateReducer';
import { ModalCreators } from 'core/modal/modalReducer';
import { BUTTON_TYPE, DRAFT_STATUS } from 'core/utils/constant';
import { ToastMessage } from 'components';
import { isValidPopup, isValidDocument } from 'core/utils/utilsModal';

import DownloadButton from './downloadButtonContainer';
import PrintButton from './PrintButton';
import DocumentButton from './DocumentButton';

const DocumentButtonContainer = ({
  // stores
  t,
  history,
  templates,
  updateTemplate,
  updateIsSubmitting,
  // props
  isValidForm,
  documentID,
  clickable,
  closeModal,
  openModal,
  changeSize,
  popupAction,
  viewersWarning,
  formData,
  requiredFields,
  patternFields,
  filterSelection,
  selectedTemplate,
  currentUser,
  updateCurrentUser,
  clearFunc,
  submitFunc,
}) => {
  const [startPrint, setStartPrint] = useState();
  const [, setInnerFormData] = useState(formData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInnerFormData(formData);
  }, [formData]);

  const { cId, btnKey, btnName, btnType, toStatus } = {
    cId: clickable.clickableId,
    btnKey: clickable.key,
    btnName: clickable.name,
    btnType: clickable.type,
    toStatus: clickable.toStatus,
  };

  const handleCloseModal = () => {
    setLoading(false);
    closeModal();
  };

  // show modal on button click
  const showModal = () => {
    setLoading(true);

    // read fields from templates
    const fields = Utils.getFieldsFromTemplates(templates);

    // if valid document
    if (
      _.includes([BUTTON_TYPE.print], btnType) ||
      //(_.includes([BUTTON_TYPE.download], btnType) /*&& documentID > 0*/) ||
      toStatus === DRAFT_STATUS ||
      (!filterSelection &&
        _.includes([BUTTON_TYPE.link, BUTTON_TYPE.reset], btnType)) ||
      isValidDocument(formData, requiredFields, patternFields, fields)
    ) {
      // define modal
      const { name, message, ok, cancel } = popupAction;

      const modalOptions = {
        title: name ? t(`common|${name}`) : '',
        message: message ? t(message) : '',
        warning: viewersWarning
          ? t(
              `Other users are viewing this document, continuing may disrupt their operations`
            )
          : '',
        btnTitle: ok ? t(`common|${ok}`) : '',
        onBtnClick: () => yesAction(),
        secondaryBtnTitle: cancel ? t(`common|${cancel}`) : '',
        onSecondaryBtnClick: handleCloseModal,
        popupAction: popupAction,
      };

      // open modal
      changeSize('tiny');
      openModal('form-action', modalOptions);
    } else {
      setLoading(false);
    }
  };

  // when user clicks ok in modal
  const yesAction = async () => {
    updateIsSubmitting(true);

    if (await isValidPopup(popupAction, setInnerFormData)) {
      switch (btnType) {
        case BUTTON_TYPE.status:
          changeStatus();
          break;
        case BUTTON_TYPE.reset:
          clearDocument();
          break;
        case BUTTON_TYPE.print:
          print();
          break;
        //case BUTTON_TYPE.download:
        //  download();
        //  break;
        case BUTTON_TYPE.link:
          redirect();
          break;
        case BUTTON_TYPE.popup:
        default:
          closeModal();
          break;
      }
    } else {
      updateIsSubmitting(false);
    }
  };

  // submit document to new status
  const changeStatus = () => {
    submitFunc(true, cId);
    closeModal();
  };

  // reset all field values
  const clearDocument = () => {
    clearFunc();
    closeModal();
  };

  // start print
  const print = () => {
    setStartPrint(true);
    closeModal();
  };

  // stop print
  const resetPrint = () => {
    setStartPrint(false);
    setLoading(false);
  };

  // download as file
  const download = async (templateName, fileType) => {
    if (templateName && fileType) {
      setLoading(true);
      const fileUrl = await documentApi.downloadAs(
        documentID,
        templateName,
        fileType
      );

      if (fileUrl) {
        // open to save file
        Utils.openInNewTab(fileUrl);
        setLoading(false);
      }
    }
  };

  // redirect
  const redirect = async () => {
    await closeModal();

    if (
      btnKey.indexOf('https://') === 0 ||
      btnKey.indexOf('http://') === 0 ||
      btnKey.indexOf('/') === 0
    ) {
      if (filterSelection) {
        const selectedFilters = Utils.mapDataToFilters(formData);

        const res = await userFilterApi.putMany(selectedFilters);

        if (res.status === 204) {
          // separate backend filters and frontend filters
          const filters = _.filter(
            currentUser.filters,
            f => !_.some(selectedFilters, sf => sf.key === f.key)
          );

          updateCurrentUser({
            ...currentUser,
            filters,
            selectedFilters,
          });
        }
      }

      // redirect
      if (filterSelection) {
        history.push(btnKey);
      } else {
        // delay redirect to wait for end of execution
        setTimeout(() => (window.location.href = btnKey), 1000);
      }
    } else {
      toast.error(<ToastMessage error message={'Link is not valid'} />);
    }
  };

  const btnProps = {
    name: btnName,
    primary: true,
    color: Utils.getColor(clickable.color),
    icon: clickable.icon,
    iconColor: Utils.getColor(clickable.iconColor),
    disabled:
      formData === undefined || loading || isValidForm ? true : undefined,
    type: btnType === BUTTON_TYPE.status ? 'submit' : 'button',
    form: btnType === BUTTON_TYPE.status ? 'renderedDocument' : undefined,
    onClick: submitFunc ? showModal : null,
  };

  if (_.includes([BUTTON_TYPE.print], btnType)) {
    btnProps.documentID = documentID;
    btnProps.printNow = startPrint;
    btnProps.onPrint = resetPrint;
    btnProps.selectedTemplate = selectedTemplate;
  } else if (_.includes([BUTTON_TYPE.download], btnType)) {
    btnProps.onDownload = download;
  }

  // render
  return _.includes([BUTTON_TYPE.print], btnType) ? (
    <PrintButton {...btnProps} />
  ) : _.includes([BUTTON_TYPE.download], btnType) ? (
    <DownloadButton {...btnProps} />
  ) : (
    <DocumentButton {...btnProps} />
  );
};

DocumentButtonContainer.propTypes = {
  t: func.isRequired,
  clickable: object,
  popupAction: object,
  formData: object,
  requiredFields: arrayOf(object),
  patternFields: arrayOf(object),
  filterSelection: bool,
  isValidForm: bool,
  clearFunc: func,
  submitFunc: func,
};

DocumentButtonContainer.defaultProps = {
  filterSelection: false,
};

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  templates: getTemplates(state),
});

const mapDispatchToProps = dispatch => ({
  updateCurrentUser: user => dispatch(AuthCreators.updateCurrentUser(user)),

  updateTemplate: (templateId, field, value) =>
    dispatch(TemplateCreators.updateTemplate(templateId, field, value)),

  closeModal: () => dispatch(ModalCreators.closeModal()),
  openModal: (modalType, options) =>
    dispatch(ModalCreators.openModal(modalType, options)),
  changeSize: size => dispatch(ModalCreators.changeSize(size)),
  updateIsSubmitting: isSubmitting =>
    dispatch(ModalCreators.updateIsSubmitting(isSubmitting)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(DocumentButtonContainer))
);
