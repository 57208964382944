import React from 'react';
import { arrayOf, number, object } from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import Utils from 'core/utils/utils';

import FormSectionGridColumn from './FormSectionGridColumn';

const GridRow = styled(Grid.Row)`
  .ui.grid > &.row {
    margin: 0;
    padding: 1rem 0 0 0;
  }

  @media only screen and (min-width: 768px) {
    .ui.grid > &.row {
      flex-wrap: nowrap;
    }
  }

  .ui.grid > &.row:first-child {
    margin-top: 1rem;
  }

  .ui.grid > &.row:last-child {
    margin-bottom: 1rem;
  }
`;

const FormSectionGridRow = ({
  colsSorted,
  elementsInRow,
  totalSpanInRow,
  ...formSectionProps
}) => (
  <Form.Group widths="equal" stretched as={GridRow}>
    {colsSorted.map(col => {
      // can have more than 1 section with same position (case has rowSpan)
      const inputs = elementsInRow
        .filter(f => f.column === col)
        .sort((a, b) => a.nestedRow - b.nestedRow);

      const hasColSpan = inputs.some(f => f.columnSpan >= 2);
      const columnWidth = hasColSpan
        ? Utils.columnWidthByColsSpan(Utils.maxColSpan(inputs), totalSpanInRow)
        : undefined;

      return (
        <FormSectionGridColumn
          key={Utils.getEltKey(inputs[0])}
          inputs={inputs}
          columnWidth={columnWidth}
          {...formSectionProps}
        />
      );
    })}
  </Form.Group>
);

FormSectionGridRow.propTypes = {
  colsSorted: arrayOf(number).isRequired,
  elementsInRow: arrayOf(object).isRequired,
  totalSpanInRow: number.isRequired,
};

export default FormSectionGridRow;
