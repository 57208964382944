import React from 'react';
import {
  ActionModal,
  PopupAlert,
  PopupActionModal,
  PopupInputs,
  PopupTabs,
  TransportDetails,
} from './components';
import PopupDrivers from 'scenes/dashboard/scenes/dispatch/components/popups/drivers/PopupDrivers';
import PopupStops from 'scenes/dashboard/scenes/dispatch/components/popups/stops/PopupStops';

const componentsByType = {
  'popup-alert': PopupAlert,
  'form-action': PopupActionModal,
  'popup-inputs': PopupInputs,
  'popup-inputs-clean': PopupInputs,
  'popup-tabs': PopupTabs,
  'transport-details': TransportDetails,
  'popup-drivers': PopupDrivers,
  'popup-stops': PopupStops,
};

const Modal = ({ modalType, isOpen, options, closeModal, size }) => {
  const BodyModal = componentsByType[modalType];

  return (
    <ActionModal
      type={modalType}
      open={isOpen}
      onClose={(options && options.onSecondaryBtnClick) || closeModal}
      size={size}
    >
      {modalType && <BodyModal modalType={modalType} {...options} />}
    </ActionModal>
  );
};

export default Modal;
