import React, { Component } from 'react';
import { arrayOf, object, bool, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getTemplates } from 'core/template/templateReducer';
import { getTemplateContents } from 'core/utils/form';

import GridFilterTable from './components/table/GridFilterTable';
import { getFixedFiltersGrid } from './helpers/fixed-filters';
import { getFixedFilterPropsGrid } from './helpers/fixed-filter-props';

const emptyFilter = {
  position: Number.MAX_SAFE_INTEGER,
  columnID: -1,
  column: undefined,
  value: '',
};

class GridFilter extends Component {
  state = {
    templateOptions: [],
    sectionOptions: [],
    columnOptions: [],
    columns: [],
  };

  async componentDidMount() {
    const { filters, templates, handleReloadColOpts } = this.props;

    // load available fields from redux-stored templates
    const templateOptions = [];
    const sectionOptions = [];
    const columnOptions = [];
    const columns = [];

    templates.forEach(t => {
      templateOptions.push({
        reference: t.key,
        value: t.templateId,
        text: t.name,
      });
      _.filter(getTemplateContents(t), ct => ct.sectionID >= 0).forEach(s => {
        sectionOptions.push({
          temp: t.templateId,
          reference: s.key,
          value: s.sectionID,
          text: s.name,
        });
        s.fields.forEach(f => {
          columnOptions.push({
            temp: t.templateId,
            sect: s.sectionID,
            reference: f.key,
            value: f.fieldID,
            text: f.name,
            disabled: filters.some(fltr => fltr.columnID === f.fieldID),
          });
          columns.push(f);
        });
      });
    });

    this.setState({ templateOptions, sectionOptions, columnOptions, columns });

    if (handleReloadColOpts) {
      handleReloadColOpts(this.reloadColOptions);
    }
  }

  handleAddFilter = () => {
    const { advanced, filters, callbackFunc } = this.props;
    const { templateOptions } = this.state;

    // check available for filter adding
    // + check if column filling
    // + check if filter filling
    const isAvailable = f =>
      f.columnID === -1 || (!f.operator && advanced && f.columnPosition === '');

    if (!filters.some(f => isAvailable(f)) && templateOptions.length > 0) {
      // compute new position
      const position = !_.isEmpty(filters)
        ? _.maxBy(filters, 'position').position + 1
        : 1;

      // update state
      callbackFunc(filters.concat({ ...emptyFilter, position }));
    }
  };

  handleRemoveFilter = () => {
    const { filters, callbackFunc } = this.props;
    const { activeFilter, columnOptions } = this.state;

    // new options
    const colOpts = columnOptions.some(o => o.value === activeFilter[2])
      ? [
          ...columnOptions.filter(o => o.value !== activeFilter[2]),
          ...columnOptions
            .filter(o => o.value === activeFilter[2])
            .map(o => ({ ...o, disabled: false })), // enable old option
        ]
      : [...columnOptions];

    // update state
    this.setState({
      // disable selected option
      columnOptions: colOpts,
    });

    // update state
    callbackFunc(getFixedFiltersGrid(filters, activeFilter));
  };

  // set active row
  handleRowClick = activeFilter => () => {
    this.setState({ activeFilter });
  };

  handleChangePosition = (e, data) => {
    // console.log(name, value);
    // this.setState({ [name]: value });
    // if (e.target.value) {
    //   const { table, columnOptions, activeColumn } = this.state;
    //   const { columns } = table;
    //   const column = columns.find(
    //     c => [c.position, c.columnID] === activeColumn
    //   );
    //   if (column && column.position !== undefined) {
    //     column.position = +e.target.value;
    //     if (column.position < 0) {
    //       // remove field
    //       columns.splice(columns.indexOf(column), 1);
    //       // enable again in list field option
    //       if (column.columnID > 0) {
    //         columnOptions.find(
    //           o => o.value === column.columnID
    //         ).disabled = false;
    //       }
    //     }
    //     const newTable = { ...table, columns };
    //     this.setState({
    //       table: newTable,
    //       activeColumn: activeColumn,
    //     });
    //   } else {
    //     console.log(e.target.value);
    //   }
    // }
  };

  handleChangeColumn = (e, data) => {
    const { activeFilter, columnOptions, columns } = this.state;

    // new options
    const newColumnOptions = columnOptions.some(
      o => o.value === activeFilter[2]
    )
      ? [
          ...columnOptions.filter(
            o => o.value !== data.value && o.value !== activeFilter[2]
          ),
          ...columnOptions
            .filter(o => o.value === data.value)
            .map(o => ({ ...o, disabled: true })), // disable selected option
          ...columnOptions
            .filter(o => o.value === activeFilter[2])
            .map(o => ({ ...o, disabled: false })), // enable old option
        ]
      : [
          ...columnOptions.filter(o => o.value !== data.value),
          ...columnOptions
            .filter(o => o.value === data.value)
            .map(o => ({ ...o, disabled: true })), // disable selected option
        ];

    const newActiveFilter = [...activeFilter];
    newActiveFilter[2] = data.value;

    // update state
    this.setState({
      columnOptions: newColumnOptions, // disable selected option
      activeFilter: newActiveFilter, // set active row
    });

    this.handleChangeAttr(
      'columnID',
      ['operator', 'value'],
      [['column', columns.find(c => c.fieldID === data.value)]]
    )(e, data);
  };

  handleChangeAttr = (attr, attrReset, attrCustom) => (e, { value }) => {
    const { filters, callbackFunc } = this.props;
    const { activeFilter } = this.state;

    const attrResets = _.reduce(
      attrReset,
      (acc, elem) => {
        acc[elem] = undefined;
        return acc;
      },
      {}
    );

    const attrCustoms = _.reduce(
      attrCustom,
      (acc, elem) => {
        acc[elem[0]] = elem[1];
        return acc;
      },
      {}
    );

    // update state
    callbackFunc([
      // leave unaffected filters unchanged
      ...getFixedFiltersGrid(filters, activeFilter),
      {
        // leave all properties unchanged first
        ...getFixedFilterPropsGrid(filters, activeFilter),
        // then update desired property
        [attr]: value,
        ...attrResets,
        ...attrCustoms,
      },
    ]);
  };

  reloadColOptions = filters => {
    const { columnOptions } = this.state;

    // first enable all the options
    let colOpts = columnOptions.map(o => ({ ...o, disabled: false }));

    // then disable the option which is in new filters
    filters.forEach(f => {
      colOpts = [
        ...colOpts.filter(o => o.value !== f.columnID),
        ...colOpts
          .filter(o => o.value === f.columnID)
          .map(o => ({ ...o, disabled: true })), // disable selected option
      ];
    });

    this.setState({ columnOptions: colOpts });
  };

  render() {
    const { filters, advanced } = this.props;
    const {
      templateOptions,
      sectionOptions,
      columnOptions,
      columns,
      activeFilter,
    } = this.state;

    return (
      <GridFilterTable
        advanced={advanced}
        filters={filters}
        templateOptions={templateOptions}
        sectionOptions={sectionOptions}
        columnOptions={columnOptions}
        columns={columns}
        activeFilter={activeFilter}
        handleAddFilter={this.handleAddFilter}
        handleRemoveFilter={this.handleRemoveFilter}
        handleRowClick={this.handleRowClick}
        handleChangePosition={this.handleChangeAttr('columnPosition')}
        handleChangeTemplate={this.handleChangeAttr('templateId', [
          'operator',
          'value',
        ])}
        handleChangeSection={this.handleChangeAttr('sectionID', [
          'operator',
          'value',
        ])}
        handleChangeColumn={this.handleChangeColumn}
        handleChangeOperator={this.handleChangeAttr('operator')}
        handleChangeValue={this.handleChangeAttr('value')}
      />
    );
  }
}

GridFilter.propTypes = {
  filters: arrayOf(object).isRequired,
  advanced: bool,
  callbackFunc: func.isRequired,
  handleReloadColOpts: func.isRequired,
  templates: arrayOf(object).isRequired,
};

GridFilter.defaultProps = {
  advanced: false,
};

const mapStateToProps = state => ({
  templates: getTemplates(state),
});

const mapDispatchToProps = null;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GridFilter)
);
