import React from 'react';
import { Form, Input, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { FIELD_TYPE } from 'core/utils/constant';
import { InputSearch, ThemedInput } from 'components';

const FilterTableCellInput = ({
  t,
  advanced,
  columns,
  fieldID,
  operator,
  value,
  handleChangeValue,
}) => (
  <Table.Cell>
    {columns.some(c => `${c.fieldID}` === fieldID) && (
      <InputSearch
        t={t}
        column={columns.find(c => `${c.fieldID}` === fieldID)}
        disabled={
          (!advanced && (!fieldID ? true : undefined)) ||
          (advanced && (!operator || operator < 0 ? true : undefined))
        }
        operator={operator}
        controlled
        defaultValue={value ? value : ''}
        levelFilter="advanced"
        handleValue={handleChangeValue}
        singleChoice={!advanced}
      />
    )}
    {!fieldID && (
      <Form.Field type={FIELD_TYPE.text} control={ThemedInput} disabled />
    )}
  </Table.Cell>
);

const SmallInput = styled(Input)`
  ${({
    theme: {
      color: { fg, inputFg, inputFgHover, inputBg, borderColor },
    },
  }) => `
    &.ui.input {
      max-width: 70px;

      background-color: ${inputBg};
      color: ${inputFg};
      border-color: ${borderColor};

      &:hover {
        background-color: ${inputBg};
        color: ${inputFgHover};
      }

      input {
        background-color: ${inputBg};
        color: ${inputFg};
        border-color: ${borderColor};
      }

      + i {
        color: ${fg};
      }
    }
  `}
`;

const FilterTableCellInputNumber = ({ value, onChange }) => (
  <Table.Cell>
    <Form.Field>
      <SmallInput type={FIELD_TYPE.number} value={value} onChange={onChange} />
    </Form.Field>
  </Table.Cell>
);

export { FilterTableCellInput, FilterTableCellInputNumber };
