import axios from 'axios';
import AuthService from 'core/auth/authService';
import Log from 'core/utils/log';
import _ from 'lodash';

const getUrl = window.location;
const baseUrl = getUrl.protocol + '//' + getUrl.host + '/api/';

const validateStatusHelper = (status, validErrors) =>
  (200 <= status && status < 300) ||
  (validErrors && validErrors.includes(status));

class BaseApi {
  constructor() {
    axios.defaults.baseURL = baseUrl;

    axios.interceptors.request.use(
      config => {
        if (sessionStorage.getItem('auth')) {
          const auth = JSON.parse(sessionStorage.getItem('auth'));

          if (auth != null && auth.token != null) {
            config.headers.Authorization = `Bearer ${auth.token}`;
          }
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => {
        if (response.status === 200 && response.data) {
          return response.data;
        }
        return response;
      },
      error => {
        const { status } = error.response;

        if (status === 401) {
          AuthService.logout();
        }

        if (error.response.data && error.response.data.details) {
          console.error(error.response.data.details);
        }

        return Promise.reject(error);
      }
    );
  }

  base = func => (url, params, data) => {
    const extra = {
      ...params,
      validateStatus: status =>
        validateStatusHelper(status, params.validErrors),
    };

    return axios[func](..._.compact([url, data, extra])).catch(
      this.handleError
    );
  };

  get = (url, params = {}) => this.base('get')(url, params);

  post = (url, data, params = {}) => this.base('post')(url, params, data);

  put = (url, data, params = {}) => this.base('put')(url, params, data);

  delete = (url, params = {}) => this.base('delete')(url, params);

  handleError = error => {
    if (axios.isCancel(error)) {
      Log.error('Request canceled', error.message);
    }
  };
}

export default new BaseApi();
