import { useEffect, useRef } from 'react';

export function useHub(hub, token, handler) {
  const pending = useRef(true);
  const connection = useRef(undefined);

  useEffect(() => {
    let cancelled = false;

    const connectToHub = async (hubClass, auth, callback) => {
      // get hub
      const h = new hubClass(auth);

      // define handler callbacks
      h.addHandlers(callback);

      // start hub connection
      const c = await h.getConnection();

      if (!cancelled) {
        connection.current = c;
      }
    };

    connectToHub(hub, token, handler).then(() => (pending.current = false));

    return () => {
      cancelled = true;

      if (!pending.current && connection.current) {
        connection.current.stop();
      }
    };
  }, [hub, token, handler]);

  return [];
}
