const showClearAction = (
  clearable,
  multiple,
  activeData,
  searchable = false,
  editable = false,
  open = false
) => {
  return (editable || searchable) && open
    ? false
    : clearable &&
        activeData &&
        ((!multiple && activeData.value && activeData.value.length > 0) ||
          (multiple && activeData.length > 0));
};

export default showClearAction;
