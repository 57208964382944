import _ from 'lodash';

// dispatch to app settings (redux stores and local storage)
const dispatchPreferences = (preferences, tools) => {
  const { toggleSidebar, i18n } = tools;
  const { language, smallMenu } = preferences;

  if (!_.isNil(smallMenu)) {
    toggleSidebar(smallMenu);
  }

  if (language) {
    const currentLng = localStorage.getItem('faster-lang');

    if (currentLng !== language) {
      localStorage.setItem('faster-lang', language);
      i18n.changeLanguage(language);
    }
  }
};

export { dispatchPreferences };
