import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

const { Types, Creators } = createActions({
  requestModules: ['modules'],
  receiveModules: ['modules'],
  updateModulesStore: ['modules'],
});

export const ModulesCreators = Creators;
export const ModulesTypes = Types;

export const INITIAL_STATE = Immutable({
  isLoading: false,
  modules: null,
});

export const requestModules = (state = INITIAL_STATE, action) => {
  const { modules } = action;

  return {
    ...state,
    isLoading: modules === undefined,
    modules: null,
  };
};

export const receiveModules = (state = INITIAL_STATE, action) => {
  const { modules } = action;

  return {
    ...state,
    isLoading: false,
    modules,
  };
};

export const updateModulesStore = (state = INITIAL_STATE, action) => {
  const { isLoading, modules } = action.modules;

  return {
    ...state,
    isLoading,
    modules,
  };
};

export const HANDLERS = {
  [Types.REQUEST_MODULES]: requestModules,
  [Types.RECEIVE_MODULES]: receiveModules,
  [Types.UPDATE_MODULES_STORE]: updateModulesStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);

// SELECTORS
const selectorModules = state => state.modules;

export const getModules = createSelector(
  [selectorModules],
  modules => modules
);

export const getInnerModules = createSelector(
  [selectorModules],
  modules => modules.modules
);
