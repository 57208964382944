import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

const { Types, Creators } = createActions({
  initBatchTemplates: [],
  addBatchTemplate: ['batchTemplate'],
  updateBatchTemplateStore: ['batchTemplate'],
});

export const BatchTemplateCreators = Creators;
export const BatchTemplateTypes = Types;

export const INITIAL_STATE = Immutable({
  batchTemplates: [],
});

export const initBatchTemplates = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    batchTemplates: [],
  };
};

export const addBatchTemplate = (state = INITIAL_STATE, action) => {
  const { batchTemplate } = action;
  const tmpBatchTemplates = [...state.batchTemplates];

  tmpBatchTemplates.push(batchTemplate);

  return {
    ...state,
    batchTemplates: tmpBatchTemplates,
  };
};

export const updateBatchTemplateStore = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.batchTemplate,
});

export const HANDLERS = {
  [Types.ADD_BATCH_TEMPLATE]: addBatchTemplate,
  [Types.INIT_BATCH_TEMPLATES]: initBatchTemplates,
  [Types.UPDATE_BATCH_TEMPLATE_STORE]: updateBatchTemplateStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);

// SELECTORS
const selectorBatchTemplates = state => state.batchTemplate.batchTemplates;

export const getBatchTemplates = createSelector(
  [selectorBatchTemplates],
  batchTemplates => batchTemplates
);
