import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getCurrentUser } from 'core/auth/authReducer';
import CustomPage from './CustomPage';

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = null;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomPage)
);
