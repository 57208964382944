import React, { useEffect, useRef } from 'react';

//import Utils from 'core/utils/utils';
import { getDateFormatted } from 'core/utils/date';
import { useForm } from 'core/hooks/useForm';

import maskRowsToTimelineData from './helpers/maskRowsToTimelineData';
import Timeline from './Timeline';
import { computeDayDateRange } from '../agenda/helpers/initializeAgendaCriteria';

const TimelineContainer = ({
  maskStructure,
  maskRows,
  updateCriteriaFunc,
  ...props
}) => {
  const defaultDate = useRef(getDateFormatted(new Date()));
  const {
    view,
    //event: { start: eventStart, end: eventEnd }, // todo unused var
  } = maskStructure;
  const [tlProps, , setTlProp] = useForm({
    selectedDate: defaultDate.current,
    timeline: [],
  });

  useEffect(() => {
    if (maskStructure && maskRows) {
      setTlProp('timeline', maskRowsToTimelineData(maskStructure, maskRows));
    }
  }, [maskStructure, maskRows, setTlProp]);

  const handleDateChange = newDate => {
    const { selectedDate: oldDate } = tlProps;
    const { date /*, start, end*/ } = view;

    // set state
    setTlProp('selectedDate', newDate);

    // if date has changed, update query
    if (oldDate !== newDate) {
      //const range = `${Utils.toHourString(start)}:00|${Utils.toHourString(
      //  // todo unused var
      //  end
      //)}:00`;
      const { dateRange } = computeDayDateRange(newDate);

      updateCriteriaFunc([
        { key: date, value: dateRange },
        //{ key: eventStart, value: range },
        //{ key: eventEnd, value: range },
      ]);
    }
  };

  return (
    <Timeline
      {...props}
      tlProps={tlProps}
      view={maskStructure.view}
      handleChangeDate={handleDateChange}
    />
  );
};

TimelineContainer.propTypes = {};

export default TimelineContainer;
