import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
// import frLocale from 'moment/locale/fr';
import styled, { createGlobalStyle } from 'styled-components';

const Wrapper = styled.div`
  ${({
    theme: {
      color: { inputBg, inputFg, borderColor },
    },
  }) => `
    .DateRangePickerInput__withBorder {
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
    }

    .DateRangePickerInput__showClearDates {
      padding-right: 22px;
    }

    .DateRangePicker_picker {
      z-index: 2;
    }

    .DateInput {
      width: 85px;
    }

    .DateRangePickerInput_arrow {
      width: 5px;
      color: ${inputFg};
    }

    .DateInput_input {
      font-weight: 400;
      font-style: normal;
      font-size: 1em;
      font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
      padding: 0.44em 0.3em;
      line-height: 24px !important;
      width: 100% !important;
      border: 0 !important;
      border-radius: 0 !important;
      background-color: ${inputBg};
      color: ${inputFg};
      border-color: ${borderColor};
    }

    .DateRangePickerInput {
      background-color: ${inputBg};
      color: ${inputFg};
    }

    .DateRangePickerInput_clearDates {
      margin: 0 5px 0 0;
      padding: 5px;
      line-height: 10px;
    }

    .DateRangePickerInput_clearDates_svg {
      height: 12px;
      width: 12px;
    }
  `}
`;

const DateRangePickerModal = createGlobalStyle`
  .DateRangePicker_picker__portal {
    z-index: 99;
  }
`;

class DateRangePickerWrapper extends Component {
  state = {
    startDate: null,
    endDate: null,
    focusedInput: null,
  };

  onDatesChange = ({ startDate, endDate }) => {
    const { submit } = this.props;

    this.setState({ startDate, endDate });

    if (
      (startDate !== null && endDate !== null) ||
      (startDate === null && endDate === null)
    ) {
      submit(startDate, endDate);
    }
  };

  onFocusChange = focusedInput => this.setState({ focusedInput });

  render() {
    // set fr dates localization
    // (should load correct language from i18n for dates)
    // moment.locale('fr', frLocale);

    const { focusedInput, startDate, endDate } = this.state;
    const { showAsModal, direction } = this.props;

    return (
      <Wrapper
        onClick={e => {
          e.stopPropagation();
        }} // prevent event handleSort from parent component
      >
        {showAsModal && <DateRangePickerModal />}
        <DateRangePicker
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          endDateId="endDate"
          startDateId="startDate"
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          openDirection={direction ? direction : 'down'}
          isOutsideRange={() => false}
          displayFormat="YYYY-MM-DD"
          withPortal={showAsModal}
          initialVisibleMonth={() => moment().add(-1, 'M')}
          minimumNights={0}
          startDatePlaceholderText="aaaa-mm-jj"
          endDatePlaceholderText="aaaa-mm-jj"
          showClearDates
          hideKeyboardShortcutsPanel
        />
      </Wrapper>
    );
  }
}

export default DateRangePickerWrapper;
