import React from 'react';
import { withTranslation } from 'react-i18next';
import { Table, Placeholder } from 'semantic-ui-react';
import _ from 'lodash';

import TableRowData from './TableRowData';
import { ThemedPlaceholder } from '../..';

const showRows = (
  rows,
  table,
  activeItem,
  handleRowClick,
  area,
  module,
  history,
  admin
) =>
  rows.map(r => (
    <TableRowData
      key={r.key}
      r={r}
      table={table}
      activeItem={activeItem}
      handleRowClick={handleRowClick}
      area={area}
      module={module}
      history={history}
      admin={admin}
    />
  ));

const showSingleRow = (colSpanLength, content) => (
  <Table.Row>
    <Table.Cell colSpan={colSpanLength} content={content} />
  </Table.Row>
);

const showPlaceholderRow = (columns = [], colSpanLength = 100) => (
  <tr>
    {columns.length > 0 &&
      columns.map((c, index) => (
        <Table.Cell key={index} collapsing>
          <ThemedPlaceholder>
            <Placeholder.Line />
          </ThemedPlaceholder>
        </Table.Cell>
      ))}
    {!(columns.length > 0) && (
      <Table.Cell colSpan={colSpanLength} collapsing>
        <ThemedPlaceholder>
          <Placeholder.Line />
        </ThemedPlaceholder>
      </Table.Cell>
    )}
  </tr>
);

const TableRow = ({
  t,
  rows,
  table,
  activeItem,
  colSpanLength,
  handleRowClick,
  loading,
  area,
  module,
  history,
  admin,
}) => {
  if (loading === undefined) {
    loading = true;
  }

  if (loading) {
    return showPlaceholderRow(table ? table.columns : [], colSpanLength);
  }

  if (!loading && _.isEmpty(rows)) {
    return showSingleRow(colSpanLength, t('No records found'));
  }

  return showRows(
    rows,
    table,
    activeItem,
    handleRowClick,
    area,
    module,
    history,
    // type,
    // clientLayoutName,
    admin
  );
};

export { showSingleRow, showPlaceholderRow };

export default withTranslation()(TableRow);
