import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/fr';

import React from 'react';
import moment from 'moment';
import { arrayOf, object } from 'prop-types';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';

import i18n from 'config/i18n';

import {
  CalendarWrapper,
  CalendarPopupWrapper,
} from './components/CalendarWrapper';
import Toolbar from './components/Toolbar';
import messagesFrench from './helpers/messagesFrench';
import { langIsFrench } from './helpers/initializeAgendaCriteria';

const localizer = momentLocalizer(moment);
const { WEEK, MONTH } = Views;

const FasterAgenda = ({
  calendarProps: { currentDate, views, currentView, color, events },
  dataLoading,
  selectedDate,
  handleChangeDate,
  handleNavigate,
  handleChangeView,
  handleDoubleClick,
  ...props
}) => {
  const messages = langIsFrench ? messagesFrench : undefined;

  const { className } = props;

  return (
    <div className={className}>
      <Toolbar
        dataLoading={dataLoading}
        view={currentView}
        views={views}
        selectedDate={selectedDate}
        handleChangeDate={handleChangeDate}
        handleChangeView={handleChangeView}
      />
      <CalendarWrapper color={color}>
        <Calendar
          popup
          step={15}
          timeslots={16}
          startAccessor="start"
          endAccessor="end"
          toolbar={false}
          selectable="ignoreEvents"
          culture={i18n.language}
          localizer={localizer}
          events={events}
          date={currentDate}
          view={currentView}
          messages={messages}
          views={views}
          onNavigate={handleNavigate}
          onView={handleChangeView}
          onDoubleClickEvent={handleDoubleClick}
          onSelectSlot={slotInfo => handleNavigate(slotInfo.start, currentView)}
          dayPropGetter={date => {
            if (
              [WEEK, MONTH].includes(currentView) &&
              moment(date)
                .startOf('day')
                .isSame(moment(currentDate).startOf('day'))
            ) {
              return { className: 'selected' };
            }
            return {};
          }}
          eventPropGetter={(event, start, end, isSelected) => {
            if (event.color) {
              return { style: { backgroundColor: event.color } };
            }
            return {};
          }}
        />
      </CalendarWrapper>
      <CalendarPopupWrapper color={color} />
    </div>
  );
};

FasterAgenda.propTypes = {
  events: arrayOf(object),
};

export default FasterAgenda;
