import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button, Menu, Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { InputSearch } from 'components';

import { TableTotalPages, TableTotalRows } from './TableTotalItems';
import ThemedPlaceholder from '../../theme/ThemedPlaceholder';

const StyledPlaceholder = styled(ThemedPlaceholder)`
  min-width: 300px;
  padding-left: 0;
  padding-right: 0;
`;

const StyledMenuButton = styled(Button).attrs({
  circular: true,
})`
  ${({
    theme: {
      color: { primary, primaryHover, white },
    },
  }) => `
    &&& {
      background-color: ${primary};
      color: ${white};

      &:hover {
        background-color: ${primaryHover};
        color: ${white};
      }

      @media (min-width: 767px) {
        .ui.menu:not(.vertical) .item > &.button {
          max-width: 200px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  `}
`;

const StyledMenu = styled(Menu).attrs({
  stackable: true,
  secondary: true,
})`
  &&& {
    margin-bottom: 0.5rem;
  }
`;

const MenuItem = styled(Menu.Item)`
  ${({
    theme: {
      color: { fg },
    },
  }) => `
    &&& {
      color: ${fg};
    }
  `}
`;

const TableHeader = ({
  t,
  activeItem,
  totalItems,
  totalPages,
  activePage,
  handleSearchAllChange,
  area,
  module,
  loading,
  showSearchAll,
  admin,
}) => (
  <StyledMenu stackable secondary>
    {showSearchAll && (
      <Menu.Item fitted="horizontally">
        <InputSearch
          name="search"
          icon="search"
          placeholder={t('common|Search...')}
          handleValue={handleSearchAllChange}
          loading={loading}
          clearable
        />
      </Menu.Item>
    )}
    {loading && (
      <Menu.Item fitted="horizontally">
        <StyledPlaceholder>
          <Placeholder.Line />
        </StyledPlaceholder>
      </Menu.Item>
    )}
    {!loading && (
      <>
        <MenuItem>
          <TableTotalPages activePage={activePage} totalPages={totalPages} />
        </MenuItem>
        <MenuItem>
          <TableTotalRows totalItems={totalItems} />
        </MenuItem>
      </>
    )}
    <Menu.Menu position="right">
      {!admin && !_.isEmpty(activeItem) && (
        <Menu.Item fitted="vertically">
          {activeItem[1] ? (
            <Link to={Utils.buildUrlNav(area, module, activeItem[0])}>
              <StyledMenuButton content={t('common|Edit')} icon="pencil" />
            </Link>
          ) : (
            <StyledMenuButton
              disabled={!activeItem[1]}
              content={t('common|Edit')}
              icon="pencil"
            />
          )}
        </Menu.Item>
      )}
    </Menu.Menu>
  </StyledMenu>
);

export default withTranslation()(TableHeader);
