import { Comment } from 'semantic-ui-react';
import styled from 'styled-components';

import { ThemedLabel } from 'components/';

export const StyledAuthor = styled(Comment.Author)`
  &&&&& {
    color: ${({ theme: { color } }) => color.fg} !important;
    display: inline-block;
    font-style: italic;
    font-size: 0.875em;
  }
`;

export const StyledMetadata = styled(Comment.Metadata)`
  &&&&& {
    color: ${({ theme: { color } }) => color.metadata};
    font-size: 0.875em;
  }
`;

export const StyledText = styled(Comment.Text)`
  color: ${({ theme: { color } }) => color.buttonFg} !important;
`;

export const StyledLabelValue = styled(ThemedLabel).attrs({ basic: true })`
  && {
    margin: 1px 2px;
    padding: 6px 4px;
  }
`;
