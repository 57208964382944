import React from 'react';
import _ from 'lodash';

import { FIELD_TYPE } from 'core/utils/constant';
import {
  TrackedCheckbox,
  FasterDropdown,
  TrackedInput,
  IconInput,
} from 'components';

const TrackedFormInput = ({
  name,
  type,
  value,
  unchanged,
  saved,
  onChange,
  ...props
}) => {
  const Component = _.includes([FIELD_TYPE.checkbox], type)
    ? TrackedCheckbox
    : _.includes([FIELD_TYPE.combolist], type)
    ? FasterDropdown
    : _.includes([FIELD_TYPE.icon], type)
    ? IconInput
    : TrackedInput;

  const tracked = _.includes([FIELD_TYPE.combolist], type) ? true : undefined;

  return (
    <Component
      tracked={tracked}
      name={name}
      type={type}
      value={value}
      unchanged={unchanged}
      saved={saved}
      onChange={onChange}
      {...props}
    />
  );
};

export default TrackedFormInput;
