import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { DocumentButtonGroup, ThemedSegment } from 'components';

const StyledSegment = styled(ThemedSegment)`
  &&& {
    margin-bottom: 0.5rem;
    ${({ frosted }) => (frosted ? `margin-top: -5px;` : '')}
  }
`;

const DocumentButtonsSegment = ({
  clickables,
  viewersWarning,
  documentID,
  status,
  isValidForm,
  submitFunc,
  clearFunc,
  requiredFields,
  patternFields,
  filterSelection,
  printable,
  printed,
  print,
  selectedTemplate,
  exceptionURole,
  formData,
  hovering,
}) => (
  <StyledSegment
    textAlign="center"
    frosted={hovering}
    backgroundColor={selectedTemplate.backgroundColor}
    color={selectedTemplate.color}
  >
    {clickables && (
      <DocumentButtonGroup
        documentID={documentID}
        status={status}
        clickables={clickables}
        viewersWarning={viewersWarning}
        isValidForm={isValidForm}
        submitFunc={submitFunc}
        clearFunc={clearFunc}
        formData={formData}
        requiredFields={requiredFields}
        patternFields={patternFields}
        filterSelection={filterSelection}
        printable={printable}
        printed={printed}
        print={print}
        selectedTemplate={selectedTemplate}
        exceptionURole={exceptionURole}
      />
    )}
  </StyledSegment>
);

const mapStateToProps = state => {
  const { formData, printed } = state.formData;

  return {
    formData,
    printed,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(DocumentButtonsSegment)
);
