import React from 'react';

import Utils from 'core/utils/utils';
import { CONTENT_TYPE } from 'core/utils/constant';

import {
  DocumentTable,
  FormSection,
  History,
  ImagePreview,
  PageSegment,
  TrackingByAddress,
} from 'components';

import Embedded from '../components/Embedded';

const {
  title,
  iframe,
  agenda,
  table,
  map,
  cards,
  trackingByAddress,
  history,
  image,
  timeline,
} = CONTENT_TYPE;

const buildPage = (pc, area, module, currentUser, props) => {
  const { type, value, visible, sectionID, tableKey } = pc || {};
  const key = getContentKey(pc);

  const { backgroundColor, color } = props;

  const segmentProps = { backgroundColor, color };
  return sectionID && sectionID >= 0 ? (
    pc && visible ? (
      <PageSegment key={key} basic {...segmentProps}>
        <FormSection section={pc} {...props} />
      </PageSegment>
    ) : (
      <FormSection key={key} section={pc} {...props} />
    )
  ) : (
    <PageSegment
      key={key}
      embedding={[iframe, map].includes(type)}
      {...segmentProps}
    >
      {type === title ? (
        <h3>{value}</h3>
      ) : type === iframe ? (
        <Embedded title={value} url={value} compact />
      ) : [table, agenda, cards, map, timeline].includes(type) ? (
        <DocumentTable
          module={module}
          area={area}
          tableKey={tableKey}
          documentID={props.documentID}
          mask={type !== table ? type : undefined}
        />
      ) : type === trackingByAddress ? (
        Utils.canPerform(currentUser, 'trackbyaddress') ? (
          <TrackingByAddress area={area} />
        ) : (
          undefined
        )
      ) : type === history ? (
        props.histories ? (
          <History histories={props.histories} />
        ) : (
          undefined
        )
      ) : type === image ? (
        <ImagePreview url={value} />
      ) : (
        // default 'text'
        <p>{value}</p>
      )}
    </PageSegment>
  );
};

/*
 * key is pageContentID or section.key
 * because pageContentID might be duplicate with section.sectionID
 */
const getContentKey = _ct =>
  _ct.pageContentID > 0 ? _ct.pageContentID : _ct.key;

export { buildPage, getContentKey };
