import React from 'react';
import { Button } from 'semantic-ui-react';

import { FasterDropdown } from 'components';

const DownloadButton = ({
  disabled,
  loading,
  dropdownRef,
  name,
  icon,
  iconColor,
  color,
  downloadOptions,
  handleFetch,
  handleChange,
}) => {
  return (
    <Button.Group primary>
      <FasterDropdown
        dropdownRef={dropdownRef}
        className={`icon ${icon ? 'left' : 'right'} labeled button`}
        categorize
        fluid={false}
        disabled={disabled}
        loading={loading}
        icon={icon || 'caret down'}
        iconColor={iconColor}
        color={color}
        text={name}
        options={downloadOptions || []}
        onClick={handleFetch}
        onChange={handleChange}
      />
    </Button.Group>
  );
};

export default DownloadButton;
