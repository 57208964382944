import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Menu } from 'semantic-ui-react';
import styled from 'styled-components';

import QuickAccessContainer from './QuickAccessContainer';
import Tile from './Tile';

const TilesGrid = styled(Menu)`
  &.ui.text.menu {
    margin: 0;
  }
`;

const TilesScrollGrid = styled(Menu)`
  &.ui.text.menu {
    margin: 0;
    max-height: 100%;
    min-width: max-content;
  }
`;

const TilesPanel = ({ t, routes, scroll }) => {
  const Tiles = () => (
    <Grid as={scroll ? TilesScrollGrid : TilesGrid} text columns="equal">
      {routes.map(
        (
          {
            active,
            icon,
            iconColor,
            name,
            path,
            rotated,
            color,
            handleChangeTile,
          },
          index
        ) => (
          <Tile
            key={index}
            active={active}
            label={t(`common|${name}`)}
            scroll={scroll}
            path={path}
            icon={icon}
            iconColor={iconColor}
            rotated={rotated}
            color={color}
            handleChangeTile={handleChangeTile}
          />
        )
      )}
    </Grid>
  );

  return scroll ? (
    <QuickAccessContainer>
      <Tiles />
    </QuickAccessContainer>
  ) : (
    <Tiles />
  );
};

export default withTranslation()(TilesPanel);
