import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';

// TYPES / ACTIONS
const { Types, Creators } = createActions({
  updateTemplateStore: ['template'],
  updateSelectedTemplate: ['field', 'value'],
  updateTemplate: ['templateId', 'field', 'value'],
  addTemplate: ['template'],
  initTemplates: [],
  requestSelectedTemplate: [],
  selectTemplateById: ['templateId'],
});

export const TemplateCreators = Creators;
export const TemplateTypes = Types;

export const INITIAL_STATE = Immutable({
  templates: [],
  selectedTemplate: null,
});

// REDUCERS
export const initTemplates = (state = INITIAL_STATE, action) => ({
  ...state,
  templates: [],
  selectedTemplate: null,
});

export const requestSelectedTemplate = (state = INITIAL_STATE, action) => ({
  ...state,
  selectedTemplate: null,
});

export const selectTemplateById = (state = INITIAL_STATE, action) => {
  const { templateId } = action;

  const cloneTemplate = cloneDeep(
    _.find(state.templates, { templateId: parseInt(templateId) })
  );

  return {
    ...state,
    selectedTemplate: cloneTemplate,
  };
};

export const updateSelectedTemplate = (state = INITIAL_STATE, action) => {
  const { field, value } = action;
  const cloneTemplate = { ...state.selectedTemplate };

  cloneTemplate[field] = value;

  return {
    ...state,
    selectedTemplate: cloneTemplate,
  };
};

export const updateTemplate = (state = INITIAL_STATE, action) => {
  const { templateId, field, value } = action;
  const template = _.find(state.templates, {
    templateId: parseInt(templateId),
  });

  if (template) {
    template[field] = value;
  }

  return {
    ...state,
  };
};

export const addTemplate = (state = INITIAL_STATE, action) => {
  const { template } = action;
  const tmpTemplates = [...state.templates];

  tmpTemplates.push(template);

  return {
    ...state,
    templates: tmpTemplates,
  };
};

export const updateTemplateStore = (state = INITIAL_STATE, action) => {
  const { templates, selectedTemplate } = action.template;
  return { ...state, templates, selectedTemplate };
};

export const HANDLERS = {
  [Types.UPDATE_SELECTED_TEMPLATE]: updateSelectedTemplate,
  [Types.ADD_TEMPLATE]: addTemplate,
  [Types.INIT_TEMPLATES]: initTemplates,
  [Types.SELECT_TEMPLATE_BY_ID]: selectTemplateById,
  [Types.REQUEST_SELECTED_TEMPLATE]: requestSelectedTemplate,
  [Types.UPDATE_TEMPLATE]: updateTemplate,
  [Types.UPDATE_TEMPLATE_STORE]: updateTemplateStore,
};

export default createReducer(INITIAL_STATE, HANDLERS);

// OPERATIONS
export const TemplateOperations = {
  fetchTemplateDTO: template => dispatch => {
    dispatch(TemplateCreators.addTemplate({ ...template }));
  },
};

// SELECTORS
const selectorTemplates = state => state.template.templates;
const selectorSelectedTemplate = state => state.template.selectedTemplate;

export const getTemplates = createSelector(
  [selectorTemplates],
  templates => templates
);

export const getSelectedTemplate = createSelector(
  [selectorSelectedTemplate],
  selectedTemplate => selectedTemplate
);
