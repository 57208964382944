import _ from 'lodash';

const renderOptions = options => {
  return _.chain(options)
    .map(o => {
      return o.items
        ? { name: o.text, results: renderOptions(o.items) }
        : { title: o.key !== undefined && o.key !== null ? o.key : o.value };
    })
    .value();
};

const getOptions = (options, isCategory, allowAddition, value) => {
  // Add value of dropdown input even when not in list
  if (!isCategory) {
    if (!_.some(options, o => o.value === value) && value !== undefined) {
      options.push({ key: value, text: value, value: value, disabled: true });
    }

    if (allowAddition) {
      options.push({ key: '', value: '', text: '' });
    }
  } else {
    const itemsWithoutCategory = _.filter(options, o => {
      return o.items === null || o.items === undefined;
    });
    const categories = _.filter(options, o => {
      return o.items !== null && o.items !== undefined;
    });
    const itemsHasCategory = _.flatMap(categories, opt => opt.items);

    if (
      value !== undefined &&
      !_.some(itemsWithoutCategory, o => o.value === value) &&
      !_.some(itemsHasCategory, o => o.value === value)
    ) {
      options.push({ key: value, text: value, value: value, disabled: true });
    }
  }

  return renderOptions(options);
};

export default getOptions;
