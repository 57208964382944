import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import Utils from 'core/utils/utils';
import { getCurrentUser } from 'core/auth/authReducer';

const AdminRoute = ({ render: Component, currentUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Utils.canAccess(currentUser, 'admin') ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
});

export default connect(
  mapStateToProps,
  null
)(AdminRoute);
