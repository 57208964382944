import React from 'react';
import { withTranslation } from 'react-i18next';
import { Comment, Header } from 'semantic-ui-react';

import { ThemedHeader } from 'components';

import HistoryStatus from './components/HistoryStatus';

const History = ({ t, histories }) => (
  <Comment.Group minimal>
    <Header as={ThemedHeader} dividing>
      {t('common|History')}
    </Header>
    {histories.map(h => (
      <HistoryStatus key={h.historyID} t={t} h={h} />
    ))}
  </Comment.Group>
);

export default withTranslation()(History);
