import _ from 'lodash';

const getMaskRowText = (maskStructure, rowData) => {
  const { text: fieldKeysMatrix } = maskStructure;

  const textValues = fieldKeysMatrix
    ? _.map(fieldKeysMatrix, fieldKeysArray =>
        _.map(fieldKeysArray, fieldKey => rowData[fieldKey]).join(' ')
      )
    : _.values(rowData);

  return _.compact(textValues).join('\n');
};

export default getMaskRowText;
