import axios from 'axios';
import Utils from 'core/utils/utils';

const instance = axios.create({
  baseURL: 'https://pelias.prod.fasterlogistic.com/v1/',
});

const addressApi = {
  autocomplete: (data, params) =>
    instance.get(`autocomplete?${Utils.stringify(data)}`, params),
  search: (data, params) =>
    instance.get(`search?${Utils.stringify(data)}`, params),
};

export default addressApi;
