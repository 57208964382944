const equals = (filter, activeFilter, equalPositions) => {
  return (
    `${filter.templateId}` === activeFilter[0] &&
    `${filter.sectionID}` === activeFilter[1] &&
    `${filter.columnID}` === activeFilter[2] &&
    (equalPositions ? filter.columnPosition === activeFilter[3] : true)
  );
};

export default equals;
