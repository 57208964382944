import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import createRootReducer from './reducers';
import AuthService from 'core/auth/authService';

export const history = createBrowserHistory();

export default function configureStore(initialState) {
  const composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const enhancers = composeEnhancer(
    applyMiddleware(routerMiddleware(history), thunk)
  );

  const store = createStore(
    createRootReducer(history),
    initialState,
    enhancers
  );
  const persistor = persistStore(store);

  AuthService.init(store, persistor);

  return { store, persistor };
}
