import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Icon } from 'semantic-ui-react';
import { Views } from 'react-big-calendar';
import styled from 'styled-components';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { getDateFormatted } from 'core/utils/date';
import { ThemedButton } from 'components';
import FasterDropdown from '../../input/dropdown/fasterDropdownContainer';
import CalendarPicker from '../../input/calendar/CalendarPicker';
import { getMoment } from '../../../core/utils/date';

const { DAY, WEEK, MONTH } = Views;

const StyledGrid = styled(Grid)`
  &&& {
    margin-bottom: -0.5rem;
  }
`;

const StyledDiv = styled.div`
  display: flex;
`;

const StyledButton = styled(ThemedButton)`
  &&& {
    margin: 0;
  }
`;

const StyledDropdown = styled(FasterDropdown)`
  &&& {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

const prevnext = (view, oldDateString, next = false) => {
  const newMoment = getMoment(oldDateString);
  const increment = next ? 1 : -1;

  if (view === MONTH) {
    newMoment.month(newMoment.month() + increment);
  } else if (view === WEEK) {
    newMoment.week(newMoment.week() + increment);
  } else if (view === DAY) {
    newMoment.dayOfYear(newMoment.dayOfYear() + increment);
  }

  return getDateFormatted(newMoment.toDate());
};

const Toolbar = ({
  // state
  t,
  // props
  dataLoading,
  selectedDate,
  view,
  views,
  // func
  handleChangeDate,
  handleChangeView,
}) => {
  const onChangeDate = (e, { value }) => {
    handleChangeDate(value);
  };

  const onPrevious = () => {
    handleChangeDate(prevnext(view, selectedDate, false));
  };

  const onNext = () => {
    handleChangeDate(prevnext(view, selectedDate, true));
  };

  const label = getMoment(selectedDate).format(
    `${view === DAY ? 'DD ' : ''}MMM YYYY`
  );

  return (
    <StyledGrid stackable columns="equal" verticalAlign="middle">
      <Grid.Column floated="left">
        <StyledDiv>
          <StyledButton
            icon="left chevron"
            type="button"
            onClick={onPrevious}
          />
          <StyledDropdown
            value={view}
            options={_.map(views, v => ({
              key: v,
              text: t(`calendar|${Utils.toSentenceCase(v)}`),
              value: v,
            }))}
            onChange={(e, { value }) => handleChangeView(value)}
          />
          <StyledButton icon="right chevron" type="button" onClick={onNext} />
        </StyledDiv>
      </Grid.Column>
      <Grid.Column textAlign="center">
        {dataLoading && <Icon name="sync" loading />}
        {label}
      </Grid.Column>
      <Grid.Column floated="right" textAlign="right">
        <CalendarPicker
          fluid
          fallback
          value={selectedDate}
          onChange={onChangeDate}
        />
      </Grid.Column>
    </StyledGrid>
  );
};

export default withTranslation()(Toolbar);
