import React from 'react';
import { Form } from 'semantic-ui-react';
import _ from 'lodash';

import { Filter, ResponsiveButtonCore, ThemedSegment } from 'components';

import BatchTemplates from '../templates/BatchTemplates';

const BatchSearch = ({
  t,
  batchTemplates,
  batchTempOptions,
  selectedBatchFilterId,
  handleChangeBatchTemplate,
  filters,
  setFilters,
  bindReloadColOpts,
  funnel,
}) => (
  <>
    {!_.isEmpty(batchTemplates) && (
      <BatchTemplates
        t={t}
        value={selectedBatchFilterId}
        batchTempOptions={batchTempOptions}
        handleChangeBatchTemplate={handleChangeBatchTemplate}
      />
    )}
    <ThemedSegment>
      <Form>
        <Form.Field>
          <label>{t('common|Filters')}</label>
          <Filter
            advanced
            filters={filters}
            callbackFunc={setFilters}
            handleReloadColOpts={bindReloadColOpts}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveButtonCore
            primary
            icon="filter"
            type="button"
            labelPosition="right"
            onClick={funnel}
            disabled={_.some(filters, { columnID: -1 })}
            content={t('common|Select')}
          />
        </Form.Field>
      </Form>
    </ThemedSegment>
  </>
);

export default BatchSearch;
