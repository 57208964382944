import getMaskRowText from '../../mask/helpers/getMaskRowText';

const formToCard = (maskStructure, maskRow) => {
  const { key, data: rowData, color } = maskRow;

  // define title
  const title = getMaskRowText(maskStructure, rowData);

  return {
    title,
    key,
    color,
    row: maskRow,
  };
};

export default formToCard;
