import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Bold = styled.span`
  font-weight: 700;
  margin: 0 6px;
`;

const TableTotalPages = ({ activePage, totalPages }) => {
  const { t } = useTranslation();

  return (
    <>
      {t('Page')} <Bold>{activePage}</Bold> {t('out of')}{' '}
      <Bold>{totalPages}</Bold>
    </>
  );
};

const TableTotalRows = ({ totalItems }) => {
  const { t } = useTranslation();

  return (
    <>
      {t('Total of')} <Bold>{totalItems}</Bold> {t('form(s)')}
    </>
  );
};

export { TableTotalPages, TableTotalRows };
