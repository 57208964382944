import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

import { ColoredIcon } from 'components';

const applyTheme = ({ theme, primary, secondary, negative, positive }) => {
  return theme && !primary && !secondary && !negative && !positive;
};

const ThemedButton = styled(
  ({
    children,
    theme,
    color,
    icon,
    iconColor,
    content,
    labelPosition,
    ...props
  }) => {
    const iconPassedAsProp = icon && typeof icon === 'string';
    const iconPassedAsChild = icon && icon === true ? true : undefined;
    const defaultLabelPosition = labelPosition
      ? labelPosition
      : (iconPassedAsChild &&
          !(!content && !(Array.isArray(children) && children.length > 1))) ||
        (iconPassedAsProp && (content || children))
      ? 'left'
      : undefined;
    const hasLabelPosition = defaultLabelPosition ? true : false;

    return (
      <Button
        icon={iconPassedAsChild || iconPassedAsProp || hasLabelPosition}
        labelPosition={defaultLabelPosition}
        {...props}
      >
        {iconPassedAsProp && <ColoredIcon icon={icon} iconColor={iconColor} />}
        {content}
        {children}
      </Button>
    );
  }
)`
  ${({
    theme,
    theme: {
      color: { buttonBg, buttonFg, buttonBgHover, buttonFgHover },
    },
    color,
    ...props
  }) => `
    &&& {
      margin-top: 0.2em;
      margin-bottom: 0.2em;

      ${
        color
          ? `background-color: ${color}!important;`
          : applyTheme({ theme, ...props }) &&
            `background-color: ${buttonBg}!important;`
      }
      ${applyTheme({ theme, ...props }) ? `color: ${buttonFg}!important;` : ''}

      &:hover,
      &:active,
      &:focus {
        ${applyTheme({ theme, ...props }) &&
          `
            background-color: ${buttonBgHover}!important;
            color: ${buttonFgHover}!important;
          `}
      }
    }
  `}
`;

export { applyTheme };

export default ThemedButton;
