import BaseApi from 'api/baseApi';

const tableApi = {
  getAll: params => BaseApi.get('table', params),
  getNew: params => BaseApi.get('table/new', params),
  getTable: (tableKey, params) => BaseApi.get(`table/${tableKey}`, params),
  postTable: (table, params) => BaseApi.post('table', { table }, params),
  putTable: (table, params) =>
    BaseApi.put(
      'table',
      { table },
      { ...params, validErrors: [400, 401, 404, 409, 500] }
    ),
};

export default tableApi;
