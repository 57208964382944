import React from 'react';
import styled from 'styled-components';
import { Grid, GridColumn } from 'semantic-ui-react';

//import { ThemedButton, ThemedLabel } from 'components';

const HeaderCellMiddle = styled.div`
  display: flex;
  align-items: center;
`;

const RoundName = styled.span`
  text-transform: uppercase;
  margin-right: 5px;
`;

//const DriverName = styled.span`
//  font-weight: bold;

//  &:after {
//    content: '|';
//    font-weight: normal;
//    margin: 0 5px;
//  }
//`;

//const Volume = styled.span`
//  color: #999;
//`;

const RoundInfo = ({ data: { name } }) => {
  return (
    <Grid verticalAlign="middle" columns="equal">
      <GridColumn>
        <HeaderCellMiddle>
          <RoundName>{name}</RoundName>
        </HeaderCellMiddle>
      </GridColumn>
    </Grid>
  );
};

export default RoundInfo;
