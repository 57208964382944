import React from 'react';
import { Table } from 'semantic-ui-react';

import { FasterDropdown } from 'components';

import FilterTableOperatorSelect from '../operator-select/FilterTableOperatorSelect';

const FilterTableCellSelect = ({ disabled, options, value, onChange }) => (
  <Table.Cell>
    <FasterDropdown
      disabled={disabled}
      value={value ? `${value}` : ''}
      options={options}
      onChange={onChange}
    />
  </Table.Cell>
);

const FilterTableCellOperatorSelect = ({
  advanced,
  columns,
  fieldID,
  operator,
  handleChangeOperator,
}) =>
  advanced ? (
    <Table.Cell>
      <FilterTableOperatorSelect
        disabled={!fieldID ? true : undefined}
        operator={operator}
        columnType={
          fieldID && columns.some(c => fieldID === `${c.fieldID}`)
            ? columns.find(c => fieldID === `${c.fieldID}`).type
            : undefined
        }
        handleChangeOperator={handleChangeOperator}
      />
    </Table.Cell>
  ) : null;

export { FilterTableCellSelect, FilterTableCellOperatorSelect };
