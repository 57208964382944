import BaseApi from 'api/baseApi';

const appPreferenceApi = {
  post: (appPreference, params) =>
    BaseApi.post('apppreference', appPreference, params),
  put: (appPreference, property, params) =>
    BaseApi.put(`apppreference`, { property, ...appPreference }, params),
};

export default appPreferenceApi;
