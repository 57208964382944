import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, Dropdown, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { CONTENT_TYPE, DOWNLOAD_TYPE } from 'core/utils/constant';
import { getCurrentUser } from 'core/auth/authReducer';
import { useApi } from 'api/useApi';
import { useHub } from 'api/useHub';
import tableDataApi from 'api/tableData/tableDataApi';
import exportApi from 'api/export/exportApi';
import tableHub from 'api/table/tableHub';
import { SegmentMenu } from 'components';

import Table from './Table';
import { filterTableColumns } from '../helpers/filterTableColumns';
import {
  initializeTableCriteria,
  setCriterion,
} from '../helpers/tableCriteriaHelpers';
import Mask from '../../mask/maskContainer';
import MaskedComponent from '../../mask/MaskedComponent';

const { agenda, cards, map, timeline } = CONTENT_TYPE;
const { csv, xlsx } = DOWNLOAD_TYPE;

const StyledDivider = styled(Divider)`
  &&& {
    margin-top: 0;
    margin-bottom: 0.5rem;
    border: none !important;
  }
`;

const TableContainer = props => {
  const { t } = useTranslation();
  const {
    tableKey,
    table,
    token,
    ids,
    filters,
    dataType,
    dataKey,
    documentID,
    activeItem,
    setActiveItem,
    mask,
  } = props;

  // state
  const canRefetch = useRef(false);
  const [criteria] = useState(() => ({
    // do not replace by useRef https://github.com/facebook/react/issues/14490
    current: initializeTableCriteria(table, filters, mask),
  }));
  const [maskToggle, setMaskToggle] = useState(mask ? true : false);
  const [loading, setLoading] = useState(true);
  const [disableExportBtn, setDisableExportBtn] = useState(false);
  const [activeColumn, setActiveColumn] = useState(table.sortByColumnKey);
  const [direction, setDirection] = useState(
    table.ascendingSort ? 'ascending' : 'descending'
  );
  const [query, setQuery] = useState(
    tableDataApi.buildQuery(tableKey, table.type, criteria.current)
  );
  const [args, setArgs] = useState([
    query,
    ids,
    table,
    dataType,
    dataKey,
    documentID,
  ]);

  // hooks
  const reFetch = useCallback(() => {
    setQuery(query);
    setArgs([query, ids, table, dataType, dataKey, documentID]);
  }, [query, ids, table, dataType, dataKey, documentID]);

  useHub(tableHub, token, reFetch);

  const [fetchedData, pendingData] = useApi(tableDataApi.getMany, args, {
    tableData: undefined,
  });

  useEffect(() => {
    if (canRefetch.current) {
      reFetch();
    } else {
      canRefetch.current = true;
    }
  }, [reFetch]);

  useEffect(() => {
    if (pendingData) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [fetchedData, pendingData]);

  const handleRowClick = (item, actionable) => {
    setActiveItem([item, actionable]);
  };

  // trigger query
  const queryWithCriteria = useCallback(() => {
    setActiveItem([]);
    setQuery(tableDataApi.buildQuery(tableKey, table.type, criteria.current));
    setLoading(true);
  }, [tableKey, table, criteria, setActiveItem]);

  const handleExportData = async fileType => {
    setDisableExportBtn(true);

    const res = await exportApi.exportTo(
      fileType,
      tableKey,
      criteria.current,
      ids,
      documentID
    );

    if (res && res.fileUrl) {
      // open to save file
      Utils.openInNewTab(res.fileUrl);
      setDisableExportBtn(false);
    }
  };

  // update sorting
  const handleSort = clickedColumn => () => {
    // set sort direction
    const switchToDescending =
      activeColumn === clickedColumn && direction === 'ascending';
    const newDirection = switchToDescending ? 'descending' : 'ascending';
    setDirection(newDirection);

    // set sorted column
    criteria.current.orderByField = switchToDescending
      ? undefined
      : clickedColumn;
    criteria.current.orderByFieldDesc = switchToDescending
      ? clickedColumn
      : undefined;

    // set active column
    if (activeColumn !== clickedColumn) {
      setActiveColumn(clickedColumn);
    }

    // query
    queryWithCriteria();
  };

  // page change
  const handlePageChange = (e, { activePage }) => {
    // set page
    criteria.current.indexPage = activePage;
    // query
    queryWithCriteria();
  };

  // global search change
  const handleSearchAllChange = (e, column) => {
    // reset page
    criteria.current.indexPage = 1;
    // query
    criteria.current.searchAllByValue =
      column.value === '' ? undefined : column.value;
    queryWithCriteria();
  };

  // column filter change
  const handleSearchFieldChange = (e, column, preventSearch) => {
    // reset page
    criteria.current.indexPage = 1;

    // query
    if (!preventSearch) {
      setCriterion(criteria.current, column);
      queryWithCriteria();
    }
  };

  // mask filter change
  const handleUpdateCriteriaAndQuery = useCallback(
    criteriaObjects => {
      // update criteria
      _.forEach(criteriaObjects, criterion => {
        setCriterion(criteria.current, criterion);
      });

      // query
      queryWithCriteria();
    },
    [criteria, queryWithCriteria]
  );

  // hide or show mask or table
  const toggleMask = () => {
    setMaskToggle(!maskToggle);
  };

  const tableProp = filterTableColumns(table, mask);
  const dataProp = fetchedData.tableData;

  const iconByType = {
    [agenda]: 'calendar',
    [cards]: 'align justify',
    [map]: 'map',
    [timeline]: 'clock',
  };

  const textByType = {
    [agenda]: 'agenda',
    [cards]: 'cards',
    [map]: 'map',
    [timeline]: 'timeline',
  };

  const DropdownItems = () => (
    <>
      <Dropdown.Header>
        <Icon name="download" />
        {t('common|Download')}
      </Dropdown.Header>
      <Dropdown.Item onClick={() => handleExportData(csv)}>csv</Dropdown.Item>
      <Dropdown.Item onClick={() => handleExportData(xlsx)}>xlsx</Dropdown.Item>
    </>
  );

  const icon = maskToggle ? 'th' : iconByType[mask];
  const text = t(`See ${maskToggle ? 'table' : textByType[mask]}`);
  const onClick = toggleMask;
  const menuButtonProps = { icon, text, onClick };

  return (
    <>
      {mask && (
        <Mask
          {...props}
          type={mask}
          masked={!maskToggle}
          table={tableProp}
          data={dataProp}
          dataLoading={loading}
          updateCriteriaFunc={handleUpdateCriteriaAndQuery}
        />
      )}
      <MaskedComponent
        {...props}
        component={Table}
        masked={maskToggle}
        table={tableProp}
        data={dataProp}
        activeItem={activeItem}
        activeColumn={activeColumn}
        direction={direction}
        loading={loading}
        handleSearchAllChange={handleSearchAllChange}
        handleSort={handleSort}
        handleSearchFieldChange={handleSearchFieldChange}
        handlePageChange={handlePageChange}
        handleRowClick={handleRowClick}
        handleExportData={handleExportData}
        disableExportBtn={disableExportBtn}
      />
      <StyledDivider />
      {!dataType && (
        <SegmentMenu
          mainButtonProps={mask ? menuButtonProps : undefined}
          dropdownItems={DropdownItems}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  const { token /*, selectedFilters*/ } = getCurrentUser(state);

  return {
    token,
    //selectedFilters,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(TableContainer)
);
